import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function InsightIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M5.86667 21.8182H10.1333C10.1333 23.0182 9.17333 24 8 24C6.82667 24 5.86667 23.0182 5.86667 21.8182ZM3.73333 19.6364C3.73333 20.2364 4.21333 20.7273 4.8 20.7273H11.2C11.7867 20.7273 12.2667 20.2364 12.2667 19.6364C12.2667 19.0364 11.7867 18.5455 11.2 18.5455H4.8C4.21333 18.5455 3.73333 19.0364 3.73333 19.6364ZM16 10.3636C16 14.5309 13.1627 16.7564 11.9787 17.4545H4.02133C2.83733 16.7564 0 14.5309 0 10.3636C0 5.84727 3.584 2.18182 8 2.18182C12.416 2.18182 16 5.84727 16 10.3636ZM21.1947 8.04L19.7333 8.72727L21.1947 9.41455L21.8667 10.9091L22.5387 9.41455L24 8.72727L22.5387 8.04L21.8667 6.54545L21.1947 8.04ZM18.6667 6.54545L19.6693 4.29818L21.8667 3.27273L19.6693 2.24727L18.6667 0L17.664 2.24727L15.4667 3.27273L17.664 4.29818L18.6667 6.54545Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
