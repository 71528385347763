import { useTranslation } from 'react-i18next';
import { DOMAIN_URL, STATIC_DIR } from '../../constants/common';
import { useUser } from '../../context/UserContext';
import { useUserNoticeBoard } from '../../hooks/useUserNoiceBoard';
import { MenuData } from '../../model/header';
import SysConfigStore from '../../store/common/SysConfigStore';
import AlarmList from './AlarmList';
import MenuItem1Depth from './MenuItem1Depth';
import MenuItemDoa from './MenuItemDoa';

type Props = {
	openMenu: string | null;
	onToggle: (title: string) => void;
};

export default function HeaderRight({ openMenu, onToggle }: Props) {
	const { isModuleEnabled, isComponentEnabled, labelText } = SysConfigStore();
	const { t } = useTranslation();
	const { loginUser, isAdmin, isGroupAdmin, permission, isLawyer } = useUser();
	const doaList = loginUser?.DOAList || [];
	const { getUserNoticeBoardList } = useUserNoticeBoard();
	const { data: noticeBoardList } = getUserNoticeBoardList;

	const menus: MenuData[] = [
		// UI 페이지
		{
			title: 'UI',
			link: '/ui',
			classes: { button: 'p-2' },
			display: process.env.NODE_ENV === 'development',
		},
		// SaaS 공지사항
		{
			link: `${DOMAIN_URL}/manage/notice_list_view/`,
			title: t('MSG_ID_1'),
			classes: { button: 'p-2' },
			display: !isComponentEnabled('CMPNT171_CUSTOM_NOTICE_BOARD_BF'),
			isExternal: true,
		},
		// 다이나믹게시판 공지사항
		{
			link: `${DOMAIN_URL}/manage/get-notice-board-list-view/?announcement=1`,
			title: t('MSG_ID_1'),
			classes: { button: 'p-2' },
			display:
				isComponentEnabled('CMPNT171_CUSTOM_NOTICE_BOARD_BF') &&
				!isComponentEnabled('CMPNT174_HIDE_NOTICE_ANNOUNCEMENT_BF'),
			isExternal: true,
		},
		// 게시판
		{
			title: t('label_notice_board'),
			classes: { button: 'p-2' },
			display: permission.DISPLAY_NOTICE_BOARD,
			children: noticeBoardList
				? noticeBoardList.map(board => {
						return {
							link: `${DOMAIN_URL}/manage/get-notice-board-list-view/?board-id=${board.id}`,
							title: board.title,
							display: true,
							isExternal: true,
						};
				  })
				: [
						{
							title: t('notice_warn_there_is_no_notice_category_to_access'),
							display: true,
						},
				  ],
		},
		// 그룹관리자 메뉴
		{
			link: `${DOMAIN_URL}/manage/get_list_view_group_view/`,
			title: t('label_group_manager'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isGroupAdmin,
			isExternal: true,
		},
		// 관리자 드롭박스
		{
			title: t('MSG_ID_2'),
			classes: { button: 'p-2' },
			display: isAdmin,
			children: [
				// 계약 관리
				{
					title: t('MSG_ID_5'),
					display: true,
					children: [
						// 워크플로우
						{
							link: `${DOMAIN_URL}/admin/admin_workflow_list_view/`,
							title: t('MSG_ID_11'),
							display: true,
							isExternal: true,
						},
						// 템플릿
						{
							link: `${DOMAIN_URL}/admin/admin_template_list_view/`,
							title: t('MSG_ID_12'),
							display: true,
							isExternal: true,
						},
						// 디지털직인
						{
							link: `${DOMAIN_URL}/admin/admin_stamp_list_view/`,
							title: t('cmmn_label_digital_stamp'),
							display: true,
							isExternal: true,
						},
						// 검토자관리
						{
							link: `${DOMAIN_URL}/admin/admin_lawyer_list_view/`,
							title: t('cmmn_label_reviewer_management'),
							display: true,
							isExternal: true,
						},
						// 계약이관
						{
							link: `${DOMAIN_URL}/contract/switch_contractor_view_each_for_adele/`,
							title: t('MSG_ID_15'),
							display: true,
							isExternal: true,
						},
						// 인감
						{
							link: `${DOMAIN_URL}/admin/admin_seal_stamp_list_view/`,
							title: t('label_seal'),
							display: isModuleEnabled('SEAL_STAMP'),
							isExternal: true,
						},
						// 통계
						{
							link: `${DOMAIN_URL}/admin/admin_statistics_list_view/`,
							title: t('label_statistics'),
							display: isModuleEnabled('STATISTICAL_STATUS'),
							isExternal: true,
						},
						// 날인 담당자
						{
							link: `${DOMAIN_URL}/admin/admin_seal_manager_edit_view/`,
							title: t('MSG_ID_466'),
							display: isModuleEnabled('ENABLED_SEAL_MANAGER_EDIT'),
							isExternal: true,
						},
						// 서류관리
						{
							link: '/paperManage/list',
							title: t('paper_manage'),
							display: isComponentEnabled('CMPNT166_PAPER_MANAGE_BF'),
						},
						// 계약 상태변경
						{
							link: `${DOMAIN_URL}/admin/get_change_contract_status_view`,
							title: t('cmmn_label_change_contract_status'),
							display: true,
							isExternal: true,
						},
					],
				},
				// 법률자문
				{
					link: `${DOMAIN_URL}/admin/admin_counsel_view/`,
					title: t('MSG_ID_6'),
					display: isModuleEnabled('COUNSEL_MANAGE'),
					isExternal: true,
				},
				// 소송관리
				{
					link: `${DOMAIN_URL}/admin/admin_lawsuit/`,
					title: t('cmmn_label_litigation_management'),
					display: isModuleEnabled('APP_LAWSUIT'),
					isExternal: true,
				},
				// 공문관리
				{
					title: '공문관리',
					display: true,
					children: [
						{
							link: '/admin/officialLetter/setting',
							title: '공문 사용 설정',
							display: true,
						},
						{
							link: '/admin/officialLetter/template',
							title: '공문 서식 관리',
							display: true,
						},
						{
							link: '/admin/officialLetter/field',
							title: '공문 수집항목 관리',
							display: true,
						},
					],
				},
				// 프로젝트관리
				{
					link: `${DOMAIN_URL}/admin/admin_epic_view/`,
					title: t('cmmn_label_project_management'),
					display: isModuleEnabled('EPIC_MANAGE'),
					isExternal: true,
				},
				// 업체
				{
					title: t('MSG_ID_8'),
					display: true,
					isExternal: true,
					children: [
						// 외부업체
						{
							link: `${DOMAIN_URL}/admin/admin_external_company_list_view/`,
							title: t('cmmn_label_external_company'),
							display: true,
							isExternal: true,
						},
						{
							link: `${DOMAIN_URL}/admin/admin_external_lawfirm_list_view/`,
							title: t('label_external_lawfirm'),
							display: isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
							isExternal: true,
						},
						{
							link: `${DOMAIN_URL}/admin/admin_external_lawyer_list_view/`,
							title: t('label_external_lawyer'),
							display: isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
							isExternal: true,
						},
						// 법무법인
						{
							link: `${DOMAIN_URL}/admin/admin_external_lawfirm_list_view/`,
							title: t('MSG_ID_22'),
							display:
								isModuleEnabled('APP_LAWSUIT') &&
								!isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
							isExternal: true,
						},
						// 사외변호사
						{
							link: `${DOMAIN_URL}/admin/admin_external_lawyer_list_view/`,
							title: t('cmmn_label_outside_attorney'),
							display:
								isModuleEnabled('APP_LAWSUIT') &&
								!isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
							isExternal: true,
						},
						// 계열사 관리
						{
							link: `${DOMAIN_URL}/admin/admin_subsidiary_company_list_view/`,
							title: t('MSG_ID_24'),
							display: permission.ACTIVATE_SUBSIDIARY,
							isExternal: true,
						},
					],
				},
				// 라벨
				{
					link: `${DOMAIN_URL}/admin/admin_label_list/`,
					title: t('MSG_ID_9'),
					display: true,
					isExternal: true,
				},
				// 시스템관리
				{
					title: t('MSG_ID_10'),
					display: true,
					isExternal: true,
					children: [
						// 유저
						{
							link: `${DOMAIN_URL}/admin/admin_user_list_view/`,
							title: t('MSG_ID_17'),
							display: true,
							isExternal: true,
						},
						// 그룹
						{
							link: `${DOMAIN_URL}/admin/admin_group_list_view/`,
							title: t('MSG_ID_18'),
							display: true,
							isExternal: true,
						},
						// 보안등급
						{
							link: `${DOMAIN_URL}/admin/admin_external_lawyer_list_view/`,
							title: t('MSG_ID_684'),
							display: isModuleEnabled('SECURITY_GRADE_ENABLED'),
							isExternal: true,
						},
						// 회사정보
						{
							link: `${DOMAIN_URL}/admin/admin_company_info_view/`,
							title: t('cmmn_label_company_information'),
							display: true,
							isExternal: true,
						},
						// 권한위임
						{
							link: `${DOMAIN_URL}/admin/admin_emporwerment_view/`,
							title: t('MSG_ID_20'),
							display: isModuleEnabled('DOA'),
							isExternal: true,
						},
						// 공지사항 관리부서
						{
							link: `${DOMAIN_URL}/admin/admin_notice_manage_dept_view/`,
							title: t('label_notice_management_department'),
							display: isModuleEnabled('CUSTOM_NOTICE_MANAGE_BY_DEPT'),
							isExternal: true,
						},
						{
							link: `${DOMAIN_URL}/admin/admin_reviewer_satisfaction_ratings_view/`,
							title: t('cmmn_label_evaluate_legal_satisfaction'),
							display: isComponentEnabled('CMPNT141_REVIEWER_SATISFACTION_RATINGS_BF'),
							isExternal: true,
						},
						// 물리적 삭제
						{
							link: '/hardDelete',
							title: t('hard_delete'),
							display: isComponentEnabled('CMPNT172_HARD_DELETE_BF'),
							// isExternal: true,
						},
						// 게시판
						{
							link: `${DOMAIN_URL}/admin/admin_notice_board_creation_view/`,
							title: t('label_notice_board'),
							display: isComponentEnabled('CMPNT236_ADMIN_NOTICE_BOARD_BF'),
							isExternal: true,
						},
					],
				},
			],
		},
		// 권한위임
		{
			title: t('MSG_ID_20'),
			display: (isModuleEnabled('DOA') && doaList.length > 0) || loginUser.isDOA,
			classes: { root: 'flex', button: 'p-2' },
			component: <MenuItemDoa />,
		},
		// 알람
		{
			title: t('MSG_ID_84'),
			display: true,
			classes: { root: 'flex items-center' },
			component: <AlarmList />,
		},
		// 유저 프로필
		{
			title: ` ${loginUser.name} `,
			display: true,
			icon: <img src={`${STATIC_DIR}/images/user-profile.png`} alt='user' className='w-[40px]' />,
			classes: { button: 'p-2' },
			children: [
				// 내 정보 보기
				{
					link: `${DOMAIN_URL}/setup/mypage_info/`,
					title: t('MSG_ID_3'),
					display: true,
					isExternal: true,
				},
				// 로그아웃
				{
					link: '/logout',
					title: t('MSG_ID_4'),
					display: true,
				},
			],
		},
	];

	return menus
		.filter(({ display }) => display)
		.map(menu => (
			<MenuItem1Depth
				key={menu.title}
				itemData={menu}
				isOpen={openMenu === menu.title}
				onToggle={() => onToggle(menu.title)}
			/>
		));
}
