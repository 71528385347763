import { DocumentLoadEvent, PageChangeEvent, RenderPage, RenderPageProps, ZoomEvent } from '@react-pdf-viewer/core';
import { useQuery } from '@tanstack/react-query';
import { uniqueId } from 'lodash';
import { ChangeEvent, MouseEvent, SyntheticEvent, useCallback, useState } from 'react';
import { RndDragCallback, RndResizeCallback } from 'react-rnd';
import { AnyObject } from 'yup';
import TabsSign from '../../../components/sign/common/TabsSign';
import HeaderSign from '../../../components/sign/common/header/HeaderSign';
import HeaderSignAction from '../../../components/sign/common/header/HeaderSignAction';
import CardBox from '../../../components/ui/containers/CardBox';
import Container from '../../../components/ui/containers/Container';
import StickyBox from '../../../components/ui/containers/StickyBox';
import SignProvider from '../../../components/ui/signs/ProviderSign';
import SignRenderPage from '../../../components/ui/viewer/SignRenderPage';
import Viewer from '../../../components/ui/viewer/Viewer';
import { Sign } from '../../../model/sign';
import { useProviderPage } from '../ProviderPage';

export default function DetailPage() {
	const [signs, setSigns] = useState<Sign[]>([]);
	const [zoom, setZoom] = useState(1);
	const [pageInfo, setPageInfo] = useState({
		width: 0,
		height: 0,
	});
	const [goToPageIndex, setGoToPageIndex] = useState<number | null>(null);
	const [saveSize, setSaveSize] = useState(0);
	const [tabValue, setTabValue] = useState(0);

	const { data: pdfData } = useQuery(
		['test'],
		() =>
			fetch('/pdf/pdf_test.pdf')
				.then(response => response.arrayBuffer())
				.then(buffer => new Uint8Array(buffer)),
		{ staleTime: 360000 },
	);

	const { setPdf } = useProviderPage();

	const handleDragStop: RndDragCallback = useCallback(
		(e, data) => {
			if (!data) return;
			const {
				x: prevX,
				y: prevY,
				node: {
					dataset: { id },
				},
			} = data;

			setSigns(prev =>
				prev.map(sign => {
					const { id: signId } = sign;

					const x = prevX / zoom;
					const y = prevY / zoom;
					return signId !== id ? sign : { ...sign, x, y };
				}),
			);
		},
		[signs, zoom],
	);

	// 날인 이미지 사이즈 조정
	const handleResizeStop: RndResizeCallback = useCallback(
		(e, dir, elementRef, delta, position) => {
			if (!elementRef || !position) return;
			const {
				offsetWidth: width,
				offsetHeight: height,
				dataset: { id },
			} = elementRef;
			const { x, y } = position;

			setSigns(prev =>
				prev.map(sign => {
					const { id: signId } = sign;
					return signId !== id
						? sign
						: {
								...sign,
								width: width / zoom,
								height: height / zoom,
								x: x / zoom,
								y: y / zoom,
						  };
				}),
			);
		},
		[signs, zoom],
	);

	// 날인 리스트에서 계약서로 이미지 추가
	const handleDrop = useCallback(
		(item: AnyObject, dropResult: AnyObject) => {
			const { id, imageUrl, name, width, height, x: cursorX, y: cursorY } = item;
			const { id: pageIndex, viewerZoom, x: pageCursorX, y: pageCursorY, pageWidth, pageHeight } = dropResult;
			const x = pageCursorX - cursorX;
			const y = pageCursorY - cursorY;

			setZoom(viewerZoom);

			setPageInfo({
				width: pageWidth / viewerZoom,
				height: pageHeight / viewerZoom,
			});

			setSigns(prev => [
				...prev,
				{
					id: uniqueId(`${id}-`),
					name,
					pageIndex: Number(pageIndex),
					imageUrl,
					x: x / viewerZoom,
					y: y / viewerZoom,
					width: width / viewerZoom,
					height: height / viewerZoom,
				},
			]);
		},
		[signs, zoom],
	);

	// PDF 확대 축소
	const handleZoom = useCallback(
		(e: ZoomEvent) => {
			const { scale = 1 } = e;
			setZoom(scale);
		},
		[signs, zoom],
	);

	// 날인 이미지 입력으로 사이즈 조정
	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, sign: Sign) => {
			const {
				target: { value: v },
			} = e;
			const value = Number(v);
			const { id, width: imageWidth, height: imageHeight } = sign;
			const { width: pageWidth } = pageInfo;

			const minValue = value < 40 ? 40 : value;
			const width = minValue > pageWidth ? pageWidth : minValue;
			const height = (imageHeight * width) / imageWidth;

			setSigns(prev => {
				return [
					...prev.map(item =>
						item.id === id
							? ({
									...item,
									id: uniqueId(`${id.toString().split('-')[0]}-`),
									width,
									height,
							  } as Sign)
							: item,
					),
				];
			});
		},
		[signs, zoom],
	);

	// 날인 이미지 삭제
	const handleDeleteSign = useCallback(
		(e: MouseEvent<HTMLButtonElement>, signId: string, isAllDeleted: boolean = false) => {
			if (isAllDeleted) {
				const splitId = signId.split('-')[0];
				setSigns(prev => prev.filter(({ id }) => id.split('-')[0] !== splitId));
			}

			setSigns(prev => prev.filter(({ id }) => id !== signId));
		},
		[signs, zoom],
	);

	// 날인 이미지 사이즈 저장
	const handleSaveSize = useCallback(
		(e: MouseEvent<HTMLButtonElement>, sign: Sign) => {
			const { width } = sign;

			setSaveSize(width);
		},
		[signs, zoom],
	);

	// 날인 저장된 이미지 사이즈 불러오기
	const handleLoadSize = useCallback(
		(e: MouseEvent<HTMLButtonElement>, sign: Sign) => {
			const { id, width: imageWidth, height: imageHeight } = sign;
			const { width: pageWidth } = pageInfo;

			const width = Number(saveSize) > pageWidth ? pageWidth : Number(saveSize);
			const height = (imageHeight * width) / imageWidth;

			setSigns(prev => {
				return [
					...prev.map(item =>
						item.id === id
							? ({
									...item,
									id: uniqueId(`${id.toString().split('-')[0]}-`),
									width,
									height,
							  } as Sign)
							: item,
					),
				];
			});
		},
		[signs, zoom],
	);

	// PDF 페이지 렌더링
	const renderPage: RenderPage = useCallback(
		(props: RenderPageProps) => (
			<SignRenderPage
				// isClippable
				signs={signs}
				onDragStop={handleDragStop}
				onResizeStop={handleResizeStop}
				onDeleteSign={handleDeleteSign}
				onChange={handleChange}
				onSaveSize={handleSaveSize}
				onLoadSize={handleLoadSize}
				{...props}
			/>
		),
		[signs, zoom],
	);

	// PDF 페이지 사용자가 변경한 경우
	const handlePageChange = useCallback((e: PageChangeEvent) => setGoToPageIndex(null), []);

	// PDF 페이지 강제 이동
	const handleGoToPage = useCallback((e: AnyObject, pageIndex: number) => setGoToPageIndex(pageIndex), [signs, zoom]);

	// 날인 탭 이동
	const handleChangeTab = useCallback((_: SyntheticEvent, v: number) => setTabValue(v), [signs, zoom]);

	const handleDocumentLoad = useCallback(
		async (e: DocumentLoadEvent) => {
			const data = await e.doc.getData();
			if (pdfData) setPdf(data);
		},
		[pdfData],
	);

	return (
		<Container>
			<HeaderSign title='날인' onClick={() => {}} />
			<div>
				<SignProvider>
					<div className='flex gap-5'>
						<CardBox className='basis-8/12 p-5'>
							{pdfData && (
								<Viewer
									fileUrl={pdfData}
									renderPage={renderPage}
									onZoom={handleZoom}
									goToPageIndex={goToPageIndex}
									onPageChange={handlePageChange}
									onDocumentLoad={handleDocumentLoad}
								/>
							)}
						</CardBox>
						<div className='basis-4/12'>
							<StickyBox className='grid gap-4'>
								<HeaderSignAction signs={signs} />
								<TabsSign
									id='signature_list'
									signs={signs}
									value={tabValue}
									onChange={handleChangeTab}
									onDrop={handleDrop}
									onDeleteSign={handleDeleteSign}
									onGoToPage={handleGoToPage}
								/>
							</StickyBox>
						</div>
					</div>
				</SignProvider>
			</div>
		</Container>
	);
}
