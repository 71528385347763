import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function ThumbUpIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M13.0194 2.45787L7.06831 8.17399C6.67085 8.55575 6.44527 9.08197 6.44527 9.62882V19.9364C6.44527 21.0714 7.41206 22 8.59369 22H18.2616C19.121 22 19.8944 21.5047 20.2381 20.7515L23.7401 12.8996C24.6424 10.8566 23.0848 8.58671 20.7752 8.58671H14.7059L15.7264 3.8611C15.8339 3.34521 15.6727 2.81899 15.286 2.44755C14.6522 1.84911 13.6425 1.84911 13.0194 2.45787ZM2.14842 22C3.33005 22 4.29684 21.0714 4.29684 19.9364V11.6821C4.29684 10.5471 3.33005 9.6185 2.14842 9.6185C0.96679 9.6185 0 10.5471 0 11.6821V19.9364C0 21.0714 0.96679 22 2.14842 22Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
