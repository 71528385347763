import ButtonText from '../../ui/buttons/ButtonText';
import CircleIcon from '../../ui/icons/CircleIcon';

export default function AIReportHeader() {
	return (
		<header className='z-10 flex items-center gap-3 px-[30px] py-4 shadow-md bg-white'>
			<CircleIcon className='text-blue-600' />
			<p className='text-sm font-bold'>법틀 AI 계약서 분석</p>
			{window.opener && (
				<ButtonText
					size='small'
					variant='outlined'
					className='px-5 ml-auto'
					onClick={() => {
						window.close();
					}}
				>
					닫기
				</ButtonText>
			)}
		</header>
	);
}
