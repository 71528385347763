import { memo, useEffect, useMemo } from 'react';
import { PROCESS_NAME } from '../../../../constants/officialLetter';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import { IOfficialLetter, ISign } from '../../../../model/officialLetter';
import { useProviderPage } from '../../../../pages/admin/officialLetter/workflow/ProviderPage';
import PopupUsers from '../../../common/popups/PopupUsers';
import InputCheckbox from '../../../ui/inputs/InputCheckbox';
import InputRadio from '../../../ui/inputs/InputRadio';
import InputSelect from '../../../ui/inputs/InputSelect';

interface IProps {
	processId: string;
	data: ISign;
}

function ItemProcessSign({ processId, data }: IProps) {
	const { setOfficialLetter } = useProviderPage();
	const validationSchema = useMemo(() => {
		const optionals = Object.entries(data)
			.filter(([key]) => key === 'owner')
			.map(([key, value]) => [
				`${processId}_${key}_userAssignmentType`,
				{ initialValue: value.options.userAssignmentType, type: 'string' },
			]);

		return [
			...Object.entries(data)
				.filter(([key]) => key !== 'id' && key !== 'steps')
				.map(([key, value]) => {
					if (key === 'owner') return [`${processId}_${key}`, { initialValue: value.user, type: 'array' }];

					if (key === 'isAuthorizedToSign')
						return [`${processId}_${key}`, { initialValue: value, type: 'boolean' }];

					return [
						`${processId}_${key}`,
						{ initialValue: value === true ? '1' : '2', type: 'string', required: true },
					];
				}),
			...optionals,
		];
	}, [processId, data]);

	const formik = useValidateForm({
		validationSchema: Object.fromEntries(validationSchema),
		onSubmit: values => {
			const isEnabled = values[`${processId}_isEnabled`] === '1';
			const ownerUserAssignmentType = values[`${processId}_owner_userAssignmentType`];
			const ownerUser = ownerUserAssignmentType === '1' ? [] : values[`${processId}_owner`];
			const isAuthorizedToSign = values[`${processId}_isAuthorizedToSign`];

			setOfficialLetter(
				prev =>
					({
						...prev,
						process: prev.process.map(item => {
							if (item.id === processId)
								return {
									...item,
									isEnabled,
									isAuthorizedToSign,
									owner: {
										user: ownerUser,
										options: {
											userAssignmentType: ownerUserAssignmentType,
										},
									},
									steps: item.steps.map(step => {
										if (step.type === 'sign') {
											return {
												...step,
												options: {
													...step.options,
													isDeleted: !isEnabled,
												},
											};
										}

										return {
											...step,
										};
									}),
								};

							return {
								...item,
							};
						}),
					}) as IOfficialLetter,
			);
		},
	});

	useEffect(() => {
		formik.handleSubmit();
	}, [formik.values]);

	return (
		<>
			<p>{PROCESS_NAME[data.id]}</p>
			<div className='flex-1 grid grid-cols-2 gap-4 px-4 py-2 rounded-md bg-slate-50'>
				<div className='flex items-center'>
					<p>서명</p>
					<div className='flex'>
						<InputRadio
							id={`${processId}_isEnabled_1`}
							name={`${processId}_isEnabled`}
							value='1'
							label='필요'
							required
							formik={formik}
						/>
						<InputRadio
							id={`${processId}_isEnabled_2`}
							name={`${processId}_isEnabled`}
							value='2'
							label='불필요'
							required
							formik={formik}
						/>
					</div>
				</div>
				<div className='flex items-center gap-2'>
					<InputSelect
						id={`${processId}_owner_userAssignmentType`}
						label='유저 옵션'
						size='small'
						required
						options={[
							{ name: '유저 지정안함', value: '1' },
							{ name: '유저 지정(변경 허용)', value: '2' },
							{ name: '유저 지정(변경 허용안함)', value: '3' },
							{ name: '여러명 지정(변경 허용)', value: '4' },
						]}
						formik={formik}
					/>
					{formik.values[`${processId}_owner_userAssignmentType`] !== '1' && (
						<PopupUsers
							id={`${processId}_owner`}
							label='서명인'
							maxSelected={formik.values[`${processId}_owner_userAssignmentType`] === '4' ? 5 : 1}
							inputSize='small'
							formik={formik}
						/>
					)}
				</div>
				<div>
					<InputCheckbox
						id={`${processId}_isAuthorizedToSign`}
						label='기안자에게 서명인 권한 부여'
						formik={formik}
					/>
				</div>
			</div>
		</>
	);
}

export default memo(ItemProcessSign);
