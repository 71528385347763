import { memo, ReactNode } from 'react';
import CircleIcon from '../../../ui/icons/CircleIcon';

interface IProps {
	children: ReactNode;
}

function AIReportLeftChatStyleSection({ children }: IProps) {
	return (
		<article className='flex items-start gap-6'>
			<section className='shrink-0 flex items-start gap-2'>
				<CircleIcon className='-m-0.5 text-blue-600' />
				<p className='text-sm font-bold'>법틀 AI</p>
			</section>
			<section>{children}</section>
		</article>
	);
}

export default memo(AIReportLeftChatStyleSection);
