import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { FormikValues } from 'formik';
import _, { CollectionChain } from 'lodash';
import { ChangeEvent, MouseEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import { ITemplate } from '../../../../model/officialLetter';
import { IHeadCell, TOrder } from '../../../../model/table';
import FileChip from '../../../ui/chips/FileChip';
import SearchIcon from '../../../ui/icons/SearchIcon';
import InputSelect from '../../../ui/inputs/InputSelect';
import InputText from '../../../ui/inputs/InputText';
import TableFooter from '../../../ui/tables/TableFooter';
import TableListCell from '../../../ui/tables/TableListCell';
import TableListRow from '../../../ui/tables/TableListRow';
import TableSearchBar from '../../../ui/tables/TableSearchBar';
import ButtonTemplate from './ButtonTemplate';
import TableTemplateHeader from './TableTemplateHeader';

interface IProps {
	id: string;
	listType?: 'checkbox' | 'radio';
	list: ITemplate[];
	orderBy?: keyof ITemplate;
	headCells: readonly IHeadCell<ITemplate>[];
	formik: FormikValues;
	selectedFormik: FormikValues;
}

function TableTemplateList({ id: formikId, listType, list, orderBy: ob, headCells, formik, selectedFormik }: IProps) {
	const { t } = useTranslation();
	const [order, setOrder] = useState<TOrder>('asc');
	const [orderBy, setOrderBy] = useState(ob || undefined);
	const [selected, setSelected] = useState<readonly string[]>(
		formik.values[formikId].map((item: ITemplate) => item.id),
	);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const formikTableSearch = useValidateForm({
		validationSchema: {
			table_search_input: { initialValue: '', type: 'string' },
			table_search_select: { initialValue: orderBy || '', type: 'string' },
		},
	});

	const handleRequestSort = (e: MouseEvent<unknown>, property: keyof ITemplate) => {
		// console.log(property);
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
		if (!listType) return;
		if (event.target.checked) {
			const newSelected = list.map(n => n.id);
			setSelected(newSelected);
			selectedFormik.setFieldValue(formikId, list);
			return;
		}
		setSelected([]);
		selectedFormik.setFieldValue(formikId, []);
	};

	const handleClick = (e: MouseEvent<unknown>, id: string) => {
		if (!listType) return;
		const selectedIndex = _.indexOf(selected, id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) newSelected = _.concat(selected, id);
		else newSelected = _.concat(_.slice(selected, 0, selectedIndex), _.slice(selected, selectedIndex + 1));

		setSelected(newSelected);

		selectedFormik.setFieldValue(
			formikId,
			_.filter(list, obj => _.includes(newSelected, obj.id)),
		);
	};

	const handleChangePage = (e: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(e.target.value, 10));
		setPage(0);
	};

	// 검색하면 page, rowsPerPage 초기화
	const handleBeforeDebounceChange = () => {
		setRowsPerPage(10);
		setPage(0);
	};

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	// rows 양이 적을 경우 크기 조절
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

	// sort 조건
	const getSort = (
		_list: ITemplate[],
		_order: TOrder,
		_orderBy: keyof ITemplate | undefined,
	): CollectionChain<ITemplate> => {
		if (!_orderBy) return _.chain(_list);

		const result = _.chain(_list).sortBy(_orderBy);

		if (_order === 'desc') return result.reverse();

		return result;
	};

	// 현재 page 와 filter 에 맞는 rows slice
	const visibleRows = useMemo(() => {
		const orderArr = getSort(list, order, orderBy);

		const filterArr = orderArr.filter(item => {
			if (formikTableSearch.values.table_search_select === 'file') {
				return item[
					formikTableSearch.values.table_search_select as keyof Pick<ITemplate, 'file'>
				].name.includes(formikTableSearch.values.table_search_input);
			}

			return String(item[formikTableSearch.values.table_search_select as keyof ITemplate]).includes(
				formikTableSearch.values.table_search_input,
			);
		});

		return {
			count: filterArr.value().length,
			rows: filterArr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).value(),
		};
	}, [
		order,
		orderBy,
		page,
		rowsPerPage,
		formikTableSearch.values.table_search_input,
		formikTableSearch.values.table_search_select,
	]);

	return (
		<section className='flex flex-col'>
			<TableSearchBar numSelected={selected.length}>
				<div className='flex items-center gap-2 w-full'>
					<div className='min-w-[150px] rounded-sm bg-white'>
						<InputSelect
							id='table_search_select'
							label={t('search_option')}
							placeholder={t('select_search_option')}
							options={headCells.map(({ id, label }) => ({ name: label, value: id }))}
							required
							formik={formikTableSearch}
						/>
					</div>
					<div className='flex-1 rounded-sm bg-white'>
						<InputText
							id='table_search_input'
							icon={<SearchIcon />}
							labelText={`${
								headCells.filter(cell => cell.id === formikTableSearch.values.table_search_select)[0]
									?.label ?? ''
							} ${t('MSG_ID_637')}`}
							delay={500}
							formik={formikTableSearch}
							onBeforeDebounceChange={handleBeforeDebounceChange}
						/>
					</div>
				</div>
			</TableSearchBar>

			<TableContainer className='overflow-visible'>
				<Table size='medium' stickyHeader>
					<TableTemplateHeader
						headCells={headCells}
						numSelected={listType ? selected.length : 0}
						order={order}
						orderBy={orderBy}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={list.length}
					/>
					<TableBody>
						{visibleRows.rows.length === 0 && (
							<TableListRow>
								<TableListCell colSpan={headCells.length + 1} classes={{ root: 'text-center' }}>
									{t('label_no_search_results_exist')}
								</TableListCell>
							</TableListRow>
						)}
						{visibleRows.rows.length > 0 &&
							visibleRows.rows.map((row, index) => {
								const isItemSelected = isSelected(row.id);
								const labelId = `enhanced-table-checkbox-${index}`;

								return (
									<TableListRow
										hover
										onClick={e => handleClick(e, row.id)}
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.id}
										selected={listType && isItemSelected}
										sx={{ cursor: 'pointer' }}
									>
										<TableListCell align='left'>{row.title}</TableListCell>
										<TableListCell align='left'>{row.type}</TableListCell>
										<TableListCell align='left'>
											<FileChip key={row.file.id} text={row.file.name} size='small' disabled />
										</TableListCell>
										<TableListCell align='center'>{row.formatting}</TableListCell>
										<TableListCell align='center'>{row.inProgress}</TableListCell>
										<TableListCell align='center'>{row.completed}</TableListCell>
										<TableListCell align='center'>
											<ButtonTemplate
												text='미리보기'
												size='small'
												variant='outlined'
												color='inherit'
											/>
										</TableListCell>
										<TableListCell align='center'>
											<ButtonTemplate
												id={row.id}
												text='설정'
												size='small'
												variant='contained'
												color='primary'
											/>
										</TableListCell>
									</TableListRow>
								);
							})}
						{emptyRows > 0 && (
							<TableListRow
								style={{
									height: 53 * emptyRows,
								}}
							>
								<TableListCell colSpan={5} />
							</TableListRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TableFooter
				rowsPerPageOptions={[10, 20, 30, 50]}
				showFirstButton
				showLastButton
				count={visibleRows.count}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</section>
	);
}

export default memo(TableTemplateList);
