import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import InputText from '../../../ui/inputs/InputText';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import ButtonText from '../../../ui/buttons/ButtonText';
import { createUserArticleFavoriteList } from '../../../../api/ai';
import { queryKey } from '../../../../constants/queryKey';
import { IArticleFavoriteListItem } from '../../../../model/api/ai';
import PopoverDefault from '../../../ui/popovers/PopoverDefault';
import { ButtonOwnPropsOverride } from '../../../../model/button';

export default function AIArticleFavoriteListCreateButton(props: ButtonOwnPropsOverride) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setAnchorEl(e.currentTarget);
	};

	const queryClient = useQueryClient();

	const mutation = useMutation((name: string) => createUserArticleFavoriteList(name), {
		onMutate: async newName => {
			await queryClient.cancelQueries([queryKey.aiArticleFavoriteList]);

			const previousFavorites = queryClient.getQueryData([queryKey.aiArticleFavoriteList]);

			queryClient.setQueryData<IArticleFavoriteListItem[]>([queryKey.aiArticleFavoriteList], old => [
				...(old || []),
				{ id: Date.now(), name: newName },
			]);

			return { previousFavorites };
		},
		onError: (err, newFavorite, context) => {
			queryClient.setQueryData([queryKey.aiArticleFavoriteList], context?.previousFavorites);
		},
		onSettled: () => {
			queryClient.invalidateQueries([queryKey.aiArticleFavoriteList]);
		},
	});

	const createFormik = useValidateForm({
		validationSchema: {
			name: {
				initialValue: '',
				type: 'string',
				required: ' ',
			},
		},
		onSubmit: values => {
			mutation.mutate(values.name);
			setAnchorEl(null);
		},
	});

	return (
		<>
			<ButtonText className='justify-start px-8 py-4' fullWidth {...props} onClick={e => handleClick(e)}>
				{props?.children}
			</ButtonText>
			<PopoverDefault
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'center', vertical: 'top' }}
			>
				<form
					onSubmit={e => {
						e.preventDefault();
						createFormik.handleSubmit();
					}}
					className='flex flex-col gap-3 p-4'
				>
					<p>새 목록 만들기</p>
					<InputText id='name' placeholder='목록명' formik={createFormik} size='small' />
					<div className='flex gap-3'>
						<ButtonText variant='outlined' className='flex-1' onClick={() => createFormik.handleSubmit()}>
							확인
						</ButtonText>
						<ButtonText variant='outlined' className='flex-1' onClick={() => setAnchorEl(null)}>
							취소
						</ButtonText>
					</div>
				</form>
			</PopoverDefault>
		</>
	);
}
