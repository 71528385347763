import React, { createContext, useContext, useMemo, useState } from 'react';

type ExpandType = 'SEEN' | 'SAVED' | null;

interface AIArticleSideContext {
	expand: ExpandType;
	setExpand: React.Dispatch<React.SetStateAction<ExpandType>>;
}

const AIArticleSideContext = createContext<AIArticleSideContext | undefined>(undefined);

type Props = {
	children: React.ReactNode;
};

export function AIArticleSideProvider({ children }: Props) {
	const [expand, setExpand] = useState<ExpandType>(null);

	const value = useMemo(() => ({ expand, setExpand }), [expand]);

	return <AIArticleSideContext.Provider value={value}>{children}</AIArticleSideContext.Provider>;
}

export function useAIArticleSide() {
	const context = useContext(AIArticleSideContext);
	if (context === undefined) {
		throw new Error('AIArticleSide must be used within a PaperManageProvider');
	}

	return context;
}
