import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getDashboardProgressCount } from '../../../api/dashboard';
import { contractSearchParams } from '../../../constants/adeleSearchParams';
import { contractListUrl } from '../../../constants/adeleUrl';
import { DASHBOARD_PROGRESS_GROUP_COLOR_MAP } from '../../../constants/common';
import { DASHBOARD_CONTRACT_PROGRESS_MAP } from '../../../constants/legalStatus';
import { queryKey } from '../../../constants/queryKey';
import { useUser } from '../../../context/UserContext';
import { DashboardContractProgress, DashboardContractProgressMap } from '../../../model/common';
import { navigateToAdele } from '../../../util/commonUtils';
import ButtonText from '../../ui/buttons/ButtonText';
import CardBox from '../../ui/containers/CardBox';

// TODO: 사용 고객사 늘어나면 컨피그에 따른 목록 노출 작업 및 라벨 텍스트 처리 필요
export default function ContractCountDashboard() {
	const { permission } = useUser();
	const { t } = useTranslation();
	const { data: progressCountMap } = useQuery(queryKey.dashboardProgressCount, getDashboardProgressCount, {
		staleTime: 60000,
	});

	const statusTextMap: Record<DashboardContractProgress, string> = {
		CONTRACT_DRAFT: t('label_draft'),
		CONTRACT_WAIT_DEPT_APPROVAL: t('label_waiting_for_approval_of_department_heads'),
		CONTRACT_DEPT_REJECTED: t('label_response_to_the_head_of_the_department_head'),
		CONTRACT_LEGAL_RE_REVIEW: t('label_re_crafted'),
		CONTRACT_NEW_CONTRACT_WRITING: t('contract_guide_writing_a_new_contract'),
		CONTRACT_WAIT_ASSIGN_REVIEWER: t('label_wait_for_the_reviewer'),
		CONTRACT_REVIEW: t('MSG_ID_28'),
		CONTRACT_WAIT_FINAL_APPROVAL: t('label_final_approval_waiting'),
		CONTRACT_NEGO: t('cmmn_guide_negotiating'),
		CONTRACT_RESERVED_SENDING: t('label_shipment_reservation'),
		CONTRACT_RESERVED_SENDING_RUNNING: t('cmmn_guide_sending_schedule_running'),
		CONTRACT_RESERVED_SENDING_FAILED: t('cmmn_error_shipment_reservation_failed'),
		CONTRACT_WAIT_APPROVAL: t('cmmn_label_waiting_for_payment'),
		CONT_STS_WF_SIGN: t('cmmn_label_waiting_for_payment'),
		CONT_STS_WF_SEAL_PROCESS: t('cmmn_label_waiting_for_payment'),
		CONTRACT_WAIT_SIGN_AND_SEAL: t('MSG_ID_1319'),
		CONTRACT_WAIT_REG_SCAN_DOC: t('label_scan_registration_waiting'),
		CONTRACT_WAIT_RECOVER: t('label_original_collection_waiting'),
		CONTRACT_WAIT_COMPLETE: t('label_complete_atmosphere'),
		CONTRACT_COMPLETE: t('MSG_ID_54'),
		CONTRACT_REJECT_AND_CANCEL: t('MSG_ID_47'),
	};

	const entries = Object.entries(DASHBOARD_CONTRACT_PROGRESS_MAP) as [
		keyof DashboardContractProgressMap,
		DashboardContractProgress[],
	][];

	const handleNavigate = (progressGroup: keyof DashboardContractProgressMap, status: DashboardContractProgress) => {
		sessionStorage.setItem(
			'contract_search_param',
			JSON.stringify({
				...contractSearchParams,
				status_check: `PROGRESS_STS_${progressGroup}`,
				progress_status: status,
			}),
		);

		navigateToAdele(contractListUrl);
	};

	return (
		<CardBox className={`${permission.DISPLAY_COUNSEL ? 'basis-7/12' : 'w-full'} flex flex-row gap-5 p-6`}>
			<p className='text-lg font-bold pr-5 whitespace-nowrap text-ellipsis'>
				{t('contract_label_current_status')}
			</p>
			{entries.map(([progressGroup, statusList]) => (
				<div key={progressGroup} className='flex flex-col flex-grow gap-2'>
					<div
						className='h-2'
						style={{
							backgroundColor: DASHBOARD_PROGRESS_GROUP_COLOR_MAP[progressGroup],
						}}
					/>
					<div
						className='flex grid gap-2'
						style={{
							gridAutoFlow: 'column',
							gridTemplateRows: `repeat(4, minmax(0, 1fr))`,
						}}
					>
						{statusList.map((status: DashboardContractProgress) => (
							<ButtonText
								className='justify-start p-1 whitespace-nowrap text-ellipsis overflow-hidden'
								key={status}
								onClick={() => handleNavigate(progressGroup, status)}
							>
								<div className='flex flex-col text-start' title={statusTextMap[status]}>
									<p className='text-sm font-bold'>{statusTextMap[status]}</p>
									<p className='text-sm'>
										{`${progressCountMap?.[status] ?? 0}${t('label_number_of_cases')}`}
									</p>
								</div>
							</ButtonText>
						))}
					</div>
				</div>
			))}
		</CardBox>
	);
}
