import { create } from 'zustand';
import { LABEL_TEXT_MAP, LabelTextKeys } from '../../constants/labelText';
import { EVALUATION_DEFAULT_ITEM } from '../../model/api/external_lawyer';

interface SystemConfig {
	sysCompanyConfigs: any;
	sysCompanyComponents: any;
	setSysCompanyConfigs: (sysCompanyConfigs: any) => void;
	setSysCompanyComponents: (sysCompanyComponents: any) => void;
	isModuleEnabled: (moduleName: string) => boolean;
	isComponentEnabled: (componentName: string) => boolean;
	// labelTextSettings: () => Record<LabelTextKeys, string> | null;
	labelText: (key: LabelTextKeys) => string;
	getEvaluationItems: () => any;
	getSysConfigValue: (keyName: string) => any;
}

const SysConfigStore = create<SystemConfig>((set, get) => ({
	sysCompanyConfigs: null,
	sysCompanyComponents: null,
	setSysCompanyConfigs: configs => {
		set({ sysCompanyConfigs: configs });
		sessionStorage.setItem('configData', JSON.stringify(configs));
	},
	setSysCompanyComponents: components => {
		set({ sysCompanyComponents: components });
	},
	isModuleEnabled: moduleName => {
		const configs = get().sysCompanyConfigs;
		if (configs !== null) {
			const moduleNames = configs.MODULES.replaceAll('\r\n', '').replaceAll(' ', '');
			return moduleNames.includes(moduleName.toUpperCase());
		}
		return false;
	},
	isComponentEnabled: componentName => {
		const components = get().sysCompanyComponents;

		if (components === null || !components.COMPONENTS.length) return false;

		const componentNames = components.COMPONENT.replaceAll('\r\n', '').replaceAll(' ', '');
		return componentNames.includes(componentName.toUpperCase());
	},
	labelTextSettings: (): Record<LabelTextKeys, string> | null => {
		const labelTextDict = get().sysCompanyConfigs?.LABEL_TEXT_DICT;
		try {
			return JSON.parse(
				labelTextDict!
					.replace(/(\w+)(\s*:\s*)/g, '"$1"$2')
					.replace(/:\s*([^",\s]+)/g, ': "$1"')
					.replace(/,\s*}/g, '}'),
			);
		} catch {
			return null;
		}
	},
	labelText: (key: LabelTextKeys) => {
		// const labelTextSettings = get().labelTextSettings(); // 파싱 오류가 있어 주석 처리
		const configs = get().sysCompanyConfigs;

		if (!configs || !configs.LABEL_TEXT_DICT) return LABEL_TEXT_MAP[key];

		return configs.LABEL_TEXT_DICT[key] ? configs.LABEL_TEXT_DICT[key] : LABEL_TEXT_MAP[key];
	},
	getEvaluationItems: () => {
		const configs = get().sysCompanyConfigs;
		const evaluationConfigs = configs.EXTERNAL_LAWYER_EVALUATION_ITEMS;
		let itemObj = EVALUATION_DEFAULT_ITEM;

		if (configs !== null && evaluationConfigs) {
			try {
				itemObj = JSON.parse(evaluationConfigs.replaceAll('\r\n', '').replaceAll("'", '"'));
			} catch (error) {
				itemObj = EVALUATION_DEFAULT_ITEM;
			}
		}

		const itemsArray = Object.keys(itemObj).map(key => {
			return {
				key,
				...itemObj[key],
			};
		});

		itemsArray.sort((a, b) => {
			return a.ordering_value - b.ordering_value;
		});

		return itemsArray;
	},
	getSysConfigValue: keyName => {
		const configs = get().sysCompanyConfigs;
		if (configs !== null) {
			return configs[keyName].replaceAll('\r\n', '').replaceAll(' ', '');
		}
		return '';
	},
}));

export default SysConfigStore;
