import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { dndItemTypes } from '../../../../constants/dndType';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import ButtonText from '../../../ui/buttons/ButtonText';
import ClearIcon from '../../../ui/icons/ClearIcon';
import DragHandleIcon from '../../../ui/icons/DragHandleIcon';
import InputCheckbox from '../../../ui/inputs/InputCheckbox';
import InputSelect from '../../../ui/inputs/InputSelect';
import InputText from '../../../ui/inputs/InputText';

interface IProps {
	id: string;
	text: string;
	moveItem: (id: string | number, to: number) => void;
	findItem: (id: string | number) => { index: number };
	deleteItem: (id: string | number) => void;
}

interface Item {
	id: string;
	originalIndex: number;
}

function ItemField({ id, text, moveItem, findItem, deleteItem }: IProps) {
	const originalIndex = findItem(id).index;

	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: dndItemTypes.field,
			item: { id, originalIndex },
			collect: monitor => ({
				isDragging: monitor.isDragging(),
			}),
			end: (item, monitor) => {
				const { id: droppedId, originalIndex: index } = item;
				const didDrop = monitor.didDrop();
				if (!didDrop) {
					moveItem(droppedId, index);
				}
			},
		}),
		[id, originalIndex, moveItem],
	);

	const [, drop] = useDrop(
		() => ({
			accept: dndItemTypes.field,
			hover({ id: draggedId }: Item) {
				if (draggedId !== id) {
					const { index: overIndex } = findItem(id);
					moveItem(draggedId, overIndex);
				}
			},
		}),
		[findItem, moveItem],
	);

	const opacity = isDragging ? 0 : 1;

	const formik = useValidateForm({
		validationSchema: {
			[`${id}-doc-type`]: { initialValue: 'all', type: 'string' },
			[`${id}-title`]: { initialValue: text, type: 'string' },
			[`${id}-description`]: { initialValue: '', type: 'string' },
			[`${id}-type`]: { initialValue: '', type: 'string' },
			[`${id}-required`]: { initialValue: false, type: 'boolean' },
		},
	});

	return (
		<div className='grid grid-cols-10 items-center gap-4' style={{ opacity }}>
			<div>
				<InputSelect
					id={`${id}-doc-type`}
					label='문서 종류'
					placeholder='문서 종류를 선택하세요.'
					options={[
						{ name: '전체', value: 'all' },
						{ name: '공문서', value: '1' },
						{ name: '등기', value: '2' },
						{ name: '내용증명', value: '3' },
						{ name: '확약서', value: '4' },
						{ name: '양해각서', value: '5' },
						{ name: '경고장', value: '6' },
						{ name: '동의서', value: '7' },
					]}
					required
					formik={formik}
				/>
			</div>
			<div className='col-span-3'>
				<InputText id={`${id}-title`} labelText='항목명' placeholder='항목명을 입력하세요.' formik={formik} />
			</div>
			<div className='col-span-2'>
				<InputText
					id={`${id}-description`}
					labelText='안내문구'
					placeholder='안내문구를 입력하세요.'
					formik={formik}
				/>
			</div>
			<div className='col-span-2'>
				<InputSelect
					id={`${id}-type`}
					label='항목 종류'
					placeholder='항목 종류를 선택하세요.'
					options={[
						{ name: '한줄 입력 (텍스트)', value: '1' },
						{ name: '숫자 입력 (숫자만)', value: '2' },
						{ name: '여러줄 입력 (텍스트 여러줄)', value: '3' },
						{ name: '파일 업로드', value: '4' },
						{ name: '선택  예 / 아니오 (라디오 버튼)', value: '5' },
					]}
					required
					formik={formik}
				/>
			</div>
			<div>
				<InputCheckbox id={`${id}-required`} label='필수' formik={formik} />
			</div>
			<div className='flex items-center justify-between'>
				<div ref={node => drag(drop(node))} className='cursor-move'>
					<DragHandleIcon />
				</div>
				<div>
					<ButtonText className='min-w-0' onClick={() => deleteItem(id)}>
						<ClearIcon />
					</ButtonText>
				</div>
			</div>
		</div>
	);
}

export default memo(ItemField);
