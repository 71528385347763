import { throttle } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';

type TPosition = 'top' | 'bottom' | 'top-bottom';
interface IProps {
	className?: string;
	position?: TPosition;
	children: ReactNode;
}

const getStickyStyles = (position: TPosition, direction: 'up' | 'down' | null) => {
	const top = 'top-0 self-start';
	const bottom = 'bottom-0 self-end';
	let styles = top;

	switch (position) {
		case 'top':
			styles = top;
			break;
		case 'bottom':
			styles = bottom;
			break;
		case 'top-bottom':
			styles = direction === 'up' ? 'top-0 self-start' : 'bottom-0 self-end';
			break;
		default:
	}

	return styles;
};

export default function StickyBox({ className = '', position = 'top', children }: IProps) {
	const [direction, setDirection] = useState<'up' | 'down' | null>(null);

	useEffect(() => {
		let lastScrollY = window.scrollY;
		const handleScroll = () => {
			const currentScrollY = window.scrollY;

			if (currentScrollY > lastScrollY) {
				setDirection('down');
			} else if (currentScrollY < lastScrollY) {
				setDirection('up');
			}
			lastScrollY = currentScrollY;
		};

		const debouncedHandleScroll = throttle(handleScroll, 100);
		window.addEventListener('scroll', debouncedHandleScroll);

		return () => {
			window.removeEventListener('scroll', debouncedHandleScroll);
		};
	}, []);
	return (
		<section className='grid h-full'>
			<div className={`sticky ${getStickyStyles(position, direction)} ${className}`}>{children}</div>
		</section>
	);
}
