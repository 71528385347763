import { InputLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deletePapaerManage, downloadPaperManageFile } from '../../../api/paperManage';
import usePaperManage from '../../../hooks/usePaperManage';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { SavedFile } from '../../../model/file';
import { User } from '../../../model/user';
import SubsidiarySelector from '../../common/SubsidiarySelector';
import PopupUsers from '../../common/popups/PopupUsers';
import ButtonText from '../../ui/buttons/ButtonText';
import CardBox from '../../ui/containers/CardBox';
import WarningIcon from '../../ui/icons/WarningIcon';
import InputFile from '../../ui/inputs/InputFile';
import InputSwitch from '../../ui/inputs/InputSwitch';
import InputText from '../../ui/inputs/InputText';
import PopupCommonInternal from '../../ui/popups/PopupCommonInternal';
import InputSealStamp from '../sealStamp/InputSealStamp';
import PaperTypeSelector from './PaperTypeSelector';

type Props = {
	objId: number;
};
export default function DetailInfoPaperManage({ objId }: Props) {
	const { t } = useTranslation();
	const { getPaperManageDetailHook, postPaperManageDetail, resetList } = usePaperManage();
	const { data: detailInfo } = getPaperManageDetailHook(objId);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isPaperCount, setIsPaperCount] = useState(false);
	const formik = useValidateForm({
		validationSchema: {
			id: { initialValue: -1, type: 'number', required: true },
			title_paper_manage_detail: { initialValue: '', type: 'string', required: true },
			viewcode_paper_manage_detail: { initialValue: '', type: 'string' },
			type_paper_manage_detail: { initialValue: '', type: 'string', required: true },
			description_paper_manage_detail: { initialValue: '', type: 'string' },
			subsidiary_paper_manage_detail: { initialValue: '', type: 'string' },
			users_list_paper_manage_detail: { initialValue: [], type: 'array' },
			file_list_paper_manage_detail: { initialValue: [], type: 'array' },
			is_paper_count_paper_manage_detail: { initialValue: false, type: 'boolean' },
			paper_count_paper_manage_detail: { initialValue: 0, type: 'number' },
			is_deactive_paper_manage_detail: { initialValue: false, type: 'boolean' },
			handle_active_time_and_user_name: { initialValue: '', type: 'string' },
			seal_stamp_paper_manage_detail: { initialValue: [], type: 'array' },
		},
		onSubmit: values => {
			postPaperManageDetail({
				id: values.id,
				title: values.title_paper_manage_detail,
				paperType: values.type_paper_manage_detail,
				subsidiaryId: values.subsidiary_paper_manage_detail,
				description: values.description_paper_manage_detail,
				isActive: !values.is_deactive_paper_manage_detail,
				isPaperCount: values.is_paper_count_paper_manage_detail,
				paperCount: values.paper_count_paper_manage_detail,
				userIds: values.users_list_paper_manage_detail.map((user: User) => user.id),
				fileIds: values.file_list_paper_manage_detail.map((file: SavedFile) => file.id), // 기존 파일용
				fileList: values.file_list_paper_manage_detail, // 새로 추가된 파일용
				sealStamp: values.seal_stamp_paper_manage_detail.length
					? values.seal_stamp_paper_manage_detail[0].seal_stamp_id
					: '',
			});
			setIsEditMode(false);
		},
	});

	const initFormik = () => {
		if (detailInfo) {
			formik.setFieldValue('id', detailInfo.id);
			formik.setFieldValue('title_paper_manage_detail', detailInfo.title);
			formik.setFieldValue('description_paper_manage_detail', detailInfo.description);
			formik.setFieldValue('subsidiary_paper_manage_detail', detailInfo.subsidiaryId);
			formik.setFieldValue('type_paper_manage_detail', detailInfo.paperType);
			formik.setFieldValue('users_list_paper_manage_detail', detailInfo.userIds);
			formik.setFieldValue('file_list_paper_manage_detail', detailInfo.fileList);
			formik.setFieldValue('is_paper_count_paper_manage_detail', detailInfo.isPaperCount);
			formik.setFieldValue('paper_count_paper_manage_detail', detailInfo.paperCount);
			formik.setFieldValue('is_deactive_paper_manage_detail', !detailInfo.isActive);
			formik.setFieldValue('seal_stamp_paper_manage_detail', detailInfo.sealStamp ? [detailInfo.sealStamp] : []);
			formik.setFieldValue(
				'handle_active_time_and_user_name',
				detailInfo.handleActiveUsers?.length
					? `${detailInfo.handleActiveTime}, ${detailInfo.handleActiveUsers[0].name}`
					: '',
			);
		}
	};
	useEffect(() => {
		initFormik();
	}, [detailInfo]);
	useEffect(() => {
		setIsPaperCount(formik.values.is_paper_count_paper_manage_detail);
	}, [formik.values.is_paper_count_paper_manage_detail]);
	const cancelEdit = () => {
		setIsEditMode(false);
		initFormik();
	};
	const fileButton = (
		<ButtonText
			classes={{ root: 'min-w-0 py-0 font-bold border-2 border-gray-300 rounded-2xl' }}
			text='+'
			color='inherit'
			variant='outlined'
		/>
	);
	const navigate = useNavigate();
	const delBtn = <ButtonText color='inherit' variant='contained' text={t('MSG_ID_124')} />;
	const deletePaperManageConfirm = () => {
		deletePapaerManage(objId);
		resetList();
		navigate(`/paperManage/list`, {});
	};
	return (
		<section className='flex flex-col gap-6 px-6 pt-14'>
			<header className='flex flex-col gap-6'>
				<div className='flex flex-col items-center justify-between gap-6 lg:flex-row'>
					<h2 className='text-2xl'>
						{detailInfo &&
							(isEditMode ? (
								<InputText id='title_paper_manage_detail' formik={formik} />
							) : (
								detailInfo.title
							))}
					</h2>
					<div className='flex items-center gap-5'>
						{!isEditMode && (
							<PopupCommonInternal
								openTrigger={delBtn}
								icon={<WarningIcon />}
								msg={t('waring_delete_msg')}
								confirmClose
								onConfirm={deletePaperManageConfirm}
								onCancel={() => false}
							/>
						)}
						{!isEditMode && (
							<ButtonText
								text={t('MSG_ID_1285')}
								color='primary'
								variant='contained'
								onClick={() => {
									setIsEditMode(true);
								}}
							/>
						)}
						{isEditMode && (
							<ButtonText
								text={t('MSG_ID_105')}
								color='inherit'
								variant='contained'
								onClick={cancelEdit}
							/>
						)}
						{isEditMode && (
							<ButtonText
								text={t('save_btn_msg')}
								color='primary'
								variant='contained'
								onClick={() => {
									formik.handleSubmit();
								}}
							/>
						)}
					</div>
				</div>
			</header>
			<CardBox className='flex flex-col gap-2 p-6'>
				<h3 className='text-xl font-bold'>{t('detail_info')}</h3>
				<div className='flex flex-row'>
					<div className='flex-1 pr-8'>
						<div className='flex items-center gap-2 w-full pb-2 pt-2 border-b border-b-zinc-200'>
							<div className='basis-1/2'>
								<InputLabel id='label_viewcode_paper_manage_detail'>
									{t('paper_manage_viewcode')}
								</InputLabel>
							</div>
							<div className='basis-1/2'>
								{detailInfo && (
									<input type='text' disabled value={detailInfo.viewCode} className='text-right' />
								)}
							</div>
						</div>
						<div className='flex items-center gap-2 w-full pb-2 pt-2 border-b border-b-zinc-200'>
							<div className='basis-1/2'>
								<InputLabel id='label_subsidiary_paper_manage_detail'>
									{t('label_company_name')}
								</InputLabel>
							</div>
							<div className='basis-1/2'>
								<SubsidiarySelector
									formikId='subsidiary_paper_manage_detail'
									formik={formik}
									disabled={!isEditMode}
								/>
							</div>
						</div>
						<div className='flex items-center gap-2 w-full pb-2 pt-2 border-b border-b-zinc-200'>
							<div className='basis-1/2'>
								<InputLabel id='label_type_paper_manage_detail'>{t('paper_manage_type')}</InputLabel>
							</div>
							<div className='basis-1/2'>
								<PaperTypeSelector
									formikId='type_paper_manage_detail'
									formik={formik}
									disabled={!isEditMode}
								/>
							</div>
						</div>
						<div className='flex items-center gap-2 w-full pb-2 pt-2 border-b border-b-zinc-200'>
							<div className='basis-1/2'>
								<InputLabel id='label_create_time_paper_manage_detail'>{t('MSG_ID_141')}</InputLabel>
							</div>
							<div className='basis-1/2'>
								{detailInfo && (
									<input type='text' disabled value={detailInfo.createTime} className='text-right' />
								)}
							</div>
						</div>
						<div className='flex items-center gap-2 w-full pb-2 pt-2 border-b border-b-zinc-200'>
							<div className='basis-1/2'>
								<InputLabel id='label_last_used_date_paper_manage_detail'>
									{t('last_used_date')}
								</InputLabel>
							</div>
							<div className='basis-1/2'>
								{detailInfo && (
									<input
										type='text'
										disabled
										value={detailInfo.lastUsedDate}
										className='text-right'
									/>
								)}
							</div>
						</div>
					</div>

					<div className='flex-1 pr-8'>
						<div className='flex items-center gap-2 w-full pb-2 pt-2 border-b border-b-zinc-200'>
							<div>
								<InputLabel id='label_manager_paper_manage_detail'>
									{t('paper_manage_manager')}
								</InputLabel>
							</div>
							<div className='flex-auto'>
								<PopupUsers
									id='users_list_paper_manage_detail'
									formik={formik}
									inputSize='small'
									disabled={!isEditMode}
								/>
							</div>
						</div>
						<div className='flex flex-wrap items-center gap-2 w-full pb-2 pt-2 border-b border-b-zinc-200'>
							<InputSealStamp
								formikId='seal_stamp_paper_manage_detail'
								formik={formik}
								disabled={!isEditMode}
							/>
						</div>
						<div className='flex flex-wrap items-center gap-2 w-full pb-2 pt-2 border-b border-b-zinc-200'>
							<div>
								<InputLabel id='label_file_paper_manage_detail'>{t('MSG_ID_131')}</InputLabel>
							</div>
							<div>
								<InputFile
									id='file_list_paper_manage_detail'
									formik={formik}
									button={isEditMode ? fileButton : undefined}
									multiple
									disableFileChip={!isEditMode}
									downloadFile={!isEditMode ? downloadPaperManageFile : undefined}
								/>
							</div>
						</div>
						<div className='flex flex-wrap items-center gap-2 w-full pb-2 pt-2 border-b border-b-zinc-200'>
							<div>
								<InputLabel id='label_paper_count_paper_manage_detail'>{t('have_count')}</InputLabel>
							</div>
							<div>
								<InputSwitch
									id='is_paper_count_paper_manage_detail'
									formik={formik}
									disabled={!isEditMode}
								/>
							</div>
							<div>
								<InputText
									id='paper_count_paper_manage_detail'
									type='number'
									labelText={t('write_have_count')}
									placeholder={t('write_have_count')}
									size='small'
									disabled={!isPaperCount || !isEditMode}
									formik={formik}
								/>
							</div>
						</div>
					</div>

					<div className='flex-1'>
						<div className='flex items-center gap-2 w-full pb-2 pt-2 border-b border-b-zinc-200'>
							<div className='basis-1/4'>
								<InputLabel id='label_description_paper_manage_detail'>{t('MSG_ID_152')}</InputLabel>
							</div>
							<div className='basis-3/4'>
								{detailInfo && (
									<InputText
										multiline
										id='description_paper_manage_detail'
										formik={formik}
										disabled={!isEditMode}
									/>
								)}
							</div>
						</div>
						<div className='flex items-center gap-2 w-full pb-2 pt-2 border-b border-b-zinc-200'>
							<div className='basis-1/3'>
								<InputLabel id='label_active_paper_manage_detail'>
									{t('disable_paper_manage')}
								</InputLabel>
							</div>
							<div className='basis-1/3'>
								<input
									type='text'
									disabled
									className='text-right'
									value={formik.values.handle_active_time_and_user_name}
								/>
							</div>
							<div className='basis-1/3'>
								<InputSwitch
									id='is_deactive_paper_manage_detail'
									formik={formik}
									disabled={!isEditMode}
								/>
							</div>
						</div>
					</div>
				</div>
			</CardBox>
		</section>
	);
}
