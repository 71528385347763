import ProviderSortTable from '../../../ui/sortTables/ProviderSortTable';
import DustbinField from './DustbinField';

export default function ListField() {
	return (
		<ProviderSortTable>
			<DustbinField />
		</ProviderSortTable>
	);
}
