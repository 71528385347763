import { useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { AnyObject } from 'yup';
import { dndItemTypes } from '../../../constants/dndType';
import { DndSign } from '../../../model/sign';

type Props = {
	id: string | number;
	name?: string;
	imageUrl?: string;
	canDrag: boolean;
	onDrop: (item: AnyObject, dropResult: AnyObject) => void;
};

export default function ItemSign({ id, name = '', canDrag = false, imageUrl, onDrop }: Props) {
	const ref = useRef<HTMLDivElement>(null);
	const [cursor, setCursor] = useState<{ x: number; y: number } | null>(null);
	const [isDragging = false, drag] = useDrag(
		() => ({
			type: dndItemTypes.sign,
			item: () => {
				const { width, height } = (ref.current as HTMLElement).getBoundingClientRect();
				return { id, name, imageUrl, width, height } as DndSign;
			},
			canDrag: () => {
				return canDrag;
			},
			end: (item, monitor) => {
				const dropResult = monitor.getDropResult();
				const { x: reactX, y: reactY } = (ref.current as HTMLElement).getBoundingClientRect();
				if (!item || !dropResult || !cursor) return;

				const { x, y } = cursor;
				const calcX = x - reactX;
				const calcY = y - reactY;

				onDrop({ ...item, x: calcX, y: calcY }, dropResult);
			},
			collect: monitor => {
				const offset = monitor.getClientOffset();
				if (offset != null) setCursor(offset);
				return {
					isDragging: monitor.isDragging(),
					handlerId: monitor.getHandlerId(),
				};
			},
		}),
		[id, ref, cursor],
	);

	drag(ref);

	return (
		<div
			className={`inline-flex ${
				canDrag
					? 'outline-2 outline-dotted outline-transparent hover:outline-red-700 hover:cursor-grab transition-[outline-color]'
					: 'opacity-30'
			}`}
			ref={ref}
		>
			<img className='pointer-events-none' src={imageUrl} alt={name} />
		</div>
	);
}
