import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import ButtonText from '../../../ui/buttons/ButtonText';
import CardBox from '../../../ui/containers/CardBox';
import InputLabel from '../../../ui/inputs/InputLabel';
import InputRadio from '../../../ui/inputs/InputRadio';
import InputText from '../../../ui/inputs/InputText';
import InfoTooltip from '../../../ui/tooltip/InfoTooltip';

function ContainerCommon() {
	const { t } = useTranslation();
	const formik = useValidateForm({
		validationSchema: {
			menu: { initialValue: '1', type: 'string' },
			description: { initialValue: '', type: 'string' },
			sendEmail: { initialValue: '1', type: 'string' },
			senderName: { initialValue: '', type: 'string' },
			senderEmail: { initialValue: '', type: 'email' },
		},
		onSubmit: values => {
			if (formik.values.sendEmail === '2') return;
			Object.entries(values).forEach(([key, value]) => {
				if (key === 'senderName' || key === 'senderEmail') {
					if (value.trim()) return;
					formik.setFieldError(key, t('cmmn_warn_essential_item'));
				}
			});
		},
	});

	return (
		<CardBox className='flex flex-col gap-4 p-6'>
			<header className='pb-4 border-b border-neutral-300'>
				<h2 className='text-xl'>공문 사용 설정</h2>
				<p className='mt-2 text-sm text-gray-600'>공문에 적용될 아래 옵션을 설정해주세요.</p>
			</header>

			<form onSubmit={formik.handleSubmit}>
				<ul className='flex flex-col gap-4'>
					<li className='flex pb-4 border-b border-neutral-300'>
						<div className='basis-1/4 pt-2'>
							<InputLabel htmlFor='menu1'>공문 메뉴</InputLabel>
						</div>
						<div className='basis-3/4 flex items-center'>
							<InputRadio id='menu1' name='menu' value='1' label='사용' formik={formik} />
							<InputRadio id='menu2' name='menu' value='2' label='미사용' formik={formik} />
						</div>
					</li>
					<li className='flex pb-4 border-b border-neutral-300'>
						<div className='basis-1/4 pt-2'>
							<InputLabel htmlFor='menu1'>공지사항</InputLabel>
						</div>
						<div className='basis-3/4'>
							<InputText
								multiline
								id='description'
								labelText='공지사항'
								placeholder='공문 생성 화면 상단에 노출됩니다.'
								formik={formik}
							/>
						</div>
					</li>

					<li className='flex pb-4 border-b border-neutral-300'>
						<div className='basis-1/4 pt-2'>
							<InputLabel htmlFor='sendEmail1'>발송 기능</InputLabel>
						</div>
						<div className='basis-3/4'>
							<div className='flex items-center'>
								<InputRadio id='sendEmail1' name='sendEmail' value='1' label='필요' formik={formik} />
								<InputRadio id='sendEmail2' name='sendEmail' value='2' label='불필요' formik={formik} />
							</div>
							{formik.values.sendEmail === '1' && (
								<>
									<div className='flex items-center'>
										<InputLabel>발신명의 설정</InputLabel>
										<InfoTooltip
											title='공문을 발송하는 발신처명과 이메일 주소를 설정 할 수 있습니다.'
											color='inherit'
										/>
									</div>
									<div className='flex items-center gap-2'>
										<InputText
											id='senderName'
											labelText='발신처명'
											placeholder='발신처명을 입력해주세요.'
											required
											formik={formik}
										/>
										<InputText
											id='senderEmail'
											type='email'
											labelText='발신처 이메일'
											placeholder='발신처 이메일을 입력해주세요.'
											required
											formik={formik}
										/>
									</div>
								</>
							)}
						</div>
					</li>
					<li className='flex justify-center'>
						<ButtonText text='저장' variant='contained' color='primary' type='submit' />
					</li>
				</ul>
			</form>
		</CardBox>
	);
}

export default memo(ContainerCommon);
