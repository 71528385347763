import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function TreeIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M18 10.7778H21.6C22.932 10.7778 24 9.67778 24 8.33333V3.44444C24 2.08778 22.92 1 21.6 1H18C16.668 1 15.6 2.1 15.6 3.44444V4.66667H8.412V3.44444C8.412 2.08778 7.332 1 6.012 1H2.4C1.08 1 0 2.1 0 3.44444V8.33333C0 9.69 1.08 10.7778 2.4 10.7778H6C7.332 10.7778 8.4 9.67778 8.4 8.33333V7.11111H10.8V15.6789C10.8 17.6956 12.408 19.3333 14.388 19.3333H15.6V20.5556C15.6 21.9122 16.68 23 18 23H21.6C22.932 23 24 21.9 24 20.5556V15.6667C24 14.31 22.92 13.2222 21.6 13.2222H18C16.668 13.2222 15.6 14.3222 15.6 15.6667V16.8889H14.388C13.74 16.8889 13.2 16.3389 13.2 15.6789V7.11111H15.6V8.33333C15.6 9.67778 16.68 10.7778 18 10.7778Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
