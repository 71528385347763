import { memo } from 'react';
import { useTheme } from '../../../../hooks/useTheme';
import { IProcess, IReview, ISign } from '../../../../model/officialLetter';
import ItemProcessComplete from './ItemProcessComplete';
import ItemProcessDraft from './ItemProcessDraft';
import ItemProcessReview from './ItemProcessReview';
import ItemProcessSign from './ItemProcessSign';

interface IProps {
	processId: string;
	data: IProcess;
}

function ItemProcess({ processId, data }: IProps) {
	const theme = useTheme();

	return (
		<>
			{processId !== 'complete' && (
				<span
					className='absolute -left-[1px] top-[11px] block w-[2px] h-full'
					style={{ backgroundColor: theme.palette.primary.main }}
				/>
			)}
			<span
				className='absolute left-0 top-[11px] -translate-x-1/2 block w-5 h-5 rounded-full border-3 bg-white'
				style={{ borderColor: theme.palette.primary.main }}
			/>
			<article className='flex items-center gap-4 min-h-[42px]'>
				{data.id === 'draft' && <ItemProcessDraft processId={processId} data={data} />}
				{data.id === 'review' && <ItemProcessReview processId={processId} data={data as IReview} />}
				{data.id === 'sign' && <ItemProcessSign processId={processId} data={data as ISign} />}
				{data.id === 'complete' && <ItemProcessComplete processId={processId} data={data} />}
			</article>
		</>
	);
}

export default memo(ItemProcess);
