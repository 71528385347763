import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { doaBackdo } from '../../../../api/login';
import ButtonText from '../../../ui/buttons/ButtonText';
import PopupCommonInternal from '../../../ui/popups/PopupCommonInternal';

export default function DoaUserOption() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<PopupCommonInternal
			openTrigger={
				<ButtonText className='px-7 py-4 text-base text-gray-950 font-bold'>
					{t('cmmn_label_switch_to_my_account')}
				</ButtonText>
			}
			msg={t('would_you_like_to_switch_to_my_account_1')}
			confirmClose={false}
			onConfirm={() => {
				doaBackdo({ navigate });
			}}
			onCancel={() => false}
		/>
	);
}
