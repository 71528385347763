import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getNotificationCount } from '../../../api/notification';
import { queryKey } from '../../../constants/queryKey';
import { useUser } from '../../../context/UserContext';
import ButtonText from '../../ui/buttons/ButtonText';
import CardBox from '../../ui/containers/CardBox';

type Props = {
	onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
};
export default function AlarmButtonDashboard({ onClick }: Props) {
	const { loginUser } = useUser();
	const { t } = useTranslation();

	const { data } = useQuery(queryKey.notificationCount, getNotificationCount, { staleTime: Infinity });

	return (
		<ButtonText className='p-0 rounded-2xl' onClick={onClick} disabled={!data?.notification_count}>
			<CardBox className='flex flex-col gap-2 w-full h-full p-6'>
				<h3 className='text-xl font-bold text-left'>
					{t('cmmn_guide_need_users_confirm', { 이름: loginUser.name })}
				</h3>
				<ul className='flex flex-wrap justify-end gap-5'>
					<li className='max-w-full'>
						{data && (
							<p className='text-4xl text-right font-bold text-blue-600 truncate'>
								{data.notification_count}
							</p>
						)}
						<p className='text-sm text-right text-white'>{t('MSG_ID_1255')}</p>
					</li>
				</ul>
			</CardBox>
		</ButtonText>
	);
}
