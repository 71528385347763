import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function HistoryIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M13.6603 1.00426C7.64113 0.833187 2.70987 5.83111 2.70987 12.0021H0.593092C0.0609423 12.0021 -0.19922 12.662 0.179198 13.0408L3.47853 16.4624C3.71504 16.7068 4.08163 16.7068 4.31814 16.4624L7.61747 13.0408C7.98407 12.662 7.7239 12.0021 7.19175 12.0021H5.07498C5.07498 7.23639 8.83551 3.38714 13.4711 3.44824C17.8702 3.50933 21.5716 7.33415 21.6308 11.8799C21.6899 16.6579 17.9648 20.556 13.3529 20.556C11.449 20.556 9.68695 19.8839 8.29153 18.7475C7.81851 18.3687 7.15628 18.4053 6.73056 18.8452C6.23388 19.3585 6.26936 20.2261 6.82516 20.666C8.62265 22.1324 10.8813 23 13.3529 23C19.3248 23 24.1614 17.9043 23.9959 11.6844C23.8421 5.95331 19.2065 1.16312 13.6603 1.00426ZM13.0572 7.11419C12.5724 7.11419 12.1703 7.52967 12.1703 8.03068V12.5276C12.1703 12.9553 12.395 13.3585 12.7498 13.5785L16.4393 15.8392C16.8651 16.0958 17.409 15.9491 17.6574 15.5214C17.9057 15.0815 17.7638 14.5194 17.3499 14.2628L13.9441 12.1732V8.01846C13.9441 7.52967 13.5421 7.11419 13.0572 7.11419Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
