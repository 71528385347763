import { useMemo } from 'react';

type Props = {
	number?: number;
	size?: number;
	className?: string;
};

export default function DotLoader({ number = 3, size = 12, className = 'bg-blue-600' }: Props) {
	const { animation, keyframes } = useMemo(
		() => ({
			animation: 'dotFlashing 3s infinite linear alternate',
			keyframes: `@keyframes dotFlashing {
                0% { opacity: 1; }
                50% { opacity: 0.4; }
                100% { opacity: 1; }
            }`,
		}),
		[],
	);

	return (
		<div className='flex items-center gap-1'>
			<style>{keyframes}</style>
			{Array.from({ length: number }, (_, index) => (
				<div
					key={index}
					className={`rounded-full ${className}`}
					style={{ animation, animationDelay: `${index * 0.5}s`, width: size, height: size }}
				/>
			))}
		</div>
	);
}
