import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { doaBackdo } from '../../api/login';
import ButtonText from '../ui/buttons/ButtonText';
import PopupCommonInternal from '../ui/popups/PopupCommonInternal';

export default function DoaUserOption() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<PopupCommonInternal
			openTrigger={
				<ButtonText className='p-2 text-sm text-gray-950'>{t('cmmn_label_switch_to_my_account')}</ButtonText>
			}
			msg={t('cmmn_guide_would_you_like_to_return_to_my_account')}
			confirmClose={false}
			onConfirm={() => {
				doaBackdo({ navigate });
			}}
			onCancel={() => false}
		/>
	);
}
