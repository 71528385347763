import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import SearchIcon from '../../../ui/icons/SearchIcon';
import InputText from '../../../ui/inputs/InputText';

export default function MenuSearch() {
	const { t } = useTranslation();
	const formik = useValidateForm({
		validationSchema: {
			search: { initialValue: '', type: 'string' },
		},
		onSubmit: ({ search }) => {
			// 여기서 작업 필요
		},
	});

	const handleChange = useCallback(() => {
		formik.handleSubmit();
	}, [formik.values.search]);

	return (
		<InputText
			id='search'
			classes={{ root: 'border-transparent rounded-3xl bg-zinc-100' }}
			icon={<SearchIcon />}
			labelText={t('cmmn_guide_global_search')}
			placeholder={t('cmmn_guide_please_enter_your_search_details')}
			size='small'
			delay={1000}
			onBeforeDebounceChange={handleChange}
			formik={formik}
		/>
	);
}
