import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ChartIcon from '../../components/ui/icons/ChartIcon';
import DashboardIcon from '../../components/ui/icons/DashboardIcon';
import TabSnb from '../../components/ui/tabs/TabSnb';
import SysConfigStore from '../../store/common/SysConfigStore';

export default function RoutePage() {
	const { isModuleEnabled, labelText } = SysConfigStore();
	const { t } = useTranslation();
	const [value, setValue] = useState(0);
	const { pathname } = useLocation();

	const tabs = [
		{
			id: 0,
			icon: <DashboardIcon />,
			label: t('cmmn_label_dashboard'),
			isVisible: true,
		},
		{
			id: 1,
			icon: <ChartIcon />,
			label: t('cmmn_label_chart'),
			disabled: true,
			isVisible: true,
		},
		{
			id: 2,
			query: 'NEW-REVIEW',
			depth: 2,
			label: isModuleEnabled('COUNSEL_MANAGE')
				? t('cmmn_guide_number_of_new_contracts_advisory_reviews')
				: t('cmmn_guide_number_of_new_contract_reviews'),
			isVisible: true,
		},
		{
			id: 3,
			query: 'NEW-REVIEW-STATUS-RATE',
			depth: 2,
			label: isModuleEnabled('COUNSEL_MANAGE')
				? t('cmmn_guide_number_of_cases_by_contract_advisory_status')
				: t('cmmn_guide_number_of_cases_by_contract_status'),
			isVisible: true,
		},
		{
			id: 4,
			query: 'NEW-REVIEWED-COUNT',
			depth: 2,
			label: isModuleEnabled('COUNSEL_MANAGE')
				? t('cmmn_guide_trends_in_the_number_of_contract_advisory_review_cases_processed')
				: t('cmmn_guide_trends_in_the_number_of_contract_reviews_processed'),
			isVisible: true,
		},
		{
			id: 5,
			query: 'NEW-REVIEW-PERIOD-COUNT',
			depth: 2,
			label: isModuleEnabled('COUNSEL_MANAGE')
				? t('cmmn_guide_trends_in_the_number_of_new_contracts_advisory_cases')
				: t('cmmn_guide_trends_in_the_number_of_new_contracts'),
			isVisible: true,
		},
		{
			id: 6,
			query: 'NEW-REVIEW-MANAGERS',
			depth: 2,
			label: isModuleEnabled('COUNSEL_MANAGE')
				? t('cmmn_guide_new_contract_advisory_reviewer_assignment_status')
				: t('cmmn_guide_new_contract_reviewer_assignment_status'),
			isVisible: true,
		},
		{
			id: 7,
			query: 'NEW-REVIEW-CONTRACT-COUNT-BY-CATEGORY',
			depth: 2,
			label: t('cmmn_guide_new_status_by_contract_category'),
			isVisible: true,
		},
	];

	if (isModuleEnabled('COUNSEL_MANAGE')) {
		tabs.push({
			id: tabs.length,
			query: 'NEW-REVIEW-COUNSEL-COUNT-BY-CATEGORY',
			depth: 2,
			label: t('cmmn_guide_new_status_by_advisory_category'),
			isVisible: isModuleEnabled('COUNSEL_MANAGE'),
		});
	}

	if (isModuleEnabled('APP_LAWSUIT')) {
		tabs.push({
			id: tabs.length,
			query: 'NEW-LAWSUIT-MANAGE-COUNT',
			depth: 2,
			label: t('lawsuit_guide_status_of_new_litigation_personnel_assigned'),
			isVisible: isModuleEnabled('APP_LAWSUIT'),
		});
		tabs.push({
			id: tabs.length,
			query: 'NEW-LAWSUIT-MANAGE-WITH-IN-AND-OUT',
			depth: 2,
			label: `${labelText('cmmn_label_lawsuit_external_delegation')}`,
			isVisible: isModuleEnabled('APP_LAWSUIT'),
		});
	}

	const navigate = useNavigate();
	const handleChange = (_: SyntheticEvent, v: number) => {
		// console.log(tabs);
		setValue(v);
		if (v === 0) {
			navigate('/statistics');
		} else {
			const { query } = tabs.filter(tab => tab.id === v)[0];
			navigate(`/statistics/detail/${query}`, { state: { query } });
		}
	};

	useEffect(() => {
		const filterTab = tabs.filter(({ query }) => query && pathname.split('/').at(-1) === query);
		if (filterTab.length > 0) {
			const { query, id } = tabs.filter(tab => tab.id === filterTab[0].id)[0];
			setValue(id);
			navigate(`/statistics/detail/${query}`, { state: { query } });
		} else {
			setValue(0);
			navigate('/statistics/');
		}
	}, [value]);

	return (
		<section className='flex flex-col'>
			<div className='grid grid-cols-1 lg:grid-cols-12'>
				<div className='col-span-2 bg-slate-100'>
					<header className='py-14'>
						<h3 className='text-3xl text-center font-bold'>{t('label_statistics')}</h3>
					</header>

					<TabSnb value={value} onChange={handleChange} tabs={tabs} />
				</div>
				<div className='col-span-10'>
					<Outlet />
				</div>
			</div>
		</section>
	);
}
