import { useParams } from 'react-router-dom';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { Tab } from '@mui/material';
import { useAIArticle } from '../../../context/AIArticleContext';
import TabsCommon from '../../ui/tabs/TabsCommon';

type Props = {
	type: 'DETAIL' | 'SLIDE';
};

export default function AIArticleContentHistoryTab({ type }: Props) {
	const { id } = useParams();
	const { slideState, detailState, setSlideState, setDetailState } = useAIArticle();

	const state = useMemo(() => (type === 'DETAIL' ? detailState : slideState), [type, slideState, detailState]);
	const setState = useCallback(
		(index: number) => {
			if (type === 'DETAIL') {
				setDetailState(Number(id), { ...detailState!, index });
			} else {
				setSlideState(prev => ({ ...prev, index }));
			}
		},
		[type, id, setSlideState, detailState],
	);

	const handleTabChange = useCallback(
		(_: SyntheticEvent, v: number) => {
			if (state) {
				setState(v);
			}
		},
		[state, setState],
	);

	if (!state?.history_map) {
		return null;
	}

	const { history_map: historyMap } = state;
	const { article_histories: articleHistories } = historyMap;

	return (
		<TabsCommon
			classes={{
				flexContainer: 'flex-row-reverse',
				indicator: 'h-1 bg-blue-300',
			}}
			value={state.index}
			onChange={handleTabChange}
		>
			{articleHistories.map((item, index) => (
				<Tab
					key={item.doc_id}
					classes={{
						root: `min-w-0 min-h-0 text-gray-950 ${!item.article ? 'bg-red-200' : ''}`,
						selected: 'text-blue-500 underline',
					}}
					id={`tab-${index + 1}`}
					label={`Ver.${index + 1}`}
					disabled={!item.article}
				/>
			))}
		</TabsCommon>
	);
}
