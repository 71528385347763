import _ from 'lodash';
import { alarmList } from '../model/alarm';
import { AppList, SignList } from '../model/list';
import { ITemplate, IWorkflow } from '../model/officialLetter';
import { ISealStamp } from '../model/sealStamp';
import { User } from '../model/user';
import {
	randAlarmList,
	randAppList,
	randOfficialLetterTemplateList,
	randOfficialLetterWorkflowList,
	randSealStampList,
	randSignList,
	randUser,
} from '../util/dummyData';

// 유저 데이터
export const getFakeUser = (range = 200) =>
	new Promise<User[]>(resolve => {
		setTimeout(() => {
			const arr = _.range(range).map(index => randUser(index));
			return resolve(arr);
		}, 3000);
	});

// 리스트 데이터
export const getFakeAppList = (range = 200) =>
	new Promise<AppList[]>(resolve => {
		setTimeout(() => {
			const arr = _.range(range || 200).map(() => randAppList());
			return resolve(arr);
		}, 3000);
	});

// 알람 리스트 데이터
export const getFakeAlarmList = (range = 10) =>
	new Promise<alarmList[]>(resolve => {
		setTimeout(() => {
			const arr = _.range(range).map(() => randAlarmList());
			return resolve(arr);
		}, 3000);
	});

// 서명 리스트 데이터
export const getFakeSignList = (range = 200) =>
	new Promise<SignList[]>(resolve => {
		setTimeout(() => {
			const arr = _.range(range || 200).map(() => randSignList());
			return resolve(arr);
		}, 3000);
	});

// 관리자 공문 워크플로우 리스트 데이터
export const getFakeOfficialLetterList = (range = 200) =>
	new Promise<IWorkflow[]>(resolve => {
		setTimeout(() => {
			const arr = _.range(range || 200).map((item, index) => randOfficialLetterWorkflowList(index));
			return resolve(arr);
		}, 3000);
	});

// 관리자 공문 템플릿 리스트 데이터
export const getFakeOfficialLetterTemplateList = (range = 200) =>
	new Promise<ITemplate[]>(resolve => {
		setTimeout(() => {
			const arr = _.range(range || 200).map(() => randOfficialLetterTemplateList());
			return resolve(arr);
		}, 3000);
	});

// 인감 선택 팝업 리스트 데이터
export const getFakeSealStampList = (range = 200) =>
	new Promise<ISealStamp[]>(resolve => {
		setTimeout(() => {
			const arr = _.range(range ?? 200).map(() => randSealStampList());
			return resolve(arr);
		}, 3000);
	});
