import { useCallback, useEffect, useRef, useState } from 'react';
import { useAIReport } from '../../../../context/AIReportContext';
import ButtonText from '../../../ui/buttons/ButtonText';
import ChevronRightIcon from '../../../ui/icons/ChevronRight';
import AIReportHighlightArticleWord from '../AIReportHighlightArticleWord';
import { extractReportDetailDisplayList } from '../../../../util/ai';

export default function AIReportLeftAnalysisResult() {
	const { refMap, data } = useAIReport();
	const [expand, setExpand] = useState(false);
	const [expandable, setExpandable] = useState(false);

	const contentRef = useRef<HTMLDivElement | null>(null);

	const { detail } = data;

	useEffect(() => {
		if (contentRef.current) {
			setExpandable(contentRef.current.scrollHeight > 288);
		}
	}, [detail]);

	const handleToggle = useCallback(() => {
		const container = refMap.leftContainerRef.current;
		if (expand && container) {
			container.scrollTo({
				top: 0,
			});
		}
		setExpand(!expand);
	}, [refMap, expand]);

	return (
		<div className='flex flex-col'>
			<div ref={contentRef} className={`overflow-hidden ${expand ? 'max-h-auto' : 'max-h-[288px]'}`}>
				<p className='mb-6 text-blue-600 font-bold'>계약서 분석 결과</p>
				<section className='flex flex-col gap-6'>
					{extractReportDetailDisplayList(detail).map(item => (
						<div key={item.label}>
							<p className='mb-2 text-neutral-400 text-sm'>{item.label}</p>
							<AIReportHighlightArticleWord content={item.value} articles={data.articles} />
						</div>
					))}
				</section>
			</div>
			{expandable && (
				<div className='flex justify-between items-center'>
					<p>{expand ? '' : '...'}</p>
					<ButtonText size='small' onClick={handleToggle}>
						{expand ? (
							<>
								<p>접기</p>
								<ChevronRightIcon className='transform -rotate-90' />
							</>
						) : (
							<>
								<p>펼치기</p>
								<ChevronRightIcon className='transform rotate-90' />
							</>
						)}
					</ButtonText>
				</div>
			)}
		</div>
	);
}
