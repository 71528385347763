import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../../../hooks/useAlert';
import { Sign } from '../../../../model/sign';
import { useProviderPage } from '../../../../pages/sign/ProviderPage';
import { downloadPdfWithFixedImage } from '../../../../util/pdf';
import CardBox from '../../../ui/containers/CardBox';
import PopupSignSuccess from '../PopupSignSuccess';

interface IProps {
	signs: Sign[];
}

export default function HeaderSignAction({ signs }: IProps) {
	const { t } = useTranslation();
	const [snackbar] = useAlert();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const { pdf } = useProviderPage();

	const handleConfirm = useCallback(async () => {
		if (signs.length === 0 || !pdf) {
			snackbar(t('contract_warn_no_seal_stamp'), 'error');
			setOpen(false);
			return;
		}

		setLoading(true);

		Promise.all([
			downloadPdfWithFixedImage(pdf, signs, { position: 'left' }),
			downloadPdfWithFixedImage(pdf, signs, { position: 'both' }),
			downloadPdfWithFixedImage(pdf, signs, { position: 'right' }),
		]) //
			.then(() => {
				setLoading(false);
				setOpen(false);
			});
	}, [signs, pdf]);

	return (
		<CardBox className='grid grid-cols-2 direction-rtl gap-4 p-4'>
			<PopupSignSuccess
				loading={loading}
				open={open}
				renderPage={<p className='text-center'>{t('contract_guide_confirm_seal_stamp')}</p>}
				setOpen={setOpen}
				onConfirm={handleConfirm}
			/>
		</CardBox>
	);
}
