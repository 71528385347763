import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import SearchIcon from '../../../../components/ui/icons/SearchIcon';
import InputText from '../../../../components/ui/inputs/InputText';
import ButtonText from '../../../../components/ui/buttons/ButtonText';
import { useAIArticle } from '../../../../context/AIArticleContext';
import { queryKey } from '../../../../constants/queryKey';
import { getTodayStoreStatus } from '../../../../api/ai';

export default function AIArticleSearchMainPage() {
	const navigate = useNavigate();
	const { setSlideState } = useAIArticle();

	useEffect(() => {
		setSlideState(prev => ({ ...prev, display: false }));
	}, []);

	const searchFormik = useValidateForm({
		validationSchema: {
			query: { initialValue: '', type: 'string', required: '검색어를 입력해주세요.' },
		},
		onSubmit: values => {
			const params = new URLSearchParams({ query: values.query });
			navigate(`/buptle_ai/article/search/?${params.toString()}`);
		},
	});

	const { data } = useQuery([queryKey.aiTodayStoreStatus], getTodayStoreStatus, { staleTime: 300000 });

	return (
		<section className='p-20'>
			<div className='p-20'>
				<div className='flex flex-col gap-20'>
					<div className='flex justify-center items-start gap-3'>
						<p className='text-5xl'>법틀AI 조항검색</p>
						<span className='px-4 py-1 rounded-md text-xl font-bold text-white bg-blue-600'>Beta</span>
					</div>
					<div>
						{data?.greetings
							.split('. ')
							.join('.\n')
							.split('\n')
							.map((line, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<p key={index} className='text-neutral-600 text-center'>
									{line}
								</p>
							))}
					</div>
					<form onSubmit={searchFormik.handleSubmit} className='flex rounded-3xl shadow-xl'>
						<InputText
							id='query'
							color='primary'
							classes={{
								root: 'rounded-tl-3xl rounded-bl-3xl border-blue-600',
							}}
							required
							formik={searchFormik}
						/>
						<ButtonText
							color={searchFormik.errors.query ? 'error' : 'primary'}
							variant='contained'
							className='rounded-tl-none rounded-bl-none rounded-tr-3xl rounded-br-3xl'
							onClick={() => searchFormik.handleSubmit()}
						>
							<SearchIcon />
						</ButtonText>
					</form>
				</div>
			</div>
		</section>
	);
}
