import React, { useCallback, useEffect, useRef } from 'react';
import { useAIArticle } from '../../../../context/AIArticleContext';
import AIArticleSlideView from '../../../../components/ai/article/slide/AIArticleSlideView';
import AIArticleFavoriteView from '../../../../components/ai/article/favorite/AIArticleFavoriteView';

export default function AIArticleFavoritePage() {
	const { slideState, setSlideState } = useAIArticle();

	const leftRef = useRef<HTMLDivElement | null>(null);

	const updateLeftScrollHeight = useCallback(() => {
		if (leftRef.current) {
			leftRef.current.style.height = `${window.innerHeight - window.scrollY}px`;
		}
	}, []);

	useEffect(() => {
		setSlideState(prev => ({ ...prev, display: false }));
	}, []);

	useEffect(() => {
		if (slideState.display) {
			updateLeftScrollHeight();
			window.addEventListener('resize', updateLeftScrollHeight);
			return () => {
				window.removeEventListener('resize', updateLeftScrollHeight);
			};
		}

		if (leftRef.current) {
			leftRef.current.style.height = 'auto';
		}
		return () => {};
	}, [slideState.display]);

	return slideState.display ? (
		<div className='flex'>
			<div className='w-1/4 border-r border-neutral-600 overflow-y-auto scrollbar sticky top-0' ref={leftRef}>
				<AIArticleFavoriteView />
			</div>
			<div className='w-3/4'>
				<AIArticleSlideView />
			</div>
		</div>
	) : (
		<div ref={leftRef}>
			<AIArticleFavoriteView />
		</div>
	);
}
