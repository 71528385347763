import { Button } from '@mui/material';
import { throttle } from 'lodash';
import { MouseEvent } from 'react';
import { ButtonOwnPropsOverride } from '../../../model/button';

const getOverrideClasses = (className: string) => {
	return { root: `${className}` };
};

export default function ButtonText({
	delay = 1000,
	children,
	text,
	className = '',
	color = 'inherit',
	size = 'medium',
	variant = 'text',
	disabled = false,
	onClick = () => {},
	onMouseDown = () => {},
	...props
}: ButtonOwnPropsOverride) {
	const delayedClick = throttle(e => onClick(e), delay);
	const delayedMouseDown = throttle(e => onMouseDown(e), delay);

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => delayedClick(e);
	const handleMouseDown = (e: MouseEvent<HTMLButtonElement>) => delayedMouseDown(e);

	return (
		<Button
			classes={getOverrideClasses(className)}
			color={color}
			size={size}
			variant={variant}
			disabled={disabled}
			onClick={handleClick}
			onMouseDown={handleMouseDown}
			{...props}
		>
			{children && children}
			{text && text}
		</Button>
	);
}
