import { memo } from 'react';
import { PROCESS_NAME } from '../../../../constants/officialLetter';
import { IProcess } from '../../../../model/officialLetter';

interface IProps {
	processId: string;
	data: IProcess;
}

function ItemProcessComplete({ processId, data }: IProps) {
	return <p>{PROCESS_NAME[data.id]}</p>;
}

export default memo(ItemProcessComplete);
