/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { StatisticsTableSearchParams } from '../model/statistics';
import { useUser } from './UserContext';

interface StatisticsContext {
	period: string | '';
	startDate?: string | '';
	endDate?: string | '';
	setPeriod: React.Dispatch<React.SetStateAction<string>>;
	setStartDate: React.Dispatch<React.SetStateAction<string>>;
	setEndDate: React.Dispatch<React.SetStateAction<string>>;
	searchParams: StatisticsTableSearchParams;
	setSearchParams: React.Dispatch<React.SetStateAction<StatisticsTableSearchParams>>;
}

const StatisticsContext = createContext<StatisticsContext | undefined>(undefined);

export const StatisticsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const { permission } = useUser();
	const { state } = useLocation();

	if (!permission.DISPLAY_STATISTICS_PAGE) {
		return <Navigate to='/' replace />;
	}

	const [period, setPeriod] = useState<string>('week');
	const [startDate, setStartDate] = useState<string>('');
	const [endDate, setEndDate] = useState<string>('');
	const [searchParams, setSearchParams] = useState<StatisticsTableSearchParams>({
		currentPage: 0,
		searchType: 'title',
		searchText: '',
		rowsPerPage: 10,
		orderColumn: -1,
		order: 'desc',
	});

	const { searchType, searchText, rowsPerPage, orderColumn, order } = searchParams;

	useEffect(() => {
		setSearchParams(prev => ({ ...prev, currentPage: 0 }));
	}, [searchType, searchText, rowsPerPage, orderColumn, order, state]);

	return (
		<StatisticsContext.Provider
			value={{ period, startDate, endDate, setPeriod, setStartDate, setEndDate, searchParams, setSearchParams }}
		>
			{children}
		</StatisticsContext.Provider>
	);
};

export function useStatistics() {
	const context = useContext(StatisticsContext);
	if (context === undefined) {
		throw new Error('useSubsidiary must be used within a StatisticsProvider');
	}

	return context;
}
