import { FormikErrors, FormikHelpers, FormikValues, useFormik } from 'formik';
import * as yup from 'yup';
import { MaxSchema, MinSchema, RequiredSchema, TypeSchema, validationFormat } from '../util/validation';

/**
 * initialValue: 디폴트 값
 */
type SchemaObject = {
	[key: string]: {
		initialValue: string | number | boolean | string[] | yup.AnyObject[];
		type: TypeSchema;
		min?: MinSchema;
		max?: MaxSchema;
		required?: RequiredSchema;
	};
};

type ValidationSchema = {
	validationSchema: SchemaObject;
	onSubmit?: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void | Promise<object>;
	validate?: (values: FormikValues) => void | object | Promise<FormikErrors<FormikValues>>;
};
export function useValidateForm({ validationSchema, onSubmit = () => {}, validate }: ValidationSchema) {
	const validations: yup.AnyObject = {};
	const initialValues: yup.AnyObject = {};

	Object.keys(validationSchema).forEach(schemaId => {
		const { initialValue, type, min = [0, ''], max = [0, ''], required = false } = validationSchema[schemaId];

		validations[schemaId] = validationFormat({ type, min, max, required });
		initialValues[schemaId] = initialValue;
	});

	return useFormik({
		initialValues,
		validationSchema: yup.object(validations),
		onSubmit,
		validate,
	});
}
