import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { useAlert } from '../../hooks/useAlert';

type Props = {
	children: React.ReactNode;
};

export default function QueryClientSetter({ children }: Props) {
	const [snackbar] = useAlert();

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				staleTime: 1,
				onError: error => {
					if (error instanceof Error) {
						snackbar(error.message, 'error');
					} else {
						// console.log('An unknown error occurred');
					}
				},
			},
		},
	});

	return (
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools initialIsOpen={false} />
			{children}
		</QueryClientProvider>
	);
}
