import ButtonText from '../../../components/ui/buttons/ButtonText';
import { STATIC_DIR } from '../../../constants/common';
import SysConfigStore from '../../../store/common/SysConfigStore';

export default function LoginPage() {
	const { sysCompanyConfigs } = SysConfigStore();
	const handleClick = () => {
		window.location.href = sysCompanyConfigs.REDIRECT_LOGIN_URL;
	};

	return (
		<div className='flex h-screen'>
			<section className='relative basis-3/5 flex flex-col justify-start'>
				<div className='absolute left-[5.55%] top-[7.9%] w-[123px]'>
					<img src={`${STATIC_DIR}/images/telechips/icon-telechips.svg`} alt='Telechips Logo' />
				</div>
				<div className='flex-1 flex items-center justify-center'>
					<div className='w-[55%]'>
						<img
							className='object-contain'
							src={`${STATIC_DIR}/images/telechips/bg-telechips.png`}
							alt='Telechips Login Img'
						/>
					</div>
				</div>
			</section>
			<section className='basis-2/5 flex flex-col items-center justify-center bg-white'>
				<div className='w-[31.2%]'>
					<img
						className='w-full'
						src={`${STATIC_DIR}/images/telechips/icon-telechips.svg`}
						alt='Telechips Logo'
					/>
				</div>
				<div className='w-[50%] mt-[32px] mb-[71px]'>
					<img
						className='w-full'
						src={`${STATIC_DIR}/images/telechips/img-telechips-1.svg`}
						alt='Legal Management System'
					/>
				</div>
				<div>
					<ButtonText className='gap-4 px-10 py-4 bg-white' variant='contained' onClick={handleClick}>
						<img className='w-8' src={`${STATIC_DIR}/images/telechips/icon-ms.svg`} alt='MS Logo' />
						<p className='text-[#626262] text-xl font-bold' style={{ fontFamily: 'AppleSDGothicNeo' }}>
							Sign in with Microsoft
						</p>
					</ButtonText>
				</div>
			</section>
		</div>
	);
}
