import { FormHelperText, InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';
import { FormikValues } from 'formik';
import { memo, useState } from 'react';

interface ISelectPropsOverrides extends SelectProps {
	id: string;
	size?: 'small' | 'medium';
	formik?: FormikValues;
	onClick?: () => void;
}

function InputPopupSelect({
	id,
	label,
	size = 'medium',
	classes = { root: undefined },
	disabled = false,
	required = false,
	onClick,
	formik,
	...props
}: ISelectPropsOverrides) {
	const [open, setOpen] = useState(false);
	const { root = 'relative w-full rounded text-m' } = classes;
	return (
		<FormControl className={root} size={size} error={formik?.touched[id] && formik?.errors[id] && true}>
			<InputLabel id={`${id}_label`} required={required}>
				{label}
			</InputLabel>
			<Select
				labelId={`${id}_label`}
				label={label}
				id={id}
				multiple
				value={formik?.values[id]}
				open={open}
				autoFocus={false}
				disabled={disabled}
				onOpen={() => {
					if (onClick) onClick();
					setOpen(false);
				}}
				{...props}
			/>
			{formik?.touched[id] && formik?.errors[id] && (
				<FormHelperText classes={{ root: 'absolute bottom-0 translate-y-full mx-0' }} error>
					{formik?.errors[id]}
				</FormHelperText>
			)}
		</FormControl>
	);
}

export default memo(InputPopupSelect);
