import { Chip, ChipOwnProps } from '@mui/material';

type ChipOwnPropsOverrides = ChipOwnProps & { text: string };

export default function StateChip({
	text,
	classes = {
		root: 'text-neutral-600 px-1',
	},
	size = 'small',
	...props
}: ChipOwnPropsOverrides) {
	return <Chip label={text} size={size} variant='outlined' classes={classes} {...props} />;
}
