import { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ButtonText from '../../../ui/buttons/ButtonText';
import ThumbUpIcon from '../../../ui/icons/ThumbUpIcon';
import ThumbDownIcon from '../../../ui/icons/ThumbDownIcon';
import PopoverDefault from '../../../ui/popovers/PopoverDefault';
import CardBox from '../../../ui/containers/CardBox';
import InputText from '../../../ui/inputs/InputText';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import { useAlert } from '../../../../hooks/useAlert';
import { saveReportFeedback } from '../../../../api/ai';

export default function AIReportLeftFeedback() {
	const { id } = useParams();
	const [open, setOpen] = useState<boolean>(false);
	const anchorRef = useRef<HTMLDivElement | null>(null);

	const [snackbar] = useAlert();

	const formik = useValidateForm({
		validationSchema: {
			content: { initialValue: '', type: 'string', required: ' ' },
			is_positive: { initialValue: false, type: 'boolean' },
		},
		onSubmit: values => {
			saveReportFeedback({
				report_id: Number(id),
				content: values.content,
				is_positive: values.is_positive,
			}).then(() => {
				snackbar('피드백을 주셔서 감사합니다.', 'success');
				setOpen(false);
			});
		},
	});

	const handleOpen = useCallback((positiveCondition: boolean) => {
		setOpen(true);
		formik.setValues({ is_positive: positiveCondition });
	}, []);

	return (
		<>
			<div ref={anchorRef} className='flex justify-end'>
				<ButtonText onClick={() => handleOpen(true)}>
					<ThumbUpIcon
						className={`${open && formik.values.is_positive ? 'text-blue-600' : 'text-neutral-600'}`}
						fontSize='small'
					/>
				</ButtonText>
				<ButtonText onClick={() => handleOpen(false)}>
					<ThumbDownIcon
						className={`${open && !formik.values.is_positive ? 'text-blue-600' : 'text-neutral-600'}`}
						fontSize='small'
					/>
				</ButtonText>
			</div>
			<PopoverDefault
				open={open}
				onClose={() => setOpen(false)}
				anchorEl={anchorRef?.current}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			>
				<CardBox>
					<form
						onSubmit={formik.handleSubmit}
						className='flex gap-1 p-3'
						style={{ width: anchorRef.current?.getBoundingClientRect().width }}
					>
						<InputText
							size='small'
							id='content'
							placeholder='자유롭게 의견을 남겨주세요.'
							formik={formik}
						/>
						<ButtonText
							className='flex-1 truncate'
							variant='outlined'
							onClick={() => formik.handleSubmit()}
						>
							보내기
						</ButtonText>
					</form>
				</CardBox>
			</PopoverDefault>
		</>
	);
}
