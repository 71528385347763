import { memo, useCallback } from 'react';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import { useProviderPage } from '../../../../pages/admin/officialLetter/workflow/ProviderPage';
import InputText from '../../../ui/inputs/InputText';

function ContainerEditor() {
	const { officialLetter, setOfficialLetter } = useProviderPage();
	const formik = useValidateForm({
		validationSchema: {
			title: { initialValue: officialLetter.title, type: 'string', required: true },
			description: { initialValue: officialLetter.description, type: 'string' },
		},
		onSubmit: ({ title, description }) => {
			// console.log('여기');
			setOfficialLetter(prev => ({
				...prev,
				title,
				description,
			}));
		},
	});
	const handleChange = useCallback(() => {
		formik.handleSubmit();
	}, []);
	return (
		<ul className='grid gap-4'>
			<li>
				<InputText
					id='title'
					labelText='워크플로우명'
					delay={1000}
					required
					formik={formik}
					onBeforeDebounceChange={handleChange}
				/>
			</li>
			<li>
				<InputText
					multiline
					id='description'
					labelText='설명'
					delay={1000}
					formik={formik}
					onBeforeDebounceChange={handleChange}
				/>
			</li>
		</ul>
	);
}

export default memo(ContainerEditor);
