import SummarizeIcon from '../../../ui/icons/SummarizeIcon';
import TreeIcon from '../../../ui/icons/TreeIcon';
import ViewStreamIcon from '../../../ui/icons/ViewStreamIcon';
import AIReportRightArticleNumbers from './AIReportRightArticleNumbers';
import AIReportRightCategories from './AIReportRightCategories';
import AIReportRightCategoryNavigator from './AIReportRightCategoryNavigator';
import AIReportRightPastReview from './AIReportRightPastReview';
import AIReportRightScrollToAnalysis from './AIReportRightScrollToAnalysis';
import AIReportRightSimilarClauses from './AIReportRightSimilarClauses';
import AIReportRightSummary from './AIReportRightSummary';

export default function AIReportRightView() {
	return (
		<div className='p-8 shadow-md bg-white'>
			<div className='flex-1 flex flex-col gap-5'>
				<div>
					<div className='flex items-center gap-2 mb-2'>
						<SummarizeIcon className='text-neutral-400 text-base' />
						<p className='font-bold'>요약</p>
					</div>
					<AIReportRightSummary />
				</div>
				<div className='py-5 border border-x-0'>
					<AIReportRightScrollToAnalysis />
				</div>
				<div className='pb-5 border border-x-0 border-t-0'>
					<div className='flex items-center gap-2 mb-2'>
						<TreeIcon className='text-neutral-400 text-base' />
						<p className='font-bold'>조항 분류</p>
					</div>
					<AIReportRightCategories />
					<AIReportRightCategoryNavigator />
				</div>
				<div>
					<div className='flex items-center gap-2 mb-2'>
						<ViewStreamIcon className='text-neutral-400 text-base' />
						<p className='font-bold'>AI 유사조항</p>
						<p className='text-sm text-neutral-600'>이 계약 조항과 비슷한 유사 조항</p>
					</div>
					<AIReportRightPastReview />
				</div>

				<div id='similar-clauses'>
					<div className='z-10 sticky top-0 flex flex-col gap-3 py-3 bg-white'>
						<AIReportRightArticleNumbers />
					</div>
					<AIReportRightSimilarClauses />
				</div>
			</div>
		</div>
	);
}
