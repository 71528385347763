import { FormHelperText, TextFieldClasses } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';
import { FormikValues } from 'formik';
import { memo } from 'react';
import { dateFormat } from '../../../util/dateFormat';

interface IProps {
	id: string;
	label?: string;
	placeholder?: string;
	size?: 'small' | 'medium';
	classes?: Partial<TextFieldClasses>;
	format?: 'YYYY.MM.DD (ddd) A hh' | 'YYYY.MM.DD (ddd)';
	required?: boolean;
	disabled?: boolean;
	shouldDisableDate?: (date: Dayjs) => boolean;
	formik: FormikValues;
}

function InputDate({
	id,
	label,
	placeholder,
	size = 'medium',
	classes = { root: undefined },
	format = 'YYYY.MM.DD (ddd) A hh',
	required = false,
	disabled,
	shouldDisableDate,
	formik,
}: IProps) {
	const { root = 'rounded' } = classes;

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ko'>
			<FormControl
				className='w-full relative'
				size={size}
				error={formik.touched[id] && formik.errors[id] && true}
			>
				<DateTimePicker
					label={label}
					value={dateFormat(formik.values[id])}
					onChange={value => {
						if (format === 'YYYY.MM.DD (ddd) A hh') {
							formik.setFieldValue(id, value?.format('YYYY.MM.DD HH:00'));
						} else {
							formik.setFieldValue(id, value?.format('YYYY.MM.DD'));
						}
					}}
					views={
						format === 'YYYY.MM.DD (ddd) A hh'
							? ['year', 'month', 'day', 'hours']
							: ['year', 'month', 'day']
					}
					format={format}
					disabled={disabled}
					slotProps={{
						textField: {
							id,
							type: 'text',
							placeholder,
							size,
							classes: {
								root,
							},
							InputLabelProps: {
								required,
							},
							error: formik.touched[id] && formik.errors[id],
							InputProps: {
								classes: { input: '!text-gray-950 !text-opacity-100' },
							},
						},
					}}
					shouldDisableDate={shouldDisableDate}
				/>
				{formik.touched[id] && formik.errors[id] && (
					<FormHelperText classes={{ root: 'absolute bottom-0 translate-y-full' }} error>
						{formik.errors[id]}
					</FormHelperText>
				)}
			</FormControl>
		</LocalizationProvider>
	);
}

export default memo(InputDate);
