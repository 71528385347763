import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { IArticleHistoryItem } from '../../../model/api/ai';
import HighlightTextDiff from '../../common/HighlightTextDiff';
import ButtonText from '../../ui/buttons/ButtonText';
import ChevronRightIcon from '../../ui/icons/ChevronRight';

type Props = {
	item: IArticleHistoryItem;
};

export default function AIArticleHistoryItemCompare({ item }: Props) {
	const [currentIndex, setCurrentIndex] = useState<number>(0);

	const { history_map: historyMap } = item;
	const articleHistories = historyMap?.article_histories ?? [];

	const { currentHistory, nextHistory } = useMemo(
		() => ({ currentHistory: articleHistories[currentIndex], nextHistory: articleHistories[currentIndex + 1] }),
		[articleHistories, currentIndex],
	);

	const handlePrev = useCallback(() => {
		if (currentIndex > 0) {
			setCurrentIndex(currentIndex - 1);
		}
	}, [articleHistories, currentIndex]);

	const handleNext = useCallback(() => {
		if (currentIndex < articleHistories.length - 2) {
			setCurrentIndex(currentIndex + 1);
		}
	}, [articleHistories, currentIndex]);

	if (!historyMap) {
		return null;
	}

	return (
		<>
			<div className='flex justify-end'>
				<ButtonText className='min-w-min' onClick={handlePrev} disabled={currentIndex === 0}>
					<ChevronRightIcon className={`scale-x-[-1] ${currentIndex === 0 ? 'opacity-50' : ''}`} />
				</ButtonText>
				<ButtonText
					className='min-w-min'
					onClick={handleNext}
					disabled={currentIndex >= articleHistories.length - 2}
				>
					<ChevronRightIcon
						className={`${currentIndex >= articleHistories.length - 2 ? 'opacity-50' : ''}`}
					/>
				</ButtonText>
			</div>

			<div className='flex justify-between border min-h-[10rem]'>
				<div className='w-1/2 border-r flex flex-col'>
					<div className='flex justify-between p-2 text-neutral-600 text-xs border-b bg-gray-50'>
						<p>Ver.{currentIndex + 1}</p>
						<p>{dayjs(currentHistory.doc_created_time).format('YYYY/MM/DD HH:MM')}</p>
					</div>
					<div
						className={`text-neutral-800 text-sm flex-grow p-2 ${
							!currentHistory.article ? 'bg-red-200' : ''
						}`}
					>
						<HighlightTextDiff
							originText={currentHistory?.article?.title ?? ''}
							changedText={nextHistory?.article?.title ?? ''}
							type='DELETED'
							bold
						/>
						<div className='pl-2'>
							<HighlightTextDiff
								originText={
									currentHistory?.article?.clauses.map(clause => clause.content).join('\n') ?? ''
								}
								changedText={
									nextHistory?.article?.clauses.map(clause => clause.content).join('\n') ?? ''
								}
								type='DELETED'
							/>
						</div>
					</div>
				</div>
				<div className='w-1/2 flex flex-col'>
					<div className='flex justify-between p-2 text-neutral-600 text-xs border-b bg-gray-50'>
						<p>Ver.{currentIndex + 2}</p>
						<p>{dayjs(nextHistory.doc_created_time).format('YYYY/MM/DD HH:MM')}</p>
					</div>
					<div
						className={`text-neutral-800 text-sm flex-grow p-2 ${!nextHistory.article ? 'bg-red-200' : ''}`}
					>
						<HighlightTextDiff
							originText={currentHistory?.article?.title ?? ''}
							changedText={nextHistory?.article?.title ?? ''}
							type='ADDED'
							bold
						/>
						<br />
						<div className='pl-2'>
							<HighlightTextDiff
								originText={
									currentHistory?.article?.clauses.map(clause => clause.content).join('\n') ?? ''
								}
								changedText={
									nextHistory?.article?.clauses.map(clause => clause.content).join('\n') ?? ''
								}
								type='ADDED'
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
