import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { FormikValues } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubsidiary } from '../../context/SubsidiaryContext';

type SelectPropsOverrides = SelectProps & {
	individualId?: number;
	setIndividualId?: Dispatch<SetStateAction<number>>;
	size?: 'small' | 'medium';
	required?: boolean;
	label?: string;
	formikId?: string;
	formik?: FormikValues;
	disabled?: boolean;
};

export default function SubsidiarySelector({
	individualId,
	setIndividualId,
	formikId,
	classes = { root: undefined },
	size = 'medium',
	label,
	required = false,
	formik,
	disabled = false,
}: SelectPropsOverrides) {
	const { t } = useTranslation();
	const { root = 'rounded' } = classes;
	const { id, setId, subsidiaries, isActive } = useSubsidiary();

	if (!isActive) {
		return null;
	}

	const handleChange = (event: SelectChangeEvent<number>) => {
		if (setIndividualId) {
			setIndividualId(Number(event.target.value));
		} else if (formik && formikId) {
			formik.setFieldValue(formikId, Number(event.target.value));
		} else {
			setId(Number(event.target.value));
		}
	};

	return (
		<FormControl
			classes={{
				root: `relative w-full ${root}`,
			}}
			size={size}
			error={formik && formikId && formik.touched[formikId] && formik.errors[formikId] && true}
		>
			<InputLabel id={`${formikId}_label`} required={required}>
				{label}
			</InputLabel>
			<Select
				className='w-full'
				labelId={`${formikId}_label`}
				label={label}
				value={individualId || (formikId && formik?.values[formikId]) || id}
				onChange={handleChange}
				displayEmpty
				disabled={disabled}
			>
				<MenuItem value={-1}>{t('MSG_ID_1255')}</MenuItem>
				{subsidiaries.map(({ id: itemId, name }) => (
					<MenuItem key={itemId} value={itemId}>
						{name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
