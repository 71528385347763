import { useQuery } from '@tanstack/react-query';
import { memo } from 'react';
import { getCompanyInfoSummary } from '../../../api/login';
import { queryKey } from '../../../constants/queryKey';

function Footer() {
	const { data: company } = useQuery(queryKey.companyInfoSummary, getCompanyInfoSummary, { staleTime: 60000 });

	return (
		<footer className='flex flex-col justify-between px-8 py-2 bg-gray-900 lg:flex-row'>
			<div className='flex items-center justify-center ml-auto'>
				<div className='flex items-center gap-3'>
					<span className='text-s text-white' />
					<span className='text-s text-white'>v2.9</span>
					<span className='text-s text-white'>
						©{company?.name} [{company?.serverId}]
					</span>
				</div>
			</div>
		</footer>
	);
}

export default memo(Footer);
