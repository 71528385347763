import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from 'react';

interface IProps {
	children: ReactNode;
}

interface IProvider {
	pdf: Uint8Array | undefined;
	setPdf: Dispatch<SetStateAction<Uint8Array | undefined>>;
}

const context = createContext<IProvider | undefined>(undefined);

/**
 * Sign Provicder
 * @State pdf
 */
export default function ProviderPage({ children }: IProps) {
	const [pdf, setPdf] = useState<Uint8Array | undefined>(undefined);

	const value = useMemo(() => ({ pdf, setPdf }), [pdf]);

	return <context.Provider value={value}>{children}</context.Provider>;
}

export const useProviderPage = () => {
	const result = useContext(context);
	if (!result) throw new Error(`Sign context is undefined`);
	return result;
};
