import update from 'immutability-helper';
import { uniqueId } from 'lodash';
import { memo, useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';
import { dndItemTypes } from '../../../../constants/dndType';
import ButtonText from '../../../ui/buttons/ButtonText';
import ItemField from './ItemField';

interface IField {
	id: string | number;
	text: string;
}
const FIELDS: IField[] = [
	{
		id: 1,
		text: '테스트 항목 1',
	},
	{
		id: 2,
		text: '테스트 항목 2',
	},
	{
		id: 3,
		text: '테스트 항목 3',
	},
	{
		id: 4,
		text: '테스트 항목 4',
	},
	{
		id: 5,
		text: '테스트 항목 5',
	},
	{
		id: 6,
		text: '테스트 항목 6',
	},
	{
		id: 7,
		text: '테스트 항목 7',
	},
];

function DustbinField() {
	const [fields, setFields] = useState(FIELDS);

	const findItem = useCallback(
		(id: string | number) => {
			const field = fields.filter(c => `${c.id}` === id)[0] as IField;
			return {
				field,
				index: fields.indexOf(field),
			};
		},
		[fields],
	);

	const moveItem = useCallback(
		(id: string | number, atIndex: number) => {
			const { field, index } = findItem(id);

			setFields(
				update(fields, {
					$splice: [
						[index, 1],
						[atIndex, 0, field],
					],
				}),
			);
		},
		[findItem, fields, setFields],
	);

	const [, drop] = useDrop(() => ({ accept: dndItemTypes.field }));

	const handleAddField = () => {
		const id = uniqueId(`${fields.length}`);

		setFields(
			update(fields, {
				$push: [
					{
						id,
						text: `테스트 항목 ${id}`,
					},
				],
			}),
		);
	};

	const deleteItem = (id: string | number) => {
		const { index } = findItem(id);

		// console.log(id);

		setFields(
			update(fields, {
				$splice: [[index, 1]],
			}),
		);
	};

	return (
		<div ref={drop} className='grid gap-4'>
			{fields.map(field => (
				<ItemField
					key={field.id}
					id={`${field.id}`}
					text={field.text}
					deleteItem={deleteItem}
					moveItem={moveItem}
					findItem={findItem}
				/>
			))}
			<ButtonText
				classes={{ root: 'min-w-0 py-0 font-bold border-2 border-gray-300 rounded-2xl' }}
				text='+'
				variant='outlined'
				onClick={handleAddField}
			/>
		</div>
	);
}

export default memo(DustbinField);
