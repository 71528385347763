import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getPaperManageList } from '../../../api/paperManage';
import PaperManageTable from '../../../components/main/paperManage/PaperManageTable';
import PopupCreatePaperManage from '../../../components/main/paperManage/PopupCreatePaperManage';
import TableSkeleton from '../../../components/ui/tables/TableSkeleton';
import { usePaperManageList } from '../../../context/PaperManageContext';
import usePaperManage from '../../../hooks/usePaperManage';
import { PaperManageTableRow } from '../../../model/paperManage';
import { IHeadCell } from '../../../model/table';
import SysConfigStore from '../../../store/common/SysConfigStore';

export default function PaperManageList() {
	const { t } = useTranslation();
	const { searchParams } = usePaperManageList();
	const { listKeys: keys } = usePaperManage();
	const { data: paginationData } = useQuery(keys, () => getPaperManageList(searchParams));
	const { isModuleEnabled, isComponentEnabled, labelText } = SysConfigStore();

	const headCells: IHeadCell<PaperManageTableRow>[] = [
		{
			id: 'no',
			width: 'w-[70px]',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: labelText('cmmn_label_number_no'),
		},
		{
			id: 'company',
			width: 'w-[100px]',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('label_company_name'),
		},
		{
			id: 'paperType',
			width: 'w-[100px]',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('paper_manage_type'),
		},
		{
			id: 'title',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('paper_manage_title'),
		},
		{
			id: 'manager',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('paper_manage_manager'),
		},
		{
			id: 'paperCount',
			width: 'w-[100px]',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('left_count'),
		},
		{
			id: 'sealStamp',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('seal_stamp'),
		},
		{
			id: 'id',
			width: 'w-[100px]',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('paper_manage_viewcode'),
		},
		{
			id: 'isActive',
			width: 'w-[100px]',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('is_active'),
		},
		{
			id: 'createTime',
			width: 'w-[100px]',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('MSG_ID_141'),
		},
	];

	return (
		<section className='flex flex-col gap-6 px-6 py-14'>
			<header className='flex items-center justify-between'>
				<h2 className='text-2xl'>{t('paper_manage')}</h2>
				<PopupCreatePaperManage />
			</header>
			{paginationData ? (
				<PaperManageTable
					list={paginationData.data}
					headCells={headCells}
					totalListCount={paginationData.recordsTotal}
				/>
			) : (
				<TableSkeleton colSpan={headCells.length + 1} />
			)}
		</section>
	);
}
