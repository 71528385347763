import { Divider } from '@mui/material';
import _ from 'lodash';
import { Fragment, useMemo } from 'react';
import { useAIReport } from '../../../../context/AIReportContext';
import { extractHistoryMapAndIndex } from '../../../../util/ai';
import AIArticleHistoryItemClickHandler from '../../common/AIArticleHistoryItemClickHandler';

export default function AIReportRightSimilarClauses() {
	const { data, focusState } = useAIReport();
	const { all_similar_clauses: allSimilarClauses, all_article_histories: allArticleHistories } = data;

	const displayNodes = useMemo(() => {
		if (!focusState) {
			const flatAllNodes = allSimilarClauses.flatMap(({ result }) => result);
			return _.uniqBy(flatAllNodes, 'article_id');
		}

		const { clause, article } = focusState;

		if (clause && article) {
			const flatClauseMatchNodes = allSimilarClauses
				.filter(({ query_clause: queryClause }) => queryClause.id === clause.id)
				.flatMap(({ result }) => result);

			return _.uniqBy(flatClauseMatchNodes, 'article_id');
		}
		if (article) {
			const flatArticleMatchNodes = allSimilarClauses
				.filter(({ query_clause: queryClause }) => queryClause.article_id === article.id)
				.flatMap(({ result }) => result);

			return _.uniqBy(flatArticleMatchNodes, 'article_id');
		}

		return [];
	}, [allSimilarClauses, focusState]);

	return (
		<div className={`flex flex-col px-2 ${!focusState?.clause ? 'bg-blue-50' : ''}`}>
			{displayNodes &&
				displayNodes
					.sort((a, b) => b.score.cosine - a.score.cosine)
					.map(node => {
						const item = extractHistoryMapAndIndex(node, allArticleHistories);
						const nodeId = focusState?.clause ? node.id : undefined;
						return (
							<Fragment key={node.id}>
								<div key={node.id} className='p-6'>
									<AIArticleHistoryItemClickHandler item={item} nodeId={nodeId} />
								</div>
								<Divider />
							</Fragment>
						);
					})}
		</div>
	);
}
