import { memo, useMemo } from 'react';
import { useAlert } from '../../../../hooks/useAlert';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import { IOfficialLetter, IStep } from '../../../../model/officialLetter';
import { useProviderPage } from '../../../../pages/admin/officialLetter/workflow/ProviderPage';
import CardBox from '../../../ui/containers/CardBox';
import ProcessIcon from '../../../ui/icons/ProcessIcon';
import InputText from '../../../ui/inputs/InputText';
import ButtonModityStep from './ButtonModityStep';

interface IProps {
	processId: string;
	data: IStep;
}
function ItemStep({ processId, data }: IProps) {
	const { setOfficialLetter } = useProviderPage();
	const [snackbar] = useAlert();
	const validationSchema = useMemo(
		() =>
			Object.entries(data)
				.filter(([key]) => key !== 'id' && key !== 'options')
				.map(([key, value]) => [
					`${processId}_${data.id}_${key}`,
					{ initialValue: value, type: 'string', required: true },
				]),
		[processId, data],
	);

	const formik = useValidateForm({
		validationSchema: Object.fromEntries(validationSchema),
		onSubmit: v => {
			const title = Object.entries(v).filter(([key, value]) => key.split('_')[2] === 'title')[0][1];
			setOfficialLetter(
				prev =>
					({
						...prev,
						process: prev.process.map(item => {
							return {
								...item,
								steps: item.steps.map(step => {
									return step.id === data.id && processId === item.id
										? {
												...step,
												title,
												options: {
													...step.options,
													isDisabled: true,
												},
										  }
										: {
												...step,
												options: {
													...step.options,
													isDisabled: true,
												},
										  };
								}),
							};
						}),
					}) as IOfficialLetter,
			);
			snackbar(`[${title}] 단계 저장 완료했습니다`, 'success');
		},
	});

	return (
		<>
			<li className='relative'>
				<span className='absolute -left-8 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-7 h-7 text-neutral-400 border-neutral-400 rounded-full border bg-white'>
					<ProcessIcon fontSize='inherit' />
				</span>
				<CardBox className='grid grid-cols-4 gap-4 p-6'>
					<div className='col-span-3 grid grid-cols-2 gap-4'>
						<div>
							<InputText
								id={`${processId}_${data.id}_title`}
								labelText='단계명'
								size='small'
								disabled={data.options.isDisabled}
								required
								formik={formik}
							/>
						</div>

						{/* <div className='grid grid-cols-[auto,1fr] gap-1'>
							<div className='flex items-center'>
								<InputLabel required>단계 오너</InputLabel>
								<InfoTooltip title='주요 액션을 수행하는 유저입니다. 액션 버튼 권한이 있습니다.' />
							</div>
							<div className='flex items-center'>
								<InputSelect
									id={`${processId}_${data.id}_owner`}
									label='단계 오너'
									size='small'
									options={Object.entries(ROLE_NAME).map(([value, name]) => ({ name, value }))}
									disabled={'deleted' in data.options ? data.options.disabled : true}
									required
									formik={formik}
								/>
							</div>
						</div> */}
					</div>

					<div className='col-span-1'>
						<div className='flex items-center justify-end gap-2'>
							{/* {'deleted' in data.options && <ButtonRemoveStep processId={processId} stepId={data.id} />} */}
							<ButtonModityStep processId={processId} data={data} formik={formik} />
						</div>
					</div>
				</CardBox>
			</li>
			{/* {processId !== 'complete' && (
				<div className='flex justify-center my-2'>
					<ButtonAddStep />
				</div>
			)} */}
		</>
	);
}

export default memo(ItemStep);
