import { DEXT5Editor } from 'dext5editor-react';
import { memo } from 'react';
import { STATIC_DIR } from '../../../constants/common';

interface IProps {
	id: string;
	data?: string;
	mode?: 'view' | 'edit';
}
function EditorDext5({ id, data, mode = 'edit' }: IProps) {
	return (
		<DEXT5Editor
			key={id}
			id={id}
			config={{ DevelopLangage: 'NONE', Width: '100%', Mode: mode }}
			initData={data}
			componentUrl={`${STATIC_DIR}/dext5editor/js/dext5editor.js`}
		/>
	);
}

export default memo(EditorDext5);
