import { InputLabel } from '@mui/material';
import { FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { SealStampProvider } from '../../../context/SealStampContext';
import { SealStamp } from '../../../model/sealStamp';
import ItemChip from '../../ui/chips/ItemChip';
import InfoTooltip from '../../ui/tooltip/InfoTooltip';
import PopupSealStamp from './PopupSealStamp';

type Props = {
	formikId: string;
	formik: FormikValues;
	disabled?: boolean;
};

export default function InputSealStamp({ formikId, formik, disabled = false }: Props) {
	const { t } = useTranslation();

	const handleDelete = (objIdToDelete: number) => {
		formik.setFieldValue(
			formikId,
			formik.values[formikId].filter((sealStamp: SealStamp) => sealStamp.seal_stamp_id !== objIdToDelete),
		);
	};

	return (
		<>
			<InputLabel>
				{t('seal_stamp')}
				<InfoTooltip title={t('cmmn_guide_paper_management')} />
			</InputLabel>

			<SealStampProvider>
				{!disabled && <PopupSealStamp id={formikId} formik={formik} />}
				{formik.values[formikId] &&
					formik.values[formikId].map((sealStamp: SealStamp) => (
						<ItemChip
							key={sealStamp.seal_stamp_id}
							text={sealStamp.name}
							onDelete={() => handleDelete(sealStamp.seal_stamp_id)}
							disabled={disabled}
						/>
					))}
			</SealStampProvider>
		</>
	);
}
