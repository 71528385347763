import React, { useCallback, useEffect } from 'react';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';
import { useAIArticle } from '../../../context/AIArticleContext';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { DOMAIN_URL } from '../../../constants/common';
import ButtonText from '../../ui/buttons/ButtonText';
import OpenInIcon from '../../ui/icons/OpenInIcon';
import ClearIcon from '../../ui/icons/ClearIcon';
import { combineContentsInArticle, processCategoriesInClauses } from '../../../util/ai';
import StateChip from '../../ui/chips/StateChip';
import { AI_CLAUSE_CATEGORY_MAP } from '../../../constants/aiConstans';
import InputSwitch from '../../ui/inputs/InputSwitch';
import AIArticleHistoryItemCompare from './AIArticleHistoryItemCompare';
import AdeleLegalLinkHandler from '../../common/AdeleLegalLinkHandler';
import DescriptionIcon from '../../ui/icons/DescriptionIcon';
import BookmarkBorderIcon from '../../ui/icons/BookmarkBorderIcon';
import CopyToClipboard from '../../common/CopyToClipboard';
import ContentCopyIcon from '../../ui/icons/ContentCopyIcon';
import InfoIcon from '../../ui/icons/InfoIcon';
import AIArticleContentHistoryTab from './AIArticleContentHistoryTab';

type Props = {
	type: 'DETAIL' | 'SLIDE';
};

export default function AIArticleContent({ type }: Props) {
	const { detailState, setDetailState, slideState, setSlideState, setFavoriteState } = useAIArticle();

	const state = type === 'DETAIL' ? detailState : slideState;

	const compareFormik = useValidateForm({
		validationSchema: {
			switch: { initialValue: false, type: 'boolean', required: true },
		},
	});

	useEffect(() => {
		compareFormik.setValues({ switch: false });
	}, [state]);

	const handlePDFView = useCallback((docId: number) => {
		window.open(`${DOMAIN_URL}/contract/getscandocfile/?${docId}`, '_blank', 'noopener,noreferrer');
	}, []);

	const handleNewTab = useCallback(() => {
		const article = state?.history_map?.article_histories[state?.index].article;
		if (article) {
			setDetailState(article.id, state);
			window.open(`/buptle_ai/article/detail/${article.id}`, '_blank');
		}
	}, [state]);

	const handleSave = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			if (state) {
				setFavoriteState({
					history_map: state.history_map,
					index: state.index,
					anchorEl: e.currentTarget,
				});
			}
		},
		[state],
	);

	if (!state?.history_map) {
		return null;
	}

	const { history_map: historyMap, index: historyIndex } = state;

	const { contract, article_histories: articleHistories } = historyMap;
	const selectedHistory = articleHistories[historyIndex];
	const { doc_id: docId, doc_created_time: docCreatedTime, article } = selectedHistory;

	return (
		<>
			<div className='flex justify-between'>
				<h3 className='text-xl font-bold'>{article?.title}</h3>
				{type === 'SLIDE' && (
					<div>
						<ButtonText onClick={handleNewTab}>
							<OpenInIcon />
						</ButtonText>
						<ButtonText onClick={() => setSlideState(prev => ({ ...prev, display: false }))}>
							<ClearIcon />
						</ButtonText>
					</div>
				)}
			</div>
			<div className='flex gap-1 py-1 overflow-hidden truncate'>
				{processCategoriesInClauses(article?.clauses).map(key => (
					<StateChip key={key} text={AI_CLAUSE_CATEGORY_MAP[key]} />
				))}
			</div>
			<br />
			<Divider />
			{articleHistories.some(({ article: item }) => item && item?.change_type !== 'NOT_CHANGED') && (
				<>
					<div className='flex justify-between'>
						<div className='flex items-center gap-2 truncate'>
							<InfoIcon />
							<p className='text-blue-600 '>이 조항은 과거 검토중 수정이력을 가지고 있어요</p>
						</div>
						<InputSwitch id='switch' label='비교보기' labelPlacement='start' formik={compareFormik} />
					</div>
					<div className='flex justify-end'>
						{!compareFormik.values.switch && <AIArticleContentHistoryTab type={type} />}
					</div>
				</>
			)}
			<br />
			{compareFormik.values.switch ? (
				<AIArticleHistoryItemCompare item={state} />
			) : (
				<>
					<p className='font-semibold'>{article?.title}</p>
					<p className='whitespace-pre-line'>{combineContentsInArticle(article)}</p>
				</>
			)}
			<br />
			<div className='flex items-center gap-5'>
				<p className='font-semibold'>계약</p>
				<div className='flex items-center'>
					<p>{dayjs(docCreatedTime).format('YYYY/MM/DD')}</p>
					<AdeleLegalLinkHandler id={contract.app_id} type='CONTRACT' newTab>
						<ButtonText
							className='text-blue-600 font-semibold underline'
							onClick={e => e.stopPropagation()}
						>
							{contract.title}
						</ButtonText>
					</AdeleLegalLinkHandler>
				</div>
			</div>
			<br />
			<Divider />
			<div className='flex items-center justify-between py-2'>
				<ButtonText onClick={() => handlePDFView(docId)} variant='outlined'>
					<div className='flex items-center gap-1 text-neutral-600'>
						<DescriptionIcon />
						<p>계약서 PDF</p>
					</div>
				</ButtonText>
				<div className='flex gap-3'>
					<ButtonText
						variant='outlined'
						onClick={e => {
							handleSave(e);
						}}
					>
						<div className='flex items-center gap-1 text-neutral-600'>
							<BookmarkBorderIcon />
							<p>조항 저장</p>
						</div>
					</ButtonText>
					<CopyToClipboard text={combineContentsInArticle(article)}>
						<ButtonText variant='outlined'>
							<ContentCopyIcon />
							<p>텍스트 복사</p>
						</ButtonText>
					</CopyToClipboard>
				</div>
			</div>
			<div />
			<Divider />
			<br />
		</>
	);
}
