import { uniqueId } from 'lodash';
import { memo } from 'react';
import { IWorkflow } from '../../../../model/officialLetter';
import { dateFormat } from '../../../../util/dateFormat';
import ButtonText from '../../../ui/buttons/ButtonText';
import StateChip from '../../../ui/chips/StateChip';
import UserChip from '../../../ui/chips/UserChip';
import ButtonWorkflow from './ButtonWorkflow';
import PopupDeleteItemWorkflow from './PopupDeleteItemWorkflow';

interface IProps {
	data: IWorkflow;
}

const typeToStringMap: { [key: number]: string } = {
	1: '기본',
	2: '수신본검토',
};

function ItemWorkflow({ data }: IProps) {
	const { id, title, description, type, createdBy, statusCounts } = data;
	const { formatting = 0, inProgress = 0, completed = 0 } = statusCounts;

	return (
		<div className='pb-4 border-b border-neutral-300'>
			<div className='flex justify-between'>
				<div className='grid grid-cols-[auto,1fr] items-center gap-2'>
					{!createdBy && type && <StateChip text={typeToStringMap[type]} />}
					<p className='truncate' title={title}>
						{title}
					</p>
				</div>

				<div className='shrink-0 flex items-center gap-3'>
					<span className='text-xs'>적용 서식 {formatting}</span>
					<span className='text-xs'>공문 진행중 {inProgress}</span>
					<span className='text-xs'>공문 완료 {completed}</span>
				</div>
			</div>
			<div className='my-2'>
				<p className='min-h-[20px] text-sm'>{description}</p>
			</div>
			<div className='flex items-center justify-between'>
				<div>
					<p className='mb-1 text-sm'>{dateFormat('20240523').format('YYYY/MM/DD')}</p>
					<div className='flex items-center gap-2'>
						<p className='text-sm'>생성자</p>
						{createdBy && <UserChip id={String(createdBy.id)} user={createdBy} size='small' disabled />}
						{!createdBy && (
							<UserChip
								id={uniqueId()}
								user={{ id: parseInt(uniqueId(), 10), name: '시스템', email: '' }}
								size='small'
								disabled
							/>
						)}
					</div>
				</div>
				<div className='flex gap-3'>
					{createdBy && <PopupDeleteItemWorkflow data={data} />}
					{type === 1 && <ButtonText text='초기화' variant='outlined' size='small' />}
					{type !== 2 && <ButtonText text='복제' variant='outlined' size='small' />}
					<ButtonWorkflow
						id={String(id) || ''}
						text='수정'
						variant='contained'
						color='primary'
						size='small'
					/>
				</div>
			</div>
		</div>
	);
}

export default memo(ItemWorkflow);
