import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAIReport } from '../../../../context/AIReportContext';
import { IStoreArticle } from '../../../../model/api/ai';
import AIReportLeftArticleTable from './AIReportLeftArticleTable';

export default function AIReportLeftContract() {
	const { data, refMap, focusState, setFocusState, categoryNavigator } = useAIReport();
	const { articles, etc, store_info: storeInfo } = data;
	const { leftContainerRef, contractContainerRef } = refMap;

	const lastScrollTop = useRef(0);

	const focusWithScroll = useCallback(() => {
		const leftContainer = leftContainerRef.current;
		const contractContainer = contractContainerRef.current;

		if (!leftContainer || !contractContainer) {
			return;
		}

		const articleElements = Array.from(leftContainer.querySelectorAll('div[id^="article-"]')) as HTMLElement[];
		const scrollDirectionDown = leftContainer.scrollTop > lastScrollTop.current;
		lastScrollTop.current = leftContainer.scrollTop;

		const leftContainerRect = leftContainer.getBoundingClientRect();
		const nearestElement = articleElements.filter(element => {
			const elementRect = element.getBoundingClientRect();

			if (scrollDirectionDown) {
				return elementRect.height + elementRect.top - leftContainerRect.top - 15 > 0;
			}
			return elementRect.top - leftContainerRect.top > 0;
		})[0];

		const isEnterArticleArea = leftContainerRect.top - contractContainer.getBoundingClientRect().top > 0;

		if (nearestElement) {
			const articleId = Number(nearestElement.id.split('article-')[1]);

			if (scrollDirectionDown && (!focusState || Number(focusState?.article?.id) < articleId)) {
				setFocusState({
					article: {
						id: articleId,
						element: nearestElement,
					},
					clause: null,
					scroll: false,
				});

				const clausesArea = window.document.querySelector('#similar-clauses') as HTMLElement;
				if (isEnterArticleArea && clausesArea.offsetTop > window.scrollY) {
					window.scrollTo({
						top: clausesArea.offsetTop,
						behavior: 'smooth',
					});
				}
			} else if (!scrollDirectionDown && (!focusState || Number(focusState?.article?.id) > articleId)) {
				setFocusState({
					article: {
						id: articleId,
						element: nearestElement,
					},
					clause: null,
					scroll: false,
				});

				const clausesArea = window.document.querySelector('#similar-clauses') as HTMLElement;
				if (isEnterArticleArea && clausesArea.offsetTop > window.scrollY) {
					window.scrollTo({
						top: clausesArea.offsetTop,
						behavior: 'smooth',
					});
				}
			}
		}
	}, [focusState, leftContainerRef, setFocusState]);

	const debouncedFocusWithScroll = useCallback(debounce(focusWithScroll, 200), [focusWithScroll]);

	useEffect(() => {
		const container = leftContainerRef.current;
		if (!container || categoryNavigator.navigatorAnchorEl) {
			return () => {};
		}

		container.addEventListener('scroll', debouncedFocusWithScroll);

		return () => {
			container.removeEventListener('scroll', debouncedFocusWithScroll);
		};
	}, [debouncedFocusWithScroll, leftContainerRef, categoryNavigator]);

	const mergedData = useMemo(() => {
		const etcMap = new Map(etc.map(item => [item.index, item]));
		const totalLength = (etc?.length || 0) + articles.length;

		return Array.from({ length: totalLength }, (_, i) => {
			if (etcMap.has(i)) {
				return etcMap.get(i);
			}
			const articleIndex = i - Array.from(etcMap.keys()).filter(key => key < i).length;
			return articles[articleIndex];
		});
	}, [data]);

	return (
		<div className='flex flex-col gap-5'>
			<div className='px-20 py-10 border-b'>
				<p className='text-4xl text-center'>{storeInfo.contract.title}</p>
			</div>
			<div className='grid gap-14 px-20 pb-14'>
				{mergedData.map(item => {
					if (!item) {
						return null;
					}

					if ('index' in item) {
						return (
							<p key={`etc-${item.index}`} className='whitespace-pre-line text-neutral-600'>
								{item.content}
							</p>
						);
					}

					const article = item as IStoreArticle;
					return <AIReportLeftArticleTable key={`article-${article.id}`} article={article} />;
				})}
			</div>
		</div>
	);
}
