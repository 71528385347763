import FontFaceObserver from 'fontfaceobserver';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { TCanvasRef } from '../model/canvas';

/**
 * @description Canvas 로 작성한 내용을 Image 파일로 변환 후 다운로드
 * @ref Canvas Element
 * @imageName 이미지 파일명
 */
export const downloadCanvasAsImage = (ref: TCanvasRef, imageName: string = 'image.png') => {
	const canvas = (ref as MutableRefObject<SignatureCanvas>).current.getCanvas();
	canvas.getContext('2d', { willReadFrequently: true });

	const image = canvas.toDataURL('image/png');
	const link = document.createElement('a');
	link.href = image;
	link.download = imageName;
	link.click();
};

/**
 * @description Canvas 로 작성한 내용을 Base64 변환 후 return
 * @ref Canvas Element
 */
export const getCanvasAsImage = (ref: TCanvasRef) => {
	const canvas = (ref as MutableRefObject<SignatureCanvas>).current.getCanvas();
	canvas.getContext('2d', { willReadFrequently: true });

	const data = canvas.toDataURL('image/png');
	return data;
};

/**
 * @description Canvas 내용 제거
 * @ref Canvas Element
 */
export const clearCanvas = (ref: TCanvasRef) => {
	(ref as MutableRefObject<SignatureCanvas>).current.clear();
};

interface ICreateDigitalSealStamp {
	scale?: number;
	borderWidth?: number;
	radius?: number;
	x?: number;
	y?: number;
	color?: string;
	width?: number;
	height?: number;
	text: string;
	setImage: Dispatch<SetStateAction<string | undefined>>;
}

/**
 * @description Canvas 에 둥근 border 그리기
 */
const drawRoundedRect = ({
	context,
	x = 0,
	y = 0,
	color = '#D5261E',
	width = 270,
	height = 70,
	scale = 3,
	borderWidth = 5,
	radius = 5,
}: Omit<ICreateDigitalSealStamp, 'setImage' | 'text'> & { context: CanvasRenderingContext2D }) => {
	const scaleToBorderWidth = borderWidth * scale;
	const scaleToRadius = radius * scale;
	const calcX = x + scaleToBorderWidth / 2;
	const calcY = y + scaleToBorderWidth / 2;
	const calcWidth = width - scaleToBorderWidth;
	const calcHeight = height - scaleToBorderWidth;
	context.strokeStyle = color;
	context.lineWidth = scaleToBorderWidth;

	context.beginPath();
	context.moveTo(calcX + scaleToRadius, calcY);
	context.arcTo(calcX + calcWidth, calcY, calcX + calcWidth, calcY + calcHeight, scaleToRadius);
	context.arcTo(calcX + calcWidth, calcY + calcHeight, calcX, calcY + calcHeight, scaleToRadius);
	context.arcTo(calcX, calcY + calcHeight, calcX, calcY, scaleToRadius);
	context.arcTo(calcX, calcY, calcX + calcWidth, calcY, scaleToRadius);
	context.closePath();
	context.stroke();
};

/**
 * @description 이미지 생성
 */
export const createDigitalSealStamp = ({
	text,
	color = '#D5261E',
	width = 270,
	height = 70,
	scale = 3,
	borderWidth = 5,
	radius = 5,
	setImage,
}: ICreateDigitalSealStamp) => {
	const font = new FontFaceObserver('Hanjunseo');
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;
	canvas.width = width * scale;
	canvas.height = height * scale;

	// 배경색 (현재는 필요 없음)
	// context.fillStyle = '#FFF';
	// drawRoundedRect({ context, color: '#FFF', radius, width: canvas.width, height: canvas.height });
	// context.fill();

	drawRoundedRect({
		context,
		color,
		radius,
		width: canvas.width,
		height: canvas.height,
	});

	font.load() //
		.then(() => {
			const lines = text.split('\r\n');
			const fontSize = 24 * scale;
			context.font = `${fontSize}px Hanjunseo`;

			// 라인별 Text 높이 값
			const lineMetrics = lines.map(line => context.measureText(line));
			const textHeight = lineMetrics.reduce(
				(acc, metrics) => acc + metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
				0,
			);

			const canvasWidth = canvas.width;
			const canvasHeight = canvas.height;

			// 초기 y 값
			let currentY = (canvasHeight - textHeight) / 2;

			context.fillStyle = color;

			lines.forEach((line, index) => {
				const metrics = lineMetrics[index];
				const textWidth = metrics.width;
				const currentX = (canvasWidth - textWidth) / 2;
				currentY += metrics.actualBoundingBoxAscent - borderWidth;
				context.fillText(line, currentX, currentY);
				currentY += metrics.actualBoundingBoxDescent + borderWidth * 3;
			});

			context.scale(scale, scale);
			const data = canvas.toDataURL('image/png');
			setImage(data);
		});
};
