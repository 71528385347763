import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { contractCreateUrl, counselCreateUrl } from '../../../constants/adeleUrl';
import { STATIC_DIR } from '../../../constants/common';
import { useUser } from '../../../context/UserContext';
import SysConfigStore from '../../../store/common/SysConfigStore';
import { navigateToAdele } from '../../../util/commonUtils';
import ButtonText from '../../ui/buttons/ButtonText';
import AddCounselIcon from '../../ui/icons/AddCounselIcon';
import AddContractIcon from '../../ui/icons/apps/AddContractIcon';
import PopoverInternalState from '../../ui/popovers/PopoverInternalState';
import PopupDefault from '../../ui/popups/PopupDefault';
import AlarmButtonDashboard from './AlarmButtonDashboard';
import AlarmDisplayDashboard from './AlarmDisplayDashboard';
import NoticeListDashboard from './NoticeListDashboard';
import StatusDashboardDefault from './StatusDashboardDefault';
import StatusDashboardLawyer from './StatusDashboardLawyer';
import StatusDigitalCertificationDashboardDefault from './StatusDigitalCertificationDashboardDefault';
import StatusSignSealProcessDashboardDefault from './StatusSignSealProcessDashboardDefault';

export default function HeaderDashboard() {
	const { isLawyer, permission } = useUser();
	const { isModuleEnabled, isComponentEnabled } = SysConfigStore();
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [count, setCount] = useState(10);
	const [createType, setCreateType] = useState<
		'CONTRACT' | 'COUNSEL' | 'CONTRACT_ETC' | 'CONTRACT_SIGN_SEAL_PROCESS'
	>('CONTRACT');

	const handleNavigate = (type: 'CONTRACT' | 'COUNSEL' | 'CONTRACT_ETC' | 'CONTRACT_SIGN_SEAL_PROCESS') => {
		const urlMap = {
			CONTRACT: contractCreateUrl,
			COUNSEL: counselCreateUrl,
			CONTRACT_ETC: contractCreateUrl,
			CONTRACT_SIGN_SEAL_PROCESS: contractCreateUrl,
		};

		if (['CONTRACT', 'CONTRACT_ETC', 'CONTRACT_SIGN_SEAL_PROCESS'].includes(type)) {
			sessionStorage.setItem('/contract/get_list_view_template/', JSON.stringify({ template_type: type }));
		}

		if (['COUNSEL'].includes(type) && isComponentEnabled('CMPNT169_COUNSEL_CUSTOM_BF')) {
			urlMap.COUNSEL = '/counsel/get-list-view-v28/';
			sessionStorage.setItem('CMPNT169_COUNSEL_CUSTOM_BF_isAutoOpen', 'true');
		}

		navigateToAdele(urlMap[type]);
	};

	const handleTogglePopup = () => {
		setOpen(prev => !prev);
	};

	const goToCreate = (type: 'CONTRACT' | 'COUNSEL' | 'CONTRACT_ETC' | 'CONTRACT_SIGN_SEAL_PROCESS') => {
		if (isModuleEnabled('CREATE_CONTRACT_COUNSEL_GUIDE_POPUP')) {
			setCreateType(type);
			handleTogglePopup();
		} else {
			handleNavigate(type);
		}
	};

	const infoModalConfirm = () => {
		if (count >= 0) return `${count} ${t('cmmn_guide_have_confirmed_the_contents')}`;

		return t('cmmn_guide_have_confirmed_the_contents');
	};

	useEffect(() => {
		let interval: string | number | NodeJS.Timer | undefined;
		// 1초마다 카운트 다운
		if (open) {
			setCount(10);
			interval = setInterval(() => {
				setCount(prevCount => prevCount - 1);
			}, 1000);
		} else {
			setCount(10);
		}

		if (count < 0) {
			clearInterval(interval);
		}

		// 컴포넌트가 언마운트될 때 인터벌 정리
		return () => clearInterval(interval);
	}, [open]);

	return (
		<header className='flex flex-col gap-5 w-full lg:flex-row'>
			<div className='basis-4/5 grid grid-cols-1 gap-5 lg:grid-cols-4'>
				<PopoverInternalState openTrigger={<AlarmButtonDashboard />}>
					<AlarmDisplayDashboard />
				</PopoverInternalState>
				{isLawyer ? <StatusDashboardLawyer /> : <StatusDashboardDefault />}
				<NoticeListDashboard />
				{isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF') ? (
					<StatusSignSealProcessDashboardDefault />
				) : null}
				{isComponentEnabled('CMPNT163_DIGITAL_CERTIFICATE_BF') ? (
					<StatusDigitalCertificationDashboardDefault />
				) : null}
			</div>

			<div className='basis-1/5 flex flex-col gap-5'>
				<ButtonText
					classes={{ root: 'gap-4 h-full rounded-lg' }}
					color='primary'
					variant='contained'
					onClick={() => {
						goToCreate('CONTRACT');
					}}
				>
					<AddContractIcon className='text-white' />
					<p className='text-xl text-white font-bold'>{t('cmmn_label_new_contract')}</p>
				</ButtonText>
				{permission.DISPLAY_COUNSEL && (
					<ButtonText
						classes={{ root: 'gap-4 h-full rounded-lg' }}
						color='secondary'
						variant='contained'
						onClick={() => {
							goToCreate('COUNSEL');
						}}
					>
						<AddCounselIcon className='text-white' />
						<p className='text-xl text-white font-bold'>{t('cmmn_label_new_counsel_create')}</p>
					</ButtonText>
				)}
				<PopupDefault open={open} size='medium' onClose={handleTogglePopup} externalClickClose={false}>
					<section>
						<div>
							<img src={`${STATIC_DIR}/images/img-guide.png`} alt='Guidelines for Processing Tasks' />
						</div>
						<div className='flex gap-3 pt-8'>
							<ButtonText
								text={t('label_cancellation')}
								variant='contained'
								onClick={handleTogglePopup}
							/>
							<ButtonText
								text={infoModalConfirm()}
								variant='contained'
								color='primary'
								disabled={count >= 0}
								onClick={() => {
									handleNavigate(createType);
								}}
							/>
						</div>
					</section>
				</PopupDefault>
				{isComponentEnabled('CMPNT165_CONTRACT_ETC_BF') ? (
					<ButtonText
						classes={{ root: 'gap-4 h-full rounded-lg' }}
						color='primary'
						variant='contained'
						onClick={() => handleNavigate('CONTRACT_ETC')}
					>
						<AddContractIcon className='text-white' />
						<p className='text-xl text-white font-bold'>{t('new_contract_etc')}</p>
					</ButtonText>
				) : null}
				{isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF') ? (
					<ButtonText
						classes={{ root: 'gap-4 h-full rounded-lg' }}
						color='primary'
						variant='contained'
						onClick={() => handleNavigate('CONTRACT_SIGN_SEAL_PROCESS')}
					>
						<AddContractIcon className='text-white' />
						<p className='text-xl text-white font-bold'>{t('new_contract_sign_seal_process')}</p>
					</ButtonText>
				) : null}
			</div>
		</header>
	);
}
