import { useQuery } from '@tanstack/react-query';
import { FormikValues } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { ISealStamp } from '../../../model/sealStamp';
import { IHeadCell } from '../../../model/table';
import { getFakeSealStampList } from '../../../service/fakeService';
import ButtonText from '../../ui/buttons/ButtonText';
import ChipDefault from '../../ui/chips/ChipDefault';
import InputPopupSelect from '../../ui/inputs/InputPopupSelect';
import PopupCommonExternal from '../../ui/popups/PopupCommonExternal';
import { ModalSize } from '../../ui/popups/PopupDefault';
import TableSkeleton from '../../ui/tables/TableSkeleton';
import TableSealStampList from '../tables/sealStamp/TableSealStampList';

type IProps = {
	id: string;
	label?: string;
	trigger?: 'button' | 'select';
	formik: FormikValues;
	popupSize?: ModalSize;
	inputSize?: 'small' | 'medium';
	required?: boolean;
	disabled?: boolean;
	maxSelected?: number;
};

export default function PopupSealStamps({
	id,
	formik,
	trigger = 'select',
	label,
	popupSize = 'medium',
	inputSize = 'medium',
	required = false,
	disabled = false,
	maxSelected = 0,
}: IProps) {
	const [open, setOpen] = useState(false);
	const handleTogglePopup = () => setOpen(prev => !prev);
	const handleClose = () => setOpen(false);
	const { data, isLoading } = useQuery(['testSeal'], () => getFakeSealStampList(), { staleTime: 999999 });

	const selectedFormik = useValidateForm({
		validationSchema: {
			[id]: { initialValue: [], type: 'array' },
		},
	});

	const headCells: readonly IHeadCell<ISealStamp>[] = useMemo(
		() => [
			{
				id: 'imageUrl',
				width: 'w-20',
				align: 'left',
				disablePadding: false,
				disableSort: true,
				label: '이미지',
			},
			{
				id: 'name',
				width: 'w-40',
				align: 'left',
				disablePadding: true,
				disableSort: false,
				label: '인감명',
			},
			{
				id: 'description',
				width: 'w-72',
				align: 'left',
				disablePadding: false,
				disableSort: false,
				label: '설명',
			},
			{
				id: 'subsidiary',
				align: 'left',
				disablePadding: false,
				disableSort: false,
				label: '계열사',
			},
			{
				id: 'user',
				align: 'left',
				disablePadding: false,
				disableSort: false,
				label: '담당자',
			},
			{
				id: 'id',
				align: 'left',
				disablePadding: false,
				disableSort: false,
				label: '인감ID',
			},
			{
				id: 'isAuto',
				width: 'w-28',
				align: 'left',
				disablePadding: false,
				disableSort: false,
				label: '자동날인',
			},
		],
		[],
	);

	const handleClick = useCallback(() => {
		formik.setFieldValue(id, selectedFormik.values[id]);
		handleTogglePopup();
	}, [data, formik.values, selectedFormik.values[id]]);

	const renderPage = useCallback(
		() => (
			<>
				{isLoading && !data && <TableSkeleton colSpan={headCells.length + 1} />}
				{!isLoading && data && (
					<TableSealStampList
						id={id}
						listType='radio'
						list={data}
						headCells={headCells}
						formik={formik}
						maxSelected={maxSelected}
						selectedFormik={selectedFormik}
					/>
				)}
			</>
		),
		[data, formik.values, selectedFormik.values],
	);

	const handleDelete = useCallback(
		(targetId: string) => {
			formik.setFieldValue(
				id,
				(formik.values[id] as ISealStamp[]).filter(value => value.id !== targetId),
			);
		},
		[data, formik.values, selectedFormik.values],
	);

	const optionRenderPage = useCallback(
		(selected: unknown) => {
			return (
				<div className='flex flex-wrap gap-1'>
					{(selected as ISealStamp[]).map(item => (
						<ChipDefault
							key={item.id}
							id={String(item.id)}
							label={item.name}
							disabled={disabled}
							onDelete={handleDelete}
						/>
					))}
				</div>
			);
		},
		[data, formik.values, selectedFormik.values],
	);

	return (
		<>
			{trigger === 'select' && (
				<InputPopupSelect
					id={id}
					label={label}
					formik={formik}
					size={inputSize}
					required={required}
					disabled={disabled}
					onClick={handleTogglePopup}
					renderValue={optionRenderPage}
				/>
			)}
			{trigger === 'button' && (
				<ButtonText variant='contained' color='secondary' text='+ 법인 도장' onClick={handleTogglePopup} />
			)}
			<PopupCommonExternal
				open={open}
				size={popupSize}
				title='인감 검색'
				renderPage={renderPage()}
				onConfirm={handleClick}
				onCancel={handleClose}
				onClose={handleClose}
			/>
		</>
	);
}
