import { memo } from 'react';
import ContainerList from '../../../../components/admin/officialLetter/field/ContainerList';
import Container from '../../../../components/ui/containers/Container';

function ListPage() {
	return (
		<Container className='flex flex-col gap-4'>
			<ContainerList />
		</Container>
	);
}

export default memo(ListPage);
