import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CardBox from '../../../ui/containers/CardBox';
import { extractHistoryMapAndIndex } from '../../../../util/ai';
import AIArticleHistoryItemClickHandler from '../../common/AIArticleHistoryItemClickHandler';
import { queryKey } from '../../../../constants/queryKey';
import { getSimilarClausesWithQuery } from '../../../../api/ai';

export default function AIArticleSearchResult() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const query = useMemo(() => searchParams.get('query') || '', [searchParams]);
	useEffect(() => {
		if (!searchParams.get('query')) {
			navigate('/buptle_ai/article/main');
		}
	}, [searchParams]);

	const { data } = useQuery(
		[queryKey.aiSimilarClausesWithQuery, searchParams.toString()],
		() => getSimilarClausesWithQuery(JSON.stringify(Object.fromEntries(searchParams.entries()))),
		{
			enabled: !!query,
			staleTime: 300000,
		},
	);

	if (!data) {
		return <CircularProgress />;
	}

	const { similar_clauses: similarClauses, all_article_histories: allArticleHistories } = data;

	return similarClauses.result
		.sort((a, b) => b.score.cosine - a.score.cosine)
		.map(node => {
			const item = extractHistoryMapAndIndex(node, allArticleHistories);
			return (
				<CardBox key={node.id} className='p-3'>
					<AIArticleHistoryItemClickHandler item={item} nodeId={node.id} />
				</CardBox>
			);
		});
}
