import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PopupBack from './PopupBack';

interface IProps {
	title: string;
	onClick: () => void;
}
export default function HeaderSign({ title, onClick }: IProps) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(false);
		onClick();
	};

	return (
		<header>
			<PopupBack
				open={open}
				setOpen={setOpen}
				renderPage={<p className='text-center'>{t('contract_guide_confirm_seal_sign_cancel')}</p>}
				onConfirm={handleClick}
			/>
			<h2 className='text-xl font-bold my-4'>{title}</h2>
		</header>
	);
}
