import { MutableRefObject, forwardRef, memo, useCallback, useEffect, useMemo } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { TCanvasRef } from '../../../model/canvas';
import { clearCanvas, downloadCanvasAsImage, getCanvasAsImage } from '../../../util/canvas';
import ButtonText from '../buttons/ButtonText';
import CleaningIcon from '../icons/CleaningIcon';
import DownloadIcon from '../icons/DownloadIcon';
import SaveIcon from '../icons/SaveIcon';

interface IProps {
	width: number;
	height?: number;
	scale?: 2 | 3 | 4;
	onSave?: (data: string) => void;
	disabledDownload?: boolean;
	disabledSave?: boolean;
}

/**
 * @description util 에 Download, convert to Image, clear 기능 구현
 */
const Canvas = forwardRef(({ width, height: h, scale = 2, onSave, ...options }: IProps, ref: TCanvasRef) => {
	const { disabledSave = false, disabledDownload = false } = options;
	const height = useMemo(() => h || width, [ref]);

	const handleClear = useCallback(() => clearCanvas(ref), [ref]);

	const handleSave = useCallback(() => {
		const data = getCanvasAsImage(ref);
		if (onSave) onSave(data);
	}, [ref]);

	const handleDownload = useCallback(() => downloadCanvasAsImage(ref), [ref]);

	useEffect(() => {
		const canvas = (ref as MutableRefObject<SignatureCanvas>).current.getCanvas();
		const context = canvas.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;

		canvas.width = width * scale;
		canvas.height = height * scale;
		context.scale(scale, scale);
	}, [width, h, scale, ref]);

	return (
		<section className='inline-grid rounded border border-neutral-400 bg-white'>
			<div className='flex items-center justify-end gap-2 border-b border-b-neture-400'>
				<ButtonText className='min-w-0' size='small' onClick={handleClear}>
					<CleaningIcon fontSize='small' />
				</ButtonText>

				{!disabledDownload && (
					<ButtonText className='min-w-0' size='small' onClick={handleDownload}>
						<DownloadIcon fontSize='small' />
					</ButtonText>
				)}

				{!disabledSave && (
					<ButtonText className='min-w-0' size='small' onClick={handleSave}>
						<SaveIcon fontSize='small' />
					</ButtonText>
				)}
			</div>

			<section style={{ width, height }}>
				<SignatureCanvas ref={ref} canvasProps={{ className: 'w-full h-full' }} />
			</section>
		</section>
	);
});

export default memo(Canvas);
