import { FormikValues } from 'formik';
import { memo, useCallback } from 'react';
import { IOfficialLetter, IStep } from '../../../../model/officialLetter';
import { useProviderPage } from '../../../../pages/admin/officialLetter/workflow/ProviderPage';
import ButtonText from '../../../ui/buttons/ButtonText';

interface IProps {
	processId: string;
	data: IStep;
	formik: FormikValues;
}

function ButtonModityStep({ processId, data, formik }: IProps) {
	const { setOfficialLetter } = useProviderPage();
	const handleModity = useCallback(() => {
		setOfficialLetter(
			prev =>
				({
					...prev,
					process: prev.process.map(item => ({
						...item,
						steps: item.steps.map(step => {
							return step.id === data.id && processId === item.id
								? {
										...step,
										options: {
											...step.options,
											isDisabled: false,
										},
								  }
								: {
										...step,
										options: {
											...step.options,
											isDisabled: true,
										},
								  };
						}),
					})),
				}) as IOfficialLetter,
		);
	}, [processId, data, formik]);

	const handleSave = useCallback(() => {
		formik.handleSubmit();
	}, [processId, data, formik]);

	return (
		<>
			{data.options.isDisabled && <ButtonText text='수정' variant='outlined' onClick={handleModity} />}
			{!data.options.isDisabled && (
				<ButtonText text='저장' variant='contained' color='primary' onClick={handleSave} />
			)}
		</>
	);
}

export default memo(ButtonModityStep);
