import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonOwnPropsOverride } from '../../../../model/button';
import ButtonText from '../../../ui/buttons/ButtonText';

interface IProps extends ButtonOwnPropsOverride {}

export default function ButtonWorkflow({ id, ...props }: IProps) {
	const navigate = useNavigate();

	const handleClick = useCallback(() => {
		const param = id ? `/${id}` : '';
		navigate(`/admin/officialLetter/workflow${param}`);
	}, []);

	return <ButtonText onClick={handleClick} {...props} />;
}
