import { ChangeEvent, useEffect } from 'react';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { Sign } from '../../../model/sign';
import InputText from '../inputs/InputText';

type Props = {
	id: string;
	value: number;
	sign: Sign;
	onChange: (e: ChangeEvent<HTMLInputElement>, sign: Sign) => void;
};
export default function InputSize({ id, value, sign, onChange }: Props) {
	const formik = useValidateForm({
		validationSchema: {
			id: { initialValue: 0, type: 'number' },
		},
	});

	useEffect(() => {
		if (value > 0) formik.setFieldValue(id, value);
	}, [id, value]);

	return (
		<InputText
			id={id}
			type='number'
			size='small'
			labelText='width size'
			onBeforeDebounceChange={e => onChange(e as ChangeEvent<HTMLInputElement>, sign)}
			delay={500}
			formik={formik}
		/>
	);
}
