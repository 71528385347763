import { useTranslation } from 'react-i18next';
import { DOMAIN_URL } from '../../constants/common';
import { useUser } from '../../context/UserContext';
import { MenuData } from '../../model/header';
import SysConfigStore from '../../store/common/SysConfigStore';
import LogoIcon from '../ui/icons/LogoIcon';
import MenuItem1Depth from './MenuItem1Depth';

type Props = {
	openMenu: string | null;
	onToggle: (title: string) => void;
};

export default function HeaderLeft({ openMenu, onToggle }: Props) {
	const { isModuleEnabled, isComponentEnabled } = SysConfigStore();
	const { t } = useTranslation();
	const { loginUser, isAdmin, isGroupAdmin, hasAnyRole, permission } = useUser();

	const menus: MenuData[] = [
		// 로고
		{
			link: '/',
			icon: <LogoIcon />,
			title: '',
			classes: { button: 'items-start w-[90px] h-[56px] p-0' },
			display: true,
		},
		// 계약
		{
			link: `${DOMAIN_URL}/contract/get-list-view-v28`,
			title: t('label_notification_app_code_contract'),
			classes: { button: 'p-2' },
			display: true,
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem('/contract/get-list-view-v28/', JSON.stringify({ contract_type: 'CONTRACT' })),
		},
		// 전자 계약 분리 커스텀(그린랩스)
		{
			link: `${DOMAIN_URL}/contract/get-list-view-v28`,
			title: t('MSG_ID_423'),
			classes: { button: 'p-2' },
			display: isModuleEnabled('CONTRACT_WEB_CONTENT'),
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem(
					'/contract/get-list-view-v28/',
					JSON.stringify({ contract_type: 'CONTRACT_WEB_CONTENT' }),
				),
		},
		// 부서 계약 커스텀(FNF)
		{
			link: `${DOMAIN_URL}/contract/get_contract_dept_view`,
			title: t('contract_label_department_contract'),
			classes: { button: 'p-2' },
			display: isModuleEnabled('CONTRACT_DEPT_LIST'),
			isExternal: true,
		},
		// 인감(한샘)
		{
			link: `${DOMAIN_URL}/contract/${
				hasAnyRole ? 'get_contract_stamp_all_view/' : 'get_contract_stamp_my_view/'
			}`,
			title: t('MSG_ID_16'),
			classes: { button: 'p-2' },
			display: isModuleEnabled('CONTRACT_STAMP_MANAGE'),
			isExternal: true,
			preHandle: () => {
				if (hasAnyRole) {
					sessionStorage.setItem(
						'/contract/get_contract_stamp_all_view/',
						JSON.stringify({ migration_only: '0' }),
					);
				}
			},
		},
		// 자문
		{
			link: `${DOMAIN_URL}/counsel/get-list-view-v28/`,
			title: t('counsel'),
			classes: { button: 'p-2' },
			display: permission.DISPLAY_COUNSEL,
			isExternal: true,
		},
		// 소송
		{
			link: `${DOMAIN_URL}/lawsuit/get-list-view-v28/`,
			title: t('label_dashboard_type_kr_lawsuit'),
			classes: { button: 'p-2' },
			display: permission.DISPLAY_LAWSUIT,
			isExternal: true,
			preHandle: () => sessionStorage.setItem('/lawsuit/get-list-view-v28/', JSON.stringify({ type: 'LAWSUIT' })),
		},
		// 분쟁
		{
			link: `${DOMAIN_URL}/lawsuit/get-list-view-v28/`,
			title: t('MSG_ID_1203'),
			classes: { button: 'p-2' },
			display: permission.DISPLAY_DISPUTE,
			isExternal: true,
			preHandle: () => sessionStorage.setItem('/lawsuit/get-list-view-v28/', JSON.stringify({ type: 'DISPUTE' })),
		},
		// 표준계약서 DB(한샘)
		{
			link: `${DOMAIN_URL}/contract/contract-form-list-view/`,
			title: '표준계약서 DB',
			classes: { button: 'p-2' },
			display: isModuleEnabled('STANDARD_CONTRACT_FORM'),
			isExternal: true,
		},
		// 수발신문서
		{
			link: `${DOMAIN_URL}/doc_received/get-list-view-v28/`,
			title: t('MSG_ID_46'),
			classes: { button: 'p-2' },
			display: isModuleEnabled('DOC_RECEIVED'),
			isExternal: true,
		},
		// 프로젝트
		{
			link: `${DOMAIN_URL}/epic/get_epic_all_list/`,
			title: t('MSG_ID_1193'),
			classes: { button: 'p-2' },
			display: isModuleEnabled('EPIC_MANAGE'),
			isExternal: true,
		},
		// HPL(한샘)
		{
			link: `${DOMAIN_URL}/contract/get_list_view_all/`,
			title: 'HPL(구 법무포탈 자료)',
			classes: { button: 'p-2' },
			display: isModuleEnabled('EXTRA_MENU_FOR_MIGRATION_DATA'),
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem('/contract/get_list_view_all/', JSON.stringify({ migration_only: '1' })),
		},
		// 통계
		{
			link: '/statistics',
			title: t('MSG_ID_682'),
			classes: { button: 'p-2' },
			display: permission.DISPLAY_STATISTICS_PAGE,
		},
		// 법률뉴스(그린랩스)
		{
			link: `${DOMAIN_URL}/manage/issue_list_view/`,
			title: t('cmmn_label_legal_news'),
			classes: { button: 'p-2' },
			display: isModuleEnabled('LEGAL_ISSUE'),
			isExternal: true,
		},
		// Buptle AI
		{
			link: '/buptle_ai/',
			title: '법틀 AI',
			classes: { button: 'p-2' },
			display: permission.ACTIVATE_BUPTLE_AI,
		},
	];

	return menus
		.filter(({ display }) => display)
		.map(menu => {
			return (
				<MenuItem1Depth
					key={menu.title}
					itemData={menu}
					isOpen={openMenu === menu.title}
					onToggle={() => onToggle(menu.title)}
				/>
			);
		});
}
