import { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from '../../hooks/useAlert';
import DetailNaverPage from './naver/DetailPage';

function ProtectedRoute() {
	const [isValid, setIsValid] = useState(false);
	const navigate = useNavigate();
	const [snackbar] = useAlert();
	const { appId, id } = useParams();

	useEffect(() => {
		if (!appId || !id) setIsValid(true);
	}, []);

	useEffect(() => {
		if (isValid) {
			snackbar('접근 권한이 없습니다.', 'error');
			setTimeout(() => navigate('/'), 1000);
		}
	}, [isValid]);

	return appId && id && <DetailNaverPage />;
}

export default memo(ProtectedRoute);
