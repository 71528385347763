import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ClearIcon } from '@mui/x-date-pickers';
import _ from 'lodash';
import { ChangeEvent, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyObject } from 'yup';
import useEseal from '../../../../hooks/useEseal';
import { SignList } from '../../../../model/list';
import { IHeadCell, TOrder } from '../../../../model/table';
import { dateFormat } from '../../../../util/dateFormat';
import ButtonText from '../../../ui/buttons/ButtonText';
import UserChip from '../../../ui/chips/UserChip';
import LockIcon from '../../../ui/icons/LockIcon';
import ItemSign from '../../../ui/signs/ItemSign';
import TableFooter from '../../../ui/tables/TableFooter';
import TableSignHeader from './TableSignHeader';

type Props = {
	list: SignList[];
	contractId: string | number;
	orderBy?: keyof SignList;
	headCells: readonly IHeadCell<SignList>[];
	sealType: string;
	onDrop: (item: AnyObject, dropResult: AnyObject) => void;
};

export default function TableSignList({ list, contractId, orderBy: ob = 'id', headCells, sealType, onDrop }: Props) {
	const { t } = useTranslation();
	const [order, setOrder] = useState<TOrder>('asc');
	const [orderBy, setOrderBy] = useState(ob);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const { deleteSeal } = useEseal();

	const handleRequestSort = (e: MouseEvent<unknown>, property: keyof SignList) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (e: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(e.target.value, 10));
		setPage(0);
	};

	// rows 양이 적을 경우 크기 조절
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

	// 현재 page 와 order 에 맞는 rows slice
	const visibleRows = useMemo(() => {
		const orderArr = order === 'asc' ? _.chain(list).sortBy(orderBy) : _.chain(list).sortBy(orderBy).reverse();

		return {
			count: orderArr.value().length,
			rows: orderArr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).value(),
		};
	}, [list, order, orderBy, page, rowsPerPage]);

	return (
		<section>
			<TableContainer className='overflow-visible'>
				<Table size='medium'>
					<TableSignHeader
						headCells={headCells}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
					/>
					<TableBody>
						{visibleRows.rows.length === 0 && (
							<TableRow>
								<TableCell colSpan={headCells.length + 1} classes={{ root: 'text-center' }}>
									{t('no_such_data')}
								</TableCell>
							</TableRow>
						)}
						{visibleRows.rows.length > 0 &&
							visibleRows.rows.map((row, index) => {
								return (
									<TableRow hover={row.canDrag} tabIndex={-1} key={row.id}>
										<TableCell align='left' className='relative'>
											<ItemSign
												id={row.id}
												name={row.title}
												imageUrl={row.imageUrl}
												canDrag={row.canDrag}
												onDrop={onDrop}
											/>
											{!row.canDrag && (
												<div className='absolute inset-0 flex items-center justify-center'>
													<LockIcon />
												</div>
											)}
										</TableCell>
										<TableCell align='left'>{row.title}</TableCell>
										{sealType === 'normal' ? (
											<TableCell align='left'>
												<div className='grid gap-2'>
													<div>
														<span>{row.status}</span>
														{row.date && (
															<>
																<span> / </span>
																<span>
																	{dateFormat(row.date).format('YY/MM/DD (ddd)')}
																</span>
															</>
														)}
													</div>
													<div>
														{row.user && (
															<UserChip
																id={String(row.id)}
																user={{ id: Number(row.id), name: row.user, email: '' }}
																size='small'
																disabled
															/>
														)}
													</div>
												</div>
											</TableCell>
										) : (
											<TableCell align='left'>
												<ButtonText
													className='min-w-0 p-0 hover:cursor-pointer'
													onClick={e => {
														deleteSeal(contractId, row.id);
													}}
												>
													<ClearIcon />
												</ButtonText>
											</TableCell>
										)}
									</TableRow>
								);
							})}
						{emptyRows > 0 && (
							<TableRow
								style={{
									height: 53 * emptyRows,
								}}
							>
								<TableCell colSpan={headCells.length + 1} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TableFooter
				rowsPerPageOptions={[5, 10, 20, 30, 50]}
				showFirstButton
				showLastButton
				count={visibleRows.count}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</section>
	);
}
