import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getMainWorkStatusCount } from '../../../../../api/dashboard';
import {
	contractSearchParams,
	counselSearchParams,
	lawsuitSearchParams,
} from '../../../../../constants/adeleSearchParams';
import { contractListUrl, counselListUrl, lawsuitListUrl } from '../../../../../constants/adeleUrl';
import { queryKey } from '../../../../../constants/queryKey';
import { useSubsidiary } from '../../../../../context/SubsidiaryContext';
import { useUser } from '../../../../../context/UserContext';
import SysConfigStore from '../../../../../store/common/SysConfigStore';
import { navigateToAdele } from '../../../../../util/commonUtils';
import StatusCardDashboard from './StatusCardDashboard';

export default function StatusDashboardLawyer() {
	const { id: subsidiaryId } = useSubsidiary();
	const { data: statusCount } = useQuery([queryKey.dashboard, queryKey.workStatusCount, subsidiaryId], () =>
		getMainWorkStatusCount(subsidiaryId),
	);
	const { t } = useTranslation();
	const { isModuleEnabled, isComponentEnabled } = SysConfigStore();
	const { loginUser } = useUser();

	const handleNavigate = (
		url: string,
		workType: 'CONTRACT' | 'COUNSEL' | 'CONTRACT_ETC' | 'LAWSUIT',
		status: 'REQUIRED_REVIEW' | 'MY_REVIEW',
	) => {
		const paramsMap = {
			CONTRACT: contractSearchParams,
			COUNSEL: counselSearchParams,
			CONTRACT_ETC: contractSearchParams,
			LAWSUIT: lawsuitSearchParams,
		};

		if (['CONTRACT', 'CONTRACT_ETC'].includes(workType)) {
			sessionStorage.setItem('/contract/get-list-view-v28/', JSON.stringify({ contract_type: workType }));
		}
		if (workType === 'CONTRACT_ETC') workType = 'CONTRACT';

		if (workType === 'CONTRACT')
			sessionStorage.setItem(
				`${workType.toLowerCase()}_search_param`,
				JSON.stringify({
					...paramsMap[workType],
					status_check: 'PROGRESS_STS_REVIEW',
					search_mode: status === 'REQUIRED_REVIEW' ? '2' : '0',
					my_contract: status === 'MY_REVIEW' ? 'True' : 'False',
					contract_reviewer: status === 'MY_REVIEW' ? loginUser.name : ''
				}),
			);
		if (workType === 'COUNSEL')
			sessionStorage.setItem(
				`counsel_search_param`,
				JSON.stringify({
					...paramsMap[workType],
					status_check: 'PROGRESS_STS_REVIEW',
					search_mode: status === 'REQUIRED_REVIEW' ? '2' : '0',
					my_counsel: status === 'MY_REVIEW' ? 'True' : 'False',
					counsel_reviewer: status === 'MY_REVIEW' ? loginUser.name : ''
				}),
			);
		if (workType === 'LAWSUIT')
			sessionStorage.setItem(
				`lawsuit_search_param`,
				JSON.stringify({
					...paramsMap[workType],
					progress_status_search: 'PROCESS',
					my_lawsuit: 'True',
					lawsuit_lawyer: loginUser.name,
				}),
			);
		sessionStorage.setItem('from_dashboard_to_con_list', 'False');
		navigateToAdele(url);
	};

	const reviewItems = [
		{
			count: statusCount?.contract.reviewer_required_count,
			label: t('MSG_ID_5'),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT', 'REQUIRED_REVIEW'),
		},
		{
			count: statusCount?.counsel.reviewer_required_count,
			label: t('label_notification_app_code_counsel'),
			onClick: () => handleNavigate(counselListUrl, 'COUNSEL', 'REQUIRED_REVIEW'),
		},
		{
			count: statusCount?.contract_etc?.reviewer_required_count,
			label: t('label_etc_contract'),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT_ETC', 'REQUIRED_REVIEW'),
			display: isComponentEnabled('CMPNT165_CONTRACT_ETC_BF'),
		},
	];

	const completedItems = [
		{
			count: statusCount?.contract.review_count,
			label: t('MSG_ID_5'),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT', 'MY_REVIEW'),
		},
		{
			count: statusCount?.counsel.review_count,
			label: t('label_notification_app_code_counsel'),
			onClick: () => handleNavigate(counselListUrl, 'COUNSEL', 'MY_REVIEW'),
		},
		{
			count: statusCount?.lawsuit?.review_count ? statusCount?.lawsuit?.review_count : 0,
			label: t('MSG_ID_1203'),
			onClick: () => handleNavigate(lawsuitListUrl, 'LAWSUIT', 'MY_REVIEW'),
			display: isComponentEnabled('CMPNT184_DISPUTE_BF'),
		},
		{
			count: statusCount?.contract_etc?.review_count,
			label: t('label_etc_contract'),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT_ETC', 'MY_REVIEW'),
			display: isComponentEnabled('CMPNT165_CONTRACT_ETC_BF'),
		},
	];

	return (
		<>
			<StatusCardDashboard
				className='col-span-2'
				title={t('cmmn_label_legal_in_review')}
				items={completedItems}
			/>
			<StatusCardDashboard
				className='col-span-2'
				title={t('contract_guide_please_assign_a_reviewer')}
				items={reviewItems}
			/>
		</>
	);
}
