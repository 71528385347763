import { memo, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';

function ProtectedRoute() {
	const { isAdmin } = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAdmin) navigate('/');
	}, []);

	return isAdmin && <Outlet />;
}

export default memo(ProtectedRoute);
