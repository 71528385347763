import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { checkSealSignAccess } from '../../../api/contract';
import { queryKey } from '../../../constants/queryKey';
import { useAlert } from '../../../hooks/useAlert';
import DetailPage from './DetailPage';

export default function ProtectedRoute() {
	const { contractId } = useParams() as { contractId: string };
	const { t } = useTranslation();
	const [snackbar] = useAlert();

	// 접근 가능 권한을 Check, 최대한 빠르게 했지만 보안코드가 느려서 응답이 느리다.
	const { data, isLoading } = useQuery(queryKey.sealPdfAccess, () => checkSealSignAccess(contractId), {
		onError: error => {
			snackbar(
				t('contract_guide_it_is_deleted_or_wrong_approach_please_contact_the_system_administrator'),
				'error',
			);
			setTimeout(() => {
				window.location.href = '/';
			}, 2000);
		},
	});

	if (!isLoading && data && !data.is_can_access) {
		snackbar(t('contract_guide_it_is_deleted_or_wrong_approach_please_contact_the_system_administrator'), 'error');

		window.location.href = '/';
	}

	if (!isLoading && data && data.is_can_access) {
		return <DetailPage contractId={contractId} />;
	}
}
