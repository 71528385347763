import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function ThumbDownIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M10.9776 21.5421L16.9193 15.826C17.3169 15.4442 17.5425 14.918 17.5425 14.3712V4.06358C17.5425 2.92861 16.5755 2 15.3936 2H5.73419C4.87462 2 4.10101 2.49526 3.76793 3.24847L0.265191 11.1004C-0.648099 13.1433 0.909867 15.4133 3.21995 15.4133H9.29065L8.26991 20.1389C8.16247 20.6548 8.32364 21.181 8.71044 21.5525C9.34437 22.1509 10.3544 22.1509 10.9776 21.5421ZM21.8511 2C20.6692 2 19.7022 2.92861 19.7022 4.06358V12.3179C19.7022 13.4529 20.6692 14.3815 21.8511 14.3815C23.033 14.3815 24 13.4529 24 12.3179V4.06358C24 2.92861 23.033 2 21.8511 2Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
