import { memo, useCallback, useState } from 'react';
import { useAlert } from '../../../../hooks/useAlert';
import { IWorkflow } from '../../../../model/officialLetter';
import ButtonText from '../../../ui/buttons/ButtonText';
import PopupWarning from '../../../ui/popups/PopupWarning';

interface IProps {
	data: IWorkflow;
}

function PopupDeleteItemWorkflow({ data }: IProps) {
	const { title, statusCounts } = data;
	const { formatting } = statusCounts;
	const [open, setOpen] = useState(false);
	const [snackbar] = useAlert();

	const handleClick = useCallback(() => {
		if (formatting > 0) {
			snackbar('서식이 적용되어 있어 삭제할 수 없습니다. 적용 해제 후 다시 시도하세요', 'error');
			return;
		}

		setOpen(true);
	}, []);

	const handleConfirm = useCallback(() => {
		setOpen(false);
	}, []);

	const handleCancel = useCallback(() => {}, []);

	const renderPage = useCallback(() => {
		return (
			<>
				<p className='text-center'>{title}</p>
				<p className='text-center'>리리리얼리 삭제하시겠습니까 ?</p>
			</>
		);
	}, []);

	return (
		<>
			<ButtonText text='삭제' variant='outlined' color='error' size='small' onClick={handleClick} />
			<PopupWarning
				open={open}
				renderPage={renderPage()}
				onClose={() => setOpen(false)}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			/>
		</>
	);
}

export default memo(PopupDeleteItemWorkflow);
