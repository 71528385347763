import { ForwardedRef, forwardRef, memo, ReactNode } from 'react';

type Size = 'small' | 'medium';

interface IProps {
	children: ReactNode;
	className?: string;
	size?: Size;
	color?: string;
}

const getSizeStyle = (size: Size) => {
	let style = '';
	switch (size) {
		case 'small':
			style = 'rounded-md shadow-sm';
			break;
		case 'medium':
			style = 'rounded-2xl shadow-md';
			break;
		default:
	}
	return style;
};

const CardBox = forwardRef((props: IProps, ref: ForwardedRef<HTMLDivElement>) => {
	const { children, className = '', size = 'medium', color = 'bg-white' } = props;

	return (
		<div ref={ref} className={`${getSizeStyle(size)} ${className} ${color}`}>
			{children}
		</div>
	);
});

export default memo(CardBox);
