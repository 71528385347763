import { Chip, ChipOwnProps } from '@mui/material';
import ClearIcon from '../icons/ClearIcon';
import FileIcon from '../icons/FileIcon';

interface IChipOwnPropsOverrides extends ChipOwnProps {
	text: string;
	disabled?: boolean;
	link?: string;
	onClick?: () => void;
	onDelete?: () => void;
}

export default function FileChip({
	text,
	link = '',
	disabled = false,
	onClick = undefined,
	onDelete = () => {},
	...props
}: IChipOwnPropsOverrides) {
	return (
		<Chip
			icon={<FileIcon color='primary' className='-mt-0.5' fontSize='inherit' />}
			label={text}
			classes={{
				root: 'px-3 bg-neutral-100',
				icon: 'ml-0',
				label: 'pr-0 pl-3',
				disabled: 'opacity-100',
			}}
			onClick={onClick} // Chip 컴포넌트에 onClick 이벤트 핸들러 추가
			deleteIcon={<ClearIcon className='m-0 -mt-0.5' />}
			onDelete={!disabled ? onDelete : undefined}
			onMouseDown={e => e.stopPropagation()}
			onFocus={e => e.stopPropagation()}
			{...props}
		/>
	);
}
