import {
	ClauseCategory,
	IArticleHistoryItem,
	IReportDetail,
	ISimilarClauseNode,
	IStoreArticle,
	IStoreArticleHistoryMap,
	IStoreClause,
} from '../model/api/ai';
import { uniqueElementsSortedByFrequency } from './commonUtils';

export function extractHistoryMapAndIndex(
	node: ISimilarClauseNode,
	histories: IStoreArticleHistoryMap[],
): IArticleHistoryItem {
	const match = histories.find(({ contract, article_histories: articleHistories }) => {
		const history = articleHistories.filter(({ article }) => article?.id === node.article_id);
		if (history.length) {
			return { contract, history };
		}
		return false;
	});

	const historyMap = match || null;
	const index = historyMap?.article_histories.findIndex(history => history.article?.id === node.article_id) ?? 0;

	return { history_map: historyMap, index };
}

export function processCategoriesInClauses(clauses: IStoreClause[] | undefined): ClauseCategory[] {
	const allCategories = clauses?.flatMap(clause => clause.categories) ?? [];
	return uniqueElementsSortedByFrequency(allCategories);
}

export function combineContentsInArticle(article: IStoreArticle | undefined) {
	return article?.clauses.map(clause => clause.content).join('\n') ?? '';
}

export function extractReportDetailDisplayList(detail: IReportDetail) {
	return [
		{
			label: '당사자 권리',
			value: detail.contract_parties_obligations.join('\n'),
		},
		{
			label: '당사자 의무',
			value: detail.contract_parties_rights.join('\n'),
		},
		{
			label: '분쟁 해결 절차',
			value: detail.dispute_resolution,
		},
		{
			label: '공정성 평가',
			value: detail.fairness_review,
		},
		{
			label: '당사자에게 유리한 조항',
			value: detail.advantageous_clauses,
		},
		{
			label: '당사자에게 불리한 조항',
			value: detail.disadvantageous_clauses,
		},
		{
			label: '기타',
			value: detail.unusual_clauses,
		},
	];
}
