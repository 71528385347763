import { InputLabel } from '@mui/material';
import { FormikValues } from 'formik';
import _ from 'lodash';
import { Children, cloneElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendSupportEmailFunction } from '../../api/common_popup';
import { useUser } from '../../context/UserContext';
import { useAlert } from '../../hooks/useAlert';
import { useValidateForm } from '../../hooks/useValidateForm';
import SysConfigStore from '../../store/common/SysConfigStore';
import EditorTinyMCE from '../common/editors/EditorTinyMCE';
import ButtonText from '../ui/buttons/ButtonText';
import ScrollBox from '../ui/containers/ScrollBox';
import InputFile from '../ui/inputs/InputFile';
import InputText from '../ui/inputs/InputText';
import PopupDefault, { ModalOwnPropsOverrides } from '../ui/popups/PopupDefault';

type Props = Omit<ModalOwnPropsOverrides, 'children'> & {
	openTrigger?: React.ReactElement;
	confirm?: React.ReactElement;
	cancel?: React.ReactElement;
	onConfirm?: (formik: FormikValues) => void;
	onCancel?: (formik: FormikValues) => void;
};

export default function PopupSupportEmail({ openTrigger, open, confirm, cancel, onConfirm, onCancel }: Props) {
	const { loginUser } = useUser();
	const [sendingSupport, setSendingSupport] = useState<boolean>(false);
	const [snackbar] = useAlert();
	const { labelText } = SysConfigStore();
	const { t } = useTranslation();

	const sendSupportEmail = (params: {
		email: string;
		support_title: string;
		support_content: string;
		files: FileList;
	}) => {
		sendSupportEmailFunction({ ...params }).then(res => {
			snackbar(t('cmmn_guide_mail_has_been_sent_normally'), 'success');
		});
	};

	const formik = useValidateForm({
		validationSchema: {
			email: { initialValue: loginUser.email, type: 'email' },
			title: { initialValue: '', type: 'string', required: true },
			content: { initialValue: '', type: 'string', required: true },
			files: { initialValue: [], type: 'array' },
		},
		onSubmit: values => {
			if (onConfirm) {
				setSendingSupport(true);
				sendSupportEmail({
					email: formik.values.email,
					support_title: formik.values.title,
					support_content: formik.values.content,
					files: formik.values.files,
				});
				onConfirm(formik);
			}
		},
	});

	// Formik 상태 초기화
	useEffect(() => {
		formik.resetForm({ values: formik.initialValues });
		if (!open && sendingSupport) {
			setSendingSupport(open);
		}
	}, [open]);

	const handleEditorChange = (content: string) => {
		formik.setFieldValue('content', content);
	};
	const handleDrop = (files: File[] | []) => {
		const values = _.chain(files).unionBy(formik.values.files, 'name').reverse().value();
		formik.setFieldValue('files', values);
	};

	const confirmComponent = useMemo(
		() =>
			confirm &&
			onConfirm &&
			cloneElement(Children.only(confirm), {
				// onClick: () => formik.handleSubmit(),
				onClick: () => formik.handleSubmit(),
			}),
		[],
	);

	const cancelComponent = useMemo(
		() =>
			cancel &&
			onCancel &&
			cloneElement(Children.only(cancel), {
				onClick: () => onCancel(formik),
			}),
		[],
	);

	const fileButton = (
		<ButtonText
			classes={{ root: 'min-w-0 py-0 font-bold border-2 border-gray-300 rounded-2xl' }}
			text='+'
			color='inherit'
			variant='outlined'
		/>
	);

	return (
		<>
			{openTrigger && openTrigger}
			<PopupDefault
				open={open}
				size='xsmall'
				onClose={() => {
					if (onCancel) onCancel(formik);
				}}
			>
				<section className='flex flex-col gap-5'>
					<div className='flex flex-col gap-5'>
						<h2 className='text-lg'>{`${labelText('send_support_email')}`}</h2>
						<p className='p-1 text-center border-2 border-yellow-500 rounded'>
							{`${labelText('guide_send_support_email')}`}
						</p>
					</div>
					<ScrollBox>
						<section className='flex flex-col gap-5'>
							<div>
								<InputText
									id='email'
									labelText={`${t('cmmn_label_account')}`}
									formik={formik}
									disabled
								/>
							</div>
							<div>
								<InputText
									id='title'
									labelText={`${t('MSG_ID_741')}`}
									placeholder={`${t('cmmn_guide_please_enter_a_title')}`}
									required
									formik={formik}
								/>
							</div>
							<div>
								<InputLabel className='mb-2'>{`${labelText(
									'support_email_request_content',
								)}`}</InputLabel>
								<EditorTinyMCE onContentChange={handleEditorChange} onDrop={handleDrop} />
							</div>
							<div>
								<InputLabel className='mb-2'>{t('MSG_ID_131')}</InputLabel>
								<InputFile
									id='files'
									classes={{ root: 'flex flex-col gap-2' }}
									formik={formik}
									button={fileButton}
									multiple
									dragDrop
								/>
							</div>
						</section>
					</ScrollBox>
					<div className='flex justify-center gap-5'>
						{cancelComponent || ''}
						{confirmComponent || ''}
					</div>
				</section>
			</PopupDefault>
		</>
	);
}
