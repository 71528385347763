import { t } from 'i18next';
import { TOrder } from '../table';

export type LegalTag = {
	id: number;
	name: string;
};

export type ExternalLaywer = {
	lawyer_id: number;
	lawyer_name: string;
	lawyer_email: string;
	lawyer_address?: string;
	lawyer_description?: string;
	lawfirm_id?: number;
	lawfirm_name?: string;
	lawfirm_code?: string;
	attachment_file_list?: string[];
	introduction_file?: string;
};

export type ExternalLaywerList = ExternalLaywer & {
	tag_name: string;
	cost_tot: number;
	eval_cnt: number;
	cost_avg: number;
	score_avg: string;
};

export type ReqCreateExternalLawyer = {
	name: string;
	law_firm_id: number;
	email: string;
	address?: string;
	description?: string;
	attachment_file_list?: FileList;
	introduction_file?: File;
};

export type ReqExternalLawyerDetail = {
	lawyer_id?: string;
	tag_id?: number;
	start_date?: string;
	end_date?: string;
	order_col?: string;
	order?: string;
};

export type evaluation = {
	id: number;
	task_name: string;
	task_id: number;
	task_type: string;
	tag_name_ids: number[];
	tag_colors: string[];
	subsidiary_name: string;
	evaluator_name: string;
	external_lawyer_name: string;
	external_lawyer_email: string;
	address: string;
	cost: number;
	avg_evaluation: number;
	evaluation_date: string;
	avg_list: number[];
};

export type ReqEvaluationList = {
	lawyer_id?: string;
	lawfirm_id?: string;
	tag_id?: number;
	start_date?: string;
	end_date?: string;
	order_col: string;
	order: TOrder;
};

export type ResEvaluationList = {
	extra_datas?: [];
	recordsTotal: number;
	recordsFiltered: number;
	data: evaluation[];
};

export type ReqExternalLawyerList = {
	lawfirm_id?: number;
	lawfirm_name?: string;
	lawyer_name?: string;
	tag_id?: number;
	currentPage?: number;
	rowsPerPage?: number;
	order_col?: string;
	order?: string;
};

export type ResExternalLawyerList = {
	external_lawyer_list: ExternalLaywerList[];
	cnt_external_lawyer: number;
};

export type ResExternalLawyerEvaluationList = {
	external_lawyer_list: ExternalLaywerList[];
	cnt_external_lawyer: number;
};

export type ResExternalLawyerDetail = {
	lawyer_name: string;
	address: string;
	email: string;
	description: string;
	total_cnt: number;
	total_cost: number;
	lawfirm_name: string;
	lawfirm_code: string;
	avg_evaluation: number;
	attachment_file_list?: string[];
};

export const EVALUATION_DEFAULT_ITEM = {
	speed: {
		detail: {
			'1': t('cmmn_guide_complete_review_comments_writing_reply_submission_etc_in_a_timely_manner'),
			'2': t('cmmn_guide_respond_promptly_to_additional_reviews_inquiries_and_meeting_requests'),
		},
		text: t('label_rapidness'),
		ordering_value: '0',
	},
	professionalism: {
		detail: {
			'1': t(
				'cmmn_guide_sufficient_expertise_to_solve_problems_including_professional_capabilities_knowledge_experience_and_networks',
			),
			'2': t(
				'cmmn_guide_the_contents_of_the_work_performance_results_are_reasonable_clear_and_provide_appropriate_grounds',
			),
		},
		text: t('label_professionalism'),
		ordering_value: '1',
	},
	suitability: {
		detail: {
			'1': t('cmmn_guide_the_results_of_the_work_were_of_practical_help_to_our_team_work'),
			'2': t(
				'cmmn_guide_we_fully_understand_the_companys_current_status_and_background_and_reflect_this_in_our_work_performance',
			),
		},
		text: t('cmmn_label_compatibility'),
		ordering_value: '2',
	},
} as { [key: string]: any };
