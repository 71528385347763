import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@tanstack/react-query';
import { getUserRecentSaveArticles } from '../../../../api/ai';
import { queryKey } from '../../../../constants/queryKey';
import { useAIArticleSide } from '../../../../context/AIArticleSideContext';
import ButtonText from '../../../ui/buttons/ButtonText';
import AIArticleSideRecentItemsDisplay from './AIArticleSideRecentItemsDisplay';

export default function AIArticleSideRecentSaveItems() {
	const { setExpand } = useAIArticleSide();
	const { data } = useQuery([queryKey.aiRecentSaveArticles], getUserRecentSaveArticles, {
		staleTime: 300000,
	});

	return (
		<div className='flex flex-col gap-6'>
			<div className='flex justify-between items-center'>
				<p className='font-bold'>최근 저장한 조항</p>
				<ButtonText color='primary' onClick={() => setExpand('SAVED')}>
					<p className='font-bold text-m underline'>더보기</p>
				</ButtonText>
			</div>
			{!data ? <CircularProgress /> : <AIArticleSideRecentItemsDisplay data={data} />}
		</div>
	);
}
