import React, { ReactElement, useCallback } from 'react';
import { useAlert } from '../../hooks/useAlert';

type Props = {
	text: string;
	children: React.ReactNode;
};
export default function CopyToClipboard({ text, children }: Props) {
	const [snackbar] = useAlert();

	const handleCopy = useCallback(async () => {
		try {
			if (navigator.clipboard && navigator.clipboard.writeText) {
				await navigator.clipboard.writeText(text);
			} else {
				const textArea = document.createElement('textarea');
				textArea.value = text;
				textArea.style.position = 'fixed';
				document.body.appendChild(textArea);
				textArea.focus();
				textArea.select();
				document.execCommand('copy');
				document.body.removeChild(textArea);
			}
			snackbar('클립보드에 복사되었습니다.', 'success');
		} catch (error) {
			console.error(error);
		}
	}, [text]);

	return (
		<>
			{React.Children.map(children, child => {
				if (React.isValidElement(child)) {
					const existingOnClick = child.props.onClick;
					return React.cloneElement(child as ReactElement, {
						onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
							if (existingOnClick) {
								existingOnClick(e);
							}
							handleCopy();
						},
					});
				}
				return child;
			})}
		</>
	);
}
