import { ButtonGroup } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useAIReport } from '../../../../context/AIReportContext';
import ButtonText from '../../../ui/buttons/ButtonText';
import HistoryIcon from '../../../ui/icons/HistoryIcon';
import { queryKey } from '../../../../constants/queryKey';
import { getContractDiffDoc } from '../../../../api/contract';

export default function AIReportLeftTitleArea() {
	const { data: reportData, openVersionView, setOpenVersionView } = useAIReport();

	const { store_info: storeInfo } = reportData;
	const { contract } = storeInfo;
	const { title, app_id: appId } = contract;

	const { data: versionData } = useQuery([queryKey.contractDiffDocData, appId], () => getContractDiffDoc(appId), {
		staleTime: 300000,
	});

	const getButtonClassName = useCallback((active: boolean) => {
		if (active) {
			return 'text-blue-600';
		}
		return 'bg-white text-neutral-600';
	}, []);

	return (
		<div className='flex items-center justify-between pr-5 py-6'>
			<h3 className='text-2xl'>{title}</h3>
			{Number(versionData?.diff_doc_list?.length) > 1 && (
				<div className='flex items-center gap-3'>
					<ButtonGroup>
						<ButtonText
							className={`border-neutral-300 font-bold ${getButtonClassName(!openVersionView)}`}
							variant='outlined'
							onClick={() => setOpenVersionView(false)}
							disabled={!openVersionView}
						>
							현재
						</ButtonText>
						<ButtonText
							className={`border-neutral-300 font-bold ${getButtonClassName(openVersionView)}`}
							variant='outlined'
							onClick={() => setOpenVersionView(true)}
							disabled={openVersionView}
						>
							<div className='flex gap-2 items-center'>
								<HistoryIcon />
								<p>버전보기</p>
							</div>
						</ButtonText>
					</ButtonGroup>
				</div>
			)}
		</div>
	);
}
