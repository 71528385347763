import { useCallback, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import AIArticleSideView from '../../../components/ai/article/side/AIArticleSideView';
import AIArticleSlideView from '../../../components/ai/article/slide/AIArticleSlideView';
import { useAIArticle } from '../../../context/AIArticleContext';
import { AIArticleSideProvider } from '../../../context/AIArticleSideContext';

export default function AIArticleWithSidebarLayout() {
	const { slideState } = useAIArticle();

	const searchRef = useRef<HTMLDivElement | null>(null);

	const updateLeftScrollHeight = useCallback(() => {
		if (searchRef.current) {
			searchRef.current.style.height = `${window.innerHeight}px`;
		}
	}, []);

	useEffect(() => {
		if (slideState.display) {
			updateLeftScrollHeight();
			window.addEventListener('resize', updateLeftScrollHeight);
			return () => {
				window.removeEventListener('resize', updateLeftScrollHeight);
			};
		}

		if (searchRef.current) {
			searchRef.current.style.height = 'auto';
		}
		return () => {};
	}, [slideState.display]);

	return (
		<div className='flex w-full h-full'>
			{slideState.display ? (
				<>
					<div
						className='w-1/4 border-r border-neutral-600 overflow-y-auto scrollbar sticky top-0'
						ref={searchRef}
					>
						<Outlet />
					</div>
					<div className='w-3/4'>
						<AIArticleSlideView />
					</div>
				</>
			) : (
				<>
					<div className='w-3/4' ref={searchRef}>
						<Outlet />
					</div>
					<div className='w-1/4 bg-slate-100'>
						<AIArticleSideProvider>
							<AIArticleSideView />
						</AIArticleSideProvider>
					</div>
				</>
			)}
		</div>
	);
}
