import { useQuery } from '@tanstack/react-query';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { getChartDataList } from '../../api/statistics';
import { queryKey } from '../../constants/queryKey';
import { useStatistics } from '../../context/StatisticsContext';
import { useAlert } from '../../hooks/useAlert';
import { LegalList, StatisticsTableRow } from '../../model/statistics';
import { IHeadCell } from '../../model/table';
import { getLegalLinkMap, navigateToAdele } from '../../util/commonUtils';
import TableSkeleton from '../ui/tables/TableSkeleton';
import TableStatisticsList from './TableStatisticsList';

type Props = {
	legalListIds: LegalList;
};

export default function ListStatistics({ legalListIds }: Props) {
	const { t } = useTranslation();
	const headCellsContract: IHeadCell<StatisticsTableRow>[] = [
		{
			id: 'type',
			width: 'w-[100px]',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('MSG_ID_233'),
		},
		{
			id: 'title',
			width: 'w-4/12',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('MSG_ID_1304'),
		},
		{
			id: 'status',
			width: 'w-[150px]',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('cmmn_label_status_progress_stage'),
		},
		{
			id: 'counterparty',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('cmmn_label_opponent'),
		},
		{
			id: 'drafter',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('label_registrant'),
		},
		{
			id: 'id',
			width: 'w-[100px]',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: 'ID',
		},
	];
	const { searchParams } = useStatistics();
	const [snackbar] = useAlert();

	const { data: paginationData, isLoading } = useQuery(
		[queryKey.statisticsList, legalListIds, searchParams],
		() => getChartDataList({ ...legalListIds, searchParams }),
		{ staleTime: 60000 },
	);

	const getListAppType = (type: string) => {
		if (type === t('MSG_ID_5')) return 'CONTRACT';
		if (type === t('label_notification_app_code_lawsuit')) return 'COUNSEL';
		if ([t('label_notification_app_code_lawsuit'), t('MSG_ID_1203')].includes(type)) return 'LAWSUIT';

		return 'DOC_RECEIVED';
	};

	const handleClick = async (e: MouseEvent<HTMLTableRowElement>, row: StatisticsTableRow) => {
		const id = Number(row.id);
		const type = getListAppType(row.type);

		const { success, url, msg } = await getLegalLinkMap(type, id);
		if (success) {
			navigateToAdele(url);
		} else {
			snackbar(msg, 'error');
		}
	};

	return (
		<div>
			{isLoading && <TableSkeleton colSpan={headCellsContract.length + 1} />}
			{!isLoading && paginationData && (
				<TableStatisticsList
					list={paginationData.data.legalList}
					headCells={headCellsContract}
					totalListCount={paginationData.recordsTotal}
					onClick={handleClick}
				/>
			)}
		</div>
	);
}
