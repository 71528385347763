import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getContractLinkParam, saveSealSign } from '../../../../api/contract';
import { contractDetailUrl } from '../../../../constants/adeleUrl';
import { useAlert } from '../../../../hooks/useAlert';
import useEseal from '../../../../hooks/useEseal';
import { pdfData } from '../../../../model/api/contract';
import { SealStamp } from '../../../../model/sealStamp';
import { Sign } from '../../../../model/sign';
import { navigateToAdele } from '../../../../util/commonUtils';
import { addImageToPdf } from '../../../../util/pdf';
import SealStampPopup from '../../../main/common/SealStampPopup';
import ButtonLoading from '../../../ui/buttons/ButtonLoading';
import CardBox from '../../../ui/containers/CardBox';
import PopupSignSuccess from '../../common/PopupSignSuccess';

type Props = {
	signs: Sign[];
	pdfObject: pdfData;
	contractId: string;
};

export default function HeaderSignAction({ signs, pdfObject, contractId }: Props) {
	const { t } = useTranslation();
	const [snackbar] = useAlert();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const { addSeal } = useEseal();

	const handlePdfSaveClick = useCallback(() => {
		if (signs.length < 1) {
			snackbar(t('contract_warn_no_seal_stamp'), 'error');
			setOpen(false);
			return;
		}

		setLoading(true);

		addImageToPdf(pdfObject, signs).then(async pdfFile => {
			await saveSealSign({
				contract_id: contractId,
				signed_file: new File([pdfFile], pdfObject.fileName),
				seal_stamp_ids: _.uniq(signs.map(sign => sign.id.toString().split('-')[0])),
			});

			snackbar(t('contract_guide_sucecss_sign_seal_stamp'), 'success');

			const param = await getContractLinkParam({
				conid: Number(contractId),
				viewmode: 'direct',
			});

			navigateToAdele(`${contractDetailUrl}?param=${param.data}`);
		});
	}, [signs, pdfObject]);

	const handleSealAddClick = async (selectedSealStamp: SealStamp[]) => {
		setLoading(true);
		await addSeal(contractId, selectedSealStamp);
		setLoading(false);
	};

	const openTrigger = <ButtonLoading variant='outlined' loading={loading} text={t('MSG_ID_688')} color='primary' />;

	return (
		<CardBox className='grid grid-cols-2 gap-4 p-4'>
			<SealStampPopup
				id='add_stamp_sign_seal'
				listType='checkbox'
				openTrigger={openTrigger}
				onClick={handleSealAddClick}
				searchStatus='NO_APPROVAL'
				contractId={contractId}
			/>
			<PopupSignSuccess
				loading={loading}
				open={open}
				renderPage={<p className='text-center'>{t('contract_guide_confirm_seal_stamp')}</p>}
				setOpen={setOpen}
				onConfirm={handlePdfSaveClick}
			/>
		</CardBox>
	);
}
