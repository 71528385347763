import React, { useCallback, useMemo } from 'react';
import { AI_CLAUSE_CATEGORY_MAP } from '../../../../constants/aiConstans';
import { CategoryNavigator, useAIReport } from '../../../../context/AIReportContext';
import { ClauseCategory } from '../../../../model/api/ai';
import ButtonText from '../../../ui/buttons/ButtonText';

export default function AIReportRightCategories() {
	const { data, categoryNavigator, setCategoryNavigator } = useAIReport();
	const { articles } = data;

	const categoryData = useMemo(() => {
		const flatAllCategories = articles.flatMap(article => article.clauses.flatMap(({ categories }) => categories));

		const categoryCountMap = flatAllCategories.reduce(
			(acc: Record<ClauseCategory, number>, value: ClauseCategory) => {
				acc[value] = (acc[value] || 0) + 1;
				return acc;
			},
			{} as Record<ClauseCategory, number>,
		);

		const categoryCountEntries = Object.entries(categoryCountMap).map(
			([key, value]) => [key as ClauseCategory, value] as [ClauseCategory, number],
		);

		return { flatAllCategories, categoryCountMap, categoryCountEntries };
	}, [articles]);

	const { categoryCountEntries } = categoryData;

	const findClauseElByCategory = useCallback(
		(e: React.MouseEvent<HTMLElement>, key: ClauseCategory) => {
			const newElements = Array.from(
				window.document.querySelectorAll(`.clause-category-${key}`),
			) as HTMLElement[];

			const newIndexMap = categoryNavigator.indexMap[key]
				? categoryNavigator.indexMap
				: { ...categoryNavigator.indexMap, [key]: 0 };

			const newState: CategoryNavigator = {
				elements: newElements,
				categoryKey: key,
				indexMap: newIndexMap,
				navigatorAnchorEl: newElements.length > 1 ? e.currentTarget : null,
			};

			setCategoryNavigator(prevState => ({ ...prevState, ...newState }));
		},
		[categoryNavigator],
	);

	return (
		<div className='grid grid-cols-3 gap-2'>
			{categoryCountEntries.map(([key, value]: [ClauseCategory, number]) => (
				<div key={key} className='truncate'>
					<ButtonText
						className='text-neutral-800 text-sm'
						variant='outlined'
						fullWidth
						onClick={e => findClauseElByCategory(e, key)}
					>
						{AI_CLAUSE_CATEGORY_MAP[key]} ({value})
					</ButtonText>
				</div>
			))}
		</div>
	);
}
