import { Checkbox, FormHelperText, InputLabel, ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { FormikValues } from 'formik';
import { ReactNode, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../../hooks/useAlert';
import { Tag } from '../../../model/tag';
import LabelChip from '../chips/LabelChip';
/**
 * option 정의
 * icon: option 에 icon 표시
 * name: ui 에 보여지는 이름
 * value: 실제 저장되는 값
 */
export interface IDefaultOption {
	id?: string | number;
	icon?: ReactNode;
	name?: string | ReactNode;
	value: string | number;
}

type TOption = IDefaultOption | Tag;
/**
 * OptionType 정의
 * text: 문자열만 표시
 * checkbox: checkbox ui 표시
 * tag: 라벨 표시
 */
type TOptionType = 'text' | 'checkbox' | 'tag';

interface ISelectPropsOverrides extends SelectProps {
	id: string;
	size?: 'small' | 'medium';
	type?: TOptionType;
	defaultValue?: string | string[] | undefined;
	formik: FormikValues;
	options: TOption[] | Tag[];
	allowEmptyCheck?: boolean;
	required?: boolean;
}

function InputSelect({
	id,
	label,
	type = 'text',
	multiple = false,
	size = 'medium',
	options,
	classes = { root: undefined },
	disabled = false,
	placeholder,
	required = false,
	formik,
	allowEmptyCheck = true,
	...props
}: ISelectPropsOverrides) {
	const { t } = useTranslation();
	const { root = 'relative w-full rounded' } = classes;
	const [snackbar] = useAlert();

	// 선택된 option 형태
	const handleRenderValue = useCallback(
		(selected: string | string[]): ReactNode => {
			let result: ReactNode;
			switch (type) {
				case 'text':
				case 'checkbox': {
					result = (
						<div className='flex flex-wrap gap-3 truncate'>
							{Array.isArray(selected) ? (
								selected.map(
									(value: string) =>
										value && (
											<span key={value} className='truncate'>
												{options.filter(item => item.value === value)[0]?.name}
											</span>
										),
								)
							) : (
								<span className='truncate'>
									{options.filter(item => item.value === selected)[0]?.name}
								</span>
							)}
						</div>
					);
					break;
				}
				case 'tag': {
					// label 형태 option
					result = (
						<div className='flex flex-wrap gap-3'>
							{Array.isArray(selected)
								? selected.map(
										(value: string) =>
											value && (
												<LabelChip
													key={value}
													id={id}
													tag={options.filter(item => item.value === value)[0] as Tag}
													formik={formik}
													disabled={disabled}
												/>
											),
								  )
								: selected && (
										<LabelChip
											key={selected}
											id={id}
											tag={options.filter(item => item.value === selected)[0] as Tag}
											formik={formik}
											disabled={disabled}
										/>
								  )}
						</div>
					);
					break;
				}
				default:
			}

			return result;
		},
		[id, options],
	);

	// option list 형태
	const handleOptionList = useCallback(
		(option: TOption) => {
			let item: ReactNode;

			switch (type) {
				case 'text': {
					// 텍스트 형태 option
					const { icon, name = '', value } = option as IDefaultOption;
					item = (
						<MenuItem key={value} value={value}>
							<div className='flex items-center gap-2'>
								{icon && icon}
								{name && name}
							</div>
						</MenuItem>
					);
					break;
				}
				case 'checkbox': {
					// checkbox 형태 option
					const { name = '', value } = option as IDefaultOption;
					const checked = type === 'checkbox' && formik.values[id].includes(value);
					item = (
						<MenuItem key={value} value={value}>
							<Checkbox
								checked={checked}
								disabled={!allowEmptyCheck && checked && formik.values[id].length === 1}
							/>
							<ListItemText primary={name} />
						</MenuItem>
					);
					break;
				}
				case 'tag': {
					// label 형태 option
					const { value } = option as Tag;
					item = (
						<MenuItem key={value} value={value}>
							<LabelChip id={value} tag={option as Tag} disabled />
						</MenuItem>
					);
					break;
				}
				default:
			}
			return item;
		},
		[id, options],
	);

	const handleChange = useCallback(
		(selectedOption: SelectChangeEvent<string | string[]>) => {
			if (type === 'checkbox' && !allowEmptyCheck && !selectedOption?.target?.value.length) {
				snackbar(t('cmmn_label_at_least_one_item_must_be_selected'), 'warning');
			} else {
				formik.setFieldValue(id, selectedOption?.target?.value);
			}
		},
		[id, options],
	);

	return (
		<FormControl
			classes={{
				root,
			}}
			size={size}
			error={formik.touched[id] && formik.errors[id] && true}
		>
			<InputLabel id={`${id}_label`} required={required}>
				{label}
			</InputLabel>
			<Select
				id={id}
				label={label}
				labelId={`${id}_label`}
				multiple={multiple}
				value={formik.values[id]}
				renderValue={handleRenderValue}
				disabled={disabled}
				onChange={selectedOption => handleChange(selectedOption)}
				{...props}
			>
				{placeholder && <MenuItem disabled>{placeholder}</MenuItem>}
				{!required && !multiple && <MenuItem value=''>{t('label_no_selection')}</MenuItem>}
				{options.map(option => handleOptionList(option))}
			</Select>
			{formik.touched[id] && formik.errors[id] && (
				<FormHelperText classes={{ root: 'absolute bottom-0 translate-y-full mx-0' }} error>
					{formik.errors[id]}
				</FormHelperText>
			)}
		</FormControl>
	);
}

export default memo(InputSelect);
