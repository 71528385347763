import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { doaCheck } from '../../api/login';
import { useUser } from '../../context/UserContext';
import { DOA } from '../../model/user';
import ButtonText from '../ui/buttons/ButtonText';
import StateChip from '../ui/chips/StateChip';
import PopoverInternalState from '../ui/popovers/PopoverInternalState';
import PopupCommonInternal from '../ui/popups/PopupCommonInternal';

type Props = {
	userList?: DOA[];
};

export default function AuthorizationList({ userList = [] }: Props) {
	const { setLoginUser } = useUser();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const makeMsg = (name: string, dept: string, doaStartDate: string, doaEndDate: string) =>
		t('cmmn_guide_delegate_infomation', {
			name,
			dept,
			doaStartDate,
			doaEndDate,
		});

	return (
		<PopoverInternalState
			classes={{ paper: '' }}
			openTrigger={<ButtonText className={`${userList} p-2 text-sm text-gray-950`}>{t('MSG_ID_20')}</ButtonText>}
		>
			<ul className='flex flex-col gap-1 min-w-[15rem] py-2'>
				{userList &&
					userList.map(({ id, name, dept, rank, doaStartDate, doaEndDate }, index) => (
						<li key={id}>
							<PopupCommonInternal
								openTrigger={
									<ButtonText className='w-full rounded-none'>
										<div className='flex items-center gap-1 w-full'>
											<p className='text-sm whitespace-nowrap'>{name}</p>
											<p className='text-sm whitespace-nowrap'>{dept}</p>
											{rank && <p className='text-sm whitespace-nowrap'>{`/ ${rank}`}</p>}
											<StateChip
												text={t('cmmn_label_transform')}
												classes={{
													root: 'text-xs px-1 py-1 border-2 border-yellow-400 ml-auto',
												}}
											/>
										</div>
									</ButtonText>
								}
								msg={makeMsg(name, dept ?? '', doaStartDate, doaEndDate)}
								confirmClose={false}
								onConfirm={() => {
									doaCheck({ index, navigate, setLoginUser });
								}}
								onCancel={() => false}
							/>
						</li>
					))}
			</ul>
		</PopoverInternalState>
	);
}
