import React, { ReactNode, useCallback } from 'react';
import { useTheme } from '../../../hooks/useTheme';
import TooltipDefault from '../../ui/tooltips/TooltipDefault';
import { AI_ARTICLE_TITLE_REGEX } from '../../../constants/aiConstans';
import { IStoreArticle } from '../../../model/api/ai';
import AIArticleTooltip from '../common/AIArticleTooltip';

type Props = {
	content: string;
	articles: IStoreArticle[];
};

export default function AIReportHighlightArticleWord({ content, articles }: Props) {
	const theme = useTheme();

	const HighlightArticleWord = useCallback(
		({ text }: { text: string }) => {
			const matches = text?.match(AI_ARTICLE_TITLE_REGEX);

			if (!matches) {
				return [text];
			}

			const parts = text.split(AI_ARTICLE_TITLE_REGEX);
			return parts.reduce<ReactNode[]>((acc, part, index) => {
				acc.push(part);

				const articleIndex = articles.findIndex(article => article.title.includes(matches[index]));
				const article = articles[articleIndex];
				if (article) {
					acc.push(
						// eslint-disable-next-line react/no-array-index-key
						<TooltipDefault key={index} title={<AIArticleTooltip article={article} />}>
							<span
								className='relative py-0.5 px-1 rounded-md cursor-pointer'
								style={{ backgroundColor: theme.palette.secondary.main }}
							>
								{matches[index]}
							</span>
						</TooltipDefault>,
					);
				} else {
					acc.push(matches[index]);
				}

				return acc;
			}, []);
		},
		[content, articles],
	);

	return (
		<p className='whitespace-pre-line'>
			<HighlightArticleWord text={content} />
		</p>
	);
}
