import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { AIArticleProvider } from '../../context/AIArticleContext';
import AIArticleFavoriteItemSave from '../../components/ai/article/favorite/AIArticleFavoriteItemSave';
import { useUser } from '../../context/UserContext';

export default function RoutePage() {
	const navigate = useNavigate();
	const { permission } = useUser();

	useEffect(() => {
		if (!permission.ACTIVATE_BUPTLE_AI) {
			navigate('/');
		}
	}, [permission]);

	if (!permission.ACTIVATE_BUPTLE_AI) {
		return null;
	}

	return (
		<AIArticleProvider>
			<Outlet />
			<AIArticleFavoriteItemSave />
		</AIArticleProvider>
	);
}
