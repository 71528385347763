import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useCallback } from 'react';
import { useAIReport } from '../../../../context/AIReportContext';
import { IStoreArticle } from '../../../../model/api/ai';

type Props = {
	article: IStoreArticle;
};

export default function AIReportLeftArticleTable({ article }: Props) {
	const { setFocusState } = useAIReport();

	const scrollToClausesArea = useCallback(() => {
		const clausesArea = window.document.querySelector('#similar-clauses') as HTMLElement;
		if (clausesArea) {
			window.scrollTo({
				top: clausesArea.offsetTop,
				behavior: 'smooth',
			});
		}
	}, []);

	const handleArticleFocus = useCallback(
		(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, articleId: number) => {
			const articleElement = window.document.querySelector(`#article-${articleId}`) as HTMLElement;
			if (articleElement) {
				setFocusState({
					article: {
						id: articleId,
						element: articleElement,
					},
					clause: null,
					scroll: true,
				});
				scrollToClausesArea();
			}
		},
		[setFocusState],
	);

	const handleClauseFocus = useCallback(
		(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, clauseId: number, articleId: number) => {
			const clauseElement = e.currentTarget;
			if (clauseElement) {
				setFocusState({
					article: {
						id: articleId,
						element: null,
					},
					clause: {
						id: clauseId,
						element: clauseElement,
					},
					scroll: true,
				});
				scrollToClausesArea();
			}
		},
		[setFocusState],
	);

	return (
		<TableContainer id={`article-${article.id}`} className='rounded-md'>
			<Table aria-label='article clauses'>
				<TableHead>
					<TableRow hover>
						<TableCell
							className='cursor-pointer p-2 font-bold text-base'
							onClick={e => handleArticleFocus(e, article.id)}
						>
							{article.title}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{article.clauses.map(({ id, content, categories }, index) => {
						const categoryClasses = categories.map(category => `clause-category-${category}`).join(' ');
						const isLastItem = article.clauses.length - 1 === index;
						return (
							<TableRow key={`clause-${id}`} hover>
								<TableCell
									id={`clause-${id}`}
									className={`cursor-pointer py-2 px-3 text-neutral-600 text-base
									${isLastItem ? 'border-none' : ''} ${categoryClasses}`}
									onClick={e => handleClauseFocus(e, id, article.id)}
								>
									{content}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
