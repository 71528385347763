import { useQuery } from '@tanstack/react-query';
import { FormikValues } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserList } from '../../../api/common';
import { queryKey } from '../../../constants/queryKey';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { IHeadCell } from '../../../model/table';
import { User } from '../../../model/user';
import SysConfigStore from '../../../store/common/SysConfigStore';
import ChipDefault from '../../ui/chips/ChipDefault';
import PersonIcon from '../../ui/icons/PersonIcon';
import InputPopupSelect from '../../ui/inputs/InputPopupSelect';
import PopupCommonExternal from '../../ui/popups/PopupCommonExternal';
import { ModalSize } from '../../ui/popups/PopupDefault';
import TableSkeleton from '../../ui/tables/TableSkeleton';
import TableUserList from '../tables/user/TableUserList';

/**
 * formik: 유저 팝업이 뜨기 전 유저 추가 밑에 있는 유저 아이콘 있는 유저들
 * selectedFormik: 유저 팝업에서 체크되어있는 유저들
 */

type Props = {
	id: string;
	label?: string;
	formik: FormikValues;
	popupSize?: ModalSize;
	inputSize?: 'small' | 'medium';
	required?: boolean;
	disabled?: boolean;
	maxSelected?: number;
};
export default function PopupUsers({
	id,
	formik,
	label,
	popupSize = 'medium',
	inputSize = 'medium',
	required = false,
	disabled = false,
	maxSelected = 0,
}: Props) {
	const { t } = useTranslation();
	const { isComponentEnabled } = SysConfigStore();
	const [open, setOpen] = useState(false);
	const handleTogglePopup = () => setOpen(prev => !prev);
	const handleClose = () => setOpen(false);
	const { data, isLoading } = useQuery(queryKey.userList, () => getUserList());

	const selectedFormik = useValidateForm({
		validationSchema: {
			[id]: { initialValue: [], type: 'array' },
		},
	});

	const companyHeadCell: IHeadCell<User>[] = isComponentEnabled('CMPNT147_ADD_COMPANY_OPTION_TO_POPUP_BF')
		? [
				{
					id: 'subsidiaryName',
					align: 'left',
					disablePadding: false,
					disableSort: false,
					label: t('label_company_name'),
				},
		  ]
		: [];

	const headCells: readonly IHeadCell<User>[] = [
		{
			id: 'name',
			align: 'left',
			disablePadding: true,
			disableSort: false,
			label: t('MSG_ID_99'),
		},
		...companyHeadCell,
		{
			id: 'dept',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('MSG_ID_100'),
		},
		{
			id: 'rank',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('label_rank'),
		},
		{
			id: 'email',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('MSG_ID_93'),
		},
	];

	const handleClick = useCallback(() => {
		formik.setFieldValue(id, selectedFormik.values[id]);
		handleTogglePopup();
	}, [data, formik.values, selectedFormik.values[id]]);

	const renderPage = useCallback(
		() => (
			<>
				{isLoading && !data && <TableSkeleton colSpan={headCells.length + 1} />}
				{!isLoading && data && (
					<TableUserList
						id={id}
						listType='checkbox'
						list={data}
						headCells={headCells}
						formik={formik}
						maxSelected={maxSelected}
						selectedFormik={selectedFormik}
					/>
				)}
			</>
		),
		[data, formik.values, selectedFormik.values],
	);

	const handleDelete = useCallback(
		(targetId: string) => {
			formik.setFieldValue(
				id,
				(formik.values[id] as User[]).filter(value => String(value.id) !== targetId),
			);
		},
		[data, formik.values, selectedFormik.values],
	);

	const optionRenderPage = useCallback(
		(selected: unknown) => (
			<div className='flex flex-wrap gap-1'>
				{(selected as User[]).map(item => (
					<ChipDefault
						key={item.id}
						id={String(item.id)}
						icon={<PersonIcon className='-mt-0.5' />}
						label={item.name}
						disabled={disabled}
						onDelete={handleDelete}
					/>
				))}
			</div>
		),
		[data, formik.values, selectedFormik.values],
	);

	useEffect(() => {
		// 다른 값에 따라 maxSelected 값이 변경되야 하면 초기화
		if (maxSelected !== 0 && maxSelected < formik.values[id].length) formik.setFieldValue(id, []);
	}, [maxSelected]);

	return (
		<>
			<InputPopupSelect
				id={id}
				label={label}
				formik={formik}
				size={inputSize}
				required={required}
				disabled={disabled}
				onClick={handleTogglePopup}
				renderValue={optionRenderPage}
			/>
			<PopupCommonExternal
				open={open}
				size={popupSize}
				title={t('MSG_ID_98')}
				renderPage={renderPage()}
				onConfirm={handleClick}
				onCancel={handleClose}
				onClose={handleClose}
			/>
		</>
	);
}
