import { useUser } from '../../context/UserContext';
import AuthorizationList from './AuthorizationList';
import DoaUserOption from './DoaUserOption';

export default function MenuItemDoa() {
	const { loginUser } = useUser();

	return (
		<>
			{loginUser.isDOA && <DoaUserOption />}
			{!loginUser.isDOA && <AuthorizationList userList={loginUser?.DOAList} />}
		</>
	);
}
