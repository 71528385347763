import { SnackbarProvider } from 'notistack';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import App from './App';
import Head from './components/head/Head';
import { PaperManageProvider } from './context/PaperManageContext';
import { StatisticsProvider } from './context/StatisticsContext';
import './i18n/i18n';
import './index.css';
import ListHardDeletePage from './pages/admin/hardDelete/ListPage';
import ListOfficialLetterFieldPage from './pages/admin/officialLetter/field/ListPage';
import DetailOfficialLetterSettingPage from './pages/admin/officialLetter/setting/DetailPage';
import DetailOfficialLetterTemplatePage from './pages/admin/officialLetter/template/DetailPage';
import ListOfficialLetterTemplatePage from './pages/admin/officialLetter/template/ListPage';
import DetailOfficialLetterWorkflowPage from './pages/admin/officialLetter/workflow/DetailPage';
import ProviderWorkflowPage from './pages/admin/officialLetter/workflow/ProviderPage';
import AdminProtectedRoute from './pages/admin/ProtectedRoute';
import ErrorPage from './pages/common/ErrorPage';
import GlobalDataSetter from './pages/common/GlobalDataSetter';
import LoginPage from './pages/common/LoginPage';
import LogoutPage from './pages/common/LogoutPage';
import ProtectedRoute from './pages/common/ProtectedRoute';
import QueryClientSetter from './pages/common/QueryClientSetter';
import RedirectHandler from './pages/common/RedirectHandler';
import { SysConfigSetter } from './pages/common/SysConfigSetter';
import RouteDashboardPage from './pages/dashboard/RoutePage';
import PaperManageDetail from './pages/main/paperManage/PaperManageDetail';
import PaperManageList from './pages/main/paperManage/PaperManageList';
import SignProtectedRoute from './pages/sign/ProtectedRoute';
import SKSignProtectedRoute from './pages/sign/sk/ProtectedRoute';

import { AIReportProvider } from './context/AIReportContext';
import ExternalApp from './ExternalApp';
import AIArticleDetailPage from './pages/ai/article/detail/AIArticleDetailPage';
import AIArticleFavoritePage from './pages/ai/article/favorite/AIArticleFavoritePage';
import AIArticleSearchMainPage from './pages/ai/article/main/AIArticleSearchMainPage';
import AIArticleSearchResultPage from './pages/ai/article/search/AIArticleSearchResultPage';
import AIArticleWithSidebarLayout from './pages/ai/article/AIArticleWithSidebarLayout';
import AIReportPage from './pages/ai/report/AIReportPage';
import AIRoutePage from './pages/ai/RoutePage';
import LoginSSOPage from './pages/login/telechips/LoginPage';
import ProviderSignPage from './pages/sign/ProviderPage';
import DashboardStatisticsPage from './pages/statistics/DashboardPage';
import DetailStatisticsPage from './pages/statistics/DetailPage';
import RouteStatisticsPage from './pages/statistics/RoutePage';
import AccodionPage from './pages/ui/AccodionPage';
import ButtonPage from './pages/ui/ButtonPage';
import CanvasPage from './pages/ui/CanvasPage';
import ChartPage from './pages/ui/ChartPage';
import ColorPage from './pages/ui/ColorPage';
import ContainerPage from './pages/ui/ContainerPage';
import FontPage from './pages/ui/FontPage';
import FormPage from './pages/ui/FormPage';
import IconPage from './pages/ui/IconPage';
import PaginationPage from './pages/ui/PaginationPage';
import PDFPage from './pages/ui/PDFPage';
import PopupPage from './pages/ui/PopupPage';
import UiRoutePage from './pages/ui/RoutePage';
import SlidePage from './pages/ui/SlidePage';
import TablePage from './pages/ui/TablePage';
import TabPage from './pages/ui/TabPage';
import TestPDFPage from './pages/ui/TestPdfPage';
import TooltipPage from './pages/ui/TooltipPage';
import reportWebVitals from './reportWebVitals';

const uiRoute =
	process.env.NODE_ENV === 'production'
		? {}
		: {
				path: '/ui',
				element: <UiRoutePage />,
				children: [
					{
						path: '/ui/font',
						element: <FontPage />,
					},
					{
						path: '/ui/color',
						element: <ColorPage />,
					},
					{
						path: '/ui/icon',
						element: <IconPage />,
					},
					{
						path: '/ui/button',
						element: <ButtonPage />,
					},
					{
						path: '/ui/container',
						element: <ContainerPage />,
					},
					{
						path: '/ui/tab',
						element: <TabPage />,
					},
					{
						path: '/ui/accodion',
						element: <AccodionPage />,
					},
					{
						path: '/ui/form',
						element: <FormPage />,
					},
					{
						path: '/ui/table',
						element: <TablePage />,
					},
					{
						path: '/ui/pagination',
						element: <PaginationPage />,
					},
					{
						path: '/ui/popup',
						element: <PopupPage />,
					},
					{
						path: '/ui/chart',
						element: <ChartPage />,
					},
					{
						path: '/ui/pdf',
						element: <PDFPage />,
					},
					{
						path: '/ui/slide',
						element: <SlidePage />,
					},
					{
						path: '/ui/tooltip',
						element: <TooltipPage />,
					},
					{
						path: '/ui/testpdf',
						element: <TestPDFPage />,
					},
					{
						path: '/ui/canvas',
						element: <CanvasPage />,
					},
				],
		  };

export const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<ProtectedRoute>
				<App />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />,
		children: [
			uiRoute,
			{
				index: true,
				path: '/',
				element: <RouteDashboardPage />,
			},
			{
				path: '/get_dashboard_view',
				element: <RedirectHandler />,
			},
			{
				path: '/manage/main',
				element: <RedirectHandler />,
			},
			{
				path: '/statistics',
				element: (
					<StatisticsProvider>
						<RouteStatisticsPage />
					</StatisticsProvider>
				),
				children: [
					{
						index: true,
						path: '/statistics',
						element: <DashboardStatisticsPage />,
					},
					{
						path: '/statistics/detail/:query',
						element: <DetailStatisticsPage />,
					},
				],
			},
			{
				path: '/sign/:appId?/:id?',
				element: (
					<ProviderSignPage>
						<SignProtectedRoute />
					</ProviderSignPage>
				),
			},
			{
				path: '/sign/contract-seal/:contractId', // SK 용
				element: <SKSignProtectedRoute />,
			},
			{
				path: '/paperManage/list',
				element: (
					<PaperManageProvider>
						<PaperManageList />
					</PaperManageProvider>
				),
			},
			{
				path: '/paperManage/detail',
				element: (
					<PaperManageProvider>
						<PaperManageDetail />
					</PaperManageProvider>
				),
			},
			{
				path: '/hardDelete',
				element: <ListHardDeletePage />,
			},
			{
				path: '/admin',
				element: <AdminProtectedRoute />,
				children: [
					{
						path: '/admin/officialLetter/setting',
						element: <DetailOfficialLetterSettingPage />,
					},
					{
						path: '/admin/officialLetter/workflow/:id?',
						element: (
							<ProviderWorkflowPage>
								<DetailOfficialLetterWorkflowPage />
							</ProviderWorkflowPage>
						),
					},
					{
						path: '/admin/officialLetter/template',
						element: <ListOfficialLetterTemplatePage />,
					},
					{
						path: '/admin/officialLetter/template/detail/:id?',
						element: <DetailOfficialLetterTemplatePage />,
					},
					{
						path: '/admin/officialLetter/field',
						element: <ListOfficialLetterFieldPage />,
					},
				],
			},
			{
				path: '/buptle_ai',
				element: <AIRoutePage />,
				children: [
					{
						index: true,
						element: <Navigate to='article/main' />,
					},
					{
						element: <AIArticleWithSidebarLayout />,
						children: [
							{
								path: '/buptle_ai/article/main',
								element: <AIArticleSearchMainPage />,
							},
							{
								path: '/buptle_ai/article/search',
								element: <AIArticleSearchResultPage />,
							},
							{
								path: '/buptle_ai/article/detail/:id',
								element: <AIArticleDetailPage />,
							},
						],
					},
					{
						path: '/buptle_ai/article/favorite',
						element: <AIArticleFavoritePage />,
					},
					{
						path: '/buptle_ai/report/:id',
						element: (
							<AIReportProvider>
								<AIReportPage />
							</AIReportProvider>
						),
					},
				],
			},
		],
	},
	{
		path: '/external',
		element: <ExternalApp />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: '/external/sign/:appId/:id',
				element: <div>가나다</div>,
			},
		],
	},
	{
		path: '/login',
		element: <LoginPage />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/login_sso',
		element: <LoginSSOPage />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/logout',
		element: <LogoutPage />,
		errorElement: <ErrorPage />,
	},
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<HelmetProvider>
			<Head />
			<QueryClientSetter>
				<SysConfigSetter>
					<SnackbarProvider maxSnack={5}>
						<CookiesProvider>
							<GlobalDataSetter />
							<RouterProvider router={router} />
						</CookiesProvider>
					</SnackbarProvider>
				</SysConfigSetter>
			</QueryClientSetter>
		</HelmetProvider>
	</React.StrictMode>,
);
reportWebVitals();
