import { IconButton, Tooltip, TooltipProps } from '@mui/material';
import { ReactNode, memo } from 'react';
import { SvgIconPropsOverrides } from '../../../model/icon';
import InfoIcon from '../icons/InfoIcon';

type TooltipPropsOverrides = Partial<TooltipProps> &
	SvgIconPropsOverrides & {
		icon?: ReactNode;
	};
function InfoTooltip({
	title,
	color,
	fontSize = 'small',
	icon = <InfoIcon color={color} fontSize={fontSize} />,
	arrow = true,
	placement = 'top-start',
	...props
}: TooltipPropsOverrides) {
	const message = <div>{title}</div>;

	return (
		<Tooltip title={message} arrow={arrow} placement={placement} {...props}>
			<IconButton>{icon}</IconButton>
		</Tooltip>
	);
}

export default memo(InfoTooltip);
