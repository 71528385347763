import { ReactNode, useRef } from 'react';
import { XYCoord, useDrop } from 'react-dnd';
import { AnyObject } from 'yup';
import { dndItemTypes } from '../../../constants/dndType';
import { DndSign } from '../../../model/sign';

type Props = {
	id: string;
	viewerZoom: number;
	pageWidth: number;
	pageHeight: number;
	children: ReactNode;
};

export default function DustbinSign({ id, viewerZoom, pageWidth, pageHeight, children }: Props) {
	const ref = useRef(null);
	const [_, drop] = useDrop(() => ({
		accept: dndItemTypes.sign,
		drop: (__: DndSign, monitor) => {
			const clientOffset = monitor.getClientOffset();
			const { x, y } = clientOffset as XYCoord;
			if (!ref.current)
				return {
					id,
					x,
					y,
				};

			const { left = 0, top = 0 }: DOMRect = (ref.current as HTMLElement).getBoundingClientRect();
			const calcX = x - left;
			const calcY = y - top;

			return {
				id,
				viewerZoom,
				x: calcX,
				y: calcY,
				pageWidth,
				pageHeight,
			};
		},
		collect: (monitor: AnyObject) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	}));

	drop(ref);

	return (
		<div className='absolute inset-0' ref={ref}>
			{children}
		</div>
	);
}
