import { memo, useCallback, useMemo, useState } from 'react';
import { STATIC_DIR } from '../../../../constants/common';
import { useTheme } from '../../../../hooks/useTheme';
import ButtonText from '../../../ui/buttons/ButtonText';
import InfoIcon from '../../../ui/icons/InfoIcon';
import PopupCommonExternal from '../../../ui/popups/PopupCommonExternal';

function PopupSignNotification() {
	const theme = useTheme();
	const [open, setOpen] = useState(false);

	const handleClick = useCallback(() => {
		setOpen(true);
	}, []);

	const handleConfirm = useCallback(() => {
		setOpen(false);
	}, []);

	const renderPage = useMemo(
		() => (
			<section className='grid gap-8'>
				<div>
					<div className='mb-2'>
						<img
							className='w-[180px] mx-auto'
							src={`${STATIC_DIR}/images/img-stamp-default.png`}
							alt='stamp'
						/>
					</div>
					<div className='flex items-center justify-center gap-1'>
						<InfoIcon className='text-gray-600' fontSize='small' />
						<p className='text-gray-600'>최종완료시 ‘서명인식 이미지’가 회사직인으로 자동 변환됩니다.</p>
					</div>
				</div>

				<div>
					<p className='font-bold'>이미지 파일 다운로드</p>
					<p className='text-gray-600'>
						서명인식 이미지를 다운로드 후, 날인에 들어갈 docx 문서 본문에 삽입해 주세요.
					</p>
					<ButtonText text='다운로드' variant='outlined' />
				</div>
				<div>
					<p className='font-bold'>예제 파일 다운로드</p>
					<p className='text-gray-600'>docx 예제 파일을 다운로드하여 사용하세요.</p>
					<p className='text-gray-600'>
						docx 파일 간에는 서명 인식 이미지를 복사하여 붙여넣기 할 수 있습니다.
					</p>
					<ButtonText text='다운로드' variant='outlined' />
				</div>
				<div>
					<div className='flex items-center gap-1'>
						<InfoIcon fontSize='small' style={{ color: theme.palette.error.main }} />
						<p className='font-bold'>주의사항</p>
					</div>

					<p className='text-gray-600'>- 서명인식 이미지의 확장자(png 파일)를 임의로 변경하지 마세요.</p>
					<p className='text-gray-600'>
						- 이미지에 포함된 $&#123;stamp&#125;$ 표기가 인식되지 않을 만큼 크기를 과도하게 줄이지 마세요.
					</p>
					<p className='text-gray-600'>
						- 별도의 편집툴, 온리오피스 제어시 파일의 시그니처가 손상되어 자동날인기능이 정상적으로 동작하지
						않을 수 있습니다.
					</p>
				</div>
			</section>
		),
		[],
	);

	return (
		<>
			<ButtonText text='자동날인 안내' color='primary' onClick={handleClick} />
			<PopupCommonExternal
				open={open}
				size='xsmall'
				title='자동 날인 안내'
				renderPage={renderPage}
				onClose={() => setOpen(false)}
				onConfirm={handleConfirm}
			/>
		</>
	);
}

export default memo(PopupSignNotification);
