export const OFFICIALLETTER_TYPE: { [key: number]: string } = {
	1: '공문서',
	2: '등기',
	3: '내용증명',
	4: '확약서',
	5: '양해각서',
	6: '경고장',
	7: '동의서',
};

interface IProcessName {
	[key: string]: '초안' | '검토' | '서명' | '완료';
}
export const PROCESS_NAME: IProcessName = {
	draft: '초안',
	review: '검토',
	sign: '서명',
	complete: '완료',
};

interface IRoleName {
	[key: string]:
		| '기안자'
		| '검토자(단계 오너)'
		| '최종 승인 담당자'
		| '서명인'
		| '검토자 권한 유저'
		| '관리자 권한 유저';
}

export const ROLE_NAME: IRoleName = {
	drafter: '기안자',
	reviewer: '검토자(단계 오너)',
	finalApprover: '최종 승인 담당자',
	signer: '서명인',
	reviewerUser: '검토자 권한 유저',
	admin: '관리자 권한 유저',
};
