import { NoticeBoardHeaderList, RecentNotice } from '../model/api/notice';
import { sendPost } from './common';

export async function getUserNoticeBoards() {
	const res = await sendPost<NoticeBoardHeaderList>('/manage/get-user-notice-board-list/', {});

	return res.data.notice_board_list;
}

export async function getRecentNotice() {
	const res = await sendPost<RecentNotice>('/manage/get_recent_notice/', {});
	return res.data;
}

export async function updateDisabledNoticeList(noticeId: string) {
	const params = {
		notice_id: noticeId,
	};
	await sendPost<RecentNotice>('/api/update-disabled-notice-list/', params);
}
