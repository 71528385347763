import { memo } from 'react';
import { useProviderPage } from '../../../../pages/admin/officialLetter/workflow/ProviderPage';
import ItemProcess from './ItemProcess';
import ItemStep from './ItemStep';

function ContainerProcess() {
	const { officialLetter } = useProviderPage();

	return (
		<ul className='relative grid'>
			{officialLetter.process.map(item => (
				<li key={item.id} className='relative pl-8'>
					<ItemProcess processId={item.id} data={item} />

					<ul className='grid gap-6 py-4'>
						{item.steps.map(
							step =>
								!step.options.isDeleted && (
									<ItemStep key={`${step.id}`} processId={item.id} data={step} />
								),
						)}
					</ul>
				</li>
			))}
		</ul>
	);
}

export default memo(ContainerProcess);
