import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { ChangeEvent, MouseEvent } from 'react';
import { AppList } from '../../model/list';
import { IHeadCell, TOrder } from '../../model/table';

type Props = {
	headCells: readonly IHeadCell<AppList>[];
	listType?: 'checkbox' | 'radio';
	numSelected?: number;
	onRequestSort: (event: MouseEvent<unknown>, property: keyof AppList) => void;
	onSelectAllClick?: (event: ChangeEvent<HTMLInputElement>) => void;
	order: TOrder;
	orderBy: number | string;
	rowCount: number;
};

export default function TableStatisticsHeader({
	listType,
	onSelectAllClick,
	order,
	orderBy,
	numSelected = 0,
	rowCount,
	onRequestSort,
	headCells,
}: Props) {
	return (
		<TableHead>
			<TableRow>
				{listType === 'checkbox' && (
					<TableCell padding='checkbox'>
						<Checkbox
							color='primary'
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
						/>
					</TableCell>
				)}
				{headCells.map(headCell => (
					<TableCell
						key={headCell?.id}
						align={headCell?.align || 'left'}
						padding={headCell?.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell?.id ? order : false}
						classes={{ head: `${headCell.width || ''}` }}
					>
						<TableSortLabel
							active={headCell.disableSort}
							hideSortIcon={!headCell.disableSort}
							sx={{
								cursor: headCell.disableSort ? 'pointer' : 'default', // 조건부로 커서 스타일 적용
								'&:hover': {
									color: headCell.disableSort ? 'inherit' : 'currentcolor', // hover 시 색상 변경 방지
								},
								'&.Mui-active': {
									color: 'inherit', // 정렬 활성화 시 색상 변경 방지
								},
							}}
						>
							{headCell?.label}
							{orderBy === headCell?.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
