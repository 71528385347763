import { RenderPageProps } from '@react-pdf-viewer/core';
import { ChangeEvent, MouseEvent } from 'react';
import { RndDragCallback, RndResizeCallback } from 'react-rnd';
import { Sign } from '../../../model/sign';
import ButtonText from '../buttons/ButtonText';
import AspectRatioIcon from '../icons/AspectRatioIcon';
import ClearIcon from '../icons/ClearIcon';
import SaveIcon from '../icons/SaveIcon';
import RndArea from '../rnds/RndArea';
import DustbinSign from '../signs/DustbinSign';
import InputSize from '../signs/InputSize';

type RenderPagePropsOverrides = RenderPageProps & {
	isClippable?: boolean;
	signs: Sign[];
	onDragStop: RndDragCallback;
	onResizeStop: RndResizeCallback;
	onDeleteSign: (e: MouseEvent<HTMLButtonElement>, signId: string) => void;
	onChange: (e: ChangeEvent<HTMLInputElement>, sign: Sign) => void;
	onSaveSize?: (e: MouseEvent<HTMLButtonElement>, sign: Sign) => void;
	onLoadSize?: (e: MouseEvent<HTMLButtonElement>, sign: Sign) => void;
};

export default function SignRenderPage({
	isClippable = false,
	width: pageWidth,
	height: pageHeight,
	scale,
	pageIndex,
	canvasLayer,
	annotationLayer,
	textLayer,
	signs,
	onDragStop,
	onResizeStop,
	onDeleteSign,
	onChange,
	onSaveSize,
	onLoadSize,
}: RenderPagePropsOverrides) {
	return (
		<>
			<DustbinSign id={`${pageIndex}`} viewerZoom={scale} pageWidth={pageWidth} pageHeight={pageHeight}>
				{canvasLayer.children}
				{annotationLayer.children}
				{textLayer.children}
			</DustbinSign>

			{signs
				.filter(sign => sign.pageIndex === pageIndex)
				.map(sign => {
					const { id, imageUrl, width, height, x: prevX, y: prevY } = sign;
					const maxX = pageWidth - width * scale;
					const maxY = pageHeight - height * scale;
					const x = prevX > 0 ? prevX * scale : 0;
					const y = prevY > 0 ? prevY * scale : 0;

					const calcX = !isClippable && x > maxX ? maxX : x;
					const calcY = !isClippable && y > maxY ? maxY : y;

					return (
						<RndArea
							className='z-10 inline-flex outline-2 outline-dotted outline-transparent hover:outline-red-700 transition-[outline-color]'
							key={id}
							id={id}
							bounds={!isClippable ? 'parent' : ''}
							width={width * scale}
							height={height * scale}
							x={calcX}
							y={calcY}
							onDragStop={onDragStop}
							onResizeStop={onResizeStop}
						>
							<img className='pointer-events-none object-contain' src={imageUrl} alt='sign item' />

							<div className='absolute left-0 top-0 -translate-y-[110%] flex items-center gap-4'>
								<InputSize id={String(id)} value={Math.floor(width)} onChange={onChange} sign={sign} />

								{onSaveSize && (
									<ButtonText
										className='flex min-w-0 p-0 hover:cursor-pointer'
										onClick={e => onSaveSize(e, sign)}
									>
										<SaveIcon fontSize='small' />
									</ButtonText>
								)}

								{onLoadSize && (
									<ButtonText
										className='flex min-w-0 p-0 hover:cursor-pointer'
										onClick={e => onLoadSize(e, sign)}
									>
										<AspectRatioIcon fontSize='small' />
									</ButtonText>
								)}

								<ButtonText
									className='flex min-w-0 p-0 hover:cursor-pointer'
									onClick={e => onDeleteSign(e, sign.id as string)}
								>
									<ClearIcon fontSize='small' />
								</ButtonText>
							</div>
						</RndArea>
					);
				})}
		</>
	);
}
