import { useCallback, useEffect } from 'react';
import AIReportHeader from '../../../components/ai/report/AIReportHeader';
import AIReportLeftTitleArea from '../../../components/ai/report/left/AIReportLeftTitleArea';
import AIReportLeftView from '../../../components/ai/report/left/AIReportLeftView';
import AIReportRightView from '../../../components/ai/report/right/AIReportRightView';
import { useAIReport } from '../../../context/AIReportContext';

export default function AIReportPage() {
	const { refMap } = useAIReport();
	const { leftContainerRef, rightContainerRef } = refMap;

	const updateHeight = useCallback(() => {
		if (leftContainerRef.current) {
			leftContainerRef.current.style.height = `${window.innerHeight}px`;
		}
	}, [refMap]);

	useEffect(() => {
		window.addEventListener('resize', updateHeight);
		updateHeight();

		return () => {
			window.removeEventListener('resize', updateHeight);
		};
	}, [refMap]);

	return (
		<section className='flex flex-col'>
			<AIReportHeader />
			<div className='grid grid-cols-12 gap-1'>
				<div
					ref={leftContainerRef}
					className='overflow-y-auto sticky top-0 scrollbar col-span-7 flex flex-col pl-[30px]'
				>
					<AIReportLeftTitleArea />
					<div className='grid gap-6 pb-3'>
						<AIReportLeftView />
					</div>
				</div>
				<div className='col-span-5 flex flex-col'>
					<div ref={rightContainerRef}>
						<AIReportRightView />
					</div>
				</div>
			</div>
		</section>
	);
}
