import { sendPost } from './common';
import {
	IArticleSearchWithOtherArticles,
	IArticleSearchWithQuery,
	IArticleHistoryItem,
	IArticleFavoriteListItem,
	IArticleRecentItem,
	IReport,
	IReportStatus,
	IStoreTodayStatus,
	IReportFeedbackParams,
} from '../model/api/ai';

export async function getReportById(reportId: number) {
	const params = { report_id: reportId };
	const res = await sendPost<IReport>('/buptle_ai/get_report_by_id/', params);
	return res.data;
}

export async function getSimilarClausesWithQuery(searchParams: string) {
	const params = { search_params: searchParams };
	const res = await sendPost<IArticleSearchWithQuery>('/buptle_ai/get_similar_clauses_with_query/', params);
	return res.data;
}

export async function getSimilarClausesInArticle(articleId: number) {
	const params = { article_id: articleId };
	const res = await sendPost<IArticleSearchWithOtherArticles>('/buptle_ai/get_similar_clauses_in_article/', params);
	return res.data;
}

export async function getUserRecentSeenArticles() {
	const res = await sendPost<IArticleHistoryItem[]>('/buptle_ai/get_user_recent_seen_articles/');
	return res.data;
}

export async function getUserRecentSaveArticles() {
	const res = await sendPost<IArticleHistoryItem[]>('/buptle_ai/get_user_recent_save_articles/');
	return res.data;
}

export async function getMoreUserRecentSeenArticles() {
	const res = await sendPost<IArticleRecentItem[]>('/buptle_ai/get_more_user_recent_seen_articles/');
	return res.data;
}

export async function getMoreUserRecentSaveArticles() {
	const res = await sendPost<IArticleRecentItem[]>('/buptle_ai/get_more_user_recent_save_articles/');
	return res.data;
}

export async function updateUserSeenArticle(item: IArticleHistoryItem) {
	const { history_map: historyMap, index } = item;
	const params = {
		history_map: JSON.stringify(historyMap),
		index,
	};

	const res = await sendPost<IArticleHistoryItem[]>('/buptle_ai/update_user_seen_article/', params);
	return res.data;
}

export async function getUserArticleFavoriteList() {
	const res = await sendPost<IArticleFavoriteListItem[]>('/buptle_ai/get_user_article_favorite_list/');
	return res.data;
}

export async function createUserArticleFavoriteList(name: string) {
	const params = { name };
	const res = await sendPost<null>('/buptle_ai/create_user_article_favorite_list/', params);
	return res.data;
}

export async function saveUserArticleFavoriteItem(item: IArticleHistoryItem, parentId: number) {
	const { history_map: historyMap, index } = item;
	const params = {
		history_map: JSON.stringify(historyMap),
		index,
		parent_id: parentId,
	};
	const res = await sendPost<null>('/buptle_ai/save_user_article_favorite_item/', params);
	return res.data;
}

export async function updateUserArticleFavoriteItem(id: number, parentId: number) {
	const params = {
		id,
		parent_id: parentId,
	};
	const res = await sendPost<null>('/buptle_ai/update_user_article_favorite_item/', params);
	return res.data;
}

export async function getUserArticleFavoriteItem(parentId: number) {
	const params = {
		parent_id: parentId,
	};
	const res = await sendPost<IArticleHistoryItem[]>('/buptle_ai/get_user_article_favorite_item/', params);
	return res.data;
}

export async function getReportStatusByUser() {
	const res = await sendPost<IReportStatus[]>('/buptle_ai/get_report_status_by_user/');
	return res.data;
}

export async function updateReportNotificationByReportId(reportId: number) {
	const params = { report_id: reportId };
	const res = await sendPost<null>('/buptle_ai/update_report_notification_by_report_id/', params);
	return res.data;
}

export async function getTodayStoreStatus() {
	const res = await sendPost<IStoreTodayStatus>('/buptle_ai/get_today_store_status/');
	return res.data;
}

export async function saveReportFeedback(params: IReportFeedbackParams) {
	const res = await sendPost<null>('/buptle_ai/save_report_feedback/', params);
	return res.data;
}
