import { memo } from 'react';
import ContainerList from '../../../../components/admin/officialLetter/template/ContainerList';
import Container from '../../../../components/ui/containers/Container';

function ListPage() {
	return (
		<Container>
			<ContainerList />
		</Container>
	);
}

export default memo(ListPage);
