import { useAIReport } from '../../../../context/AIReportContext';
import CardBox from '../../../ui/containers/CardBox';
import AIReportLeftAnalysisArea from './AIReportLeftAnalysisArea';
import AIReportLeftContract from './AIReportLeftContract';
import AIReportLeftVersionDiff from './AIReportLeftVersionDiff';

export default function AIReportLeftView() {
	const { refMap, openVersionView } = useAIReport();
	const { contractContainerRef } = refMap;

	return openVersionView ? (
		<AIReportLeftVersionDiff />
	) : (
		<>
			<CardBox className='flex flex-col gap-5 p-8 mr-3'>
				<AIReportLeftAnalysisArea />
			</CardBox>
			<CardBox ref={contractContainerRef} className='mr-3'>
				<AIReportLeftContract />
			</CardBox>
		</>
	);
}
