import React from 'react';
import AIArticleAccordion from '../../common/AIArticleAccordion';
import { useAIArticle } from '../../../../context/AIArticleContext';
import AIArticleContent from '../../common/AIArticleContent';

export default function AIArticleSlideView() {
	const { slideState } = useAIArticle();

	return (
		<div className='p-8'>
			<AIArticleContent type='SLIDE' />
			<AIArticleAccordion data={slideState} />
		</div>
	);
}
