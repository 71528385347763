import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { ChangeEvent, MouseEvent, memo } from 'react';
import { ISealStamp } from '../../../../model/sealStamp';
import { IHeadCell, TOrder } from '../../../../model/table';
import TableListCell from '../../../ui/tables/TableListCell';
import TableListRow from '../../../ui/tables/TableListRow';

interface IProps {
	headCells: readonly IHeadCell<ISealStamp>[];
	listType?: 'checkbox' | 'radio';
	numSelected: number;
	onRequestSort: (event: MouseEvent<unknown>, property: keyof ISealStamp) => void;
	onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
	order: TOrder;
	orderBy?: string;
	rowCount: number;
}

function TableSealStampHeader({
	listType,
	onSelectAllClick,
	order,
	orderBy,
	numSelected,
	rowCount,
	onRequestSort,
	headCells,
}: IProps) {
	const createSortHandler = (headCell: IHeadCell<ISealStamp>) => (e: MouseEvent<unknown>) => {
		if (!headCell.disableSort) onRequestSort(e, headCell.id);
	};

	return (
		<TableHead>
			<TableListRow>
				{listType === 'checkbox' && (
					<TableListCell padding='checkbox'>
						<Checkbox
							color='primary'
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
						/>
					</TableListCell>
				)}
				{listType === 'radio' && <TableListCell padding='checkbox' />}
				{headCells.map(headCell => (
					<TableListCell
						key={headCell.id}
						align={headCell.align || 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
						classes={{ head: `${headCell.width || ''}` }}
					>
						<TableSortLabel
							active={!headCell.disableSort && orderBy === headCell.id}
							hideSortIcon={headCell.disableSort}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell)}
							classes={{
								root: `${headCell.disableSort && 'cursor-default hover:text-inherit'}`,
							}}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableListCell>
				))}
			</TableListRow>
		</TableHead>
	);
}

export default memo(TableSealStampHeader);
