import { useTranslation } from 'react-i18next';
import { DOMAIN_URL } from '../../../../constants/common';
import { useUser } from '../../../../context/UserContext';
import { MenuData } from '../../../../model/header';
import SysConfigStore from '../../../../store/common/SysConfigStore';
import MenuItem1Depth from './MenuItem1Depth';

type Props = {
	openMenu: string | null;
	onToggle: (title: string) => void;
};
export default function MenuUser({ openMenu, onToggle }: Props) {
	const { isComponentEnabled } = SysConfigStore();
	const { t } = useTranslation();
	const { loginUser } = useUser();
	const handleclick = () => {
		window.location.href = `${DOMAIN_URL}/setup/mypage_info/`;
	};
	const menu: MenuData = {
		title: loginUser.name,
		classes: { button: 'px-7 py-4' },
		display: true,
		children: [
			// 내 정보 보기
			{
				link: `${DOMAIN_URL}/setup/mypage_info/`,
				title: t('MSG_ID_3'),
				display: true,
				isExternal: true,
			},
			// 로그아웃
			{
				link: '/logout',
				display: !isComponentEnabled('CMPNT202_HIDE_LOGOUT_BTN'),
				title: t('MSG_ID_4'),
			},
		],
	};

	return <MenuItem1Depth itemData={menu} isOpen={openMenu === menu.title} onToggle={() => onToggle(menu.title)} />;
}
