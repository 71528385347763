import React, { useCallback, useMemo, useState } from 'react';
import { Divider } from '@mui/material';
import { IReport, IStoreArticle } from '../../../model/api/ai';
import AIReportHighlightArticleWord from '../report/AIReportHighlightArticleWord';
import { extractReportDetailDisplayList } from '../../../util/ai';
import ChevronRightIcon from '../../ui/icons/ChevronRight';
import ButtonText from '../../ui/buttons/ButtonText';
import { AI_ARTICLE_TITLE_REGEX } from '../../../constants/aiConstans';
import TooltipDefault from '../../ui/tooltips/TooltipDefault';
import AIArticleTooltip from '../common/AIArticleTooltip';

type Props = {
	data: IReport;
};

export default function AINotificationExpandedItem({ data }: Props) {
	const [index, setIndex] = useState<number>(0);
	const { detail, articles } = data;

	const { detailDisplayList, allMatchArticles } = useMemo(() => {
		const displayList = extractReportDetailDisplayList(detail);
		const allMatchArticleOrderSet = new Set(
			displayList.flatMap(({ value }) => value.match(AI_ARTICLE_TITLE_REGEX)).filter(match => match) as string[],
		);

		return {
			detailDisplayList: displayList,
			allMatchArticles: Array.from(allMatchArticleOrderSet)
				.map(text => articles.find(article => article.title.includes(text)))
				.filter(article => article) as IStoreArticle[],
		};
	}, [detail]);

	const updateIndex = useCallback(
		(direction: number) => {
			const newIndex = (index + direction + detailDisplayList.length) % detailDisplayList.length;
			setIndex(newIndex);
		},
		[index, detailDisplayList],
	);

	return (
		<div className='flex flex-col gap-2'>
			<div>
				<p className='text-blue-600 text-sm'>분석 결과</p>
				<div className='flex flex-col text-sm text-neutral-600'>
					<div className='flex justify-between items-center'>
						<p className='font-bold'>{detailDisplayList[index].label}</p>
						<div className='flex items-center'>
							<ButtonText className='min-w-min' onClick={() => updateIndex(-1)}>
								<ChevronRightIcon className='transform scale-x-[-1]' />
							</ButtonText>
							<ButtonText className='min-w-min' onClick={() => updateIndex(1)}>
								<ChevronRightIcon />
							</ButtonText>
						</div>
					</div>
					<div className='px-2'>
						<AIReportHighlightArticleWord content={detailDisplayList[index].value} articles={articles} />
					</div>
				</div>
			</div>
			<Divider />
			<div>
				{allMatchArticles.length && (
					<div className='text-sm'>
						<p className='text-blue-600'>확인 필요 조항 {allMatchArticles.length}건</p>
						{allMatchArticles.map(article => (
							<TooltipDefault key={article.id} title={<AIArticleTooltip article={article} />}>
								<div className='flex gap-2 px-2 items-center hover:bg-blue-100'>
									<div className='w-2 h-2 bg-blue-300 rounded-full' />
									<p className='text-neutral-600'>{article.title}</p>
								</div>
							</TooltipDefault>
						))}
					</div>
				)}
			</div>
		</div>
	);
}
