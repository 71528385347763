import { memo } from 'react';
import ContainerCommon from '../../../../components/admin/officialLetter/setting/ContainerCommon';
import ContainerWorkflow from '../../../../components/admin/officialLetter/setting/ContainerWorkflow';
import Container from '../../../../components/ui/containers/Container';

function DetailPage() {
	return (
		<Container className='flex flex-col gap-4' size='medium'>
			<ContainerCommon />
			<ContainerWorkflow />
		</Container>
	);
}

export default memo(DetailPage);
