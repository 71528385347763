import AIArticleSearchForm from './AIArticleSearchForm';
import AIArticleSearchResult from './AIArticleSearchResult';

export default function AIArticleSearchView() {
	return (
		<div className='flex flex-col gap-5'>
			<div className='min-h-screen flex flex-col gap-5'>
				<AIArticleSearchResult />
			</div>
			<div className='sticky bottom-0 bg-white'>
				<AIArticleSearchForm />
			</div>
		</div>
	);
}
