import AIArticleSearchView from '../../../../components/ai/article/search/AIArticleSearchView';
import { useAIArticle } from '../../../../context/AIArticleContext';

export default function AIArticleSearchResultPage() {
	const { slideState } = useAIArticle();

	return (
		<div className='flex flex-col gap-5 p-8'>
			{!slideState.display && (
				<div className='flex items-start gap-3'>
					<p className='text-3xl font-bold'>법틀AI 조항검색</p>
					<span className='text-gray-400 font-semibold'>BETA</span>
				</div>
			)}
			<AIArticleSearchView />
		</div>
	);
}
