import { useQuery } from '@tanstack/react-query';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Tab } from '@mui/material';
import { queryKey } from '../../../../constants/queryKey';
import { getContractDiffDoc } from '../../../../api/contract';
import { useAIReport } from '../../../../context/AIReportContext';
import CardBox from '../../../ui/containers/CardBox';
import TabsCommon from '../../../ui/tabs/TabsCommon';

export default function AIReportLeftVersionDiff() {
	const [tab, setTab] = useState<number>(0);
	const { data: reportData } = useAIReport();
	const { store_info: storeInfo } = reportData;
	const { contract } = storeInfo;
	const { title, app_id: appId } = contract;

	const { data: versionData } = useQuery([queryKey.contractDiffDocData, appId], () => getContractDiffDoc(appId), {
		staleTime: 300000,
	});
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (ref.current && versionData) {
			ref.current.innerHTML = versionData.diff_doc_list[tab].diff_text;
		}
	}, [ref, versionData, tab]);

	if (!versionData) {
		return null;
	}

	const { diff_doc_list: diffDocList } = versionData;

	return (
		<div className='flex flex-col gap-3 items-center py-2'>
			<TabsCommon
				classes={{ indicator: 'hidden' }}
				value={tab}
				onChange={(_: SyntheticEvent, v: number) => {
					setTab(v);
				}}
			>
				{diffDocList.map((item, index) => (
					<Tab
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						className={`min-w-0 min-h-0 text-gray-950 rounded py-1 mx-1 ${
							tab === index ? 'text-white bg-blue-600' : 'bg-neutral-300'
						}`}
						id={`report-version-tab-${index + 1}`}
						label={`${diffDocList.length - 1 > index ? `Ver.${index + 1}` : 'Latest'}`}
					/>
				))}
			</TabsCommon>
			<p className='text-2xl pt-8 pb-4'>{title}</p>
			<div className='pr-2 w-full'>
				<CardBox className='p-5'>
					<div ref={ref} />
				</CardBox>
			</div>
		</div>
	);
}
