import {
	GetContractLinkRequestParams,
	SealSignAccessPermission,
	addOriginCopySealParam,
	deleteOriginCopySealParam,
	pdfData,
	saveSealSignParam,
	DiffDocData,
} from '../model/api/contract';
import { SignList } from '../model/list';
import { axiosPost, sendGet, sendPost, sendUploadPost } from './common';

export function getContractLinkParam(params: GetContractLinkRequestParams) {
	return sendPost<string>('/api/get_contract_detail_link_param/', params);
}

export async function getContractSealPdf(contractId: string): Promise<pdfData> {
	/**
	 * 날인할 PDF 불러오는 함수
	 */
	const response = await axiosPost<Uint8Array>(
		'/contract/get-contract-seal-pdf/',
		{ contract_id: contractId },
		{
			responseType: 'arraybuffer',
		},
	);

	const disposition = response.headers['content-disposition'];

	const fileNameEncoded = disposition
		?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)?.[1]
		.replace(/['"]/g, '')
		.replace('UTF-8', '');

	return {
		file: new Uint8Array(response.data),
		fileName: decodeURIComponent(fileNameEncoded),
	};
}

export async function getContractSealList(contractId: string | number): Promise<SignList[]> {
	/**
	 * 인장 리스트 불러오는 함수
	 *
	 * status
	 * IS_USED : 이미 사용
	 * APPROVED : 승인 됨
	 * DENIED : 반려
	 * PENDING : 승인 대기
	 */

	const res = await sendGet<SignList[]>(`/contract/get-user-seal-list/?contract_id=${contractId}`);

	return res.data;
}

export async function checkSealSignAccess(contractId: string): Promise<SealSignAccessPermission> {
	/**
	 *  화면에 접근할 수 있는지 권한 체크
	 */

	const res = await sendGet<SealSignAccessPermission>(`/contract/check-seal-sign-access/?contract_id=${contractId}`);

	return res.data;
}

export async function saveSealSign(params: saveSealSignParam) {
	/**
	 * 날인된 정보 저장하는 함수
	 */

	return sendUploadPost<string>('/contract/save-seal-signature/', params);
}

export async function addOriginCopySeal(params: addOriginCopySealParam) {
	/**
	 * 원본대조필 추가
	 */

	return sendPost<string>('/contract/add-origin-copy-seal/', params);
}

export async function deleteOriginCopySeal(params: deleteOriginCopySealParam) {
	/**
	 * 원본대조필 삭제
	 */

	return sendPost<string>('/contract/delete-origin-copy-seal/', {
		contract_id: params.contractId,
		seal_stamp_id: params.sealId,
	});
}

export async function getContractDiffDoc(id: number) {
	const params = { contract_id: id };
	const res = await sendPost<DiffDocData>('/contract/get_diff_datas/', params);
	return res.data;
}
