import { useNavigate } from 'react-router-dom';
import { useAIArticleSide } from '../../../../context/AIArticleSideContext';
import ButtonText from '../../../ui/buttons/ButtonText';
import BookmarkIcon from '../../../ui/icons/BookmarkIcon';
import AIArticleSideDataCount from './AIArticleSideDataCount';
import AIArticleSideExpandItems from './AIArticleSideExpandItems';
import AIArticleSideRecentSaveItems from './AIArticleSideRecentSaveItems';
import AIArticleSideRecentSeenItems from './AIArticleSideRecentSeenItems';

export default function AIArticleSideView() {
	const navigate = useNavigate();
	const { expand, setExpand } = useAIArticleSide();

	return (
		<div className='grid gap-14 p-6'>
			{!expand ? (
				<>
					<div className='flex justify-end'>
						<ButtonText onClick={() => navigate('/buptle_ai/article/favorite')}>
							<div className='flex items-center gap-1'>
								<BookmarkIcon className='text-neutral-600' />
								<p className='font-bold'>MY 조항</p>
							</div>
						</ButtonText>
					</div>
					<AIArticleSideDataCount />
					<AIArticleSideRecentSeenItems />
					<AIArticleSideRecentSaveItems />
				</>
			) : (
				<AIArticleSideExpandItems />
			)}
		</div>
	);
}
