import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSealStampList } from '../../../api/sealStamp';
import { queryKey } from '../../../constants/queryKey';
import { useAlert } from '../../../hooks/useAlert';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { SealStamp, SealStampTableSearchParams } from '../../../model/sealStamp';
import { IHeadCell } from '../../../model/table';
import ButtonText from '../../ui/buttons/ButtonText';
import ScrollBox from '../../ui/containers/ScrollBox';
import PopupInternalState from '../../ui/popups/PopupInternalState';
import TableSkeleton from '../../ui/tables/TableSkeleton';
import TableSealStamp from './TableSealStamp';

export type Props = {
	id: string;
	listType: string; // radio | checkbox
	disabled?: boolean; // 선택 disable 여부
	openTrigger: JSX.Element; // 버튼 Element
	searchStatus?: string; // 서버에서 Filtering 할 값, 프론트에서 Filtering해도 된다.
	contractId?: string;
	onClick: (sealIds: SealStamp[]) => Promise<void>;
};
export default function PopupSealStamp({
	id,
	listType,
	openTrigger,
	searchStatus,
	contractId,
	disabled = false,
	onClick,
}: Props) {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [snackbar] = useAlert();
	const [closeSwitch, setCloseSwitch] = useState(false);
	const handleTogglePopup = () => setCloseSwitch(prev => !prev);

	const [searchParams, setSearchParams] = useState<SealStampTableSearchParams>({
		currentPage: 0,
		searchType: 'title',
		searchText: '',
		rowsPerPage: 10,
		searchStatus,
		contractId,
	});

	const { data: paginationData } = useQuery(
		[queryKey.sealStamp, queryKey.list, searchParams],
		() => getSealStampList(searchParams),
		{ enabled: isOpen },
	);

	const selectedFormik = useValidateForm({
		validationSchema: {
			[id]: { initialValue: [], type: 'array' },
		},
		onSubmit: values => {
			onClick(values[id]);
		},
	});

	const handleClick = () => {
		if (selectedFormik.values[id].length < 1) {
			snackbar(t('contract_guide_please_select_a_sign_seal'), 'error');
			return;
		}

		selectedFormik.handleSubmit();
		handleTogglePopup();
	};

	const headCells: IHeadCell<SealStamp>[] = [
		{
			id: 'checkboxSection',
			align: 'left',
			disablePadding: true,
			disableSort: false,
			label: '',
		},
		{
			id: 'img_url',
			align: 'left',
			disablePadding: true,
			disableSort: false,
			label: '',
		},
		{
			id: 'name',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('contract_label_stamp_names'),
		},
		{
			id: 'seal_type',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('seal_type'),
		},
		{
			id: 'approval_user_names',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('contract_label_approval_user_name'),
		},
		{
			id: 'view_code',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('seal_stamp_viewcode'),
		},
	];

	return (
		<PopupInternalState
			onClick={() => setIsOpen(prev => !prev)}
			openTrigger={openTrigger}
			closeTrigger={closeSwitch}
			size='large'
		>
			<section className='flex flex-col gap-5'>
				<div>
					<h2 className='text-2xl font-bold'>{t('search_seal_stamp')}</h2>
				</div>

				<ScrollBox className='max-h-[70vh] xl:max-h-[77vh]'>
					{paginationData ? (
						<TableSealStamp
							id={id}
							listType='checkbox'
							list={paginationData.data}
							headCells={headCells}
							totalListCount={paginationData.recordsTotal}
							maxSelected={listType === 'radio' ? 1 : 999}
							selectedFormik={selectedFormik}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
						/>
					) : (
						<TableSkeleton colSpan={headCells.length + 1} />
					)}
				</ScrollBox>

				<div className='flex justify-center gap-5'>
					<ButtonText text={t('MSG_ID_105')} variant='contained' onClick={handleTogglePopup} />
					<ButtonText text={t('MSG_ID_104')} variant='contained' color='primary' onClick={handleClick} />
				</div>
			</section>
		</PopupInternalState>
	);
}
