import { useCallback } from 'react';
import ContainerEditor from '../../../../components/admin/officialLetter/workflow/ContainerEditor';
import ContainerProcess from '../../../../components/admin/officialLetter/workflow/ContainerProcess';
import ButtonText from '../../../../components/ui/buttons/ButtonText';
import CardBox from '../../../../components/ui/containers/CardBox';
import Container from '../../../../components/ui/containers/Container';
import { useAlert } from '../../../../hooks/useAlert';
import { useProviderPage } from './ProviderPage';

export default function DetailPage() {
	const { officialLetter } = useProviderPage();
	const [snackbar] = useAlert();

	const handleSubmit = useCallback(() => {
		const errors = officialLetter.process.every(p => p.steps.every(step => step.options.isDisabled));

		if (!errors) {
			officialLetter.process.forEach(p =>
				p.steps.forEach(
					step =>
						!step.options.isDisabled &&
						snackbar(`[${step.title}] 단계 오류 메시지를 확인해주세요.`, 'error'),
				),
			);
			return;
		}
		// console.log(officialLetter);
		snackbar(`${officialLetter.title} 워크플로우 저장 완료했습니다.`, 'success');
	}, [officialLetter]);
	return (
		<Container size='large'>
			<CardBox className='flex flex-col gap-4 p-6'>
				<header className='pb-4 border-b border-neutral-300'>
					<h2 className='text-xl'>공문 워크플로우 설정</h2>
				</header>

				<section className='grid gap-4'>
					<article className='p-6 border border-neutral-400 rounded-lg'>
						<ContainerEditor />
					</article>
					<article className='p-6 border border-neutral-400 rounded-lg'>
						<ContainerProcess />
					</article>
				</section>
				<footer className='flex justify-center gap-4'>
					<ButtonText text='취소' variant='outlined' />
					<ButtonText text='저장' variant='contained' color='primary' onClick={handleSubmit} />
				</footer>
			</CardBox>
		</Container>
	);
}
