import { Chip } from '@mui/material';
import ClearIcon from '../icons/ClearIcon';

type Props = {
	text: string;
	disabled?: boolean;
	link?: string;
	onClick?: () => void;
	onDelete?: () => void;
};

export default function ItemChip({ text, link = '', disabled, onClick = undefined, onDelete = () => {} }: Props) {
	return (
		<Chip
			label={text}
			// disabled={disabled}
			classes={{
				root: 'px-3 bg-neutral-100',
				icon: 'ml-0',
				label: 'pr-0 pl-3',
				disabled: 'opacity-100',
			}}
			onClick={onClick} // Chip 컴포넌트에 onClick 이벤트 핸들러 추가
			deleteIcon={<ClearIcon className='m-0 -mt-0.5' />}
			onDelete={!disabled ? onDelete : undefined}
			onMouseDown={e => e.stopPropagation()}
			onFocus={e => e.stopPropagation()}
		/>
	);
}
