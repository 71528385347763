import { TableCell as Cell, TableCellProps } from '@mui/material';
import { memo } from 'react';

interface ITableCellPropsOverrides extends TableCellProps {}

function TableListCell({ ...props }: ITableCellPropsOverrides) {
	const { align = 'left', children } = props;

	return (
		<Cell align={align} {...props}>
			{children}
		</Cell>
	);
}

export default memo(TableListCell);
