import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonLoading from '../../ui/buttons/ButtonLoading';
import { IPopupCommonExternalProps } from '../../ui/popups/PopupCommonExternal';
import PopupSuccess from '../../ui/popups/PopupSuccess';

type Props = IPopupCommonExternalProps & {
	loading?: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
};

export default function PopupSignSuccess({ loading, open, setOpen, onConfirm, ...props }: Props) {
	const { t } = useTranslation();
	const handleClose = () => setOpen(false);
	const handleConfirm = () => {
		if (typeof onConfirm === 'function') {
			onConfirm();
		}
	};

	return (
		<>
			<ButtonLoading
				loading={loading}
				variant='contained'
				text='서명하기'
				color='primary'
				onClick={() => setOpen(true)}
			/>
			<PopupSuccess
				size='xsmall'
				open={open}
				loading={loading}
				onConfirm={handleConfirm}
				onCancel={handleClose}
				onClose={handleClose}
				{...props}
			/>
		</>
	);
}
