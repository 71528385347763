import { useQuery } from '@tanstack/react-query';
import { OFFICIALLETTER_TYPE } from '../../../../constants/officialLetter';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import { ITemplate } from '../../../../model/officialLetter';
import { IHeadCell } from '../../../../model/table';
import { getFakeOfficialLetterTemplateList } from '../../../../service/fakeService';
import TableSkeleton from '../../../ui/tables/TableSkeleton';
import TableTemplateList from './TableTemplateList';

export default function ListTemplate() {
	const { data, isLoading } = useQuery(['testTemplateList'], () =>
		getFakeOfficialLetterTemplateList(100).then(res =>
			res.map(item => ({ ...item, type: OFFICIALLETTER_TYPE[Number(item.type)] })),
		),
	);
	const formik = useValidateForm({
		validationSchema: {
			formikId: {
				initialValue: [],
				type: 'array',
			},
		},
		onSubmit: values => {
			alert(JSON.stringify(values, null, 2));
		},
	});

	const selectedFormik = useValidateForm({
		validationSchema: {
			formikId: {
				initialValue: [],
				type: 'array',
			},
		},
		onSubmit: values => {
			alert(JSON.stringify(values, null, 2));
		},
	});
	const headCellsContract: IHeadCell<ITemplate>[] = [
		{
			id: 'title',
			width: 'w-1/6',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: '서식명',
		},
		{
			id: 'type',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: '문서 종류',
		},

		{
			id: 'file',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: '서식 파일',
		},
		{
			id: 'formatting',
			align: 'center',
			disablePadding: true,
			disableSort: false,
			label: '생성건수',
		},
		{
			id: 'inProgress',
			align: 'center',
			disablePadding: true,
			disableSort: false,
			label: '공문 진행중',
		},
		{
			id: 'completed',
			align: 'center',
			disablePadding: true,
			disableSort: false,
			label: '공문 완료',
		},
	];
	return (
		<>
			{isLoading && !data && <TableSkeleton colSpan={headCellsContract.length + 1} />}
			{!isLoading && data && (
				<TableTemplateList
					id='formikId'
					list={data}
					headCells={headCellsContract}
					formik={formik}
					selectedFormik={selectedFormik}
				/>
			)}
		</>
	);
}
