import { Change, diffChars } from 'diff';
import { useCallback } from 'react';

type Props = {
	originText: string;
	changedText: string;
	type: 'ADDED' | 'DELETED';
	bold?: boolean;
};

export default function HighlightTextDiff({ originText, changedText, type, bold = false }: Props) {
	const diffResult = diffChars(originText, changedText);

	const getClassNames = useCallback(
		(part: Change) => {
			let classNames = 'whitespace-pre-wrap';
			if (bold) classNames += ' font-bold';

			if (type === 'DELETED' && part.removed) {
				classNames += ' p-0.5 bg-red-100';
			} else if (type === 'ADDED' && part.added) {
				classNames += ' p-0.5 bg-green-100';
			}

			return classNames;
		},
		[bold, type],
	);

	return (
		<>
			{diffResult
				.filter((part: Change) => (type === 'DELETED' ? !part.added : !part.removed))
				.map((part, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<span key={index} className={getClassNames(part)}>
						{part.value}
					</span>
				))}
		</>
	);
}
