import { Checkbox } from '@mui/material';
import { FormikValues } from 'formik';
import _ from 'lodash';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DOMAIN_URL } from '../../../constants/common';
import { useSealStampList } from '../../../context/SealStampContext';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { SealStamp } from '../../../model/sealStamp';
import { IHeadCell } from '../../../model/table';
import SearchIcon from '../../ui/icons/SearchIcon';
import InputText from '../../ui/inputs/InputText';
import TableList from '../../ui/tables/TableList';
import TableListCell from '../../ui/tables/TableListCell';
import TableListRow from '../../ui/tables/TableListRow';
import TableSearchBar from '../../ui/tables/TableSearchBar';

type Props = {
	id: string;
	listType?: 'radio';
	list: SealStamp[];
	// orderBy: keyof SealStamp;
	headCells: IHeadCell<SealStamp>[];
	totalListCount?: number;
	formik: FormikValues;
	maxSelected?: number;
	selectedFormik: FormikValues;
};

export default function TableSealStampList({
	id: formikId,
	listType = 'radio',
	list,
	// orderBy: ob = 'title',
	headCells,
	totalListCount = 0,
	formik,
	maxSelected = 0,
	selectedFormik,
}: Props) {
	const { t } = useTranslation();
	// const [order, setOrder] = useState<SealStamp>('asc');
	// const [orderBy, setOrderBy] = useState(ob);SealStamp
	const [selected, setSelected] = useState<readonly number[]>(
		formik.values[formikId].map((item: SealStamp) => item.seal_stamp_id),
	);
	// const [page, setPage] = useState(0);
	// const [rowsPerPage, setRowsPerPage] = useState(10);
	const { searchParams, setSearchParams } = useSealStampList();
	const { searchType, searchText, currentPage, rowsPerPage } = searchParams;

	const formikTableSearch = useValidateForm({
		validationSchema: {
			// search_select_seal_stamp_table: { initialValue: searchType, type: 'string' },
			search_input_seal_stamp_table: { initialValue: searchText, type: 'string' },
		},
		onSubmit: values => {
			setSearchParams(prev => ({
				...prev,
				// searchType: values.search_select_seal_stamp_table,
				searchText: values.search_input_seal_stamp_table,
			}));
		},
	});

	const searchOptions = [
		{
			value: 'title',
			name: t('cmmn_label_seal_name'),
		},
	];

	// const handleRequestSort = (e: MouseEvent<unknown>, property: keyof SealStamp) => {
	// 	const isAsc = orderBy === property && order === 'asc';
	// 	setOrder(isAsc ? 'desc' : 'asc');
	// 	setOrderBy(property);
	// };

	// const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
	// 	if (event.target.checked) {
	// 		const newSelected = list.map(n => n.id);
	// 		setSelected(newSelected);
	// 		selectedFormik.setFieldValue(formikId, list);
	// 		return;
	// 	}
	// 	setSelected([]);
	// 	selectedFormik.setFieldValue(formikId, []);
	// };

	const handleClick = (e: MouseEvent<unknown>, id: number) => {
		const selectedIndex = _.indexOf(selected, id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelected = _.concat(selected, id);

			if (maxSelected && maxSelected < newSelected.length) {
				newSelected = _.slice(newSelected, 1);
			}
		} else newSelected = _.concat(_.slice(selected, 0, selectedIndex), _.slice(selected, selectedIndex + 1));

		setSelected(newSelected);

		selectedFormik.setFieldValue(
			formikId,
			_.filter(list, obj => _.includes(newSelected, obj.seal_stamp_id)),
		);
	};

	const handleChangePage = (e: unknown, newPage: number) => {
		setSearchParams(prev => ({ ...prev, currentPage: newPage }));
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchParams(prev => ({ ...prev, rowsPerPage: parseInt(e.target.value, 10), currentPage: 0 }));
	};

	// // 검색하면 page, rowsPerPage 초기화
	// const handleBeforeDebounceChange = () => {
	// 	setRowsPerPage(10);
	// 	setPage(0);
	// };

	const isSelected = (id: number) => selected.indexOf(id) !== -1;

	// // rows 양이 적을 경우 크기 조절
	// const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

	return (
		<section className='flex flex-col gap-3'>
			<TableSearchBar>
				<div className='flex items-center gap-2 w-full'>
					<div className='flex-1 rounded-sm bg-white'>
						<form onSubmit={formikTableSearch.handleSubmit}>
							<InputText
								id='search_input_seal_stamp_table'
								icon={
									<SearchIcon
										classes={{ root: 'cursor-pointer' }}
										onClick={formikTableSearch.handleSubmit}
									/>
								}
								// labelText={`${
								// 	headCells.filter(cell => cell.id === formik.values.search_select_seal_stamp_table)[0]?.label ??
								// 	''
								// } ${t('search_action')}`}
								labelText={t('search_seal_stamp_title')}
								formik={formikTableSearch}
							/>
						</form>
					</div>
				</div>
			</TableSearchBar>

			<TableList
				headCells={headCells}
				// order='asc'
				// orderBy='title'
				// onHeaderClick={handleHeaderClick}
				count={totalListCount}
				rowsPerPage={rowsPerPage}
				page={currentPage}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			>
				{totalListCount > 0 ? (
					list.map((row, idx) => {
						const isItemSelected = isSelected(row.seal_stamp_id);
						const labelId = `checkbox_seal_stamp_popup_${row.seal_stamp_id}`;
						return (
							<TableListRow
								sx={{ cursor: 'pointer' }}
								onClick={event => handleClick(event, row.seal_stamp_id)}
								key={row.seal_stamp_id}
							>
								<TableListCell padding='checkbox'>
									<Checkbox
										id={labelId}
										color='primary'
										checked={isItemSelected}
										inputProps={{
											'aria-labelledby': labelId,
										}}
									/>
								</TableListCell>
								<TableListCell>
									<img
										src={DOMAIN_URL + row.img_url}
										alt='img_seal_stamp_popup'
										className='w-[40px]'
									/>
								</TableListCell>
								<TableListCell>
									<div>{row.name}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.seal_type}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.approval_user_names}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.view_code}</div>
								</TableListCell>
							</TableListRow>
						);
					})
				) : (
					<TableListRow>
						<TableListCell colSpan={headCells.length + 1} classes={{ root: 'text-center' }}>
							{t('label_no_search_results_exist')}
						</TableListCell>
					</TableListRow>
				)}
			</TableList>
		</section>
	);
}
