import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import { queryKey } from '../../../../constants/queryKey';
import { getUserArticleFavoriteItem } from '../../../../api/ai';
import AIArticleHistoryItemClickHandler from '../../common/AIArticleHistoryItemClickHandler';
import CardBox from '../../../ui/containers/CardBox';
import MenuIcon from '../../../ui/icons/MenuIcon';
import { useAIArticle } from '../../../../context/AIArticleContext';
import AccordionDefault from '../../../ui/accordions/AccordionDefault';
import AIArticleFavoriteListTab from './AIArticleFavoriteListTab';

export default function AIArticleFavoriteItems() {
	const [searchParams] = useSearchParams();
	const { slideState } = useAIArticle();

	const parentId = useMemo(() => Number(searchParams.get('id')) ?? 0, [searchParams]);

	const { data } = useQuery([queryKey.aiArticleFavoriteItem, parentId], () => getUserArticleFavoriteItem(parentId), {
		staleTime: 300000,
	});

	return (
		<div className='flex flex-col p-8 gap-5'>
			{slideState.display && (
				<AccordionDefault expanded={false} summaryOpen={<MenuIcon />} expandIcon={null}>
					<AIArticleFavoriteListTab />
				</AccordionDefault>
			)}
			{data?.map((item, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<CardBox key={index} className='p-4'>
					<AIArticleHistoryItemClickHandler item={item} />
				</CardBox>
			))}
		</div>
	);
}
