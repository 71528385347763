import { memo } from 'react';
import ContainerDetail from '../../../../components/admin/officialLetter/template/ContainerDetail';
import Container from '../../../../components/ui/containers/Container';

function DetailPage() {
	return (
		<Container size='medium'>
			<ContainerDetail />
		</Container>
	);
}

export default memo(DetailPage);
