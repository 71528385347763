import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Chip } from '@mui/material';
import _ from 'lodash';
import dayjs from 'dayjs';
import InputText from '../../../ui/inputs/InputText';
import ButtonText from '../../../ui/buttons/ButtonText';
import SearchIcon from '../../../ui/icons/SearchIcon';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import ChevronRightIcon from '../../../ui/icons/ChevronRight';
import CardBox from '../../../ui/containers/CardBox';
import { AI_CLAUSE_CATEGORY_MAP } from '../../../../constants/aiConstans';
import { ClauseCategory } from '../../../../model/api/ai';

export default function AIArticleSearchForm() {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const filterAnchorRef = useRef<HTMLDivElement | null>(null);
	const [openFilter, setOpenFilter] = useState<boolean>(false);

	const { categoryKeys, startTimes } = useMemo(() => {
		const today = dayjs().startOf('day');

		return {
			categoryKeys: _.keys(AI_CLAUSE_CATEGORY_MAP) as ClauseCategory[],
			startTimes: [
				['최근 1개월', today.subtract(1, 'month').unix()],
				['최근 3개월', today.subtract(3, 'month').unix()],
				['최근 6개월', today.subtract(6, 'month').unix()],
				['최근 1년', today.subtract(1, 'year').unix()],
			],
		};
	}, []);

	const [selectedCategories, setSelectedCategories] = useState<ClauseCategory[]>(
		(searchParams.get('categories')?.split(',') ?? []) as ClauseCategory[],
	);

	const [selectedStartTime, setSelectedStartTime] = useState<(string | number)[] | null>(
		startTimes.find(([_label, value]) => value.toString() === searchParams.get('start_time')) ?? null,
	);
	const searchFormik = useValidateForm({
		validationSchema: {
			query: { initialValue: searchParams.get('query') || '', type: 'string', required: ' ' },
		},
		onSubmit: values => {
			const params = new URLSearchParams({
				query: values.query,
				...(selectedCategories.length ? { categories: selectedCategories.join(',') } : {}),
				...(selectedStartTime ? { start_time: selectedStartTime[1].toString() } : {}),
			});

			navigate(`?${params.toString()}`);
		},
	});

	const handleToggleCategory = useCallback((key: ClauseCategory) => {
		setSelectedCategories(prev => (prev.includes(key) ? prev.filter(k => k !== key) : [...prev, key]));
	}, []);

	const handleDeleteChip = useCallback((key: ClauseCategory) => {
		setSelectedCategories(prev => prev.filter(k => k !== key));
	}, []);

	return (
		<form className='relative p-3' onSubmit={searchFormik.handleSubmit}>
			{openFilter && (
				<CardBox className='flex flex-col gap-3 p-5'>
					<div className='flex items-center justify-start'>
						<p className='shrink-0 w-40'>조항 태그</p>
						<div className='grow text-left'>
							{categoryKeys.map(key => (
								<ButtonText
									key={key}
									onClick={() => handleToggleCategory(key)}
									variant='text'
									className={`${
										selectedCategories.includes(key) ? 'text-blue-600' : 'text-neutral-600'
									}`}
								>
									{AI_CLAUSE_CATEGORY_MAP[key]}
								</ButtonText>
							))}
						</div>
					</div>
					<div className='flex items-center justify-start'>
						<p className='shrink-0 w-40'>문서 일자</p>
						<div className='grow text-left'>
							<ButtonText
								className={`${selectedStartTime ? 'text-neutral-600' : 'text-blue-600'}`}
								variant='text'
								onClick={() => setSelectedStartTime(null)}
							>
								전체
							</ButtonText>
							{startTimes.map(([label, value]) => (
								<ButtonText
									key={label}
									className={`${
										selectedStartTime && selectedStartTime[0] === label
											? 'text-blue-600'
											: 'text-neutral-600'
									}`}
									onClick={() => setSelectedStartTime([label, value])}
									variant='text'
								>
									{label}
								</ButtonText>
							))}
						</div>
					</div>
				</CardBox>
			)}
			<div ref={filterAnchorRef} className='flex justify-between p-2 items-center'>
				<div className='flex flex-wrap'>
					{selectedCategories.map(key => (
						<Chip key={key} label={AI_CLAUSE_CATEGORY_MAP[key]} onDelete={() => handleDeleteChip(key)} />
					))}
					{selectedStartTime && (
						<Chip label={selectedStartTime[0]} onDelete={() => setSelectedStartTime(null)} />
					)}
				</div>
				<ButtonText onClick={() => setOpenFilter(prev => !prev)}>
					<p className='px-2'>필터</p>
					<ChevronRightIcon className={`${openFilter ? 'rotate-90' : '-rotate-90'}`} />
				</ButtonText>
			</div>
			<InputText
				id='query'
				icon={
					<ButtonText className='h-full' onClick={() => searchFormik.handleSubmit()}>
						<SearchIcon />
					</ButtonText>
				}
				formik={searchFormik}
			/>
		</form>
	);
}
