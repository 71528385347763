import React, { MouseEvent } from 'react';
import Modal, { ModalOwnProps } from '@mui/material/Modal';

export type ModalSize = 'xsmall' | 'small' | 'medium' | 'large';
export type ModalOwnPropsOverrides = ModalOwnProps & {
	externalClickClose?: boolean;
	size?: ModalSize;
	contentClass?: string;
};

const sizeMap = {
	xsmall: 'w-[90vw] xl:w-4/12 2xl:w-4/12',
	small: 'w-[90vw] xl:w-4/12 2xl:w-6/12',
	medium: 'w-[90vw] xl:w-8/12',
	large: 'w-[90vw] xl:w-10/12',
};

export default function PopupSideDrawer({
	children,
	open,
	size = 'xsmall',
	onClose,
	externalClickClose = true,
	classes = { root: 'outline-0', backdrop: 'bg-black/30' },
	contentClass,
	...props
}: ModalOwnPropsOverrides) {
	const handleExternalClick = (e: MouseEvent<unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
		if (externalClickClose && onClose) onClose(e, reason);
	};

	return (
		<Modal open={open} onClose={handleExternalClick} classes={classes} {...props}>
			<section
				className={`${sizeMap[size]} ${contentClass} absolute top-5 h-[95vh] rounded-2xl shadow-md bg-white outline-0`}
			>
				{children as React.ReactElement}
			</section>
		</Modal>
	);
}
