import { memo } from 'react';
import ButtonText from '../../../ui/buttons/ButtonText';
import CardBox from '../../../ui/containers/CardBox';
import ListField from './ListField';

function ContainerList() {
	return (
		<CardBox className='flex flex-col gap-4 p-6'>
			<header className='pb-4 border-b border-neutral-300'>
				<div>
					<h2 className='text-xl'>공문 수집항목 관리</h2>
					<p className='mt-2 text-sm text-gray-600'>공문 기안시 수집할 항목을 관리할 수 있습니다.</p>
				</div>
			</header>

			<section>
				<ListField />
			</section>

			<footer className='flex justify-center'>
				<ButtonText text='저장' variant='contained' color='primary' />
			</footer>
		</CardBox>
	);
}

export default memo(ContainerList);
