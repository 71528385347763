import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { login } from '../../api/login';
import DupLoginPopup from '../../components/login/DupLoginPopup';
import LoginForm from '../../components/login/LoginForm';
import PopupWarning from '../../components/ui/popups/PopupWarning';
import { BizResponse } from '../../model/api/common';
import { executePostLogin } from '../../service/executePostLogin';

export default function LoginPage() {
	const [dupLogin, setDupLogin] = useState(false);
	const [open, setOpen] = useState(false);
	const [msg, setMsg] = useState('');
	const navigate = useNavigate();
	const location = useLocation();

	const handleLogin = async (params: FormikValues) => {
		const queryParams = new URLSearchParams(location.search);
		try {
			const res = await login({
				...params,
				options: {
					next: queryParams.get('next') || '',
				},
			});
			const { next, dup_login_flag: dupLoginFlag, subsidiary_search_id: subsidiarySearchId } = res.data;

			if (dupLoginFlag) {
				setDupLogin(true);
			} else {
				executePostLogin({ navigate, next, subsidiary_search_id: subsidiarySearchId });
			}
		} catch (error) {
			setMsg((error as AxiosError<BizResponse<unknown>>).response?.data.msg as string);
			setOpen(prev => !prev);
		}
	};

	return (
		<>
			<div className='h-screen flex justify-center items-center bg-slate-200'>
				<LoginForm handleLogin={handleLogin} />
				<PopupWarning
					size='xsmall'
					open={open}
					renderPage={msg}
					onClose={() => setOpen(prev => !prev)}
					onConfirm={() => setOpen(prev => !prev)}
				/>
			</div>
			<DupLoginPopup open={dupLogin} setOpen={setDupLogin} />
		</>
	);
}
