import { useNavigate } from 'react-router-dom';
import { useAIArticle } from '../../../../context/AIArticleContext';
import ButtonText from '../../../ui/buttons/ButtonText';
import AIArticleFavoriteItems from './AIArticleFavoriteItems';
import AIArticleFavoriteListTab from './AIArticleFavoriteListTab';

export default function AIArticleFavoriteView() {
	const navigate = useNavigate();
	const { slideState } = useAIArticle();

	return slideState.display ? (
		<AIArticleFavoriteItems />
	) : (
		<div className='flex'>
			<div className='flex-shrink-0 w-[20rem] border-r border-neutral-600 overflow-y-auto scrollbar sticky top-0 p-8'>
				<div className='flex flex-col gap-5'>
					<ButtonText className='justify-start' onClick={() => navigate('/buptle_ai/article/main')}>
						{'< 뒤로'}
					</ButtonText>
					<p className='font-semibold text-xl'>MY 조항</p>
					<AIArticleFavoriteListTab />
				</div>
			</div>
			<div className='flex-grow'>
				<AIArticleFavoriteItems />
			</div>
		</div>
	);
}
