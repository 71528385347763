import { Tooltip, TooltipProps } from '@mui/material';
import React, { useState } from 'react';

interface Props extends TooltipProps {
	title: React.ReactNode;
	children: React.ReactElement;
}

export default function TooltipDefault({ title, children, ...props }: Props) {
	const [open, setOpen] = useState(false);

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	const handleTooltipClose = () => {
		setOpen(false);
	};

	return (
		<Tooltip
			title={title}
			arrow
			placement='top'
			enterDelay={0}
			leaveDelay={0}
			PopperProps={{
				modifiers: [
					{
						name: 'arrow',
						enabled: true,
						options: {
							element: '[data-popper-arrow]',
							padding: 5,
						},
					},
				],
			}}
			componentsProps={{
				tooltip: {
					sx: {
						backgroundColor: 'white',
						color: 'black',
						boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
						fontSize: '1rem',
						maxWidth: '800px',
						border: '1px solid #DDD',
						margin: '4rem',
					},
				},
				arrow: {
					sx: {
						color: 'white',
						'&::before': {
							border: '1px solid #DDD',
						},
					},
				},
			}}
			open={open}
			onOpen={handleTooltipOpen}
			onClose={handleTooltipClose}
			{...props}
		>
			<span onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
				{children}
			</span>
		</Tooltip>
	);
}
