import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getNotificationCount } from '../../../../../api/notification';
import { queryKey } from '../../../../../constants/queryKey';
import { useUser } from '../../../../../context/UserContext';
import { useTheme } from '../../../../../hooks/useTheme';
import ButtonText from '../../../../ui/buttons/ButtonText';
import CardBox from '../../../../ui/containers/CardBox';

type Props = {
	onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
};
export default function AlarmButtonDashboard({ onClick }: Props) {
	const { t } = useTranslation();
	const { loginUser } = useUser();
	const theme = useTheme();
	const { data } = useQuery(queryKey.notificationCount, getNotificationCount, { staleTime: Infinity });

	return (
		<CardBox className='flex flex-col col-span-2 gap-2 w-full h-full p-6'>
			<h3 className='text-xl font-bold text-left'>
				{t('cmmn_guide_confirmation_is_required_from_user').replace('{유저명}', loginUser.name)}
			</h3>
			<div className='flex items-center justify-between max-w-full'>
				{data && (
					<ButtonText className='p-0 rounded-2xl' onClick={onClick} disabled={!data?.notification_count}>
						<p
							className='text-4xl text-right font-bold truncate'
							style={{ color: theme.palette.primary.main }}
						>
							{data.notification_count}
						</p>
					</ButtonText>
				)}
			</div>
		</CardBox>
	);
}
