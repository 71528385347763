import { TablePagination, TablePaginationOwnProps } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

function TableFooter({ ...props }: TablePaginationOwnProps) {
	const { t } = useTranslation();

	return (
		<TablePagination
			labelDisplayedRows={({ from, to, count, page: p }) =>
				`${t('label_now')} ${from} ~ ${to} / ${t('MSG_ID_1255')} ${count} (${p + 1} ${t('label_page')})`
			}
			component='div'
			{...props}
		/>
	);
}

export default memo(TableFooter);
