import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAIArticle } from '../../../context/AIArticleContext';
import { IArticleHistoryItem } from '../../../model/api/ai';
import AIArticleHistoryItem from './AIArticleHistoryItem';

type Props = {
	item: IArticleHistoryItem;
	nodeId?: number;
};

export default function AIArticleHistoryItemClickHandler({ item, nodeId }: Props) {
	const location = useLocation();
	const { slideState, setSlideState, setDetailState, setFavoriteState } = useAIArticle();

	const currentHistory = useMemo(() => item.history_map?.article_histories[item.index], [item]);
	const isReportPath = useMemo(() => new Set(location.pathname.split('/')).has('report'), [location]);

	const handleSlide = useCallback(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});

		setSlideState({
			...item,
			display: true,
		});
	}, [item]);

	const handleItemClick = useCallback(() => {
		if (slideState.display) {
			handleSlide();
		} else {
			const article = currentHistory?.article;
			if (article) {
				setDetailState(article.id, item);
				window.open(`/buptle_ai/article/detail/${article.id}`, '_blank');
			}
		}
	}, [slideState, handleSlide]);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>) => {
			if (e.key === 'Enter' || e.key === ' ') {
				handleItemClick();
			}
		},
		[handleItemClick],
	);

	const handleSave = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			setFavoriteState({
				...item,
				anchorEl: e.currentTarget,
			});
		},
		[item],
	);

	return (
		<div role='button' tabIndex={0} onClick={handleItemClick} onKeyDown={handleKeyDown}>
			<AIArticleHistoryItem
				item={item}
				nodeId={nodeId}
				handleSave={handleSave}
				handleSlide={!slideState.display && !isReportPath ? handleSlide : undefined}
			/>
		</div>
	);
}
