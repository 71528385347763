import { Editor } from '@tinymce/tinymce-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
	onContentChange: (content: string) => void;
	onDrop?: (files: File[] | []) => void;
}

function EditorTinyMCE({ onContentChange, onDrop }: IProps) {
	const { t } = useTranslation();

	return (
		<Editor
			apiKey=''
			initialValue=''
			init={{
				height: 350,
				menubar: false,
				toolbar:
					'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
				branding: false,
				statusbar: false,
				block_unsupported_drop: false,
				placeholder: t('cmmn_guide_please_enter_your_details'),
			}}
			onEditorChange={(content, editor) => {
				onContentChange(content);
			}}
			onDrop={(e, editor) => {
				e.preventDefault();
				const files = e?.dataTransfer?.files || [];

				if (onDrop) onDrop(files as File[] | []);
			}}
			onDragOver={e => e.preventDefault()}
		/>
	);
}

export default memo(EditorTinyMCE);
