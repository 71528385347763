import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCompanyInfoSummary } from '../../api/login';
import { queryKey } from '../../constants/queryKey';
import { useAlert } from '../../hooks/useAlert';
import SysConfigStore from '../../store/common/SysConfigStore';
import { getDomainURL } from '../../util/commonUtils';
import ButtonText from '../ui/buttons/ButtonText';
import PopupSupportEmail from './PopupSupportEmail';

export default function Footer() {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { data: company } = useQuery(queryKey.companyInfoSummary, getCompanyInfoSummary, { staleTime: 60000 });
	const handleToggle = useCallback(() => setOpen(prev => !prev), []);
	const { isComponentEnabled, isModuleEnabled, labelText } = SysConfigStore();
	const [snackbar] = useAlert();

	const handleClick = useCallback(() => {
		if (isModuleEnabled('DAEKYO_FOOTER'))
			return window.open(
				`${getDomainURL()}/static/tenant/downloadformat/계약관리시스템 신규버전 매뉴얼_최종_21.01.22.pdf`,
			);
		if (isComponentEnabled('CMPNT188_SYSTEM_MANUAL_DOWNLOAD_B'))
			return window.open(`${getDomainURL()}/static/tenant/downloadformat/시연용_메뉴얼_20240327.pdf`);
		return snackbar(t('cmmn_guide_manual_is_being_prepared'), 'default');
	}, []);

	return (
		<footer className='flex flex-col justify-between px-8 py-2 bg-gray-900 lg:flex-row'>
			{!isModuleEnabled('HIDE_MANUAL_DOWNLOAD_BTN') && (
				<div className='flex items-center justify-center'>
					<ButtonText
						className='text-s text-white'
						text={`${labelText('system_manual_name')} ${labelText('system_manual_download')}`}
						onClick={handleClick}
					/>
				</div>
			)}
			<div className='flex items-center justify-center ml-auto'>
				<PopupSupportEmail
					open={open}
					openTrigger={
						<ButtonText className='gap-2 text-s text-white' size='small' onClick={handleToggle}>
							<strong>{t('MSG_ID_404')}</strong>
							<span>support@buptle.com</span>
						</ButtonText>
					}
					confirm={
						<ButtonText variant='contained' size='small' color='primary'>
							{t('label_confirm')}
						</ButtonText>
					}
					cancel={
						<ButtonText variant='contained' size='small'>
							{t('label_cancellation')}
						</ButtonText>
					}
					onCancel={handleToggle}
					onConfirm={() => handleToggle()}
				/>

				<div className='flex items-center gap-3'>
					<span className='text-s text-white' />
					<span className='text-s text-white'>v2.9</span>
					<span className='text-s text-white'>
						©{company?.name} [{company?.serverId}]
					</span>
				</div>
			</div>
		</footer>
	);
}
