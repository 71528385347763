import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useState } from 'react';
import { AnyObject } from 'yup';
import { TOrder } from '../../../model/table';
import TableListCell from './TableListCell';
import TableListRow from './TableListRow';

interface IProps {
	headCells: AnyObject[];
	order?: TOrder;
	orderBy?: string;
	onClick?: (id: string, order: string) => void;
}

export default function TableListHeader({ headCells, order: or, orderBy: ob, onClick }: IProps) {
	const [order, setOrder] = useState<TOrder>(typeof or !== 'undefined' ? or : 'asc');
	const [orderBy, setOrderBy] = useState(ob);

	const handleClick = onClick
		? (id?: string, o?: TOrder) => {
				if (id && o) {
					setOrderBy(id);
					if (orderBy === id) {
						setOrder(o as TOrder);
						onClick(id, o);
					} else {
						setOrder('asc');
						onClick(id, 'asc');
					}
				}
		  }
		: undefined;

	return (
		<TableHead>
			<TableListRow>
				{headCells.map(headCell => (
					<TableListCell
						key={headCell?.id}
						align={headCell?.align || 'left'}
						padding={headCell?.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell?.id ? order : false}
						classes={{ head: `${headCell?.width || ''}` }}
					>
						{handleClick ? (
							<TableSortLabel
								active={orderBy === headCell?.id}
								direction={orderBy === headCell?.id ? order : 'asc'}
								onClick={() => handleClick(headCell?.id, order === 'asc' ? 'desc' : 'asc')}
							>
								{headCell?.label}
								{orderBy === headCell?.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							headCell?.label
						)}
					</TableListCell>
				))}
			</TableListRow>
		</TableHead>
	);
}
