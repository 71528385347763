import { Menu } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuData } from '../../model/header';
import ButtonText from '../ui/buttons/ButtonText';
import MenuItem2Depth from './MenuItem2Depth';

type Props = {
	itemData: MenuData;
	isOpen: boolean;
	onToggle: () => void;
};

export default function MenuItem1Depth({ itemData, isOpen, onToggle }: Props) {
	const { icon, title, link = '', classes = { root: '', button: '' }, component } = itemData;
	const { root = '', button = '' } = classes;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const handleOpen = (e: MouseEvent<HTMLButtonElement>, { link: href, isExternal, preHandle }: MenuData) => {
		if (preHandle) preHandle();
		setAnchorEl(e.currentTarget); // 메뉴를 연결할 요소 설정
		onToggle(); // 상위 컴포넌트에서 상태 변경
		if (!href) return;
		if (!isExternal) navigate(href);
		else window.location.href = href;
	};

	const handleClose = () => {
		setAnchorEl(null); // anchorEl 상태 초기화
		onToggle(); // 상위 컴포넌트에서 상태 변경
	};

	return (
		<div className={`${root}`}>
			{!component && (
				<>
					<ButtonText
						className={`${
							link && pathname.includes(link) ? 'relative !text-blue-700 font-bold' : ''
						} flex-col gap-5 min-w-0 min-h-0 h-full text-sm ${button}`}
						onClick={e => handleOpen(e, itemData)}
					>
						<div className='flex items-center gap-[20px]'>
							{!icon && pathname.includes(link) && link && (
								<span
									className={`${
										pathname.includes(link) && link ? 'bg-blue-700' : 'bg-white'
									} absolute left-0 bottom-1/2 translate-y-1/2 w-1 h-1 rounded-full`}
								/>
							)}
							{title ?? title}
							{icon ?? icon}
						</div>
						{!icon && pathname.includes(link) && link && (
							<span
								className={`${
									pathname.includes(link) && link ? 'border-blue-700' : 'border-transparent'
								} absolute left-0 bottom-0 w-full border-b-2`}
							/>
						)}
					</ButtonText>
					{isOpen && itemData.children && (
						<Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
							{itemData.children
								.filter(({ display }: MenuData) => display)
								.map((childItem: MenuData) => (
									<MenuItem2Depth key={childItem.title} childItem={childItem} onClose={handleClose} />
								))}
						</Menu>
					)}
				</>
			)}
			{component && component}
		</div>
	);
}
