import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { STATIC_DIR } from '../../constants/common';

/**
 * Static or CDN script 로드시 사용
 */
function HeadComponent() {
	return (
		<Helmet>
			<script src={`${STATIC_DIR}/js/tinymce_5.10.5/tinymce.min.js`} type='text/javascript' />
		</Helmet>
	);
}

const Head = memo(HeadComponent);
export default Head;
