import { useCallback, useMemo, useState } from 'react';
import { useAIReport } from '../../../../context/AIReportContext';
import { IArticleHistoryItem } from '../../../../model/api/ai';
import ButtonText from '../../../ui/buttons/ButtonText';
import ChevronRightIcon from '../../../ui/icons/ChevronRight';
import InfoIcon from '../../../ui/icons/InfoIcon';
import InsightIcon from '../../../ui/icons/InsightIcon';
import AIArticleHistoryItemClickHandler from '../../common/AIArticleHistoryItemClickHandler';
import AIArticleHistoryItemCompare from '../../common/AIArticleHistoryItemCompare';

export default function AIReportRightPastReview() {
	const { data, changesExistHistory, setFocusState } = useAIReport();
	const [index, setIndex] = useState<number>(0);

	const updateIndex = useCallback(
		(direction: number) => {
			const newIndex = (index + direction + changesExistHistory.length) % changesExistHistory.length;
			const queryArticleId = changesExistHistory[newIndex].query_article_id;
			const element = window.document.querySelector(`#article-${queryArticleId}`) as HTMLElement;

			if (queryArticleId && element) {
				setFocusState({
					article: {
						id: queryArticleId,
						element,
					},
					clause: null,
					scroll: true,
				});
			}
			setIndex(newIndex);
		},
		[changesExistHistory, index],
	);

	const item: IArticleHistoryItem = useMemo(() => {
		if (!changesExistHistory.length) {
			return { history_map: null, index: 0 };
		}

		const allNodes = data.all_similar_clauses.flatMap(clause => clause.result.flatMap(node => node));
		const similarArticleIdSet = new Set(allNodes.map(node => node.article_id));

		const currentHistoryMap = changesExistHistory[index];
		const matchIndex =
			currentHistoryMap.article_histories.findIndex(
				({ article }) => article && similarArticleIdSet.has(article?.id),
			) ?? 0;

		return { history_map: currentHistoryMap, index: matchIndex };
	}, [data, changesExistHistory, index]);

	if (!changesExistHistory.length) {
		return null;
	}

	return (
		<section id='past-review-result'>
			<header className='flex items-center justify-end gap-1 mb-5'>
				<InsightIcon className='text-neutral-400 text-base' />
				<p className='text-sm text-neutral-600'>과거 검토 결과에서 인사이트를 얻으세요.</p>
			</header>
			<section className='border rounded-md'>
				<section className='flex items-center justify-between border-b'>
					<div className='flex items-center gap-2 p-4'>
						<InfoIcon className='text-blue-600 text-lg' />
						<p className='text-neutral-600 text-sm'>이 조항은 과거 검토중 수정이력을 가지고 있어요</p>
					</div>
					<div className='flex items-center flex-grow-0 flex-shrink-0'>
						<p className='text-sm'>
							{index + 1} / {changesExistHistory.length}
						</p>
						<div className='flex'>
							<ButtonText className='min-w-min' onClick={() => updateIndex(-1)}>
								<ChevronRightIcon className='transform scale-x-[-1] text-black' />
							</ButtonText>
							<ButtonText className='min-w-min' onClick={() => updateIndex(1)}>
								<ChevronRightIcon className='text-black' />
							</ButtonText>
						</div>
					</div>
				</section>
				<section className='flex flex-col'>
					<div className='p-4 border-b'>
						<AIArticleHistoryItemClickHandler item={item} />
					</div>
					<div className='flex flex-col px-4 pb-4'>
						<AIArticleHistoryItemCompare item={item} />
					</div>
				</section>
			</section>
		</section>
	);
}
