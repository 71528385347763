import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { IReportStatus } from '../../../model/api/ai';
import AdeleLegalLinkHandler from '../../common/AdeleLegalLinkHandler';
import ButtonText from '../../ui/buttons/ButtonText';
import ChevronRightIcon from '../../ui/icons/ChevronRight';
import { getReportById } from '../../../api/ai';
import { queryKey } from '../../../constants/queryKey';
import DotLoader from '../../ui/commons/DotLoader';
import AINotificationExpandedItem from './AINotificationExpandedItem';

type Props = {
	item: IReportStatus;
	setParentOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AINotificationItem({ item, setParentOpen }: Props) {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const { contract, status, update_time: updateTime, report_id: reportId } = item;

	const { data } = useQuery([queryKey.aiReport, reportId], () => getReportById(reportId!), {
		staleTime: 300000,
		enabled: open && !!reportId,
	});

	const statusDisplayMap: Record<IReportStatus['status'], string> = useMemo(
		() => ({
			PN: 'AI분석 요청 중',
			IN_PROGRESS: 'AI분석 진행 중',
			DONE: 'AI분석 완료',
		}),
		[item],
	);

	const handleNavigate = useCallback(() => {
		setParentOpen(false);
		navigate(`/buptle_ai/report/${reportId}`);
	}, [reportId]);

	const ExpandedItem = useCallback(() => {
		if (!open) {
			return null;
		}

		return data ? <AINotificationExpandedItem data={data} /> : <CircularProgress />;
	}, [open, data]);

	return (
		<div className='flex flex-col gap-1'>
			<div className='flex justify-between items-center'>
				<AdeleLegalLinkHandler id={contract.id} type='CONTRACT'>
					<ButtonText className='underline min-w-min p-0' color='primary'>
						<p>{contract.title}</p>
					</ButtonText>
				</AdeleLegalLinkHandler>
				{status === 'DONE' && (
					<ButtonText className='text-neutral-600 min-w-min' onClick={() => setOpen(!open)}>
						<ChevronRightIcon className={`transform ${open ? '-rotate-90' : 'rotate-90'}`} />
					</ButtonText>
				)}
			</div>
			<div className='flex items-center gap-3'>
				{!open && <p className='text-sm text-neutral-600'>{statusDisplayMap[status]}</p>}
				{status !== 'DONE' && <DotLoader />}
			</div>
			<ExpandedItem />
			{status === 'DONE' && (
				<div className='flex justify-between items-center'>
					<p className='text-neutral-400 text-sm'>{dayjs(updateTime).format('YYYY/MM/DD HH:mm')}</p>
					<ButtonText color='primary' className='min-w-min p-0 underline text-sm' onClick={handleNavigate}>
						바로 확인 ＞
					</ButtonText>
				</div>
			)}
		</div>
	);
}
