import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DOMAIN_URL } from '../../../../constants/common';
import { useUser } from '../../../../context/UserContext';
import { MenuData } from '../../../../model/header';
import SysConfigStore from '../../../../store/common/SysConfigStore';
import MenuItem1Depth from './MenuItem1Depth';

type Props = {
	openMenu: string | null;
	onToggle: (title: string) => void;
};
export default function HeaderLeft({ openMenu, onToggle }: Props) {
	const { isModuleEnabled, isComponentEnabled, labelText } = SysConfigStore();
	const { t } = useTranslation();
	const { permission } = useUser();

	const menus: MenuData[] = [
		// 계약
		{
			link: `${DOMAIN_URL}/contract/get-list-view-v28`,
			title: labelText('contract'),
			classes: { button: 'px-7 py-4 text-white' },
			display: true,
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem('/contract/get-list-view-v28/', JSON.stringify({ contract_type: 'CONTRACT' })),
		},

		// 자문
		{
			link: `${DOMAIN_URL}/counsel/get-list-view-v28/`,
			title: t('counsel'),
			classes: { button: 'px-7 py-4 text-white' },
			display: permission.DISPLAY_COUNSEL,
			isExternal: true,
		},
		// 소송
		{
			link: `${DOMAIN_URL}/lawsuit/get-list-view-v28/`,
			title: t('MSG_ID_1203'),
			classes: { button: 'px-7 py-4 text-white' },
			display: permission.DISPLAY_LAWSUIT,
			isExternal: true,
			preHandle: () => sessionStorage.setItem('/lawsuit/get-list-view-v28/', JSON.stringify({ type: 'LAWSUIT' })),
		},
		// 프로젝트
		{
			link: `${DOMAIN_URL}/epic/get_epic_all_list/`,
			title: t('MSG_ID_1193'),
			classes: { button: 'px-7 py-4 text-white' },
			display: isModuleEnabled('EPIC_MANAGE'),
			isExternal: true,
		},
		// s사 계약 외 문서
		{
			link: `${DOMAIN_URL}/contract/get-list-view-v28`,
			title: t('label_etc_contract'),
			classes: { button: 'px-7 py-4 text-white' },
			display: isComponentEnabled('CMPNT165_CONTRACT_ETC_BF'),
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem(
					'/contract/get-list-view-v28/',
					JSON.stringify({ contract_type: 'CONTRACT_ETC' }),
				),
		},
		// s사 사인 날인
		{
			link: `${DOMAIN_URL}/contract/get-list-view-v28`,
			title: t('label_contract_sign_seal_process'),
			classes: { button: 'px-7 py-4 text-white' },
			display: isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF'),
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem(
					'/contract/get-list-view-v28/',
					JSON.stringify({ contract_type: 'CONTRACT_SIGN_SEAL_PROCESS' }),
				),
		},
		// 수발신문서
		{
			link: `${DOMAIN_URL}/doc_received/get-list-view-v28/`,
			title: isComponentEnabled('CMPNT205_CUSTOMIZE_TEXT_SK_B')
				? t('cmmn_label_doc_recieved_box')
				: t('MSG_ID_46'),
			classes: { button: 'px-7 py-4 text-white' },
			display: isModuleEnabled('DOC_RECEIVED'),
			isExternal: true,
		},
		// UI 페이지
		{
			link: '/ui',
			title: 'UI',
			classes: { button: 'px-7 py-4 text-white' },
			display: process.env.NODE_ENV === 'development',
		},
	];

	return menus
		.filter(({ display }) => display)
		.map(menu => (
			<MenuItem1Depth
				key={uniqueId(`${menu.title}-`)}
				itemData={menu}
				isOpen={openMenu === menu.title}
				onToggle={() => onToggle(menu.title)}
			/>
		));
}
