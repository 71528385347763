import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../api/login';
import { DOMAIN_URL } from '../../constants/common';
import SysConfigStore from '../../store/common/SysConfigStore';

export default function LogoutPage() {
	const { isModuleEnabled } = SysConfigStore();

	if (isModuleEnabled('SSO_ENABLED') || isModuleEnabled('SSO_LOGIN_PAGE')) {
		window.location.href = `${DOMAIN_URL}/logout/`;
	}

	const navigate = useNavigate();
	useEffect(() => {
		logout().then(() => {
			navigate('/login');
		});
	}, []);

	return <div />;
}
