import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, {
	createContext,
	Dispatch,
	MutableRefObject,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { getReportById, updateReportNotificationByReportId } from '../api/ai';
import { queryKey } from '../constants/queryKey';
import { ClauseCategory, IReport, IStoreArticleHistoryMap } from '../model/api/ai';

export type CategoryNavigator = {
	elements: HTMLElement[];
	categoryKey: ClauseCategory | null;
	indexMap: Partial<Record<ClauseCategory, number>>;
	navigatorAnchorEl: HTMLElement | null;
};

type FocusProps = {
	id: number;
	element: HTMLElement | null;
};

type FocusState = {
	article: FocusProps | null;
	clause: FocusProps | null;
	scroll: boolean;
};

type RefMap = {
	leftContainerRef: MutableRefObject<HTMLDivElement | null>;
	rightContainerRef: MutableRefObject<HTMLDivElement | null>;
	contractContainerRef: MutableRefObject<HTMLDivElement | null>;
};

interface IAIReportContext {
	data: IReport;
	changesExistHistory: IStoreArticleHistoryMap[];
	refMap: RefMap;
	categoryNavigator: CategoryNavigator;
	setCategoryNavigator: Dispatch<SetStateAction<CategoryNavigator>>;
	focusState: FocusState | null;
	setFocusState: Dispatch<SetStateAction<FocusState | null>>;
	openVersionView: boolean;
	setOpenVersionView: Dispatch<SetStateAction<boolean>>;
}

const AIReportContext = createContext<IAIReportContext | undefined>(undefined);

type Props = {
	children: React.ReactNode;
};

export function AIReportProvider({ children }: Props) {
	const { id } = useParams();
	const queryClient = useQueryClient();

	const { data } = useQuery([queryKey.aiReport, id], () => getReportById(Number(id)), {
		staleTime: 300000,
	});

	const mutation = useMutation(() => updateReportNotificationByReportId(Number(id)), {
		onSuccess: () => {
			queryClient.invalidateQueries([queryKey.aiNotification]);
		},
	});

	useEffect(() => {
		if (data) {
			mutation.mutate();
		}
	}, [data]);

	const leftContainerRef = useRef<HTMLDivElement | null>(null);
	const rightContainerRef = useRef<HTMLDivElement | null>(null);
	const contractContainerRef = useRef<HTMLDivElement | null>(null);

	const refMap = useMemo(
		() => ({
			leftContainerRef,
			rightContainerRef,
			contractContainerRef,
		}),
		[leftContainerRef, rightContainerRef, contractContainerRef],
	);

	const [categoryNavigator, setCategoryNavigator] = useState<CategoryNavigator>({
		elements: [],
		categoryKey: null,
		indexMap: {},
		navigatorAnchorEl: null,
	});

	const [focusState, setFocusState] = useState<FocusState | null>(null);
	const { elements, categoryKey, indexMap } = categoryNavigator;

	useEffect(() => {
		if (elements.length && categoryKey) {
			const index = indexMap[categoryKey] ?? 0;
			const clauseElement = elements[index];
			const clauseId = clauseElement.id.split('clause-')[1];
			const articleElement = clauseElement.closest('div[id ^= "article-"]') as HTMLElement;
			const articleId = articleElement.id.split('article-')[1];
			setFocusState({
				article: {
					id: Number(articleId),
					element: null,
				},
				clause: {
					id: Number(clauseId),
					element: clauseElement,
				},
				scroll: true,
			});
		}
	}, [elements, indexMap]);

	useEffect(() => {
		if (focusState) {
			const { clause, article, scroll } = focusState;
			const { leftContainerRef: containerRef } = refMap;
			const targetElement = clause?.element || article?.element;

			if (targetElement && containerRef) {
				const targetRect = targetElement.getBoundingClientRect();
				const containerRect = containerRef.current?.getBoundingClientRect();

				targetElement.classList.add('bg-blue-50');

				if (scroll) {
					containerRef.current?.scrollTo({
						top: containerRef.current.scrollTop + targetRect.top - Number(containerRect?.top) - 12,
						behavior: 'smooth',
					});
				}
			}
		}

		return () => {
			if (focusState) {
				const { clause, article } = focusState;
				const targetElement = clause?.element || article?.element;
				if (targetElement) {
					targetElement.classList.remove('bg-blue-50');
				}
			}
		};
	}, [focusState]);

	const changesExistHistory = useMemo(() => {
		if (!data) {
			return [];
		}

		const { all_article_histories: allArticleHistories } = data;

		return allArticleHistories.filter(({ article_histories: articleHistories }) => {
			const hasChangeHistory = articleHistories.filter(
				({ article }) => article && article.change_type !== 'NOT_CHANGED',
			);
			return hasChangeHistory.length > 0;
		});
	}, [data]);

	const [openVersionView, setOpenVersionView] = useState<boolean>(false);

	const value = useMemo(() => {
		return {
			data: data as IReport,
			changesExistHistory,
			refMap,
			categoryNavigator,
			setCategoryNavigator,
			focusState,
			setFocusState,
			openVersionView,
			setOpenVersionView,
		};
	}, [data, refMap, categoryNavigator, focusState, openVersionView]);

	if (!data) {
		return null;
	}

	return <AIReportContext.Provider value={value}>{children}</AIReportContext.Provider>;
}

export function useAIReport() {
	const context = useContext(AIReportContext);
	if (context === undefined) {
		throw new Error('useAIReport must be used within a AIReportProvider');
	}

	return context;
}
