import { Chip, ChipOwnProps } from '@mui/material';
import { FormikValues } from 'formik';
import { EventHandler, memo } from 'react';
import ClearIcon from '../icons/ClearIcon';

interface IChipOwnPropsOverrides extends ChipOwnProps {
	id: string;
	formik?: FormikValues;
	onDelete: (id: string) => void;
}

function ChipDefault({ id, disabled, onDelete, formik, ...props }: IChipOwnPropsOverrides) {
	const handleDelete = (e: EventHandler<any>) => {
		if (typeof onDelete === 'function') onDelete(id);
	};

	return (
		<Chip
			id={id}
			classes={{
				root: 'px-3 bg-neutral-100',
				icon: 'ml-0',
				label: 'pr-0 pl-3',
				disabled: 'opacity-100',
			}}
			deleteIcon={<ClearIcon className='m-0 -mt-0.5' />}
			onDelete={!disabled ? handleDelete : undefined}
			onMouseDown={e => e.stopPropagation()}
			onFocus={e => e.stopPropagation()}
			{...props}
		/>
	);
}

export default memo(ChipDefault);
