import { MouseEvent, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import ButtonText from '../ui/buttons/ButtonText';
import AlarmIcon from '../ui/icons/AlarmIcon';
import AlarmPopover from './AlarmPopover';
import { queryKey } from '../../constants/queryKey';
import { getNotificationCount } from '../../api/notification';

const getAlarmIconStyle = (active: boolean) => {
	let result = {
		root: 'relative text-gray-400 text-[23px]',
		path1: 'origin-top-center',
		path2: '',
	};

	if (active)
		result = {
			root: 'relative text-yellow-400 text-[23px]',
			path1: 'animate-bell origin-top-center',
			path2: 'animate-bellClapper',
		};
	return result;
};

export default function AlarmList() {
	const [active, setActive] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const { data } = useQuery(queryKey.notificationCount, getNotificationCount, {
		staleTime: Infinity,
		refetchInterval: 60000,
	});

	const open = Boolean(anchorEl);

	useEffect(() => {
		if (data && data.notification_count > 0) setActive(true);
	}, [data]);

	const handleOpen = (e: MouseEvent<HTMLElement>) => {
		if (data?.notification_count === 0) return;
		setAnchorEl(e.currentTarget);
		setActive(false);
	};

	const handleClose = () => {
		if (data?.notification_count === 0) return;
		setAnchorEl(null);
		setActive(true);
	};

	return (
		<>
			<ButtonText className='relative min-w-[43px] p-0 h-full' onClick={handleOpen}>
				<AlarmIcon classes={getAlarmIconStyle(active)} />
				<span
					className={`absolute top-4 right-0 inline-flex items-center justify-center min-w-[18px] min-h-[18px] text-xs leading-none rounded-full text-white border-2 border-white ${
						data && data.notification_count > 0 && !open ? 'bg-red-500' : 'bg-gray-400'
					}`}
				>
					{data?.notification_count || 0}
				</span>
			</ButtonText>
			{open && <AlarmPopover open={open} anchorEl={anchorEl} onClose={handleClose} />}
		</>
	);
}
