import { Checkbox } from '@mui/material';
import { FormikValues } from 'formik';
import _ from 'lodash';
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DOMAIN_URL } from '../../../constants/common';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { SealStamp, SealStampTableSearchParams } from '../../../model/sealStamp';
import { IHeadCell } from '../../../model/table';
import SearchIcon from '../../ui/icons/SearchIcon';
import InputText from '../../ui/inputs/InputText';
import TableList from '../../ui/tables/TableList';
import TableListCell from '../../ui/tables/TableListCell';
import TableListRow from '../../ui/tables/TableListRow';
import TableSearchBar from '../../ui/tables/TableSearchBar';

type Props = {
	id: string;
	listType: string;
	selectedIdList?: number[];
	list: SealStamp[];
	headCells: IHeadCell<SealStamp>[];
	totalListCount: number;
	maxSelected?: number;
	selectedFormik: FormikValues;
	searchParams: SealStampTableSearchParams;
	setSearchParams: Dispatch<SetStateAction<SealStampTableSearchParams>>;
};

export default function TableSealStampList({
	id: formikId,
	listType: string,
	selectedIdList,
	list,
	headCells,
	totalListCount,
	maxSelected,
	selectedFormik,
	searchParams,
	setSearchParams,
}: Props) {
	const { t } = useTranslation();

	const [selected, setSelected] = useState<readonly number[]>(selectedIdList || []);

	const { searchText, currentPage, rowsPerPage } = searchParams;

	const formikTableSearch = useValidateForm({
		validationSchema: {
			search_input_seal_stamp_table: { initialValue: searchText, type: 'string' },
		},
		onSubmit: values => {
			setSearchParams(prev => ({
				...prev,
				searchText: values.search_input_seal_stamp_table,
			}));
		},
	});

	const handleClick = (e: MouseEvent<unknown>, id: number) => {
		const selectedIndex = _.indexOf(selected, id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelected = _.concat(selected, id);

			if (maxSelected && maxSelected < newSelected.length) {
				newSelected = _.slice(newSelected, 1);
			}
		} else newSelected = _.concat(_.slice(selected, 0, selectedIndex), _.slice(selected, selectedIndex + 1));

		setSelected(newSelected);

		selectedFormik.setFieldValue(
			formikId,
			_.filter(list, obj => _.includes(newSelected, obj.seal_stamp_id)),
		);
	};

	const handleChangePage = (e: unknown, newPage: number) => {
		setSearchParams(prev => ({ ...prev, currentPage: newPage }));
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchParams(prev => ({ ...prev, rowsPerPage: parseInt(e.target.value, 10), currentPage: 0 }));
	};

	const isSelected = (id: number) => {
		if (selected) {
			return selected.indexOf(id) !== -1;
		}

		return false;
	};

	return (
		<section className='flex flex-col gap-3'>
			<TableSearchBar>
				<div className='flex items-center gap-2 w-full'>
					<div className='flex-1 rounded-sm bg-white'>
						<form onSubmit={formikTableSearch.handleSubmit}>
							<InputText
								id='search_input_seal_stamp_table'
								icon={
									<SearchIcon
										classes={{ root: 'cursor-pointer' }}
										onClick={formikTableSearch.handleSubmit}
									/>
								}
								labelText={t('search_seal_stamp_title')}
								formik={formikTableSearch}
							/>
						</form>
					</div>
				</div>
			</TableSearchBar>

			<TableList
				headCells={headCells}
				count={totalListCount}
				rowsPerPage={rowsPerPage}
				page={currentPage}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			>
				{totalListCount > 0 ? (
					list.map((row, idx) => {
						const isItemSelected = isSelected(row.seal_stamp_id);
						const labelId = `checkbox_seal_stamp_popup_${row.seal_stamp_id}`;
						return (
							<TableListRow
								sx={{ cursor: 'pointer' }}
								onClick={event => handleClick(event, row.seal_stamp_id)}
								key={row.seal_stamp_id}
							>
								<TableListCell padding='checkbox'>
									<Checkbox
										id={labelId}
										color='primary'
										checked={isItemSelected}
										inputProps={{
											'aria-labelledby': labelId,
										}}
									/>
								</TableListCell>
								<TableListCell>
									<img
										src={DOMAIN_URL + row.img_url}
										alt='img_seal_stamp_popup'
										className='w-[40px]'
									/>
								</TableListCell>
								<TableListCell>
									<div>{row.name}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.seal_type}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.approval_user_names}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.view_code}</div>
								</TableListCell>
							</TableListRow>
						);
					})
				) : (
					<TableListRow>
						<TableListCell colSpan={headCells.length + 1} classes={{ root: 'text-center' }}>
							{t('label_no_search_results_exist')}
						</TableListCell>
					</TableListRow>
				)}
			</TableList>
		</section>
	);
}
