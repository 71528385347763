import React, { ReactElement, useCallback } from 'react';
import { getLegalLinkMap, navigateToAdele, openToAdele } from '../../util/commonUtils';
import { LegalType } from '../../model/common';
import { useAlert } from '../../hooks/useAlert';

type Props = {
	id: number;
	type: LegalType;
	children: React.ReactNode;
	newTab?: boolean;
};

export default function AdeleLegalLinkHandler({ type, id, children, newTab = false }: Props) {
	const [snackbar] = useAlert();

	const handleNavigate = useCallback(async () => {
		const { success, url, msg } = await getLegalLinkMap(type, id);
		if (success) {
			if (newTab) {
				openToAdele(url);
			} else {
				navigateToAdele(url);
			}
		} else {
			snackbar(msg, 'error');
		}
	}, [type, id]);

	return (
		<>
			{React.Children.map(children, child => {
				if (React.isValidElement(child)) {
					const existingOnClick = child.props.onClick;
					return React.cloneElement(child as ReactElement, {
						onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
							if (existingOnClick) {
								existingOnClick(e);
							}
							handleNavigate();
						},
					});
				}
				return child;
			})}
		</>
	);
}
