import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { MouseEvent } from 'react';
import { SignList } from '../../../../model/list';
import { IHeadCell, TOrder } from '../../../../model/table';

type Props = {
	headCells: readonly IHeadCell<SignList>[];
	onRequestSort: (event: MouseEvent<unknown>, property: keyof SignList) => void;
	order: TOrder;
	orderBy: string;
};

export default function TableSignHeader({ order, orderBy, onRequestSort, headCells }: Props) {
	const handleClick = (headCell: IHeadCell<SignList>) => (e: MouseEvent<unknown>) => {
		if (!headCell.disableSort) onRequestSort(e, headCell.id);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell?.id}
						align={headCell?.align || 'left'}
						padding={headCell?.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell?.id ? order : false}
						classes={{ head: `${headCell.width || ''}` }}
					>
						<TableSortLabel
							active={!headCell.disableSort && orderBy === headCell?.id}
							hideSortIcon={headCell.disableSort}
							classes={{
								root: `${headCell.disableSort && 'cursor-default hover:text-inherit'}`,
							}}
							direction={orderBy === headCell?.id ? order : 'asc'}
							onClick={handleClick(headCell)}
						>
							{headCell?.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
