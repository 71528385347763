import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function ProcessIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M4.33067 3.28417C4.33067 2.91586 4.62924 2.61729 4.99757 2.61729H16.5398V0.75H4.99757C3.59798 0.75 2.46338 1.88458 2.46338 3.28417V16.89H4.33067V3.28417Z'
				className='fill-inherit'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.48646 4.92093H16.831L21.804 9.89395V22.1829C21.804 22.7722 21.3262 23.25 20.737 23.25H7.48646C6.8972 23.25 6.41943 22.7722 6.41943 22.1829V5.98795C6.41943 5.39869 6.8972 4.92093 7.48646 4.92093ZM17.7129 13.0184H10.5104V15.1525H17.7129V13.0184ZM10.5104 17.2975H17.7129V19.4316H10.5104V17.2975Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
