import { memo } from 'react';
import LogoIcon from '../../ui/icons/LogoIcon';

function Header() {
	return (
		<header className='flex items-center w-full text-sm shadow-md shadow-gray-200 bg-white'>
			<nav className='flex flex-wrap-reverse justify-between w-full min-h-full px-[30px]'>
				<div className='w-[90px] h-[56px]'>
					<LogoIcon />
				</div>
			</nav>
		</header>
	);
}

export default memo(Header);
