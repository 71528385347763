import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getMoreUserRecentSaveArticles, getMoreUserRecentSeenArticles } from '../../../../api/ai';
import { queryKey } from '../../../../constants/queryKey';
import { useAIArticle } from '../../../../context/AIArticleContext';
import { useAIArticleSide } from '../../../../context/AIArticleSideContext';
import { IArticleHistoryItem } from '../../../../model/api/ai';
import ButtonText from '../../../ui/buttons/ButtonText';
import ClearIcon from '../../../ui/icons/ClearIcon';
import TooltipDefault from '../../../ui/tooltips/TooltipDefault';
import AIArticleTooltip from '../../common/AIArticleTooltip';

export default function AIArticleSideExpandItems() {
	const { id } = useParams();
	const navigate = useNavigate();
	const { detailState, setDetailState } = useAIArticle();
	const { expand, setExpand } = useAIArticleSide();

	const key = useMemo(
		() => (expand === 'SEEN' ? queryKey.aiMoreRecentSeenArticles : queryKey.aiMoreRecentSaveArticles),
		[expand],
	);
	const queryFn = useCallback(() => {
		if (expand === 'SEEN') {
			return getMoreUserRecentSeenArticles();
		}
		return getMoreUserRecentSaveArticles();
	}, [expand]);

	const { data } = useQuery([key], queryFn, { staleTime: 300000 });

	const extractArticle = useCallback((item: IArticleHistoryItem) => {
		const { history_map: historyMap, index: seenIndex } = item;
		return historyMap?.article_histories[seenIndex].article;
	}, []);

	const handleNavigate = useCallback(
		(item: IArticleHistoryItem) => {
			const article = extractArticle(item);
			if (!article || Number(id) === article?.id) {
				return;
			}
			setDetailState(article.id, item);
			navigate(`/buptle_ai/article/detail/${article.id}`);

			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		},
		[setDetailState, id],
	);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>, item: IArticleHistoryItem) => {
			if (e.key === 'Enter' || e.key === ' ') {
				handleNavigate(item);
			}
		},
		[handleNavigate],
	);

	const groupedByDate = _.groupBy(data, item => {
		if (expand === 'SEEN') {
			return dayjs(item.last_seen_time).format('YYYY-MM-DD');
		}
		return dayjs(item.create_time).format('YYYY-MM-DD');
	});

	const sortedEntries = Object.entries(groupedByDate).sort(([dateA], [dateB]) => dayjs(dateB).diff(dayjs(dateA)));

	return (
		<div className='flex flex-col gap-5'>
			<div className='flex justify-end'>
				<ButtonText className='min-w-0 p-1' onClick={() => setExpand(null)}>
					<ClearIcon />
				</ButtonText>
			</div>
			{sortedEntries?.map(([date, items]) => {
				return (
					<div key={date} className='flex flex-col gap-3'>
						<p>{date}</p>
						{items.map((item, index) => {
							const article = extractArticle(item);
							if (!article) return null;

							const prevArticle = detailState && extractArticle(detailState);
							return (
								// eslint-disable-next-line react/no-array-index-key
								<TooltipDefault key={`${date}-${index}`} title={<AIArticleTooltip article={article} />}>
									<div
										role='button'
										tabIndex={0}
										className={`truncate-2 cursor-pointer ${
											article.id === prevArticle?.id ? 'text-blue-600 underline' : ''
										}`}
										onClick={() => handleNavigate(item)}
										onKeyDown={e => handleKeyDown(e, item)}
									>
										<p>{article?.title}</p>
										{article.clauses.map(clause => clause.content).join(' ')}
									</div>
								</TooltipDefault>
							);
						})}
					</div>
				);
			}) || <CircularProgress />}
		</div>
	);
}
