import React from 'react';
import AIArticleAccordion from '../../common/AIArticleAccordion';
import { useAIArticle } from '../../../../context/AIArticleContext';
import AIArticleContent from '../../common/AIArticleContent';

export default function AIArticleDetailView() {
	const { detailState } = useAIArticle();

	if (!detailState) {
		return null;
	}

	return (
		<div className='p-8'>
			<AIArticleContent type='DETAIL' />
			<AIArticleAccordion data={detailState} />
		</div>
	);
}
