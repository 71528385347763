import { BaseTextFieldProps, FormHelperText, TextField } from '@mui/material';
import { FormikValues } from 'formik';
import { debounce } from 'lodash';
import { ChangeEvent, FocusEvent, KeyboardEvent, ReactElement, cloneElement, memo, useCallback, useRef } from 'react';

/**
 * MUI BaseTextFieldProps 상속
 */
interface IProps extends BaseTextFieldProps {
	id: string;
	labelText?: string;
	icon?: ReactElement;
	delay?: number;
	onBeforeDebounceChange?: (e: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>) => void;
	formik?: FormikValues;
}
/**
 * Input[type='text'] & Textarea 공통 컴포넌트
 * @param multiline true: textarea, false: Input[type='text']
 * @param rows Textarea 높이
 * @param labelText Label 에 들어가는 Text
 * @param required 필수여부
 * @param onBeforeDebounceChange debounce 이벤트 실행 전에 동작해야될 경우 필요
 * @returns
 */
function InputText({
	id,
	type = 'text',
	classes = { root: undefined },
	multiline = false,
	rows = 4,
	labelText,
	icon,
	required = false,
	placeholder,
	size = 'medium',
	disabled = false,
	delay = 0,
	onBeforeDebounceChange,
	formik,
}: IProps) {
	const ref = useRef(null);
	const { root = 'rounded' } = classes;
	const debounceChange =
		onBeforeDebounceChange &&
		debounce((e: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>) => {
			if (onBeforeDebounceChange) onBeforeDebounceChange(e);
		}, delay);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (debounceChange) debounceChange.cancel();
			const { value } = e.target as HTMLInputElement;
			formik?.setFieldValue(id, value);
		},
		[id],
	);

	const handleBlur = useCallback(
		(e: FocusEvent<HTMLInputElement>) => {
			if (debounceChange) {
				debounceChange.cancel();
				debounceChange(e);
			}
		},
		[id],
	);

	const handleKeyUp = useCallback(
		(e: KeyboardEvent<HTMLInputElement>) => {
			if (debounceChange) {
				debounceChange.cancel();
				if (e.code === 'Enter' && ref.current) (ref.current as HTMLInputElement).blur();
			}
		},
		[id],
	);

	return (
		<div className='relative w-full'>
			<TextField
				classes={{
					root: `flex w-full ${root}`,
				}}
				multiline={multiline}
				rows={multiline ? rows : undefined}
				size={size}
				id={id}
				name={id}
				type={type}
				inputProps={{
					className: `${icon && 'pr-8'}`,
					ref,
				}}
				label={labelText}
				InputLabelProps={{
					required,
				}}
				value={formik?.values[id] || ''}
				placeholder={placeholder}
				disabled={disabled}
				onChange={handleChange}
				onKeyUp={handleKeyUp}
				onBlur={handleBlur}
				error={formik?.touched[id] && Boolean(formik?.errors[id])}
			/>
			{formik?.touched[id] && formik?.errors[id] && formik?.errors[id].trim() !== '' && (
				<FormHelperText classes={{ root: 'absolute bottom-0 translate-y-full' }} error>
					{formik?.errors[id]}
				</FormHelperText>
			)}
			{icon &&
				cloneElement(icon, {
					className: `absolute right-2 top-1/2 -translate-y-1/2`,
					color: `${formik?.values[id] ? 'primary' : 'inherit'}`,
				})}
		</div>
	);
}

export default memo(InputText);
