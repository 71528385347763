import { Tab } from '@mui/material';
import { uniqueId } from 'lodash';
import { MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyObject } from 'yup';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { ISealStamp } from '../../../model/sealStamp';
import { Sign } from '../../../model/sign';
import { IHeadCell } from '../../../model/table';
import { createDigitalSealStamp } from '../../../util/canvas';
import PopupSealStamps from '../../common/popups/PopupSealStamps';
import CardBox from '../../ui/containers/CardBox';
import ItemSign from '../../ui/signs/ItemSign';
import TabPanel from '../../ui/tabs/TabPanel';
import TabsCommon from '../../ui/tabs/TabsCommon';
import PopupCanvas from './PopupCanvas';
import TableSelectedSignList from './tables/TableSelectedSignList';
import TableSignList from './tables/TableSignList';

interface IProps {
	id: string; // PK
	signs: Sign[];
	value: number;
	onChange: (_: SyntheticEvent, v: number) => void;
	onDrop: (item: AnyObject, dropResult: AnyObject) => void;
	onDeleteSign: (e: MouseEvent<HTMLButtonElement>, signId: string) => void;
	onGoToPage: (e: AnyObject, pageIndex: number) => void;
}

export default function TabsSign({ id, signs, value, onChange, onDrop, onDeleteSign, onGoToPage }: IProps) {
	const { t } = useTranslation();
	const [image, setImage] = useState<string | undefined>();

	const formik = useValidateForm({
		validationSchema: {
			signature: { initialValue: [], type: 'array' },
			handWrittenSignature: { initialValue: [], type: 'array' },
		},
	});

	const headCells: IHeadCell<ISealStamp>[] = [
		{
			id: 'imageUrl',
			width: 'w-[130px]',
			align: 'left',
			disablePadding: false,
			disableSort: true,
			label: t('label_image'),
		},
		{
			id: 'name',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: '인감명',
		},
		{
			id: 'canDrag',
			width: 'w-[80px]',
			align: 'center',
			disablePadding: false,
			disableSort: true,
			label: '',
		},
	];

	const headCellsHandWritten: IHeadCell<ISealStamp>[] = [
		{
			id: 'imageUrl',
			width: 'w-[130px]',
			align: 'left',
			disablePadding: false,
			disableSort: true,
			label: t('label_image'),
		},
		{
			id: 'name',
			align: 'left',
			disablePadding: false,
			disableSort: true,
			label: '',
		},
		{
			id: 'canDrag',
			width: 'w-[80px]',
			align: 'center',
			disablePadding: false,
			disableSort: true,
			label: '',
		},
	];

	const headCellsSelected: IHeadCell<Sign>[] = [
		{
			id: 'imageUrl',
			width: 'w-[100px]',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('label_image'),
		},
		{
			id: 'width',
			align: 'left',
			disablePadding: false,
			disableSort: true,
			label: t('label_size'),
		},
		{
			id: 'name',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('cmmn_label_seal_stamp_name'),
		},
		{
			id: 'pageIndex',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('label_page'),
		},
		{
			id: 'id',
			width: 'w-[50px]',
			align: 'left',
			disablePadding: false,
			disableSort: true,
			label: '',
		},
	];
	useEffect(() => {
		createDigitalSealStamp({
			text: '법틀\r\n디지털직인',
			setImage,
		});
	}, []);
	return (
		<CardBox>
			<TabsCommon
				classes={{
					indicator: 'h-1 bg-blue-300',
				}}
				value={value}
				onChange={onChange}
			>
				<Tab
					classes={{
						root: 'min-w-0 min-h-0 px-10 py-5 text-gray-950',
						selected: 'font-bold',
					}}
					id='sign_tab_1'
					label='서명 이미지'
				/>
				<Tab
					classes={{
						root: 'min-w-0 min-h-0 px-10 py-5 text-gray-950',
						selected: 'font-bold',
					}}
					id='sign_tab_2'
					label='서명 목록'
				/>
			</TabsCommon>

			<TabPanel value={value} index={0}>
				<section className='grid gap-4'>
					<header className='px-4 pt-4'>
						<p className='text-sm'>원하는 인감을 선택하고 드래그 하여 원하는 위치에 서명해주세요.</p>
						<div className='flex justify-end gap-2 mt-4'>
							<PopupCanvas id='canvas' formikId='handWrittenSignature' formik={formik} />
							<PopupSealStamps
								id='signature'
								label='법인 도장'
								trigger='button'
								popupSize='large'
								formik={formik}
							/>
						</div>
					</header>
					<article>
						<p className='px-4 pt-4 font-bold'>디지털 인감(자동생성됨)</p>
						<div className='p-4 border-b border-gray-200'>
							<div className='flex w-[270px]'>
								{image && (
									<ItemSign
										id={uniqueId()}
										name='digital seal stamp'
										canDrag
										imageUrl={image}
										onDrop={onDrop}
									/>
								)}
							</div>
						</div>
					</article>
					<article>
						<p className='px-4 pt-4 font-bold'>법인 도장</p>
						<p className='px-4 mt-4 text-m font-bold'>{`${t('MSG_ID_1255')}: ${
							(formik.values.signature && formik.values.signature.length) || 0
						}`}</p>
						<TableSignList
							id={id}
							list={formik.values.signature}
							headCells={headCells}
							onDrop={onDrop}
							onDeleteSign={onDeleteSign}
							formikId='signature'
							formik={formik}
						/>
					</article>

					<article>
						<p className='px-4 pt-4 font-bold'>수기 서명</p>
						<p className='px-4 mt-4 text-m font-bold'>{`${t('MSG_ID_1255')}: ${
							(formik.values.handWrittenSignature && formik.values.handWrittenSignature.length) || 0
						}`}</p>
						<TableSignList
							id='handWritten_list'
							list={formik.values.handWrittenSignature}
							headCells={headCellsHandWritten}
							onDrop={onDrop}
							onDeleteSign={onDeleteSign}
							formikId='handWrittenSignature'
							formik={formik}
						/>
					</article>
				</section>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<header className='px-4 pt-4'>
					<p className='text-sm'>서명내역을 페이지별로 확인하고 수정/삭제할 수 있습니다.</p>
					<p className='mt-4 text-m font-bold'>{`${t('MSG_ID_1255')}: ${signs.length}`}</p>
				</header>
				<section>
					<TableSelectedSignList
						list={signs}
						headCells={headCellsSelected}
						onDeleteSign={onDeleteSign}
						onClick={onGoToPage}
					/>
				</section>
			</TabPanel>
		</CardBox>
	);
}
