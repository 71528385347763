import { ClauseCategory } from '../model/api/ai';

export const AI_CLAUSE_CATEGORY_MAP: Record<ClauseCategory, string> = {
	purpose_of_contract: '목적',
	payment_terms: '계약금액및지불방식',
	contract_duration: '계약기간및종료',
	contract_modification: '계약의수정및변경',
	priority_of_effectiveness: '효력순위',
	breach_of_contract: '계약위반',
	intellectual_property: '지적재산권',
	confidentiality: '비밀유지',
	liability_limit: '책임제한/면제',
	critical_rights: '권리',
	jurisdiction_dispute: '관할/분쟁',
	scope_of_work: '업무의정의및범위',
	other: '기타',
};

export const AI_ARTICLE_TITLE_REGEX = /\d+\s*조(?:\s*의\s*\d+)?/g;
