import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { loginForce } from '../../api/login';
import { executePostLogin } from '../../service/executePostLogin';
import PopupWarning from '../ui/popups/PopupWarning';

type Props = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function DupLoginPopup({ open, setOpen }: Props) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const handleLoginForce = async () => {
		const res = await loginForce();
		const { next, subsidiary_search_id: SubsidiarySearchId } = res.data;
		executePostLogin({ navigate, next, subsidiary_search_id: SubsidiarySearchId });
	};

	const handleClose = () => setOpen(false);

	return (
		<PopupWarning
			size='xsmall'
			open={open}
			renderPage={
				<>
					<p>{t('cmmn_warn_the_same_id_is_in_use')}</p>
					<p>{t('cmmn_guide_do_you_want_to_disconnect_and_log_in')}</p>
				</>
			}
			onConfirm={handleLoginForce}
			onCancel={handleClose}
			onClose={handleClose}
		/>
	);
}
