import { useQuery } from '@tanstack/react-query';
import { getFakeOfficialLetterList } from '../../fakeService';

export default class WorkflowService {
	private static keys = ['testData'];

	public static useList() {
		return useQuery(WorkflowService.keys, () => getFakeOfficialLetterList());
	}
}
