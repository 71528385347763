import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { IArticleHistoryItem } from '../model/api/ai';
import { queryKey } from '../constants/queryKey';
import { getUserRecentSeenArticles, updateUserSeenArticle } from '../api/ai';

interface SlideState extends IArticleHistoryItem {
	display: boolean;
}

interface FavoriteState extends IArticleHistoryItem {
	anchorEl: HTMLElement | null;
	favorite_item_id?: number;
}

type AIArticleContext = {
	slideState: SlideState;
	setSlideState: React.Dispatch<React.SetStateAction<SlideState>>;
	detailState: IArticleHistoryItem | undefined;
	setDetailState: (articleId: number, newItem: Partial<IArticleHistoryItem>) => void;
	favoriteState: FavoriteState | null;
	setFavoriteState: React.Dispatch<React.SetStateAction<FavoriteState | null>>;
};

const AIArticleContext = createContext<AIArticleContext | undefined>(undefined);

type Props = {
	children: React.ReactNode;
};

export function AIArticleProvider({ children }: Props) {
	const queryClient = useQueryClient();
	const { id } = useParams();

	const [updateFlag, setUpdateFlag] = useState<number>(0);

	const detailState = useMemo(() => {
		const sessionData = sessionStorage.getItem(`aiArticleDetailState-${id}`);
		if (sessionData) {
			return JSON.parse(sessionData) as IArticleHistoryItem;
		}

		const queryData = queryClient.getQueryData<IArticleHistoryItem>([queryKey.aiArticleDetailState, Number(id)]);
		if (queryData) {
			sessionStorage.setItem(`aiArticleDetailState-${id}`, JSON.stringify(queryData));
		}

		return queryData;
	}, [id, queryClient, updateFlag]);

	const setDetailState = useCallback(
		(articleId: number, newItem: Partial<IArticleHistoryItem>) => {
			queryClient.setQueryData<IArticleHistoryItem>([queryKey.aiArticleDetailState, articleId], oldData => {
				const updatedData = oldData ? { ...oldData, ...newItem } : (newItem as IArticleHistoryItem);

				sessionStorage.setItem(`aiArticleDetailState-${articleId}`, JSON.stringify(updatedData));
				setUpdateFlag(prev => prev + 1);

				return updatedData;
			});
		},
		[queryClient],
	);

	const [slideState, setSlideState] = useState<SlideState>({
		history_map: null,
		index: 0,
		display: false,
	});

	const [favoriteState, setFavoriteState] = useState<FavoriteState | null>(null);

	const { data: recentSeenArticles } = useQuery([queryKey.aiRecentSeenArticles], getUserRecentSeenArticles, {
		staleTime: 300000,
	});

	const seenArticleMutation = useMutation((params: IArticleHistoryItem) => updateUserSeenArticle(params), {
		onMutate: async newArticle => {
			await queryClient.cancelQueries([queryKey.aiRecentSeenArticles]);

			const previousArticles = queryClient.getQueryData<IArticleHistoryItem[]>([queryKey.aiRecentSeenArticles]);

			queryClient.setQueryData<IArticleHistoryItem[]>([queryKey.aiRecentSeenArticles], oldArticles => {
				if (!oldArticles) {
					return [newArticle];
				}

				return [newArticle, ...oldArticles.slice(0, 2)];
			});

			return { previousArticles };
		},
		onError: (err, newArticle, context) => {
			queryClient.setQueryData([queryKey.aiRecentSeenArticles], context?.previousArticles);
		},
	});

	const handleSeenArticleMutation = (item: IArticleHistoryItem) => {
		if (!recentSeenArticles) {
			return;
		}

		const { history_map: historyMap, index } = item;

		const currentArticleIds = historyMap?.article_histories.map(h => h.article?.id);
		const recentArticleIdsList = recentSeenArticles.map(
			recentItem => recentItem.history_map?.article_histories.map(h => h.article?.id),
		);

		const hasMatchingRecentItem = recentArticleIdsList.some(recentArticleIds =>
			_.isEqual(currentArticleIds, recentArticleIds),
		);

		if (!recentSeenArticles.length || !hasMatchingRecentItem) {
			seenArticleMutation.mutate({ history_map: historyMap, index });
		}
	};

	useEffect(() => {
		if (slideState.history_map && slideState.display) {
			handleSeenArticleMutation(slideState);
		}
	}, [slideState]);

	useEffect(() => {
		if (detailState) {
			handleSeenArticleMutation(detailState);
		}
	}, [detailState]);

	const value = useMemo(
		() => ({
			slideState,
			setSlideState,
			detailState,
			setDetailState,
			favoriteState,
			setFavoriteState,
		}),
		[slideState, detailState, favoriteState],
	);

	return <AIArticleContext.Provider value={value}>{children}</AIArticleContext.Provider>;
}

export function useAIArticle() {
	const context = useContext(AIArticleContext);
	if (context === undefined) {
		throw new Error('useAIArticle must be used within a AIReportProvider');
	}

	return context;
}
