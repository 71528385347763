import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	getUserArticleFavoriteList,
	saveUserArticleFavoriteItem,
	updateUserArticleFavoriteItem,
} from '../../../../api/ai';
import { queryKey } from '../../../../constants/queryKey';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import ButtonText from '../../../ui/buttons/ButtonText';
import PopoverDefault from '../../../ui/popovers/PopoverDefault';
import { useAIArticle } from '../../../../context/AIArticleContext';
import InputSelect from '../../../ui/inputs/InputSelect';
import AIArticleFavoriteListCreateButton from './AIArticleFavoriteListCreateButton';
import { useAlert } from '../../../../hooks/useAlert';

export default function AIArticleFavoriteItemSave() {
	const { favoriteState, setFavoriteState } = useAIArticle();
	const queryClient = useQueryClient();
	const [snackbar] = useAlert();

	const handleClose = useCallback(() => {
		setFavoriteState(prev => (prev ? { ...prev, anchorEl: null } : null));
	}, [favoriteState]);

	const { data } = useQuery([queryKey.aiArticleFavoriteList], getUserArticleFavoriteList);

	const favoriteList = useMemo(() => data?.map(({ id, name }) => ({ name, value: id })) ?? [], [data]);

	const mutationFn = useCallback(
		(parentId: number) => {
			if (favoriteState?.favorite_item_id) {
				return updateUserArticleFavoriteItem(favoriteState.favorite_item_id, parentId);
			}

			return saveUserArticleFavoriteItem(favoriteState!, parentId);
		},
		[favoriteState],
	);

	const mutation = useMutation(mutationFn, {
		onSuccess: () => {
			queryClient.invalidateQueries([queryKey.aiArticleFavoriteItem]);
			queryClient.invalidateQueries([queryKey.aiRecentSaveArticles]);
			snackbar('성공적으로 저장되었습니다.', 'success');
		},
	});

	const formik = useValidateForm({
		validationSchema: {
			parent_id: {
				initialValue: '',
				type: 'number',
				required: ' ',
			},
		},
		onSubmit: values => {
			mutation.mutate(values.parent_id);
			handleClose();
		},
	});

	if (!favoriteState) {
		return null;
	}

	return (
		<PopoverDefault
			open={!!favoriteState?.anchorEl}
			onClose={handleClose}
			anchorEl={favoriteState?.anchorEl ?? null}
		>
			<form
				onSubmit={e => {
					e.preventDefault();
					formik.handleSubmit();
				}}
				className='flex gap-3 p-4'
			>
				<div className='min-w-[10rem]'>
					<InputSelect id='parent_id' formik={formik} options={favoriteList} size='small' />
				</div>
				<AIArticleFavoriteListCreateButton className='' variant='outlined' text='새 목록' />
				<ButtonText variant='outlined' onClick={() => formik.handleSubmit()}>
					{favoriteState.favorite_item_id ? '이동' : '저장'}
				</ButtonText>
			</form>
		</PopoverDefault>
	);
}
