import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { FormikValues } from 'formik';
import _, { CollectionChain } from 'lodash';
import { ChangeEvent, MouseEvent, memo, useMemo, useState } from 'react';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import { IHeadCell, TOrder } from '../../../../model/table';
import { User } from '../../../../model/user';
import SysConfigStore from '../../../../store/common/SysConfigStore';
import CardBox from '../../../ui/containers/CardBox';
import SearchIcon from '../../../ui/icons/SearchIcon';
import InputText from '../../../ui/inputs/InputText';
import TableFooter from '../../../ui/tables/TableFooter';
import TableSearchBar from '../../../ui/tables/TableSearchBar';
import SubsidiarySelector from '../../SubsidiarySelector';
import TableUserHeader from './TableUserHeader';

interface IProps {
	id: string;
	listType?: 'checkbox' | 'radio';
	list: User[];
	orderBy?: keyof User;
	headCells: readonly IHeadCell<User>[];
	formik: FormikValues;
	maxSelected?: number;
	selectedFormik: FormikValues;
}

function TableUserList({
	id: formikId,
	listType,
	list,
	orderBy: ob,
	headCells,
	formik,
	maxSelected = 0,
	selectedFormik,
}: IProps) {
	const { isComponentEnabled } = SysConfigStore();
	const [order, setOrder] = useState<TOrder>('asc');
	const [orderBy, setOrderBy] = useState(ob || undefined);
	const [selected, setSelected] = useState<readonly number[]>(formik.values[formikId].map((item: User) => item.id));
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const formikTableSearch = useValidateForm({
		validationSchema: {
			table_name_input: { initialValue: '', type: 'string' },
			table_dept_input: { initialValue: '', type: 'string' },
			table_subsidiary_select: { initialValue: -1, type: 'number' },
		},
	});

	const handleRequestSort = (e: MouseEvent<unknown>, property: keyof User) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = list.map(n => n.id);
			setSelected(newSelected);
			selectedFormik.setFieldValue(formikId, list);
			return;
		}
		setSelected([]);
		selectedFormik.setFieldValue(formikId, []);
	};

	const handleClick = (e: MouseEvent<unknown>, id: number) => {
		const selectedIndex = _.indexOf(selected, id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelected = _.concat(selected, id);

			if (maxSelected && maxSelected < newSelected.length)
				newSelected = _.slice(newSelected, newSelected.length - maxSelected);
		} else {
			newSelected = _.concat(_.slice(selected, 0, selectedIndex), _.slice(selected, selectedIndex + 1));
		}

		setSelected(newSelected);

		selectedFormik.setFieldValue(
			formikId,
			_.filter(list, obj => _.includes(newSelected, obj.id)),
		);
	};

	const handleChangePage = (e: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(e.target.value, 10));
		setPage(0);
	};

	// 검색하면 page, rowsPerPage 초기화
	const handleBeforeDebounceChange = () => {
		setRowsPerPage(10);
		setPage(0);
	};

	const isSelected = (id: number) => selected.indexOf(id) !== -1;

	// rows 양이 적을 경우 크기 조절
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

	// sort 조건
	const getSort = (_list: User[], _order: TOrder, _orderBy: keyof User | undefined): CollectionChain<User> => {
		if (!_orderBy) return _.chain(_list);

		const result = _.chain(_list).sortBy(_orderBy);

		if (_order === 'desc') return result.reverse();

		return result;
	};

	// 현재 page 와 filter 에 맞는 rows slice
	const visibleRows = useMemo(() => {
		let resultArr = getSort(list, order, orderBy);

		if (formikTableSearch.values.table_name_input)
			resultArr = resultArr.filter(item => item.name.indexOf(formikTableSearch.values.table_name_input) !== -1);
		if (formikTableSearch.values.table_dept_input)
			resultArr = resultArr.filter(item => item?.dept?.indexOf(formikTableSearch.values.table_dept_input) !== -1);
		if (formikTableSearch.values.table_subsidiary_select !== -1)
			resultArr = resultArr.filter(
				item => item.subsidiaryId === formikTableSearch.values.table_subsidiary_select,
			);

		return {
			count: resultArr.value().length,
			rows: resultArr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).value(),
		};
	}, [
		order,
		orderBy,
		page,
		rowsPerPage,
		formikTableSearch.values.table_name_input,
		formikTableSearch.values.table_dept_input,
		formikTableSearch.values.table_subsidiary_select,
	]);

	return (
		<section className='flex flex-col gap-3'>
			<TableSearchBar numSelected={selected.length}>
				<div className='flex items-center gap-2 w-full'>
					{isComponentEnabled('CMPNT147_ADD_COMPANY_OPTION_TO_POPUP_BF') && (
						<div className='flex-auto rounded-sm bg-white'>
							<SubsidiarySelector formikId='table_subsidiary_select' formik={formikTableSearch} />
						</div>
					)}

					<div className='flex-auto rounded-sm bg-white'>
						<InputText
							id='table_name_input'
							icon={<SearchIcon />}
							labelText='이름'
							formik={formikTableSearch}
							onBeforeDebounceChange={handleBeforeDebounceChange}
						/>
					</div>
					<div className='flex-auto rounded-sm bg-white'>
						<InputText
							id='table_dept_input'
							icon={<SearchIcon />}
							labelText='부서'
							formik={formikTableSearch}
							onBeforeDebounceChange={handleBeforeDebounceChange}
						/>
					</div>
				</div>
			</TableSearchBar>

			<CardBox className='pt-1' size='small'>
				<TableContainer className='overflow-visible'>
					<Table size='medium' stickyHeader>
						<TableUserHeader
							listType={maxSelected ? 'radio' : 'checkbox'}
							headCells={headCells}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={list.length}
						/>
						<TableBody style={{ height: `${visibleRows.rows.length * 53 || 53}px` }}>
							{visibleRows.rows.length === 0 && (
								<TableRow>
									<TableCell colSpan={headCells.length + 1} classes={{ root: 'text-center' }}>
										검색 결과가 없습니다.
									</TableCell>
								</TableRow>
							)}
							{visibleRows.rows.length > 0 &&
								visibleRows.rows.map((row, index) => {
									const isItemSelected = isSelected(row.id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={event => handleClick(event, row.id)}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={isItemSelected}
											sx={{ cursor: 'pointer' }}
										>
											{listType && (
												<TableCell padding='checkbox'>
													<Checkbox
														id={labelId}
														color='primary'
														checked={isItemSelected}
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												</TableCell>
											)}
											<TableCell component='th' id={labelId} scope='row' padding='none'>
												{row.name}
											</TableCell>
											{isComponentEnabled('CMPNT147_ADD_COMPANY_OPTION_TO_POPUP_BF') && (
												<TableCell align='left'>{row.subsidiaryName}</TableCell>
											)}
											<TableCell align='left'>{row.dept}</TableCell>
											<TableCell align='left'>{row.rank}</TableCell>
											<TableCell align='left'>{row.email}</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 53 * emptyRows,
									}}
								>
									<TableCell colSpan={headCells.length + 1} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TableFooter
					rowsPerPageOptions={[10, 20, 30, 50]}
					showFirstButton
					showLastButton
					count={visibleRows.count}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</CardBox>
		</section>
	);
}

export default memo(TableUserList);
