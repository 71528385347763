import { useMemo } from 'react';
import { IStoreArticle } from '../../../model/api/ai';
import { uniqueElementsSortedByFrequency } from '../../../util/commonUtils';
import StateChip from '../../ui/chips/StateChip';
import { AI_CLAUSE_CATEGORY_MAP } from '../../../constants/aiConstans';

type Props = {
	article?: IStoreArticle;
};

export default function AIArticleTooltip({ article }: Props) {
	const processCategories = useMemo(() => {
		const allCategories = article?.clauses.flatMap(({ categories }) => categories);
		return allCategories ? uniqueElementsSortedByFrequency(allCategories) : [];
	}, [article]);

	if (!article) {
		return null;
	}

	return (
		<div className='p-3 text-sm' key={article.id}>
			<div className='flex gap-1 py-1 overflow-hidden truncate'>
				{processCategories.map(key => (
					<StateChip key={key} text={AI_CLAUSE_CATEGORY_MAP[key]} />
				))}
			</div>
			<div className='flex justify-between items-center'>
				<p className='font-semibold'>{article.title}</p>
			</div>
			{article.clauses.map(({ id, content }) => (
				<p className='pl-2' key={id}>
					{content}
				</p>
			))}
		</div>
	);
}
