import { AccordionProps, AccordionSummaryClasses } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactNode, useState } from 'react';
import ExpandMoreIcon from '../icons/ExpandMoreIcon';

type AccordionPropsOverrides = AccordionProps & {
	summaryOpen: ReactNode;
	expanded?: boolean;
	summaryClose?: ReactNode;
	summaryClasses?: Partial<AccordionSummaryClasses>;
	expandIcon?: ReactNode;
	changeCallback?: (value: boolean) => void;
};
export default function AccordionDefault({
	expanded,
	summaryOpen,
	summaryClose = summaryOpen,
	children,
	onChange,
	summaryClasses,
	expandIcon = <ExpandMoreIcon />,
	changeCallback,
	...props
}: AccordionPropsOverrides) {
	const [open, setOpen] = useState(expanded);

	const handleChange = () => (e: React.SyntheticEvent, isExpanded: boolean) => {
		setOpen(isExpanded);
		if (changeCallback && isExpanded !== undefined) {
			changeCallback(isExpanded);
		}
	};

	return (
		<div>
			<Accordion {...props} expanded={open} onChange={onChange || handleChange()}>
				<AccordionSummary classes={summaryClasses} expandIcon={expandIcon}>
					{open && summaryOpen}
					{!open && summaryClose}
				</AccordionSummary>
				<AccordionDetails>{children}</AccordionDetails>
			</Accordion>
		</div>
	);
}
