import { FormikValues } from 'formik';
import { uniqueId } from 'lodash';
import { memo, useCallback, useRef, useState } from 'react';
import { useUser } from '../../../context/UserContext';
import { TCanvasRef } from '../../../model/canvas';
import { getCanvasAsImage } from '../../../util/canvas';
import ButtonText from '../../ui/buttons/ButtonText';
import Canvas from '../../ui/canvas/Canvas';
import PopupCommonExternal from '../../ui/popups/PopupCommonExternal';

interface IProps {
	id: string; // PK
	formikId: string;
	formik: FormikValues;
}

function PopupCanvas({ id, formikId, formik }: IProps) {
	const [open, setOpen] = useState(false);
	const ref = useRef() as TCanvasRef;
	const { loginUser } = useUser();

	const handleTogglePopup = () => setOpen(prev => !prev);

	const handleClose = () => setOpen(false);

	const renderPage = useCallback(
		() => (
			<section className='grid justify-center'>
				<Canvas ref={ref} width={400} disabledSave disabledDownload />
			</section>
		),
		[],
	);

	const handleClick = useCallback(() => {
		const data = getCanvasAsImage(ref);
		formik.setFieldValue(formikId, [
			...formik.values[formikId],
			{
				id: uniqueId(),
				name: '',
				imageUrl: data,
				description: '수기 서명입니다.',
				subsidiary: '',
				user: loginUser,
				isAuto: false,
				canDrag: true,
			},
		]);

		handleTogglePopup();
	}, [formik.values[formikId]]);

	return (
		<>
			<ButtonText variant='contained' color='secondary' text='+ 수기 서명' onClick={handleTogglePopup} />
			<PopupCommonExternal
				open={open}
				size='xsmall'
				title='수기 서명'
				description='직접 사인을 그려주세요.'
				renderPage={renderPage()}
				onConfirm={handleClick}
				onCancel={handleClose}
				onClose={handleClose}
			/>
		</>
	);
}

export default memo(PopupCanvas);
