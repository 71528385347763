import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonLoading from '../buttons/ButtonLoading';
import ScrollBox from '../containers/ScrollBox';
import PopupDefault, { ModalSize } from './PopupDefault';

interface IColorProps {
	confirm?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
	cancel?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

export interface IPopupCommonExternalProps {
	open: boolean;
	title?: string | ReactNode;
	description?: string | ReactNode;
	renderPage: string | ReactNode;
	icon?: ReactNode;
	size?: ModalSize;
	externalClickClose?: boolean;
	confirmClose?: boolean;
	onClose?: () => void;
	onConfirm?: boolean | (() => void);
	onCancel?: boolean | (() => void);
	color?: IColorProps;
	loading?: boolean;
}

const defaultColor: IColorProps = {
	confirm: 'primary',
	cancel: 'inherit',
};

export default function PopupCommonExternal({
	open,
	loading,
	title,
	description,
	renderPage,
	icon,
	size,
	externalClickClose = true,
	confirmClose = false,
	onClose,
	onConfirm,
	onCancel,
	color: providedColor,
}: IPopupCommonExternalProps) {
	const { t } = useTranslation();
	const handleButtonClick = async (action: typeof onConfirm, isCloseTrigger: boolean) => {
		if (typeof action === 'function') action();

		if (isCloseTrigger && onClose) onClose();
	};

	const color = {
		...defaultColor,
		...providedColor,
	};

	const getButton = (
		text: typeof renderPage,
		customColor: IColorProps['confirm' | 'cancel'],
		action: typeof onConfirm,
		buttonClose: boolean,
	) => (
		<ButtonLoading
			loading={loading}
			text={text}
			variant='contained'
			color={customColor}
			onClick={() => handleButtonClick(action, buttonClose)}
		/>
	);

	return (
		<PopupDefault open={open} size={size} onClose={onClose} externalClickClose={externalClickClose}>
			<div className='flex flex-col gap-5'>
				{title && (
					<div>
						<h2 className='text-2xl font-bold'>{title}</h2>
						{description && <p className='text-gray-600'>{description}</p>}
					</div>
				)}
				{icon && <div className='text-center'>{icon}</div>}
				<ScrollBox>{renderPage}</ScrollBox>
				<div className='flex justify-center space-x-6'>
					{onCancel && getButton(t('MSG_ID_105'), color.cancel, onCancel, true)}
					{onConfirm && getButton(t('MSG_ID_104'), color.confirm, onConfirm, confirmClose)}
				</div>
			</div>
		</PopupDefault>
	);
}
