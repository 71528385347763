import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getMainWorkStatusCount } from '../../../api/dashboard';
import { contractSearchParams, counselSearchParams } from '../../../constants/adeleSearchParams';
import { contractListUrl, counselListUrl } from '../../../constants/adeleUrl';
import { queryKey } from '../../../constants/queryKey';
import { useSubsidiary } from '../../../context/SubsidiaryContext';
import SysConfigStore from '../../../store/common/SysConfigStore';
import { navigateToAdele } from '../../../util/commonUtils';
import StatusCardDashboard from './StatusCardDashboard';

export default function StatusDashboardLawyer() {
	const { id: subsidiaryId } = useSubsidiary();
	const { data: statusCount } = useQuery([queryKey.dashboard, queryKey.workStatusCount, subsidiaryId], () =>
		getMainWorkStatusCount(subsidiaryId),
	);
	const { t } = useTranslation();
	const { isModuleEnabled, isComponentEnabled } = SysConfigStore();

	const handleNavigate = (
		url: string,
		workType: 'CONTRACT' | 'COUNSEL' | 'CONTRACT_ETC',
		status: 'REQUIRED_REVIEW' | 'MY_REVIEW',
	) => {
		const paramsMap = {
			CONTRACT: contractSearchParams,
			COUNSEL: counselSearchParams,
			CONTRACT_ETC: contractSearchParams,
		};

		if (['CONTRACT', 'CONTRACT_ETC'].includes(workType)) {
			sessionStorage.setItem('/contract/get-list-view-v28/', JSON.stringify({ contract_type: workType }));
		}
		if (workType === 'CONTRACT_ETC') workType = 'CONTRACT';

		sessionStorage.setItem(
			`${workType.toLowerCase()}_search_param`,
			JSON.stringify({
				...paramsMap[workType],
				status_check: 'PROGRESS_STS_REVIEW',
				search_mode: status === 'REQUIRED_REVIEW' ? '2' : '0',
				my_contract: status === 'MY_REVIEW' ? 'True' : 'False',
			}),
		);

		navigateToAdele(url);
	};

	const reviewItems = [
		{
			count: statusCount?.contract.reviewer_required_count,
			label: t('MSG_ID_5'),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT', 'REQUIRED_REVIEW'),
		},
		{
			count: statusCount?.counsel.reviewer_required_count,
			label: t('label_notification_app_code_counsel'),
			onClick: () => handleNavigate(counselListUrl, 'COUNSEL', 'REQUIRED_REVIEW'),
		},
		{
			count: statusCount?.contract_etc?.reviewer_required_count,
			label: t('label_etc_contract'),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT_ETC', 'REQUIRED_REVIEW'),
			display: isComponentEnabled('CMPNT165_CONTRACT_ETC_BF'),
		},
	];

	const completedItems = [
		{
			count: statusCount?.contract.review_count,
			label: t('MSG_ID_5'),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT', 'MY_REVIEW'),
		},
		{
			count: statusCount?.counsel.review_count,
			label: t('label_notification_app_code_counsel'),
			onClick: () => handleNavigate(counselListUrl, 'COUNSEL', 'MY_REVIEW'),
		},
		{
			count: statusCount?.contract_etc?.review_count,
			label: t('label_etc_contract'),
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT_ETC', 'MY_REVIEW'),
			display: isComponentEnabled('CMPNT165_CONTRACT_ETC_BF'),
		},
	];

	return (
		<>
			<StatusCardDashboard title={t('contract_guide_please_assign_a_reviewer')} items={reviewItems} />
			<StatusCardDashboard title={t('cmmn_label_legal_in_my_review')} items={completedItems} />
		</>
	);
}
