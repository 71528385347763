import { ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface IProps {
	children: ReactNode;
}

export default function ProviderSortTable({ children }: IProps) {
	return <DndProvider backend={HTML5Backend}>{children}</DndProvider>;
}
