import { useState } from 'react';
import SysConfigStore from '../../store/common/SysConfigStore';
import SubsidiarySelector from '../common/SubsidiarySelector';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';

export default function Header() {
	const { isComponentEnabled } = SysConfigStore();
	const [openMenu, setOpenMenu] = useState<string | null>(null);

	const handleMenuToggle = (title: string) => {
		if (openMenu === title) {
			setOpenMenu(null);
		} else {
			setOpenMenu(title);
		}
	};

	return (
		<header className='z-20 flex items-center w-full text-sm shadow-md shadow-gray-200 bg-white'>
			<nav className='flex flex-wrap-reverse justify-between w-full min-h-full px-[30px]'>
				<div className='flex gap-6'>
					<HeaderLeft openMenu={openMenu} onToggle={handleMenuToggle} />
				</div>
				<div className='flex gap-6 ml-auto'>
					{isComponentEnabled('CMPNT145_SUBSIDIARY_MODULE_CUSTOM_01_BF') && (
						<div className='flex items-center gap-5'>
							<SubsidiarySelector />
						</div>
					)}

					<HeaderRight openMenu={openMenu} onToggle={handleMenuToggle} />
				</div>
			</nav>
		</header>
	);
}
