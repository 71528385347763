import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from 'react';
import { IOfficialLetter } from '../../../../model/officialLetter';

interface IProps {
	children: ReactNode;
}

interface IProvider {
	officialLetter: IOfficialLetter;
	setOfficialLetter: Dispatch<SetStateAction<IOfficialLetter>>;
}

// 임시 default 데이터
const defaultOfficialLetter: IOfficialLetter = {
	title: '임시 워크플로우',
	description: '여기는 임시 입니다.',
	process: [
		{
			id: 'draft',
			isEnabled: true,
			isApproved: true,
			steps: [
				{
					id: '1',
					type: 'default',
					title: '초안 작성',
					owner: 'drafter',
					options: {
						isDisabled: true,
					},
				},
				{
					id: '2',
					type: 'approve',
					title: '초안 승인',
					owner: 'drafter',
					options: {
						isDisabled: true,
					},
				},
			],
		},
		{
			id: 'review',
			isEnabled: true,
			isApproved: true,
			owner: {
				user: [],
				options: {
					userAssignmentType: '2',
				},
			},
			approval: {
				user: [],
				options: {
					userAssignmentType: '2',
				},
			},
			steps: [
				{
					id: '1',
					type: 'review',
					title: '검토',
					owner: 'reviewer',
					options: {
						isDisabled: true,
						isDeleted: false,
					},
				},
				{
					id: '2',
					type: 'review',
					title: '검토본 수신',
					owner: 'drafter',
					options: {
						isDisabled: true,
						isDeleted: false,
					},
				},
				{
					id: '3',
					type: 'approve',
					title: '최종 승인',
					owner: 'final',
					options: {
						isDisabled: true,
						isDeleted: false,
					},
				},
			],
		},
		{
			id: 'sign',
			isAuthorizedToSign: false,
			isEnabled: true,
			owner: {
				user: [],
				options: {
					userAssignmentType: '2',
				},
			},
			steps: [
				{
					id: '1',
					type: 'sign',
					title: '서명 방식 선택',
					owner: 'signer',
					options: {
						isDisabled: true,
						isDeleted: false,
					},
				},
				{
					id: '2',
					type: 'sign',
					title: '전자 서명',
					owner: 'signer',
					options: {
						isDisabled: true,
						isDeleted: false,
					},
				},
				{
					id: '3',
					type: 'sign',
					title: '종이에 서명',
					owner: 'signer',
					options: {
						isDisabled: true,
						isDeleted: false,
					},
				},
			],
		},
		{
			id: 'complete',
			isEnabled: true,
			steps: [
				{
					id: '1',
					type: 'default',
					title: '완료',
					owner: 'drafter',
					options: {
						isDisabled: true,
					},
				},
			],
		},
	],
};

const context = createContext<IProvider | undefined>(undefined);

/**
 * Workflow provicder
 */
export default function ProviderPage({ children }: IProps) {
	const [officialLetter, setOfficialLetter] = useState<IOfficialLetter>(defaultOfficialLetter);

	const value = useMemo(() => ({ officialLetter, setOfficialLetter }), [officialLetter]);

	return <context.Provider value={value}>{children}</context.Provider>;
}

export const useProviderPage = () => {
	const result = useContext(context);
	if (!result) throw new Error(`Workflow context is undefined`);
	return result;
};
