import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ErrorPage() {
	const [isLoading, setIsLoading] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 3000);

		return () => clearTimeout(timer);
	}, []);

	const displayMessage = isLoading ? <CircularProgress /> : <>{t('cmmn_error_page')}</>;

	return <section className='flex items-center justify-center w-full h-[100vh]'>{displayMessage}</section>;
}
