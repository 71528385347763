import { ThemeProvider } from '@mui/material';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './components/external/footer/Footer';
import Header from './components/external/header/Header';
import { useTheme } from './hooks/useTheme';

function ExternalApp() {
	const theme = useTheme();

	return (
		<ThemeProvider theme={theme}>
			<section className='flex flex-col min-h-screen' style={{ backgroundColor: theme.palette.body.main }}>
				<Header />
				<main className='flex-1 grid w-full'>
					<Outlet />
				</main>
				<Footer />
			</section>
		</ThemeProvider>
	);
}

export default memo(ExternalApp);
