import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryKey } from '../../../../constants/queryKey';
import { getTodayStoreStatus } from '../../../../api/ai';
import CardBox from '../../../ui/containers/CardBox';
import CircleIcon from '../../../ui/icons/CircleIcon';

export default function AIArticleSideDataCount() {
	const { data } = useQuery([queryKey.aiTodayStoreStatus], getTodayStoreStatus, { staleTime: 300000 });

	return (
		<CardBox className='grid gap-3 p-4'>
			<div className='flex gap-1 items-center whitespace-nowrap'>
				<CircleIcon className='text-sm text-blue-600' />
				<p className='text-sm font-bold'>법틀 AI</p>
			</div>
			<div>
				<div className='flex justify-between'>
					<p className='text-sm text-neutral-600'>전체 계약</p>
					<p className='text-sm font-bold'>{data?.contracts}</p>
				</div>
				<div className='flex justify-between'>
					<p className='text-sm text-neutral-600'>전체 조항</p>
					<p className='text-sm font-bold'>{data?.articles}</p>
				</div>
				<div className='flex justify-between'>
					<p className='text-sm text-neutral-600'>변경된 조항</p>
					<p className='text-sm font-bold'>{data?.changed_articles}</p>
				</div>
			</div>
		</CardBox>
	);
}
