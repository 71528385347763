import { Components } from '@mui/material';
import tailwindColors from 'tailwindcss/colors';
import theme from 'tailwindcss/defaultTheme';

export const getComponents = (): Components => ({
	MuiTableCell: {
		styleOverrides: {
			root: {
				wordBreak: 'break-all',
			},
		},
	},
	MuiChip: {
		styleOverrides: {
			root: {
				'&.MuiChip-sizeMedium': {
					fontSize: theme.fontSize.base[0],
					lineHeight: theme.fontSize.base[1].lineHeight,
					height: 30,
				},
				'&.MuiChip-colorPrimary': {
					color: tailwindColors.blue[600],
					textDecoration: 'underline',
				},
				'.MuiChip-deleteIconColorPrimary': {
					color: 'rgba(0, 0, 0, 0.26)',
				},
				'.MuiChip-deleteIconColorPrimary:hover': {
					color: 'rgba(0, 0, 0, 0.4)',
				},
			},
		},
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				'&': {
					borderRadius: 'inherit',
					borderColor: 'inherit',
				},
				'&.Mui-disabled': {
					color: tailwindColors.gray[950],
				},
				'.MuiOutlinedInput-notchedOutline': {
					borderColor: 'inherit',
				},
			},
			input: {
				'&:-webkit-autofill': {
					transitionDelay: '9999s',
					transitionProperty: 'background-color, color',
				},
				'&.Mui-disabled': {
					textFillColor: 'inherit',
				},
			},
		},
	},
	MuiSelect: {
		styleOverrides: {
			root: {
				'& .MuiInputBase-input.MuiSelect-select': {
					display: 'flex',
					alignItems: 'center',
					paddingTop: 6,
					paddingBottom: 6,
					minHeight: 44,
				},
				'& .MuiInputBase-input.MuiSelect-select.MuiInputBase-inputSizeSmall': {
					minHeight: 28,
				},
			},
		},
	},
	MuiTablePagination: {
		styleOverrides: {
			select: {
				paddingTop: 0,
				paddingBottom: 0,
			},
		},
	},
	MuiButton: {
		styleOverrides: {
			containedSecondary: {
				color: tailwindColors.white,
			},
			containedInfo: {
				color: tailwindColors.white,
			},
			containedWarning: {
				color: tailwindColors.white,
			},
		},
	},
	MuiAccordion: {
		styleOverrides: {
			root: {
				backgroundColor: 'inherit',
				boxShadow: 'inherit',
				'&:first-of-type': {
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
				},
				'&:before': {
					display: 'none',
				},
				'&.Mui-expanded': {
					margin: 0,
				},
			},
		},
	},
	MuiAccordionSummary: {
		styleOverrides: {
			root: {
				display: 'inline-flex',
				padding: 0,
				minHeight: 0,
				'&.Mui-expanded': {
					minHeight: 0,
				},
			},
			content: {
				margin: 0,
				'&.Mui-expanded': {
					margin: 0,
				},
			},
		},
	},
	MuiAccordionDetails: {
		styleOverrides: {
			root: {
				padding: 0,
			},
		},
	},
	MuiPagination: {
		styleOverrides: {
			ul: {
				justifyContent: 'end',
			},
		},
	},
});
