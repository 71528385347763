import { useQuery } from '@tanstack/react-query';
import { FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { AnyObject } from 'yup';
import { queryKey } from '../../../constants/queryKey';
import SysConfigStore from '../../../store/common/SysConfigStore';
import InputSelect, { IDefaultOption } from '../../ui/inputs/InputSelect';

type Props = {
	formikId: string;
	formik: FormikValues;
	disabled?: boolean;
};

export default function PaperTypeSelector({ formikId, formik, disabled = false }: Props) {
	const { t } = useTranslation();
	const { getSysConfigValue } = SysConfigStore();
	const { data: paperTypes } = useQuery([queryKey.sysConfig, 'PAPER_TYPE'], (): IDefaultOption[] => {
		const data: AnyObject = JSON.parse(getSysConfigValue('PAPER_TYPE'));
		return Object.entries(data).map(([name, value]: string[]) => ({ id: value, name, value }));
	});

	return (
		paperTypes && (
			<InputSelect
				id={formikId}
				label={t('paper_manage_type')}
				options={paperTypes}
				formik={formik}
				disabled={disabled}
				required
			/>
		)
	);
}
