import _ from 'lodash';
import { alarmList } from '../model/alarm';
import { AppList, SignList } from '../model/list';
import { ITemplate, IWorkflow } from '../model/officialLetter';
import { ISealStamp } from '../model/sealStamp';
import { ExternalUser, User } from '../model/user';
import { dateFormat } from './dateFormat';

export const randName = () => {
	const hangeul =
		'김이박최정강조윤장임한오서신권황안송류전홍고문양손배조백허유남심노정하곽성차주우구신임나전민유진지엄채원천방공강현함변염양변여추노도소신석선설마주연방위표명기반왕모장남탁국여진구가강건경고관광구규근기길나남노누다단달담대덕도동두라래로루리마만명무문미민바박백범별병보사산상새서석선설섭성세소솔수숙순숭슬승시신아안애엄여연영예오옥완요용우원월위유윤율으은의이익인일자잔장재전정제조종주준중지진찬창채천철초춘충치탐태택판하한해혁현형혜호홍화환회효훈휘희운모배부림봉혼황량린을비솜공면탁온디항후려균묵송욱휴언들견추걸삼열웅분변양출타흥겸곤번식란더손술반빈실직악람권복심헌엽학개평늘랑향울련';

	return _.chain([])
		.range(3)
		.map(() => hangeul.charAt(Math.floor(Math.random() * hangeul.length)))
		.value()
		.join('');
};

export const randDepartment = () => {
	const departments = [
		'총무부',
		'경리부',
		'구매자재부',
		'품질관리부',
		'생산1팀',
		'생산2팀',
		'상품관리부',
		'기술부',
		'해외영업부',
		'국내영업부',
		'고객관리부',
		'개발부',
		'부설연구소',
		'인사부',
	];

	return departments[Math.floor(Math.random() * departments.length)];
};

export const randJobTitle = () => {
	const JobTitles = ['연구원', '수석연구원', '대리', '팀장', '인사팀장', '마켓팅팀장', '본부장', '과장', '변호사'];

	return JobTitles[Math.floor(Math.random() * JobTitles.length)];
};

export const randEmail = () => {
	const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
	const email = '@buptle.com';

	return (
		_.chain([])
			.range(Math.floor(Math.random() * 15 + 5))
			.map(() => chars.charAt(Math.floor(Math.random() * chars.length)))
			.value()
			.join('') + email
	);
};

export const randTitle = () => {
	const arr = [
		'임대차 계약서',
		'매매 계약서',
		'독점 판매 계약서',
		'공급 계약서',
		'서비스 제공 계약서',
		'협력 계약서',
		'프로젝트 계약서',
		'기술 이전 계약서',
		'라이선스 계약서',
		'고용 계약서',
		'비밀유지 계약서',
		'합작 투자 계약서',
		'위탁 운영 계약서',
		'대리점 계약서',
		'광고 계약서',
		'소프트웨어 개발 계약서',
		'연구 개발 계약서',
		'판매대리점 계약서',
		'유통 계약서',
		'유통 계약서 자문',
		'프랜차이즈 소송',
	];

	return _.chain([])
		.range(1)
		.map(() => arr[Math.floor(Math.random() * arr.length)])
		.take(1)
		.value()
		.join('');
};

export const randType = () => {
	const arr = ['계약', '자문', '소송', '수발신문서'];

	return _.chain([])
		.range(1)
		.map(() => arr[Math.floor(Math.random() * arr.length)])
		.take(1)
		.value()
		.join('');
};

export const randStatus = () => {
	const arr = ['초안', '검토', '서명및회수', '완료'];

	return _.chain([])
		.range(1)
		.map(() => arr[Math.floor(Math.random() * arr.length)])
		.take(1)
		.value()
		.join('');
};

export const randStatusDescription = () => {
	const description = [
		'부서장 반려',
		'부서장 승인',
		'서명 요청',
		'날인 요청',
		'최종승인',
		'최종반려',
		'검토반려',
		'검토승인',
		'계약완료',
	];

	return description[Math.floor(Math.random() * description.length)];
};

export const randWorkflowDescription = () => {
	const description = [
		'검토가 필요한 경우 진행되는 프로세스입니다.',
		'전자서명이 필요한 경우 진행되는 프로세스입니다.',
		'자동 날인이 필요한 경우 진행되는 프로세스입니다.',
		'부서장 승인 절차가 필요한 경우 진행되는 프로세스입니다.',
		'최종 승인 절차가 필요한 경우 진행되는 프로세스입니다.',
		'다중 수신 절차가 필요한 경우 진행되는 프로세스입니다.',
		'모든 절차가 필요한 경우 진행되는 프로세스입니다.',
		'',
	];

	return description[Math.floor(Math.random() * description.length)];
};

export const randImageUrl = () => {
	const imageUrl = ['/images/test.png', '/images/test_2.png', '/images/test_3.png', '/images/test_4.png'];
	return imageUrl[Math.floor(Math.random() * imageUrl.length)];
};

export const randBool = () => {
	return !!Math.floor(Math.random() * 2);
};

export const randDate = () => {
	const today = dateFormat();
	const lastYear = today.subtract(1, 'year');

	const randomTimestamp = Math.random() * (today.valueOf() - lastYear.valueOf()) + lastYear.valueOf();
	return dateFormat(randomTimestamp).format('YYYY-MM-DD HH:mm');
};

export const randUser = (id: number): User => {
	return {
		id,
		name: randName(),
		dept: randDepartment(),
		rank: randJobTitle(),
		email: randEmail(),
	};
};

export const randExternalUser = (): ExternalUser => {
	return {
		id: Number(_.uniqueId()),
		name: randName(),
		email: randEmail(),
	};
};

export const randAppList = (): AppList => {
	return {
		id: _.uniqueId(),
		view_code: `${_.uniqueId('202')}\n${_.uniqueId('000')}`,
		type: randType(),
		title: randTitle(),
		status: randStatus(),
		counterparty: _.chain([])
			.range(5)
			.map(() => randExternalUser())
			.take(Math.floor(Math.random() * 3 + 1))
			.value(),
		drafter: randUser(1),
	};
};

export const randAlarmList = (): alarmList => {
	return {
		id: _.uniqueId(),
		type: randType(),
		title: randTitle(),
		user: randUser(1),
		description: randStatusDescription(),
		checked: Math.floor(Math.random() * 2) === 1,
		createAt: randDate(),
	};
};

export const randSignList = (): SignList => {
	return {
		id: _.uniqueId(),
		imageUrl: randImageUrl(),
		canDrag: randBool(),
		status: randStatus(),
		title: randTitle(),
		user: randUser(1).name,
		date: randDate(),
		type: 'normal',
	};
};

export const randSealStampList = (): ISealStamp => {
	return {
		id: _.uniqueId(),
		name: `${randTitle()} 인감`,
		imageUrl: randImageUrl(),
		description: randWorkflowDescription(),
		user: randUser(Math.floor(Math.random() * 500) + 1),
		subsidiary: `회사 ${Math.floor(Math.random() * 500) + 1}`,
		isAuto: Math.floor(Math.random() * 2) === 1 || false,
		canDrag: true,
	};
};

export const randOfficialLetterWorkflowList = (index: number): IWorkflow => {
	let type;
	let createdBy;

	if (index < 3) type = index === 2 ? 2 : 1;
	if (index > 2) createdBy = randUser(Math.floor(Math.random() * 500) + 1);

	return {
		id: _.uniqueId(),
		title: randTitle(),
		description: randWorkflowDescription(),
		type,
		createdBy,
		statusCounts: {
			formatting: Math.floor(Math.random() * 3),
			inProgress: Math.floor(Math.random() * 100),
			completed: Math.floor(Math.random() * 100),
		},
	};
};

export const randOfficialLetterTemplateList = (): ITemplate => {
	return {
		id: _.uniqueId(),
		title: randTitle(),
		type: Math.floor(Math.random() * 7 + 1),
		file: { id: _.uniqueId(), name: randTitle() },
		formatting: Math.floor(Math.random() * 20),
		inProgress: Math.floor(Math.random() * 20),
		completed: Math.floor(Math.random() * 20),
	};
};
