import { useQuery } from '@tanstack/react-query';
import { getCompanyInfoSummary } from '../../../api/login';
import { DOMAIN_URL } from '../../../constants/common';
import { queryKey } from '../../../constants/queryKey';

export default function LogoIcon() {
	const { data: company, isLoading } = useQuery(queryKey.companyInfoSummary, getCompanyInfoSummary, {
		staleTime: 60000,
	});

	return (
		!isLoading && (
			<img
				className='object-contain w-auto h-[56px]'
				src={`${DOMAIN_URL}/files/${company?.logoLink}`}
				alt='logo'
			/>
		)
	);
}
