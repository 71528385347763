import { memo, useEffect, useMemo } from 'react';
import { PROCESS_NAME } from '../../../../constants/officialLetter';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import { IOfficialLetter, IProcess } from '../../../../model/officialLetter';
import { useProviderPage } from '../../../../pages/admin/officialLetter/workflow/ProviderPage';
import InputRadio from '../../../ui/inputs/InputRadio';

interface IProps {
	processId: string;
	data: IProcess;
}

function ItemProcessDraft({ processId, data }: IProps) {
	const { setOfficialLetter } = useProviderPage();
	const validationSchema = useMemo(
		() =>
			Object.entries(data)
				.filter(([key]) => key !== 'id' && key !== 'steps')
				.map(([key, value]) => [
					`${processId}_${key}`,
					{ initialValue: value === true ? '1' : '2', type: 'string', required: true },
				]),
		[],
	);

	const formik = useValidateForm({
		validationSchema: Object.fromEntries(validationSchema),
		onSubmit: v => {
			const entriesValue = Object.entries(v).map(([key, value]) => {
				const PropName = key.split('_')[1];

				return [PropName, value];
			});
			const values = Object.fromEntries(entriesValue);

			setOfficialLetter(
				prev =>
					({
						...prev,
						process: prev.process.map(item => {
							if (item.id === processId)
								return {
									...item,
									isApproved: values.isApproved === '1',
									steps: item.steps.map(step => {
										return step.type === 'approve'
											? {
													...step,
													options: {
														...step.options,
														isDeleted: !(values.isApproved === '1'),
													},
											  }
											: {
													...step,
											  };
									}),
								};

							return {
								...item,
							};
						}),
					}) as IOfficialLetter,
			);
		},
	});

	useEffect(() => {
		formik.handleSubmit();
	}, [formik.values]);

	return (
		<>
			<p>{PROCESS_NAME[data.id]}</p>
			<div className='flex-1 grid grid-cols-2 gap-4 px-4 py-2 rounded-md bg-slate-50'>
				<div className='flex items-center'>
					<p>초안 승인</p>
					<div className='flex'>
						<InputRadio
							id={`${processId}_isApproved_1`}
							name={`${processId}_isApproved`}
							value='1'
							label='필요'
							required
							formik={formik}
						/>
						<InputRadio
							id={`${processId}_isApproved_2`}
							name={`${processId}_isApproved`}
							value='2'
							label='불필요'
							required
							formik={formik}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default memo(ItemProcessDraft);
