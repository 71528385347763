import { Skeleton } from '@mui/material';
import { memo, useCallback } from 'react';
import { useProviderPage } from '../../../../pages/common/ProviderPage';
import WorkflowService from '../../../../service/admin/officialLetter/workflowService';
import ListPagination from '../../../ui/lists/ListPagination';
import ListSkeleton from '../../../ui/lists/ListSkeleton';
import ItemWorkflow from './ItemWorkflow';

function ListWorkflow() {
	const { data, isLoading } = WorkflowService.useList();
	const { getSearch, setSearch } = useProviderPage();
	const { page: defaultPage } = getSearch();

	const renderPage = useCallback(
		(p: number, rowsPerPage: number) => {
			if (isLoading || !data)
				return (
					<ListSkeleton
						isPagination={false}
						renderPage={key => (
							<Skeleton key={key} className='h-10' variant='rectangular' animation='wave' />
						)}
					/>
				);

			if (data.length === 0) return <div>워크플로우가 없습니다.</div>;

			const slicedData = data.slice((p - 1) * rowsPerPage, p * rowsPerPage);

			return slicedData.map(item => <ItemWorkflow key={item.id} data={item} />);
		},
		[data],
	);

	return (
		<>
			{!data && (
				<ListSkeleton
					renderPage={key => <Skeleton key={key} className='h-10' variant='rectangular' animation='wave' />}
				/>
			)}
			{data && (
				<div className='flex flex-col gap-4'>
					<ListPagination
						count={data.length}
						defaultPage={defaultPage}
						renderPage={renderPage}
						onChange={(e, page) => setSearch({ page })}
					/>
				</div>
			)}
		</>
	);
}

export default memo(ListWorkflow);
