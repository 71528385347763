import { useCallback } from 'react';
import { useAIReport } from '../../../../context/AIReportContext';
import ButtonText from '../../../ui/buttons/ButtonText';
import AIReportLeftAnalysisResult from './AIReportLeftAnalysisResult';
import AIReportLeftChatStyleSection from './AIReportLeftChatStyleSection';
import AIReportLeftFeedback from './AIReportLeftFeedback';

export default function AIReportLeftAnalysisArea() {
	const { changesExistHistory, data } = useAIReport();

	const handleScrollToPastReview = useCallback(() => {
		const targetElement = window.document.querySelector('#past-review-result') as HTMLElement;

		if (targetElement) {
			window.scrollTo({
				top: targetElement.offsetTop,
				behavior: 'smooth',
			});
		}
	}, []);

	return (
		<>
			<AIReportLeftChatStyleSection>
				<p className='whitespace-pre-line'>{data.greetings.split('. ').join('.\n')}</p>
			</AIReportLeftChatStyleSection>
			<AIReportLeftChatStyleSection>
				<div id='analysis-result' className='flex flex-col gap-5'>
					<AIReportLeftAnalysisResult />
					<AIReportLeftFeedback />
					{!!changesExistHistory.length && (
						<div className='flex justify-between items-center w-full py-3 px-6 rounded-md bg-neutral-100'>
							<p className='font-bold'>과거 검토중 수정이력이 있는 유사조항이 포함되어 있습니다.</p>
							<ButtonText className='text-m text-blue-600 underline' onClick={handleScrollToPastReview}>
								바로 확인
							</ButtonText>
						</div>
					)}
				</div>
			</AIReportLeftChatStyleSection>
		</>
	);
}
