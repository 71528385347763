import { useTranslation } from 'react-i18next';
import { DOMAIN_URL } from '../../../../constants/common';
import { useUserNoticeBoard } from '../../../../hooks/useUserNoiceBoard';
import { MenuData } from '../../../../model/header';
import SysConfigStore from '../../../../store/common/SysConfigStore';
import MenuItem1Depth from './MenuItem1Depth';

type Props = {
	openMenu: string | null;
	onToggle: (title: string) => void;
};
export default function HeaderRight({ openMenu, onToggle }: Props) {
	const { isComponentEnabled } = SysConfigStore();
	const { t } = useTranslation();
	const { getUserNoticeBoardList } = useUserNoticeBoard();
	const { data: noticeBoardList } = getUserNoticeBoardList;

	const menus: MenuData[] = [
		// 공지사항
		{
			link: `${DOMAIN_URL}/manage/get-notice-board-list-view/?announcement=1`,
			title: t('MSG_ID_1'),
			classes: { button: 'px-7 py-4 text-white' },
			display: true,
			isExternal: true,
		},
		// 게시판
		{
			title: t('label_notice_board'),
			classes: { button: 'px-7 py-4 text-white' },
			display: isComponentEnabled('CMPNT171_CUSTOM_NOTICE_BOARD_BF'),
			children: noticeBoardList
				? noticeBoardList.map(board => {
						return {
							link: `${DOMAIN_URL}/manage/get-notice-board-list-view/?board-id=${board.id}`,
							title: board.title,
							display: true,
							isExternal: true,
						};
				  })
				: [
						{
							title: t('notice_warn_there_is_no_notice_category_to_access'),
							display: true,
						},
				  ],
		},
		// 문서비교
		{
			link: `${DOMAIN_URL}/document-tools/dashboard-view/`,
			title: t('cmmn_label_document_comparison'),
			classes: { button: 'px-7 py-4 text-white' },
			display: isComponentEnabled('CMPNT150_MENU_DRAFTABLE_F'),
			isExternal: true,
		},
		// 전자인증서
		{
			link: `${DOMAIN_URL}/digital_certificate/digital_certificate_list_view/`,
			title: t('cmmn_label_digital_certificate'),
			classes: { button: 'px-7 py-4 text-white' },
			display: isComponentEnabled('CMPNT163_DIGITAL_CERTIFICATE_BF'),
			isExternal: true,
		},
	];

	return (
		<>
			{menus
				.filter(({ display }) => display)
				.map(menu => (
					<MenuItem1Depth
						key={menu.title}
						itemData={menu}
						isOpen={openMenu === menu.title}
						onToggle={() => onToggle(menu.title)}
					/>
				))}
		</>
	);
}
