import _, { uniqueId } from 'lodash';
import { ReactNode, memo } from 'react';
import Pagination from '../paginations/Pagination';

/**
 * Loading Pagination UI
 * @classes Style CSS
 * @range Number of skeletons
 * @renderPage Skeleton UI
 */
interface IProps {
	classes?: {
		root?: string;
	};
	range?: number;
	renderPage: (key: string) => ReactNode;
	isPagination?: boolean;
}

function ListSkeleton({ classes = { root: undefined }, range = 10, renderPage, isPagination = true }: IProps) {
	const { root = 'flex flex-col gap-4' } = classes;
	return (
		<div className={root}>
			{_.chain([])
				.range(range)
				.map(item => renderPage(uniqueId(`skeleton-${item}`)))
				.value()}

			{isPagination && <Pagination count={10} disabled />}
		</div>
	);
}

export default memo(ListSkeleton);
