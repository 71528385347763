import { useAIReport } from '../../../../context/AIReportContext';
import CircleIcon from '../../../ui/icons/CircleIcon';

export default function AIReportRightSummary() {
	const { data } = useAIReport();
	const { summary } = data;

	return (
		<ul className='flex flex-col gap-2'>
			<li className='flex items-start'>
				<p className='basis-1/5 flex items-center gap-2 text-neutral-600'>
					<CircleIcon className='text-xxs' />
					계약 당사자
				</p>
				<p className='flex-1 text-neutral-600'>{summary.contract_parties.join(', ')}</p>
			</li>
			<li className='flex items-start'>
				<p className='basis-1/5 flex items-center gap-2 text-neutral-600'>
					<CircleIcon className='text-xxs' />
					계약기간
				</p>
				<p className='flex-1 text-neutral-600'>{summary.period}</p>
			</li>
			<li className='flex items-start'>
				<p className='basis-1/5 flex items-center gap-2 text-neutral-600'>
					<CircleIcon className='text-xxs' />
					계약금액
				</p>
				<p className='flex-1 text-neutral-600'>{summary.amount}</p>
			</li>
			<li className='flex items-start'>
				<p className='basis-1/5 flex items-center gap-2 text-neutral-600'>
					<CircleIcon className='text-xxs' />
					지불조건
				</p>
				<p className='flex-1 text-neutral-600'>{summary.payment_conditions}</p>
			</li>
			<li className='flex items-start'>
				<p className='basis-1/5 flex items-center gap-2 text-neutral-600'>
					<CircleIcon className='text-xxs' />
					개요
				</p>
				<p className='flex-1 text-neutral-600'>{summary.overview}</p>
			</li>
		</ul>
	);
}
