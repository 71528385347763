import { useCallback } from 'react';
import { useAIReport } from '../../../../context/AIReportContext';
import ButtonText from '../../../ui/buttons/ButtonText';
import ChevronRightIcon from '../../../ui/icons/ChevronRight';
import CircleIcon from '../../../ui/icons/CircleIcon';

export default function AIReportRightScrollToAnalysis() {
	const { refMap } = useAIReport();

	const handleScroll = useCallback(() => {
		const element = window.document.querySelector('#analysis-result') as HTMLElement;
		const ref = refMap.leftContainerRef;
		if (element) {
			ref.current?.scrollTo({
				top: element.offsetTop - ref.current.offsetTop - 256,
				behavior: 'smooth',
			});
		}
	}, [refMap]);

	return (
		<ButtonText
			className='justify-between w-full shadow-none bg-neutral-100'
			variant='contained'
			onClick={handleScroll}
		>
			<div className='flex items-center gap-1'>
				<ChevronRightIcon className='text-neutral-500 -rotate-180' />
				<CircleIcon className='text-blue-600' />
				<p className='text-sm font-bold'>법틀 AI</p>
			</div>

			<p className='text-sm font-bold text-blue-600'>계약서 분석 결과</p>
		</ButtonText>
	);
}
