import { useQuery } from '@tanstack/react-query';
import { FormikValues } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEpicList } from '../../api/epic';
import { queryKey } from '../../constants/queryKey';
import { useEpicList } from '../../context/EpicContext';
import { useValidateForm } from '../../hooks/useValidateForm';
import { Epic } from '../../model/epic';
import { IHeadCell } from '../../model/table';
import ButtonText from '../ui/buttons/ButtonText';
import ScrollBox from '../ui/containers/ScrollBox';
import InputEpicPopupSelect from '../ui/inputs/InputEpicPopupSelect';
import PopupInternalState from '../ui/popups/PopupInternalState';
import TableSkeleton from '../ui/tables/TableSkeleton';
import TableEpicPopupList from './TableEpicPopupList';

type Props = {
	id: string;
	formik: FormikValues;
	disabled?: boolean;
	inputSize?: 'small' | 'medium';
};

export default function PopupEpic({ id, formik, disabled = false, inputSize = 'medium' }: Props) {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [closeSwitch, setCloseSwitch] = useState(false);
	const handleTogglePopup = () => setCloseSwitch(prev => !prev);
	const { searchParams } = useEpicList();
	const { data: paginationData } = useQuery(
		[queryKey.epic, queryKey.list, searchParams],
		() => getEpicList(searchParams),
		{ enabled: isOpen },
	);
	const selectedFormik = useValidateForm({
		validationSchema: {
			[id]: { initialValue: [], type: 'array' },
		},
	});
	const openTrigger = (
		<InputEpicPopupSelect
			id={id}
			label={t('label_project_selection')}
			formik={formik}
			size={inputSize}
			disabled={disabled}
		/>
	);

	const handleClick = () => {
		formik.setFieldValue(id, selectedFormik.values[id]);
		handleTogglePopup();
	};
	const headCells: IHeadCell<Epic>[] = [
		{
			id: 'id',
			align: 'left',
			disablePadding: true,
			disableSort: false,
			label: '',
		},
		{
			id: 'code',
			align: 'left',
			disablePadding: true,
			disableSort: false,
			label: t('epic_code'),
		},
		{
			id: 'name',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('MSG_ID_701'),
		},
		{
			id: 'manage_department_nmae',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('department'),
		},
		{
			id: 'create_time',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('MSG_ID_141'),
		},
		{
			id: 'owner',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('label_advisory'),
		},
		{
			id: 'contract_count',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('MSG_ID_5'),
		},
		{
			id: 'counsel_count',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('legal_counsel'),
		},
		{
			id: 'lawsuit_count',
			align: 'left',
			disablePadding: false,
			disableSort: false,
			label: t('MSG_ID_7'),
		},
	];

	return (
		<PopupInternalState
			onClick={() => setIsOpen(prev => !prev)}
			openTrigger={openTrigger}
			closeTrigger={closeSwitch}
			size='large'
		>
			<section className='flex flex-col gap-5'>
				<div>
					<h2 className='text-2xl font-bold'>{t('search_epic')}</h2>
				</div>
				<ScrollBox className='max-h-[70vh] xl:max-h-[77vh]'>
					{paginationData ? (
						<TableEpicPopupList
							id={id}
							list={paginationData.data}
							// orderBy='name'
							headCells={headCells}
							totalListCount={paginationData.recordsTotal}
							formik={formik}
							selectedFormik={selectedFormik}
						/>
					) : (
						<TableSkeleton colSpan={headCells.length + 1} />
					)}
				</ScrollBox>
				<div className='flex justify-center gap-5'>
					<ButtonText text={t('MSG_ID_105')} variant='contained' onClick={handleTogglePopup} />
					<ButtonText text={t('MSG_ID_104')} variant='contained' color='primary' onClick={handleClick} />
				</div>
			</section>
		</PopupInternalState>
	);
}
