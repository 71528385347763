import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRecentNotice, updateDisabledNoticeList } from '../../../api/notice';
import { queryKey } from '../../../constants/queryKey';
import ButtonText from '../../ui/buttons/ButtonText';
import DrawHtml from '../../ui/commons/DrawHtml';
import PopupDefault from '../../ui/popups/PopupDefault';

export default function RecentNoticePopup() {
	const [popupVisible, setPopupVisible] = useState(false);
	const { data } = useQuery(queryKey.RecentNotice, getRecentNotice, { staleTime: 0 });
	const { t } = useTranslation();

	useEffect(() => {
		if (data) {
			const { id } = data;
			// 2024.03.11 공지 팝업 비활성화 여부 데이터 LocalStorage 조회에서 DB 조회로 변경
			// const BNOTICE_DISABLED_STORAGE_VAL = localStorage.getItem('BNOTICE_DISABLED');
			// if (!!id && BNOTICE_DISABLED_STORAGE_VAL !== id) {
			// 	setPopupVisible(true);
			// }
			if (!!id && !data.is_disabled) {
				setPopupVisible(true);
			}
		}
	}, [data]);

	if (!data || !data.id) {
		return null;
	}

	const { id, title, content } = data;

	const handleFirstClose = () => {
		setPopupVisible(false);
	};

	const disableNoticePopup = () => {
		// localStorage.setItem('BNOTICE_DISABLED', id);
		updateDisabledNoticeList(id);
		setPopupVisible(false);
	};

	return (
		<PopupDefault open={popupVisible} onClose={handleFirstClose}>
			<>
				<span className='text-center'>{t('MSG_ID_685')}</span>
				<div>
					<h4 className='text-center'>{title}</h4>
					<br />
				</div>
				<DrawHtml html={content || ''} />
				<br />
				<div className='flex justify-center space-x-6 '>
					<ButtonText
						text={t('cmmn_guide_this_announcement_does_not_float_again')}
						color='error'
						variant='outlined'
						onClick={disableNoticePopup}
					/>
					<ButtonText text={t('MSG_ID_856')} variant='outlined' onClick={handleFirstClose} />
				</div>
			</>
		</PopupDefault>
	);
}
