import CalendarDashboard from '../../../../components/dashboard/custom/sk/calendar/CalendarDashboard';
import HeaderDashboard from '../../../../components/dashboard/custom/sk/header/HeaderDashboard';
import AccodionChartDashboard from '../../../../components/dashboard/custom/sk/main/AccodionChartDashboard';
import ContractCountDashboard from '../../../../components/dashboard/custom/sk/main/ContractCountDashboard';
import CounselCountDashboard from '../../../../components/dashboard/custom/sk/main/CounselCountDashboard';
import TabsDashboard from '../../../../components/dashboard/custom/sk/main/TabsDashboard';
import CardBox from '../../../../components/ui/containers/CardBox';
import { useUser } from '../../../../context/UserContext';

export default function DashboardPage() {
	const { permission } = useUser();

	return (
		<section className='flex flex-col gap-5 p-8 max-w-[1920px] mx-auto'>
			<HeaderDashboard />
			<section className='flex flex-col gap-5 lg:flex-row'>
				<div className='basis-4/5 flex flex-col gap-5'>
					{permission.DISPLAY_DASHBOARD_STATUS_COUNT && (
						<div className='flex flex-col gap-5 xl:flex-row'>
							<ContractCountDashboard />
							<CounselCountDashboard />
						</div>
					)}
					{permission.DISPLAY_DASHBOARD_STATISTICS && <AccodionChartDashboard />}
					<div className='bg-transparent'>
						<TabsDashboard />
					</div>
				</div>

				<CardBox className='basis-1/5 p-5'>
					<CalendarDashboard />
				</CardBox>
			</section>
		</section>
	);
}
