import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../../hooks/useAlert';
import { DashboardWorkStatus } from '../../../model/api/dashboard';
import { getLegalLinkMap, navigateToAdele } from '../../../util/commonUtils';
import { getTodayFormatToHour } from '../../../util/dateFormat';
import ButtonText from '../../ui/buttons/ButtonText';
import StateChip from '../../ui/chips/StateChip';
import DisputeIcon from '../../ui/icons/DisputeIcon';
import StampIcon from '../../ui/icons/StampIcon';
import ContractIcon from '../../ui/icons/apps/ContractIcon';
import CounselIcon from '../../ui/icons/apps/CounselIcon';
import DocIcon from '../../ui/icons/apps/DocIcon';
import LawsuitIcon from '../../ui/icons/apps/LawsuitIcon';
import ListItemActionDashboard from './ListItemActionDashboard';
import ListItemRolesDashboard from './ListItemRolesDashboard';

const getIcon = (type: DashboardWorkStatus['history_type']) => {
	let icon: React.ReactNode;
	switch (type) {
		case 'CONTRACT':
			icon = <ContractIcon className='text-cyan-600' size='large' />;
			break;
		case 'COUNSEL':
			icon = <CounselIcon className='text-teal-500' size='large' />;
			break;
		case 'LAWSUIT':
			icon = <LawsuitIcon className='text-yellow-500' size='large' />;
			break;
		case 'DOC_RECEIVED':
			icon = <DocIcon className='text-neutral-500' size='large' />;
			break;
		case 'STAMP':
			icon = <StampIcon className='text-sky-700' size='large' />;
			break;
		case 'DISPUTE':
			icon = <DisputeIcon className='text-teal-700' size='large' />;
			break;
		case 'CONTRACT_ETC': // TODO 작업 필요
			icon = <DocIcon className='text-neutral-500' size='large' />;
			break;
		case 'CONTRACT_SIGN_SEAL_PROCESS': // TODO 작업 필요
			icon = <DocIcon className='text-neutral-500' size='large' />;
			break;
		default:
	}
	return icon;
};

const getTypeName = (type: DashboardWorkStatus['history_type']) => {
	const { t } = useTranslation();
	let result: string = '';
	switch (type) {
		case 'CONTRACT':
			result = t('MSG_ID_5');
			break;
		case 'COUNSEL':
			result = t('label_notification_app_code_counsel');
			break;
		case 'LAWSUIT':
			result = t('MSG_ID_7');
			break;
		case 'DOC_RECEIVED':
			result = t('MSG_ID_46');
			break;
		case 'STAMP':
			result = t('label_seal');
			break;
		case 'DISPUTE':
			result = t('MSG_ID_1203');
			break;
		case 'CONTRACT_ETC':
			result = t('label_etc_contract');
			break;
		case 'CONTRACT_SIGN_SEAL_PROCESS':
			result = t('label_contract_sign_seal_process');
			break;
		default:
	}
	return result;
};

type Props = {
	item: DashboardWorkStatus;
};

export default function ListItemDashboard({ item }: Props) {
	const [snackbar] = useAlert();
	const { t } = useTranslation();
	const {
		history_type: workType,
		action_type: actionType,
		action_text: actionText,
		action_user: actionUser,
		create_time: createAt,
		title,
		comment,
		status,
		roles,
	} = item;

	const isComment =
		(workType === 'CONTRACT' && actionType === '6') || (workType === 'COUNSEL' && actionType === 'COMMENT');

	const handleNavigate = async (id: number, type: DashboardWorkStatus['history_type']) => {
		const { success, url, msg } = await getLegalLinkMap(type, id);
		if (success) {
			navigateToAdele(url);
		} else {
			snackbar(msg, 'error');
		}
	};

	const actionTextConfig = {
		CONTRACT: (
			<span>
				<strong>{actionUser}</strong>
				{t('cmmn_label_sir')} {actionText}
			</span>
		),
		COUNSEL: (
			<span>
				<strong>{actionUser}</strong>
				{t('cmmn_label_sir')} {actionText}
			</span>
		),
		LAWSUIT: <strong>[{actionText}]</strong>,
		DOC_RECEIVED: <strong>[{actionText}]</strong>,
		CONTRACT_ETC: (
			<span>
				<strong>{actionUser}</strong>
				{t('cmmn_label_sir')} {actionText}
			</span>
		),
		CONTRACT_SIGN_SEAL_PROCESS: (
			<span>
				<strong>{actionUser}</strong>
				{t('cmmn_label_sir')} {actionText}
			</span>
		),
	};

	return (
		<li className='w-full border-b border-neutral-300 last-of-type:border-none'>
			<ButtonText className='w-full p-8' onClick={() => handleNavigate(item.related_id, item.history_type)}>
				<div className='flex justify-between w-full gap-10'>
					<div className='self-center flex flex-col items-center gap-2 w-10'>
						{getIcon(workType)}
						<p className='text-sm text-neutral-600'>{getTypeName(workType)}</p>
					</div>
					<div className='flex-1 text-left'>
						<div className='flex items-center gap-6'>
							<p className='font-bold'>{title}</p>
							<StateChip text={status} />
						</div>
						{!isComment && (
							<div className='flex flex-col gap-1 px-6 pt-6'>
								<p className='text-neutral-600'>
									<ListItemActionDashboard item={item} />
								</p>
								<p className='text-neutral-600'>{workType === 'LAWSUIT' ? comment : ''}</p>
							</div>
						)}
						{isComment && (
							<div className='flex flex-col gap-1 px-6 pt-6'>
								<p className='text-neutral-600'>
									<strong>{actionUser}</strong>
									{t('cmmn_label_sir')} {actionText}
								</p>
							</div>
						)}
					</div>
					<div className='flex flex-col flex-wrap items-end gap-1'>
						<p className='text-sm text-neutral-500 pr-1'>{getTodayFormatToHour(createAt)}</p>
						<div className='flex flex-col items-end'>
							<ListItemRolesDashboard roles={roles} />
						</div>
					</div>
				</div>
			</ButtonText>
		</li>
	);
}
