import { memo } from 'react';
import { STATIC_DIR } from '../../../../constants/common';
import { OFFICIALLETTER_TYPE } from '../../../../constants/officialLetter';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import { ISealStamp } from '../../../../model/sealStamp';
import EditorDext5 from '../../../common/editors/EditorDext5';
import PopupSealStamps from '../../../common/popups/PopupSealStamps';
import PopupUsers from '../../../common/popups/PopupUsers';
import ButtonText from '../../../ui/buttons/ButtonText';
import CardBox from '../../../ui/containers/CardBox';
import InputCheckbox from '../../../ui/inputs/InputCheckbox';
import InputFile from '../../../ui/inputs/InputFile';
import InputLabel from '../../../ui/inputs/InputLabel';
import InputRadio from '../../../ui/inputs/InputRadio';
import InputSelect from '../../../ui/inputs/InputSelect';
import InputText from '../../../ui/inputs/InputText';
import PopupSignNotification from './PopupSignNotification';

function ContainerDetail() {
	const formik = useValidateForm({
		validationSchema: {
			title: { initialValue: '', type: 'string' },
			type: { initialValue: ['1'], type: 'array', required: true },
			user: { initialValue: [], type: 'array' },
			workflow: { initialValue: '', type: 'string' },
			documentType: { initialValue: '', type: 'string' },
			autoSign: { initialValue: false, type: 'boolean' },
			sealStamp: { initialValue: [], type: 'array' },
			files: { initialValue: [], type: 'array' },
		},
		onSubmit: values => {},
	});

	const fileButton = (
		<ButtonText
			classes={{ root: 'min-w-0 py-0 font-bold border-2 border-gray-300 rounded-2xl' }}
			text='+'
			color='inherit'
			variant='outlined'
		/>
	);
	return (
		<CardBox className='flex flex-col gap-4 p-6'>
			<header className='pb-4 border-b border-neutral-300'>
				<h2 className='text-xl'>공문 서식 설정</h2>
			</header>
			<section>
				<ul className='grid gap-4'>
					<li className='grid grid-cols-2 gap-4'>
						<InputText id='title' labelText='서식명' required formik={formik} />
						<InputSelect
							id='type'
							label='문서종류'
							options={Object.entries(OFFICIALLETTER_TYPE).map(type => ({
								name: type[1],
								value: type[0],
							}))}
							required
							formik={formik}
						/>
					</li>
					<li className='grid grid-cols-2 gap-4'>
						<PopupUsers id='user' label='참조인' formik={formik} />

						<InputSelect
							id='workflow'
							label='워크플로우'
							options={[
								{ name: '워크플로우1', value: '1' },
								{ name: '워크플로우2', value: '2' },
								{ name: '워크플로우3', value: '3' },
								{ name: '선택안함(생성시 선택)', value: '4' },
							]}
							required
							formik={formik}
						/>
					</li>
					<li className='grid grid-cols-2 items-center gap-4'>
						<div className='flex items-center gap-4'>
							<InputLabel htmlFor='documentType-1'>공문서 형식</InputLabel>
							<div className='flex items-center'>
								<InputRadio
									id='documentType-1'
									name='documentType'
									value='1'
									label='직접 업로드'
									formik={formik}
								/>
								<InputRadio
									id='documentType-2'
									name='documentType'
									value='2'
									label='웹에디터'
									formik={formik}
								/>
							</div>
						</div>
						<div className='flex items-center gap-4'>
							<InputCheckbox id='autoSign' label='자동날인 지원' formik={formik} />
							<PopupSignNotification />
						</div>
					</li>
					<li className='grid grid-cols-6 items-center gap-4'>
						<div className='col-span-2'>
							<PopupSealStamps
								id='sealStamp'
								label='법인 도장'
								maxSelected={1}
								popupSize='large'
								formik={formik}
							/>
						</div>
						<div className='flex items-center justify-center h-full px-3 rounded-md border border-neutral-600'>
							{formik.values.sealStamp.length > 0 && (
								<img
									src={(formik.values.sealStamp[0] as ISealStamp).imageUrl}
									alt={(formik.values.sealStamp[0] as ISealStamp).name}
								/>
							)}
						</div>
						<div>
							<img className=' w-full' src={`${STATIC_DIR}/images/img-stamp-default.png`} alt='Stamp' />
						</div>
						<div className='col-span-2 flex flex-col gap-4'>
							<ButtonText text='서명인식 이미지 다운로드' variant='outlined' />
							<ButtonText text='예제 파일 다운로드' variant='outlined' />
						</div>
					</li>
					<li>
						<InputFile
							id='files'
							classes={{ root: 'flex flex-col gap-2' }}
							formik={formik}
							button={fileButton}
							dragDrop
						/>
					</li>
					<li>
						<EditorDext5 id='editor' mode='edit' />
					</li>
					<li className='flex justify-center gap-4'>
						<ButtonText text='취소' variant='outlined' />
						<ButtonText text='저장' variant='contained' color='primary' />
					</li>
				</ul>
			</section>
		</CardBox>
	);
}

export default memo(ContainerDetail);
