import { memo } from 'react';
import CardBox from '../../../ui/containers/CardBox';
import ButtonTemplate from './ButtonTemplate';
import ListTemplate from './ListTemplate';

function ContainerList() {
	return (
		<CardBox className='flex flex-col gap-4 p-6'>
			<header className='flex items-center justify-between pb-4 border-b border-neutral-300'>
				<div>
					<h2 className='text-xl'>공문 서식 관리</h2>
					<p className='mt-2 text-sm text-gray-600'>공문 맞춤형 서식을 관리할 수 있습니다.</p>
				</div>
				<div>
					<ButtonTemplate text='+ 새 서식' variant='contained' color='primary' />
				</div>
			</header>
			<section>
				<ListTemplate />
			</section>
		</CardBox>
	);
}

export default memo(ContainerList);
