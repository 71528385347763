import { memo } from 'react';
import CardBox from '../../../ui/containers/CardBox';
import ButtonWorkflow from './ButtonWorkflow';
import ListWorkflow from './ListWorkflow';

function ContainerWorkflow() {
	return (
		<CardBox className='flex flex-col gap-4 p-6'>
			<header className='flex justify-between pb-4 border-b border-neutral-300'>
				<div>
					<h2 className='text-xl'>공문 워크플로우 설정</h2>
					<p className='mt-2 text-sm text-gray-600'>공문의 프로세스를 관리하고 저장합니다.</p>
				</div>
				<div>
					<ButtonWorkflow text='+ 새 워크플로우' variant='contained' color='primary' />
				</div>
			</header>
			<section>
				<ListWorkflow />
			</section>
		</CardBox>
	);
}

export default memo(ContainerWorkflow);
