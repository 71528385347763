import React, { useRef, useEffect } from 'react';

type Props = {
	html: string;
};

export default function DrawHtml({ html }: Props) {
	const iframeRef = useRef<HTMLIFrameElement>(null);

	useEffect(() => {
		if (iframeRef.current) {
			const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow?.document;
			if (iframeDoc) {
				iframeDoc.body.innerHTML = html;
			}
		}
	}, [html]);

	// eslint-disable-next-line jsx-a11y/iframe-has-title
	return <iframe ref={iframeRef} style={{ width: '100%', height: '100%', border: 'none' }} className='h-96' />;
}
