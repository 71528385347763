import { useCallback, useMemo } from 'react';
import { Divider } from '@mui/material';
import { AI_ARTICLE_TITLE_REGEX } from '../../../../constants/aiConstans';
import { useAIReport } from '../../../../context/AIReportContext';
import ButtonText from '../../../ui/buttons/ButtonText';
import { IStoreArticle } from '../../../../model/api/ai';

export default function AIReportRightArticleNumbers() {
	const { data, focusState, setFocusState } = useAIReport();
	const { articles } = data;

	const findArticleElById = useCallback(
		(articleId: number) => {
			const newElement = window.document.querySelector(`#article-${articleId}`) as HTMLElement;
			setFocusState({
				article: {
					id: articleId,
					element: newElement,
				},
				clause: null,
				scroll: true,
			});
		},
		[setFocusState],
	);

	const displayOrder = useCallback((text: string) => {
		const matches = text?.match(AI_ARTICLE_TITLE_REGEX);

		if (!matches) {
			return null;
		}

		return matches[0].replaceAll(' ', '');
	}, []);

	const processedArticles = useMemo(() => {
		let hasSeenOne = false;

		return articles.reduce<(IStoreArticle | { type: 'separator'; key: string })[]>((acc, article, index) => {
			const currentNumber = Number(displayOrder(article.title)?.match(/^\d+/));

			if (currentNumber === 1) {
				if (hasSeenOne) {
					acc.push({ type: 'separator', key: `separator-${index}` });
				} else {
					hasSeenOne = true;
				}
			}
			acc.push(article);

			return acc;
		}, []);
	}, [articles]);

	return (
		<div>
			{processedArticles.map(item => {
				if ('type' in item && item.type === 'separator') {
					return <Divider key={item.key} />;
				}

				const article = item as IStoreArticle;
				return (
					<ButtonText
						key={article.id}
						className={`min-w-min text-base ${
							focusState?.article?.id === article.id ? 'text-blue-600 font-bold' : 'text-neutral-600'
						}`}
						onClick={() => findArticleElById(article.id)}
					>
						{displayOrder(article.title) || `${article.id}조`}
						<span
							className={`absolute left-0 right-0 bottom-0 h-0.5 ${
								focusState?.article?.id === article.id ? 'bg-blue-600' : ''
							}`}
						/>
					</ButtonText>
				);
			})}
		</div>
	);
}
