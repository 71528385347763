import { Pagination as MuiPagination, PaginationProps } from '@mui/material';
import { memo } from 'react';

interface Props extends PaginationProps {}

function Pagination({ showFirstButton = true, showLastButton = true, ...props }: Props) {
	return <MuiPagination showFirstButton={showFirstButton} showLastButton={showLastButton} {...props} />;
}

export default memo(Pagination);
