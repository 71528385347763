import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { checkUserAuth } from '../../api/login';
import { DOMAIN_URL } from '../../constants/common';
import { queryKey } from '../../constants/queryKey';
import { UserProvider } from '../../context/UserContext';
import sysConfigStore from '../../store/common/SysConfigStore';
import ProviderPage from './ProviderPage';

type Props = {
	children: React.ReactNode;
};

export default function ProtectedRoute({ children }: Props) {
	const { isModuleEnabled } = sysConfigStore();
	const { data: loginUser, isLoading } = useQuery(queryKey.loginUser, checkUserAuth, { cacheTime: 0 });

	if (!isLoading && !loginUser) {
		if (isModuleEnabled('SAML_SSO_LOGIN')) {
			window.location.href = `${DOMAIN_URL}/login/?next=/`;
		} else if (isModuleEnabled('SSO_ENABLED')) {
			window.location.href = `${DOMAIN_URL}/login/?next=/`;
		} else if (isModuleEnabled('SSO_LOGIN_PAGE')) {
			return <Navigate to='/login_sso' replace />;
			// window.location.href = `${DOMAIN_URL}/login_sso`;
		} else {
			return <Navigate to='/login' replace />;
		}
	}

	if (loginUser) {
		return (
			<UserProvider key={loginUser.id} user={loginUser}>
				<ProviderPage>{children}</ProviderPage>
			</UserProvider>
		);
	}
}
