import axios from 'axios';
import download from 'downloadjs';
import _ from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { pdfData } from '../model/api/contract';
import { Sign } from '../model/sign';

/**
 * PDF URL Download
 */
export async function downloadPdfWithImage(pdfUrl: string, signs: Sign[]) {
	const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
	const pdfDoc = await PDFDocument.load(existingPdfBytes);

	await Promise.all(
		_.chain(signs)
			.map(async sign => {
				const { pageIndex, imageUrl, width, height, x, y } = sign;
				const imageBytes = await fetch(imageUrl).then(res => res.arrayBuffer());
				const image = await pdfDoc.embedPng(imageBytes);
				const page = pdfDoc.getPage(pageIndex);
				const { height: pageHeight } = page.getSize();

				page.drawImage(image, {
					x,
					y: pageHeight - height - y,
					width,
					height,
				});
			})
			.value(),
	);

	const pdfBytes = await pdfDoc.save();

	download(pdfBytes, 'modify_test.pdf', 'application/pdf');
}

/**
 * PDF URL Download (간인용)
 */
interface IOptions {
	position: 'left' | 'right' | 'both';
}
export async function downloadPdfWithFixedImage(pdf: Uint8Array, signs: Sign[], options: IOptions) {
	const pdfDoc = await PDFDocument.load(pdf);
	const { position } = options;

	await Promise.all(
		_.chain(signs)
			.map(async sign => {
				const { pageIndex, imageUrl, width, height, x, y } = sign;
				const imageBytes = await fetch(imageUrl).then(res => res.arrayBuffer());
				const image = await pdfDoc.embedPng(imageBytes);
				const page = pdfDoc.getPage(pageIndex);
				const { width: pageWidth, height: pageHeight } = page.getSize();
				if (position === 'left' || position === 'both') {
					page.drawImage(image, {
						x: -width / 2,
						y: pageHeight - height - y,
						width,
						height,
					});
				}

				if (position === 'right' || position === 'both') {
					page.drawImage(image, {
						x: pageWidth - width / 2,
						y: pageHeight - height - y,
						width,
						height,
					});
				}
			})
			.value(),
	);

	const pdfBytes = await pdfDoc.save();

	return download(pdfBytes, `modify_test_${position}.pdf`, 'application/pdf');
}

/**
 * PDF Uint8Array
 */
export async function addImageToPdf(pdfObject: pdfData, signs: Sign[]) {
	const pdfDoc = await PDFDocument.load(pdfObject.file);

	await Promise.all(
		_.chain(signs)
			.map(async sign => {
				const { pageIndex, imageUrl, width, height, x, y } = sign;

				const imageRes = await axios.get(imageUrl, { responseType: 'arraybuffer' });
				const imageBytes = imageRes.data;
				const imageExtension = imageUrl.split('.').pop()!.toUpperCase();

				const image =
					imageExtension === 'PNG' ? await pdfDoc.embedPng(imageBytes) : await pdfDoc.embedJpg(imageBytes);

				const page = pdfDoc.getPage(pageIndex);
				const { height: pageHeight } = page.getSize();

				page.drawImage(image, {
					x,
					y: pageHeight - height - y,
					width,
					height,
				});
			})
			.value(),
	);

	const pdfBytes = await pdfDoc.save();

	return pdfBytes;
}
