import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAIArticle } from '../../../../context/AIArticleContext';
import { IArticleHistoryItem } from '../../../../model/api/ai';
import TooltipDefault from '../../../ui/tooltips/TooltipDefault';
import AIArticleTooltip from '../../common/AIArticleTooltip';

type Props = {
	data: IArticleHistoryItem[];
};

export default function AIArticleSideRecentItemsDisplay({ data }: Props) {
	const { id } = useParams();
	const navigate = useNavigate();
	const { setDetailState } = useAIArticle();

	const extractArticle = useCallback((item: IArticleHistoryItem) => {
		const { history_map: historyMap, index: seenIndex } = item;
		return historyMap?.article_histories[seenIndex].article;
	}, []);

	const handleNavigate = useCallback(
		(item: IArticleHistoryItem) => {
			const article = extractArticle(item);
			if (!article || Number(id) === article?.id) {
				return;
			}
			setDetailState(article.id, item);
			navigate(`/buptle_ai/article/detail/${article.id}`);
		},
		[setDetailState, id],
	);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>, item: IArticleHistoryItem) => {
			if (e.key === 'Enter' || e.key === ' ') {
				handleNavigate(item);
			}
		},
		[handleNavigate],
	);

	return (
		<div className='flex flex-col gap-3'>
			{data.map((item, index) => {
				const article = extractArticle(item);

				if (!article) {
					return null;
				}

				return (
					// eslint-disable-next-line react/no-array-index-key
					<TooltipDefault key={index} title={<AIArticleTooltip article={article} />}>
						<div
							role='button'
							tabIndex={0}
							className='truncate-2 text-neutral-600 cursor-pointer'
							onClick={() => handleNavigate(item)}
							onKeyDown={e => handleKeyDown(e, item)}
						>
							<div>
								<p>{article?.title}</p>
								{article.clauses.map(clause => clause.content).join(' ')}
							</div>
						</div>
					</TooltipDefault>
				);
			})}
		</div>
	);
}
