import { ReactNode, memo } from 'react';

type TSize = 'medium' | 'large' | 'full';

interface IProps {
	size?: TSize;
	className?: string;
	children: ReactNode;
}

const getStyled = (size: TSize): string => {
	let styled = '';

	switch (size) {
		case 'medium':
			styled = 'max-w-full w-full p-[30px] lg:px-0 lg:py-[30px] lg:mx-auto lg:max-w-4xl';
			break;
		case 'large':
			styled = 'max-w-full w-full p-[30px] xl:px-0 xl:py-[30px] xl:mx-auto xl:max-w-5xl';
			break;
		case 'full':
			styled = 'max-w-full w-full p-[30px]';
			break;
		default:
	}

	return styled;
};

function Container({ className = '', size = 'full', children }: IProps) {
	return <section className={`${getStyled(size)} ${className}`}>{children}</section>;
}

export default memo(Container);
