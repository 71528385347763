import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function ViewStreamIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M0 18.4286V15.8571C0 14.4429 1.2 13.2857 2.66667 13.2857H21.3333C22.8 13.2857 24 14.4429 24 15.8571V18.4286C24 19.8429 22.8 21 21.3333 21H2.66667C1.2 21 0 19.8429 0 18.4286ZM0 5.57143V8.14286C0 9.55714 1.2 10.7143 2.66667 10.7143H21.3333C22.8 10.7143 24 9.55714 24 8.14286V5.57143C24 4.15714 22.8 3 21.3333 3H2.66667C1.2 3 0 4.15714 0 5.57143Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
