import { ReactNode, createContext, useCallback, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface IProps {
	children: ReactNode;
}

interface ISearch {
	page: number;
}

interface IProvider {
	getSearch: () => ISearch;
	setSearch: (search: ISearch) => void;
}

const defaultSearch = {
	page: 1,
};

const context = createContext<IProvider | undefined>(undefined);

/**
 * 모든 페이지 공통 provicder
 * 현재는 page number 만 구현 상태
 */
export default function ProviderPage({ children }: IProps) {
	const navigate = useNavigate();

	const getSearch = useCallback(() => {
		const location = useLocation();
		const searchParams = new URLSearchParams(location.search);
		const page = Number(searchParams.get('page')) || 1;
		return { page };
	}, []);

	const setSearch = useCallback((search: ISearch = defaultSearch) => {
		const { page } = search;
		navigate(`?page=${page}`);
	}, []);

	const value = useMemo(() => ({ getSearch, setSearch }), []);

	return <context.Provider value={value}>{children}</context.Provider>;
}

export const useProviderPage = () => {
	const result = useContext(context);
	if (!result) throw new Error(`common context is undefined`);
	return result;
};
