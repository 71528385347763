import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import { MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sign } from '../../../../model/sign';
import { IHeadCell, TOrder } from '../../../../model/table';
import ButtonText from '../../../ui/buttons/ButtonText';
import ClearIcon from '../../../ui/icons/ClearIcon';
import TableSelectedSignHeader from './TableSelectedSignHeader';

type Props = {
	list: Sign[];
	orderBy?: keyof Sign;
	headCells: readonly IHeadCell<Sign>[];
	onDeleteSign?: (e: MouseEvent<HTMLButtonElement>, signId: string) => void;
	onClick?: (e: MouseEvent<HTMLTableRowElement>, pageIndex: number) => void;
};

export default function TableSelectedSignList({ list, orderBy: ob, headCells, onClick, onDeleteSign }: Props) {
	const { t } = useTranslation();

	const [order, setOrder] = useState<TOrder>('asc');
	const [orderBy, setOrderBy] = useState(ob);

	const handleRequestSort = (e: MouseEvent<unknown>, property: keyof Sign) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	// 현재 page 와 order 에 맞는 rows slice
	const visibleRows = useMemo(() => {
		if (!orderBy) return { rows: list };
		const orderArr = order === 'asc' ? _.chain(list).sortBy(orderBy) : _.chain(list).sortBy(orderBy).reverse();

		return {
			rows: orderArr.value(),
		};
	}, [order, orderBy, list]);

	return (
		<section>
			<TableContainer className='overflow-visible'>
				<Table size='medium'>
					<TableSelectedSignHeader
						headCells={headCells}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
					/>
					<TableBody>
						{visibleRows.rows.length === 0 && (
							<TableRow>
								<TableCell colSpan={headCells.length + 1} classes={{ root: 'text-center' }}>
									{t('cmmn_warn_no_data')}
								</TableCell>
							</TableRow>
						)}
						{visibleRows.rows.length > 0 &&
							visibleRows.rows.map((row, index) => {
								return (
									<TableRow
										hover
										tabIndex={-1}
										key={row.id}
										classes={{
											root: `${onClick ? 'cursor-pointer' : ''}`,
										}}
										onClick={e => {
											if (onClick) onClick(e, row.pageIndex);
										}}
									>
										<TableCell align='left'>
											<img className='pointer-events-none' src={row.imageUrl} alt={row.name} />
										</TableCell>
										<TableCell align='left'>
											{Math.floor(row.width)} * {Math.floor(row.height)}
										</TableCell>
										<TableCell align='left'>{row.name}</TableCell>
										<TableCell align='left'>{row.pageIndex + 1}</TableCell>
										<TableCell align='left'>
											<ButtonText
												className='min-w-0 p-0 hover:cursor-pointer'
												onClick={e => {
													e.stopPropagation();
													if (onDeleteSign) onDeleteSign(e, row.id as string);
												}}
											>
												<ClearIcon />
											</ButtonText>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
		</section>
	);
}
