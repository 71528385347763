import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function SummarizeIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M16.7867 0.786667C16.28 0.28 15.6 0 14.8933 0H2.66667C1.2 0 0.0133333 1.2 0.0133333 2.66667L0 21.3333C0 22.8 1.18667 24 2.65333 24H21.3333C22.8 24 24 22.8 24 21.3333V9.10667C24 8.4 23.72 7.72 23.2133 7.22667L16.7867 0.786667ZM6.66667 18.6667C5.93333 18.6667 5.33333 18.0667 5.33333 17.3333C5.33333 16.6 5.93333 16 6.66667 16C7.4 16 8 16.6 8 17.3333C8 18.0667 7.4 18.6667 6.66667 18.6667ZM6.66667 13.3333C5.93333 13.3333 5.33333 12.7333 5.33333 12C5.33333 11.2667 5.93333 10.6667 6.66667 10.6667C7.4 10.6667 8 11.2667 8 12C8 12.7333 7.4 13.3333 6.66667 13.3333ZM6.66667 8C5.93333 8 5.33333 7.4 5.33333 6.66667C5.33333 5.93333 5.93333 5.33333 6.66667 5.33333C7.4 5.33333 8 5.93333 8 6.66667C8 7.4 7.4 8 6.66667 8ZM14.6667 8V2L22 9.33333H16C15.2667 9.33333 14.6667 8.73333 14.6667 8Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
