import { Toolbar } from '@mui/material';
import { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
	numSelected?: number;
	children: ReactNode;
}

function TableSearchBar({ numSelected = 0, children }: IProps) {
	const { t } = useTranslation();

	return (
		<Toolbar classes={{ root: 'flex-col px-0 min-h-0' }}>
			{children}
			{numSelected > 0 && (
				<div className='z-10 absolute bottom-0 translate-y-[120%] w-full'>
					<p>
						{numSelected} {t('cmmn_delete_and_recovery_select')}
					</p>
				</div>
			)}
		</Toolbar>
	);
}

export default memo(TableSearchBar);
