import React, { useCallback, useMemo } from 'react';
import PopoverDefault from '../../../ui/popovers/PopoverDefault';
import { useAIReport } from '../../../../context/AIReportContext';
import ClearIcon from '../../../ui/icons/ClearIcon';
import ChevronRightIcon from '../../../ui/icons/ChevronRight';
import ButtonText from '../../../ui/buttons/ButtonText';

export default function AIReportRightCategoryNavigator() {
	const { categoryNavigator, setCategoryNavigator } = useAIReport();
	const { elements, categoryKey, indexMap, navigatorAnchorEl } = categoryNavigator;

	const currentIndex = useMemo(() => {
		if (categoryKey) {
			return indexMap[categoryKey] ?? 0;
		}
		return 0;
	}, [categoryNavigator]);

	const updateIndex = useCallback(
		(direction: number) => {
			if (categoryKey) {
				const newIndex = (currentIndex + direction + elements.length) % elements.length;
				const newIndexMap = { ...categoryNavigator.indexMap, [categoryKey]: newIndex };
				setCategoryNavigator(prev => ({ ...prev, indexMap: newIndexMap }));
			}
		},
		[categoryNavigator],
	);

	const handleClose = useCallback(() => {
		setCategoryNavigator(prev => ({ ...prev, navigatorAnchorEl: null }));
	}, [categoryNavigator]);

	if (!categoryKey) {
		return null;
	}

	return (
		<PopoverDefault
			classes={{ paper: '' }}
			open={!!navigatorAnchorEl}
			anchorEl={navigatorAnchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<div className='px-4 py-2 flex items-center'>
				<p className='flex-1 px-4 whitespace-nowrap'>
					{currentIndex + 1} / {elements.length}
				</p>
				<ButtonText className='flex-1 px-4  min-w-min' onClick={() => updateIndex(-1)}>
					<ChevronRightIcon className='transform scale-x-[-1] text-black' />
				</ButtonText>
				<ButtonText className='flex-1 px-4  min-w-min' onClick={() => updateIndex(1)}>
					<ChevronRightIcon className='text-black' />
				</ButtonText>
				<ButtonText className='flex-1 px-4  min-w-min' onClick={handleClose}>
					<ClearIcon className='text-neutral-600' />
				</ButtonText>
			</div>
		</PopoverDefault>
	);
}
