import { useQuery } from '@tanstack/react-query';
import { FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { getCompanyInfoSummary } from '../../api/login';
import { DOMAIN_URL } from '../../constants/common';
import { queryKey } from '../../constants/queryKey';
import { useValidateForm } from '../../hooks/useValidateForm';
import ButtonLoading from '../ui/buttons/ButtonLoading';
import ButtonText from '../ui/buttons/ButtonText';
import CardBox from '../ui/containers/CardBox';
import InputText from '../ui/inputs/InputText';
import LoginCompanyTitle from './LoginCompanyTitle';
import LoginSendEmailPopup from './LoginSendEmailPopup';
import LoginSignUpButton from './LoginSignUpButton';

type Props = {
	handleLogin: (values: FormikValues) => Promise<void>;
};

export default function LoginForm({ handleLogin }: Props) {
	const { t } = useTranslation();
	const { data: company, isLoading } = useQuery(queryKey.companyInfoSummary, getCompanyInfoSummary, {
		staleTime: 60000,
	});

	const handleOuterLogin = () => {
		window.location.href = `${DOMAIN_URL}/outer_login`;
	};

	const formik = useValidateForm({
		validationSchema: {
			email: { initialValue: '', type: 'email' },
			password: { initialValue: '', type: 'string' },
		},
		onSubmit: params => {
			handleLogin(params);
		},
	});
	return (
		<CardBox className='w-full max-w-lg p-16'>
			<form onSubmit={formik.handleSubmit} className='flex flex-col gap-5'>
				{!isLoading && (
					<img
						className='max-h-14 object-contain'
						src={`${DOMAIN_URL}/files/${company?.logoLink}`}
						alt='Company Logo'
					/>
				)}
				<LoginCompanyTitle />
				<InputText id='email' labelText='Account' placeholder='' formik={formik} />
				<div>
					<InputText id='password' labelText='Password' type='password' placeholder='' formik={formik} />
					<div className='flex justify-between'>
						<LoginSignUpButton />
						<LoginSendEmailPopup text='Forgot password?' type='send_password' />
					</div>
				</div>
				<ButtonLoading type='submit' text={t('cmmn_label_log_in')} variant='contained' color='primary' />
				<ButtonText
					text={t('cmmn_guide_are_you_a_partner_law_firm_external_reviewer')}
					onClick={handleOuterLogin}
					variant='contained'
					color='primary'
				/>
			</form>
		</CardBox>
	);
}
