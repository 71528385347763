import dayjs from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import { AI_CLAUSE_CATEGORY_MAP } from '../../../constants/aiConstans';
import { DOMAIN_URL } from '../../../constants/common';
import { IArticleHistoryItem } from '../../../model/api/ai';
import { ButtonOwnPropsOverride } from '../../../model/button';
import { uniqueElementsSortedByFrequency } from '../../../util/commonUtils';
import AdeleLegalLinkHandler from '../../common/AdeleLegalLinkHandler';
import CopyToClipboard from '../../common/CopyToClipboard';
import ButtonText from '../../ui/buttons/ButtonText';
import StateChip from '../../ui/chips/StateChip';
import AspectRatioIcon from '../../ui/icons/AspectRatioIcon';
import BookmarkBorderIcon from '../../ui/icons/BookmarkBorderIcon';
import ContentCopyIcon from '../../ui/icons/ContentCopyIcon';

type Props = {
	item: IArticleHistoryItem;
	nodeId?: number;
	handleSave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	handleSlide?: () => void;
};

export default function AIArticleHistoryItem({ item, nodeId, handleSave, handleSlide }: Props) {
	const currentHistory = useMemo(() => item.history_map?.article_histories[item.index], [item]);

	const { processCategories, combineClauseContents } = useMemo(() => {
		const allCategories = currentHistory?.article?.clauses.flatMap(clause => clause.categories) ?? [];

		return {
			processCategories: allCategories ? uniqueElementsSortedByFrequency(allCategories) : [],
			combineClauseContents: currentHistory?.article?.clauses.map(clause => clause.content).join('\n') ?? '',
		};
	}, [currentHistory]);

	const handlePDFView = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		window.open(
			`${DOMAIN_URL}/contract/getscandocfile/?${currentHistory?.doc_id}`,
			'_blank',
			'noopener,noreferrer',
		);
	}, []);

	const GroupButton = useCallback(({ onClick, className, children, ...props }: ButtonOwnPropsOverride) => {
		const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.stopPropagation();
			if (onClick) {
				onClick(e);
			}
		};

		return (
			<ButtonText
				className={`shrink-0 opacity-0 group-hover:opacity-100 transition-opacity min-w-min ${className}`}
				size='small'
				onClick={handleClick}
				{...props}
			>
				{children}
			</ButtonText>
		);
	}, []);

	const changedArticleHistories = useMemo(
		() =>
			item?.history_map?.article_histories.filter(
				({ article }) => article && article.change_type !== 'NOT_CHANGED',
			),
		[item],
	);

	if (!item.history_map?.contract || !currentHistory?.article) {
		return null;
	}

	const { app_id: appId, title: appTitle } = item.history_map.contract;
	const { doc_created_time: docCreatedTime, article } = currentHistory;

	return (
		<div className='group'>
			<div className='flex justify-between items-center mb-1'>
				<div className='flex flex-wrap gap-1 py-1'>
					{processCategories.map(key => (
						<StateChip
							classes={{ root: 'border-neutral-400 text-neutral-400', label: 'text-xs' }}
							key={key}
							text={AI_CLAUSE_CATEGORY_MAP[key]}
						/>
					))}
				</div>
				<div>
					<GroupButton variant='outlined' color='primary' onClick={handlePDFView}>
						<p>계약서 PDF</p>
					</GroupButton>
					{handleSlide && (
						<GroupButton onClick={handleSlide}>
							<AspectRatioIcon className='text-neutral-600' />
						</GroupButton>
					)}
				</div>
			</div>
			<p className='text-sm text-neutral-400'>{article.title}</p>
			<div className='my-2'>
				{article.clauses.map(clause => {
					const highlightMatchClass = clause.id === nodeId ? 'bg-blue-300' : '';
					return (
						<p className={`${highlightMatchClass}`} key={clause.id}>
							{clause.content}
						</p>
					);
				})}
			</div>
			<div className='flex items-end justify-between'>
				<div className='flex flex-col'>
					<div className='flex items-center'>
						<p className='text-neutral-400'>{dayjs(docCreatedTime).format('YYYY/MM/DD')}</p>
						<div title={appTitle}>
							<AdeleLegalLinkHandler id={appId} type='CONTRACT' newTab>
								<ButtonText
									className='shrink-0 text-blue-600 text-sm font-bold underline'
									onClick={e => e.stopPropagation()}
								>
									{appTitle}
								</ButtonText>
							</AdeleLegalLinkHandler>
						</div>
					</div>
					{!!changedArticleHistories?.length && (
						<div className='flex items-center gap-2 truncate'>
							<p className='text-blue-600 text-sm'>{changedArticleHistories.length}개의 검토 수정 이력</p>
						</div>
					)}
				</div>
				<div className=' flex'>
					<GroupButton onClick={e => handleSave(e)}>
						<BookmarkBorderIcon className='text-blue-600' fontSize='small' />
					</GroupButton>
					<CopyToClipboard text={combineClauseContents}>
						<GroupButton>
							<ContentCopyIcon className='text-blue-600' fontSize='small' />
						</GroupButton>
					</CopyToClipboard>
				</div>
			</div>
		</div>
	);
}
