import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import { getSimilarClausesInArticle } from '../../../api/ai';
import { queryKey } from '../../../constants/queryKey';
import { IArticleHistoryItem } from '../../../model/api/ai';
import { extractHistoryMapAndIndex } from '../../../util/ai';
import AccordionDefault from '../../ui/accordions/AccordionDefault';
import CardBox from '../../ui/containers/CardBox';
import AIArticleHistoryItemClickHandler from './AIArticleHistoryItemClickHandler';

type Props = {
	data: IArticleHistoryItem;
};

export default function AIArticleAccordion({ data }: Props) {
	const articleId = useMemo(() => data.history_map?.article_histories[data.index].article?.id, [data]);

	const { data: searchResult } = useQuery(
		[queryKey.aiSimilarClausesInArticle, articleId],
		() => getSimilarClausesInArticle(articleId!),
		{
			staleTime: 300000,
			enabled: !!articleId,
		},
	);

	const displayNodes = useMemo(() => {
		if (!searchResult) {
			return [];
		}
		const flatAllNodes = searchResult.all_similar_clauses?.flatMap(({ result }) => result);
		return _.uniqBy(flatAllNodes, 'article_id');
	}, [searchResult]);

	if (!searchResult) {
		return <CircularProgress />;
	}

	const { all_article_histories: allArticleHistories } = searchResult;

	return (
		<AccordionDefault
			expanded
			summaryOpen={<p className='font-semibold p-2'>이 조항과 유사한 조항</p>}
			summaryClasses={{ root: 'w-full grow-0 justify-end' }}
		>
			<div className='flex flex-col gap-5'>
				{displayNodes
					.sort((a, b) => b.score.cosine - a.score.cosine)
					.map(node => {
						const item = extractHistoryMapAndIndex(node, allArticleHistories);
						return (
							<CardBox key={node.id} className='p-3'>
								<AIArticleHistoryItemClickHandler item={item} />
							</CardBox>
						);
					})}
			</div>
		</AccordionDefault>
	);
}
