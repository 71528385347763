import { PaginationProps } from '@mui/material';
import { ChangeEvent, ReactNode, memo, useCallback, useEffect, useState } from 'react';
import Pagination from '../paginations/Pagination';

/**
 * Common pagination list
 * @rowsPerPage Number of list
 * @renderPage List UI
 */
interface IProps extends PaginationProps {
	rowsPerPage?: number;
	renderPage: (page: number, rowsPerPage: number) => ReactNode;
}

function ListPagination({ count = 1, defaultPage = 1, rowsPerPage = 10, onChange, renderPage, ...props }: IProps) {
	const [totalCount] = useState(count > rowsPerPage ? Math.ceil(count / rowsPerPage) : 1);
	const [page, setPage] = useState(totalCount < defaultPage ? totalCount : defaultPage);

	const handleChange = useCallback((e: ChangeEvent<unknown>, p: number) => {
		setPage(p);
		if (typeof onChange === 'function') onChange(e, p);
	}, []);

	useEffect(() => {
		setPage(totalCount < defaultPage ? totalCount : defaultPage);
	}, [defaultPage]);

	return (
		<>
			{renderPage(page, rowsPerPage)}
			<Pagination count={totalCount} page={page} onChange={handleChange} {...props} />
		</>
	);
}

export default memo(ListPagination);
