import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loginSendEmail } from '../../api/login';
import { useValidateForm } from '../../hooks/useValidateForm';
import ButtonLoading from '../ui/buttons/ButtonLoading';
import ButtonText from '../ui/buttons/ButtonText';
import InputText from '../ui/inputs/InputText';
import PopupDefault from '../ui/popups/PopupDefault';

type Props = {
	text: string;
	type: string;
};
export default function LoginSendEmailPopup({ text, type }: Props) {
	const [open, setOpen] = useState(false);
	const [nestedOpen, setNestedOpen] = useState(false);
	const [result, setResult] = useState<React.ReactElement>(<div />);
	const { t } = useTranslation();

	const resultContent = (message: string, onConfirm: () => void) => (
		<div className='flex flex-col p-3 gap-5 text-center'>
			<div>{message}</div>
			<ButtonText text={t('MSG_ID_104')} variant='contained' color='primary' onClick={onConfirm} />
		</div>
	);

	const formik = useValidateForm({
		validationSchema: {
			email: { initialValue: '', type: 'email' },
		},
		onSubmit: values => {
			loginSendEmail({ email: values.email, type })
				.then(msg => {
					setResult(resultContent(msg, () => setOpen(false)));
				})
				.catch(error => {
					setResult(resultContent(error.response.data.msg, () => setNestedOpen(false)));
				})
				.finally(() => {
					setNestedOpen(true);
				});
		},
	});

	const guideText =
		type === 'send_register'
			? [
					t('cmmn_guide_enter_your_registered_email_id'),
					t('cmmn_guide_a_new_registration_link_will_be_sent_to_this_email'),
			  ]
			: [
					t('cmmn_guide_enter_your_registered_email_id'),
					t('cmmn_guide_a_password_change_link_will_be_sent_to_that_email'),
			  ];

	return (
		<>
			<ButtonText text={text} size='small' color='primary' onClick={() => setOpen(true)} />
			<PopupDefault open={open} onClose={() => setOpen(false)} size='xsmall'>
				<>
					<div className='my-6'>
						<p className='text-m text-center text-neutral-600'>{guideText[0]}</p>
						<p className='text-m text-center text-neutral-600'>{guideText[1]}</p>
					</div>
					<form onSubmit={formik.handleSubmit} className='flex flex-col gap-5'>
						<InputText id='email' labelText='Email' placeholder='Email address' formik={formik} />
						<ButtonLoading
							type='submit'
							text={t('label_forwarding')}
							variant='contained'
							color='primary'
							disabled={!formik.values.email || !!formik.errors.email}
						/>
						<PopupDefault size='xsmall' open={nestedOpen} onClose={() => setNestedOpen(false)}>
							{result}
						</PopupDefault>
					</form>
				</>
			</PopupDefault>
		</>
	);
}
