import { useTranslation } from 'react-i18next';
import { DashboardWorkStatus } from '../../../../../model/api/dashboard';

type Props = {
	item: DashboardWorkStatus;
};

export default function ListItemActionDashboard({ item }: Props) {
	const { t } = useTranslation();
	const { history_type: workType, action_user: actionUser, action_text: actionText } = item;

	const displayActionWithUser = (
		<>
			<strong>{actionUser}</strong>
			{t('cmmn_label_sir')} {actionText}
		</>
	);

	const displayOnlyAction = <strong>[{actionText}]</strong>;

	const actionTextMap = {
		CONTRACT: displayActionWithUser,
		CONTRACT_ETC: displayActionWithUser,
		CONTRACT_SIGN_SEAL_PROCESS: displayActionWithUser,
		COUNSEL: displayActionWithUser,
		LAWSUIT: displayOnlyAction,
		DOC_RECEIVED: displayOnlyAction,
		STAMP: displayActionWithUser,
		DISPUTE: displayOnlyAction,
	};

	return actionTextMap[workType];
}
