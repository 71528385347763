import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import ButtonText from '../../ui/buttons/ButtonText';
import ClearIcon from '../../ui/icons/ClearIcon';
import PopupSideDrawer from '../../ui/popups/PopupSideDrawer';
import { getReportStatusByUser } from '../../../api/ai';
import { queryKey } from '../../../constants/queryKey';
import AINotificationItem from './AINotificationItem';
import { useUser } from '../../../context/UserContext';

export default function AINotification() {
	const [open, setOpen] = useState(false);
	const { permission } = useUser();

	const { data } = useQuery([queryKey.aiNotification], getReportStatusByUser, {
		staleTime: Infinity,
		refetchInterval: 60000,
		enabled: permission.ACTIVATE_BUPTLE_AI,
	});

	const rightPosition = useMemo(() => {
		const doneExists = data?.some(({ status }) => status === 'DONE') ?? false;
		return doneExists ? 'right-0' : '-right-14';
	}, [data]);

	if (!permission.ACTIVATE_BUPTLE_AI) {
		return null;
	}

	return (
		<>
			<ButtonText
				className={`fixed top-40 -right-14 cursor-pointer bg-white p-3 shadow-md rounded-l-full z-20 ${rightPosition}`}
				onClick={() => setOpen(true)}
			>
				<div className='flex items-center gap-1'>
					<div className={`w-4 h-4 rounded-full ${data?.length ? 'bg-blue-600' : 'bg-neutral-300'}`} />
					<p className='font-semibold'>법틀AI</p>
				</div>
			</ButtonText>
			<PopupSideDrawer
				contentClass='right-0 overflow-y-auto scrollbar'
				open={open && !!data?.length}
				onClose={() => setOpen(false)}
			>
				<div className='flex flex-col gap-2 px-5 py-3 scrollbar overflow-y-auto'>
					<div className='flex items-center justify-between'>
						<div className='flex items-center gap-1'>
							<div className='w-4 h-4 bg-blue-600 rounded-full' />
							<p className='font-semibold'>법틀AI</p>
						</div>
						<ButtonText className='min-w-min' onClick={() => setOpen(false)}>
							<ClearIcon className='text-neutral-600' />
						</ButtonText>
					</div>
					<div className='flex flex-col gap-2'>
						{data?.map(item => (
							<div className='border border-neutral-300 rounded-xl p-3' key={item.contract.id}>
								<AINotificationItem item={item} setParentOpen={setOpen} />
							</div>
						))}
					</div>
				</div>
			</PopupSideDrawer>
		</>
	);
}
