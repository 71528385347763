import React, { SyntheticEvent, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import TabSnb from '../../../ui/tabs/TabSnb';
import { queryKey } from '../../../../constants/queryKey';
import { getUserArticleFavoriteList } from '../../../../api/ai';
import AIArticleFavoriteListCreateButton from './AIArticleFavoriteListCreateButton';

export default function AIArticleFavoriteListTab() {
	const [tab, setTab] = useState<number>(0);
	const navigate = useNavigate();

	const { data } = useQuery([queryKey.aiArticleFavoriteList], getUserArticleFavoriteList);

	if (!data) {
		return null;
	}

	const handleTabChange = (_: SyntheticEvent, v: number) => {
		setTab(v);
		const selectedId = v === 0 ? '' : data[v - 1].id;
		navigate(`?id=${selectedId}`);
	};

	return (
		<div>
			<TabSnb
				value={tab}
				onChange={handleTabChange}
				tabs={[
					{
						id: 0,
						label: '전체',
					},
					...data.map(({ id, name }) => ({ id, label: name })),
				]}
			/>
			<div className='flex'>
				<AIArticleFavoriteListCreateButton text='+ 새 목록' />
			</div>
		</div>
	);
}
